import React from 'react';
import { injectIntl } from 'react-intl';

const TargetAudience = ({ intl, target_audience: targetAudience = [] }) => {
  const t = intl.messages;
  return (
    targetAudience.length < 1 ? null :
    <div className='actionTargetAudience'>
      <div className='actionTargetAudience-title'>{ t.target_audience }</div>
        {targetAudience.map((item, index) => (
          <div className="actionTargetAudience-item">
            { t[`target_audience_${item}`]}
          </div>
        ))
        }
    </div>
  );
};

export default injectIntl(TargetAudience);
