import { toDbDate, fromDb } from 'utils/date';
import moment from 'moment';

export const indicatorFromFormToDb = (form) => {
  const {
    id,
    name,
    unit,
    initial_value,
    start_date,
    current_value,
    target_value,
    end_date,
    ask_participants,
    aggregate,
  } = form;

  return {
    id,
    name,
    unit,
    initial_value,
    start_date: start_date ? toDbDate(start_date.utc()) : undefined,
    current_value,
    target_value,
    end_date: end_date ? toDbDate(end_date.utc()) : undefined,
    ask_participants,
    aggregate: ask_participants ? aggregate : null,
  };
};

export const indicatorFromDbToForm = (db = {}) => {
  const {
    id,
    name,
    unit,
    initial_value,
    start_date,
    current_value,
    target_value,
    end_date,
    ask_participants,
    aggregate,
  } = db;

  return {
    id,
    name,
    unit,
    initial_value,
    start_date: start_date ?  moment(fromDb(start_date)) : undefined,
    current_value,
    target_value,
    end_date: end_date ?  moment(fromDb(end_date)) : undefined,
    ask_participants,
    aggregate
  }
};
