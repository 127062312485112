const fullValidation = (t, formMode) => ({
  name,
  unit,
  initial_value,
  start_date,
  current_value,
  target_value,
  end_date,
  ask_participants,
  aggregate,
}) => {
  let errors = {};

  if(!name) {
    errors.name = t.form_error_required;
    errors._firstError = errors._firstError || 'name';
  }

  if(!unit) {
    errors.unit = t.form_error_required;
    errors._firstError = errors._firstError || 'unit';
  }

  if(initial_value === undefined) {
    errors.initial_value = t.form_error_required;
    errors._firstError = errors._firstError || 'initial_value';
  }

  if(target_value && !start_date) {
    errors.start_date = t.form_error_required;
    errors._firstError = errors._firstError || 'start_date';
  }

  if (formMode === 'edit') {
    if (!current_value) {
      errors.current_value = t.form_error_required;
      errors._firstError = errors._firstError || 'current_value';
    }
  }

  if(target_value && !end_date) {
    errors.end_date = t.form_error_required;
    errors._firstError = errors._firstError || 'end_date';
  }

  if(ask_participants && !aggregate) {
    errors.aggregate = t.form_error_required;
    errors._firstError = errors._firstError || 'aggregate';
  }

  return errors;
}

export default fullValidation;
