import React from 'react';
import {injectIntl} from 'react-intl';

import {
  Card,
  Form,
  InputNumber,
  Switch,
} from 'antd';

const Restrictions = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  showError,
  getValue,
  handleChange,
  formMode,
}) => {
  const t = intl.messages;

  return (
    <Card className="InitiativeForm-card" title={t.restrictions}>
      <Form.Item label={t.max_participants} colon={false} hasFeedback="hasFeedback" validateStatus={showError('max_participants')
          ? 'error'
          : ''} help={showError('max_participants')} labelCol={{
          xs: 24,
          sm: 18,
          md: 12
        }} wrapperCol={{
          xs: 24,
          sm: 6
        }}>
        <InputNumber name="max_participants" value={getValue('max_participants') > 0
            ? getValue('max_participants')
            : ''} min={0} onChange={handleChange('max_participants')} placeholder={t.unlimited}/>
      </Form.Item>
      <Form.Item label={t.minors_allowed} colon={false} hasFeedback="hasFeedback" validateStatus={showError('minors_allowed')
          ? 'error'
          : ''} help={showError('minors_allowed')} labelCol={{
          xs: 24,
          sm: 18,
          md: 12
        }} wrapperCol={{
          xs: 24,
          sm: 6
        }}>
        <Switch checkedChildren={t.yes} unCheckedChildren={t.no} checked={getValue('minors_allowed')} onChange={handleChange('minors_allowed')}/>
      </Form.Item>
    </Card>
  );
}

export default injectIntl(Restrictions);
