import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Highlighter from 'react-highlight-words';
import {
  Card,
  Popover,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import colors from 'colors';
import SdgMiniLogo from 'components/SdgMiniLogo';
import DonationProgress from 'components/DonationProgress';
import InitiativeTypeIcon from 'components/InitiativeTypeIcon';
import InitiativeTypeString from 'components/InitiativeTypeString';
import { toDateArray } from 'utils/date';
import { usePermissionList } from 'components/PermissionSwitch';
import Avatar from 'components/Avatar';
import CustomTag from 'components/CustomTag';
import InitiativeCardCover from './InitiativeCardCover';

import { ReactComponent as ParticipantIcon } from 'containers/Nav/images/volunteer.svg';

import './style.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InitiativeCardSubmitFooter from './InitiativeCardSubmitFooter';

const TYPES_WITHOUT_ADDRESS = ['donation'];
const CARD_ORIGIN = {collaboration: 'collaboration', initiative: 'initiative', proposal: 'proposal'}
const CANCELED_STATUSES = new Set([ 'suspended', 'canceled' ]);

const InitiativeCard = ({
  organization,
  history,
  type,
  subtype,
  intl,
  sdgs = [],
  status,
  title,
  slug,
  cover,
  start_time,
  end_time,
  address,
  organization_name,
  organization_slug,
  organization_logo_small,
  organization_score = "0.00",
  organization_score_count = 0,
  categories = [],
  org_participant_count,
  participant_count,
  participation_pending_count = 0,
  collaboration_pending_count = 0,
  collaborative,
  collaboration_status,
  collaborators = [],
  donation_params,
  contributos_count,
  contribution_total = 0,
  contribution_validated = 0,
  activeNow = false,
  searchText = '',
  registration_end_time,
  submitter,
  rejectProposal,
  setInitiativeToReject,
  requestCollaboration,
  from,
  reject_reason,
  is_in_person,
  is_remote,
  isPinned,
  max_participants,
  tags = [],
  points,
  program,
}) => {
  const push = history.push;
  const isOwner = organization.slug === organization_slug;
  const isCollaborative = collaborative || collaborators.length > 0;
  const permissionList = usePermissionList();

  const currency = donation_params && donation_params.currency ? donation_params.currency : organization.config.currency;
  const target_amount = donation_params ? donation_params.target_amount : null;

  const isCanceled = CANCELED_STATUSES.has(status);
  const isParticipantionComplete = max_participants > 0 && (parseInt(participant_count) >= max_participants);
  const dateArray = toDateArray(start_time, end_time, intl);

  const startDay = useMemo(() => (
    new Date(start_time)
  ), [start_time]);

  const endDay = useMemo(() => (
    new Date(end_time)
  ), [end_time]);

  const isOneDay = useMemo(() => (
    startDay.getFullYear() === endDay.getFullYear()) && (startDay.getMonth() === endDay.getMonth()) && (startDay.getDate() === endDay.getDate()
  ), [endDay, startDay]);

  const handleClickCard = useCallback(() => {
    if (from === CARD_ORIGIN.initiative && status === 'draft') {
      push(`/initiative/${slug}/edit`)
    } else if(from === CARD_ORIGIN.initiative) {
      push(`/initiative/${slug}/details`)
    } else if(from === CARD_ORIGIN.proposal && status === 'open') {
      push(`/proposal/${slug}/details`)
    } else if(from === CARD_ORIGIN.proposal && status === 'proposed') {
      push(`/proposal/${slug}/edit`)
    } else if(from === CARD_ORIGIN.collaboration) {
      push(`/collaboration/${slug}/details`)
    }
  }, [
    from,
    push,
    slug,
    status
  ]);

  const hasPendingTasks = useMemo(() => (
    (from !== CARD_ORIGIN.proposal && permissionList.has('can_manage_participation') && participation_pending_count > 0)
      || (isCollaborative && isOwner && permissionList.has('can_manage_collaborations') && collaboration_pending_count > 0)
  ), [
    collaboration_pending_count,
    from,
    isCollaborative,
    isOwner,
    participation_pending_count,
    permissionList
  ]);

  const hasSubmitFooter = useMemo(() => (
    (!!submitter && from !== CARD_ORIGIN.initiative && ((status === 'rejected' || status === 'open') || (!isCanceled)))
      || (isCollaborative && !submitter && from !== CARD_ORIGIN.initiative)
  ), [
    isCollaborative,
    submitter,
    from,
    status,
    isCanceled
  ]);

  const hasPoints = useMemo(() => {
    const orgFeatures = organization?.features;
    if(!orgFeatures) {
      return false;
    }

    return orgFeatures.includes('points') && points > 0;
  }, [
    organization,
    points
  ]);

  const hasProgram = useMemo(() => {
    const orgFeatures = organization?.features;
    if(!orgFeatures) {
      return false;
    }

    return orgFeatures.includes('program') && program;
  }, [
    organization,
    program,
  ]);

  return (
    <Card
      size="small"
      className="InitiativeCard"
      cover={
        <InitiativeCardCover
          cover={cover}
          dateArray={dateArray}
          end_time={end_time}
          handleClickCard={handleClickCard}
          hasPendingTasks={hasPendingTasks}
          isCanceled={isCanceled}
          isCollaborative={isCollaborative}
          isFromInitiative={from === CARD_ORIGIN.initiative}
          isOneDay={isOneDay}
          organization_logo_small={organization_logo_small}
          organization_name={organization_name}
          registration_end_time={registration_end_time}
          start_time={start_time}
          submitter={submitter}
          isPinned={isPinned}
          type={type}
          isParticipantionComplete={isParticipantionComplete}
          hasPoints={hasPoints}
          hasProgram={hasProgram}
          points={points}
          program={program}
        />
      }
      >
      <div className={`InitiativeCard-body ${hasSubmitFooter ? 'hasSubmitFooter' : ''}`}>
        <div className='InitiativeCard-information'  onClick={handleClickCard}>
          <span className={`InitiativeCard-title ${!address ? 'notAddress' : ''}`}>
            { !searchText
              ? <span title={title}>{title}</span>
              : <Highlighter
                  title={title}
                  highlightStyle={{ backgroundColor: colors['@text-selection-bg'], padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={title}
                />
            }
          </span>

          { tags && tags.length > 0 && (
            <div className="InitiativeCard-tags">
              { tags.map(tag => (
                <CustomTag
                  className={`InitiativeCard-tag`}
                  key={tag}
                  name={tag}
                />
              )) }
            </div>
          )}

          { !TYPES_WITHOUT_ADDRESS.includes(type) && (
            <React.Fragment>
              { address && (
                <span className='InitiativeCard-address'>
                  { !searchText ? address :
                    <Highlighter
                      highlightStyle={{ backgroundColor: colors['@text-selection-bg'], padding: 0 }}
                      searchWords={[searchText]}
                      autoEscape
                      textToHighlight={address}
                    />
                  }
                </span>
              )}
              { isOneDay && (
                <span
                  className='InitiativeCard-time'
                >
                  {`${startDay.toTimeString().split(' ')[0].substring(0,5)} - ${endDay.toTimeString().split(' ')[0].substring(0,5)} ${startDay.toTimeString().split(' ')[1]}`}
                </span>
              )}
            </React.Fragment>
          )}
          { type === 'donation' && (
            <section className='InitiativeCard-donationInfo'>
              <DonationProgress
                total={contribution_total}
                validated={contribution_validated}
                limit={target_amount}
                currency={currency}
                active={activeNow}
                mode={'card'}
              />
            </section>
          )}
          { type === 'volunteering' && (
            <section className={`InitiativeCard-participant-count ${hasSubmitFooter ? 'hasSubmitFooter' : ''}`}>
              <ParticipantIcon/>
              <span>{ typeof org_participant_count === 'undefined' ? participant_count : org_participant_count }</span>
            </section>
          )}
          <section className={`InitiativeCard-sdgs ${hasSubmitFooter ? 'hasSubmitFooter' : ''}`}>
            {sdgs.slice(0, 3).map(sdg =>
              <SdgMiniLogo key={sdg} sdg={sdg}/>
            )}
            {sdgs.length > 3 && (
              <Popover
                placement="bottom"
                className="InitiativeCard-sdgs-popover"
                content={
                  <div className="InitiativeCard-sdgs-popover-content">
                    {sdgs.slice(3).map(sdg =>
                      <SdgMiniLogo key={sdg} sdg={sdg}/>
                    )}
                  </div>
                }
                trigger="hover"
              >
                <MoreOutlined onClick={e => e.stopPropagation()} />
              </Popover>
            )}
          </section>
          <section className={`InitiativeCard-type ${hasSubmitFooter ? 'hasSubmitFooter' : ''}`}>
            <span className='InitiativeCard-type-text'>
              <InitiativeTypeString
                type={type}
                subtype={subtype}
              />
              { is_remote ? ` ${intl.formatMessage({ id: `initiative_remote` })}` : '' }
            </span>
            <InitiativeTypeIcon
              type={type}
              subtype={subtype}
              className='InitiativeCard-type-img'
            />
            { !TYPES_WITHOUT_ADDRESS.includes(type) && (
              <>
                { is_in_person && (
                  <FontAwesomeIcon
                    className="InitiativeCard-type-img"
                    icon="map-marked-alt"
                  />
                )}
                { is_remote && (
                  <FontAwesomeIcon
                    className="InitiativeCard-type-img"
                    icon="wifi"
                  />
                )}
              </>
            )}
            {isCollaborative && organization_name && organization.slug !== organization_slug &&  (
              <Avatar
                className='InitiativeCard-type-img InitiativeCard-collaborativeFooter'
                size={15}
                name={organization_name}
                src={organization_logo_small}
              />
            )}
          </section>
        </div>
        {hasSubmitFooter && (
          <InitiativeCardSubmitFooter
            collaboration_status={collaboration_status}
            isCanceled={isCanceled}
            isCollaborative={isCollaborative}
            rejectProposal={rejectProposal}
            reject_reason={reject_reason}
            requestCollaboration={requestCollaboration}
            setInitiativeToReject={setInitiativeToReject}
            slug={slug}
            status={status}
            submitter={submitter}
            registration_end_time={registration_end_time}
          />
        )}
      </div>
    </Card>
  );
};

export default injectIntl(withRouter(InitiativeCard));
