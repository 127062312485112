import React from 'react';
import { injectIntl } from 'react-intl';
import Submitter from '../cards/Submitter';
import Collaborators from '../cards/Collaborators';
import Organization from '../cards/Organization';

const Organizer = ({
  intl,
  collaborative,
  submitter,
  collaborators,
  organization_name,
  organization_logo_small,
}) => {

  const t = intl.messages;
  const organizationData = {
    organization_name,
    organization_logo_small
  }

  return (
    <div className='actionOrganizer'>
      <div className='actionOrganizer-title'>{t.organization_role_organizer}</div>
      <Organization {...organizationData} />
      { !collaborative && (collaborators || []).length === 0 ? null :
        <Collaborators collaborators={collaborators} />
      }
      { !submitter ? null :
        <Submitter submitter={submitter} />
      }
    </div>
  )}

export default injectIntl(Organizer);
