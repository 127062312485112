import React, {useMemo, useState, useRef, useCallback} from 'react'
import { Button, Empty, Typography, Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { useHotkeys } from 'react-hotkeys-hook';
import ItemList from 'components/ItemList'
import './style.less'
import { ReactComponent as LockLogo } from './images/icon-locked.svg';

const { Paragraph } = Typography;

const ColumnMenu = ({
  intl,
  data,
  itemTitle,
  itemContent,
  itemSubContent,
  itemType,
  withBulk,
  itemDetail,
  requestSelected,
  organization,
  sortContent,
  removeItem,
  editItem,
  dataTitle,
  dataSubTitle
}) => {
  // Traductions
  const t = intl.messages;

  // Scrolleable items
  const subItemsList = useRef()

  // Focus search field on Ctrl+F
  const searchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if(searchInputRef.current.focus) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  }, [ searchInputRef ]);

  const confirmRemove = useCallback((itemSlug) => {
    Modal.confirm({
      title: t.courses_close_confirmation_title,
      content: t.courses_close_confirmation_text,
      okText: t.close,
      okType: 'danger',
      cancelText: t.cancel,
      onOk() {
        removeItem(itemSlug)
      },
      onCancel() {},
    });
  },[t, removeItem])

  const handleEdit = useCallback((name, code) => {
    const codeRepeated = data.filter((item) => item.code === code).length > 0
    if(codeRepeated) return Modal.error({ title: t.course_error, content: t.course_error_500 })
    editItem(itemDetail.slug, name, code)
  },[editItem, itemDetail, data, t])

  const renderItemSelected = useMemo(() => (
    <React.Fragment>
      { !itemDetail ? null :
        <React.Fragment>
          <section className='ColumnMenu-itemSelected-info'>
            { !itemDetail[dataTitle.title[1]] ? null : itemDetail.status === 'closed'
              ? <span className='ColumnMenu-itemSelected-subTitle'>{itemDetail[dataTitle.title[1]]}</span>
              : <Paragraph className='ColumnMenu-itemSelected-subTitle' editable={{onChange: (newSubTitle) => handleEdit(itemDetail.name, newSubTitle)}}>{itemDetail[dataTitle.title[1]]}</Paragraph>
            }
            { itemDetail.status === 'closed'
              ? <span className='ColumnMenu-itemSelected-title'>{itemDetail ? itemDetail.name : ''}</span>
              : <Paragraph className='ColumnMenu-itemSelected-title' editable={{onChange: (newTitle) => handleEdit(newTitle, itemDetail[dataTitle.title[1]])}}>{itemDetail ? itemDetail.name : ''}</Paragraph>
            }
          </section>
          { itemDetail.status === 'closed'
            ? <Button className='ColumnMenu-itemSelected-closedButton' type='default' disabled={true}><LockLogo/>{t.courses_closed}</Button>
            : <Button onClick={() => confirmRemove(itemDetail.slug)} type="default">{ t.courses_close }</Button>

          }
        </React.Fragment>
      }
    </React.Fragment>
  ),[dataTitle.title, itemDetail, confirmRemove, t, handleEdit])

  // Content state
  const [contentActive, setContentActive] = useState(itemContent[0].name)

  const renderLeftSide = useMemo(() => (
    <ItemList
      withBulk={withBulk.left}
      items={data}
      itemTitle={itemTitle}
      itemContent={itemContent}
      itemType={itemType}
      onClick={requestSelected}
      organization={organization}
      titles={dataTitle}
      itemDetail={itemDetail}
    />
  ),[itemContent, itemTitle, withBulk, itemType, data, requestSelected, organization, dataTitle, itemDetail])

  const renderSubitems = useMemo(() => (
    <ItemList
      highlightActive={false}
      withBulk={withBulk.right}
      items={itemContent.filter((content) => content.name === contentActive)[0].items}
      itemTitle={itemTitle}
      itemContent={itemSubContent}
      itemType={itemType}
      organization={organization}
      activeSubCategory={contentActive}
      itemDetail={itemDetail}
      withSearch={true}
      sortContent={sortContent}
      scrollControl={subItemsList}
      titles={dataSubTitle[contentActive]}
    />
  ),[withBulk, itemContent, itemTitle, itemSubContent, itemType, organization, contentActive, itemDetail, sortContent, dataSubTitle])

  const renderRightSide = useMemo(() => (
    <React.Fragment>
      { !itemContent || itemContent.length < 1 ? null :
        <React.Fragment>
          <section className='ColumnMenu-itemSelected'>
            {renderItemSelected}
          </section>
          <section className='ColumnMenu-contentTabs'>
            { itemContent.map(content => (
              <span className={`ColumnMenu-tab ${content.name === contentActive ? 'active' : ''}`} onClick={() => setContentActive(content.name)}>{(t[content.name] || content.name)}</span>
            ))}
          </section>
          <section className='ColumnMenu-subItemsList' ref={subItemsList}>
            { itemContent.map(content => (
              <section className={`ColumnMenu-subItemsList-tab ${content.name === contentActive ? 'active' : ''}`}>
                {renderSubitems}
              </section>
            ))}
          </section>
        </React.Fragment>
      }
    </React.Fragment>
  ),[itemContent, contentActive, t, renderSubitems, renderItemSelected])

  const renderColumnMenu = useMemo(() => (
    <section className='ColumnMenu'>
      <section className='ColumnMenu-leftSide'>
        {renderLeftSide}
      </section>
      <section className='ColumnMenu-rightSide'>
        {itemDetail ? renderRightSide : <Empty/>}
      </section>
    </section>
  ),[renderLeftSide, renderRightSide, itemDetail])

  return renderColumnMenu
}

export default injectIntl(ColumnMenu)
