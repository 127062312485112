import {
  parseISO,
  subWeeks,
  subMonths,
  isSameDay,
  differenceInHours,
  differenceInDays,
  getDate,
  format,
  isAfter,
  isBefore,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  getISODay,
  getHours,
  formatDistanceToNow,
  isYesterday,
  isToday,
} from 'date-fns';

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";

const MONTH_SHORT = 'MMM';
const MONTH_YEAR = 'MMM YYYY';
const DATE_ONLY = 'D MMMM YYYY';
const DATE_TIME = 'D MMMM YYYY HH:mm';
const TIME_ONLY = 'HH:mm';
const SHORT_FORMAT = 'D MMM HH:mm';
const LONG_FORMAT = 'ddd, D MMMM H:mm[h]';
const SHORT_DATE_ONLY_FORMAT = 'MMM. D';
const DB_FORMAT = 'YYYY-MM-DD[T]HH:mm:SS.sss[Z]';
const DB_FORMAT_DATE = 'YYYY-MM-DD';
const SLASH_DATE_FORMAT = 'DD/MM/YYYY';

// TODO: Make this configurable?
const BEGINNING_OF_WORK_DAY = 9;
const END_OF_WORK_DAY = 16; // Notice 17:00 is not working hours, but 9:00 is

const getLocale = (intl) => ((intl && intl.formats) || {}).dateFns;

export const isPassed = (time) => {
  time = new Date(time)
  return time.getTime() - new Date().getTime() < 0
}

export const fromDb = (str) => typeof str === 'string' ? parseISO(str) : str;

export const totalDays = (start_time, end_time) => {
  return 1 + differenceInDays(legacyParse(end_time), legacyParse(start_time));
}

export const totalCalendarDays = (start_time, end_time) => {
  return differenceInCalendarDays(legacyParse(end_time), legacyParse(start_time));
}

export const daysLeft = (time) => {
  return differenceInDays(legacyParse(time), legacyParse(new Date()));
}

export const timeAgo = (start_time, intl) => {
  const start = fromDb(start_time);
  return formatDistanceToNow(legacyParse(start), { locale: getLocale(intl) });
}

export const formatDateOrTime = (time, intl) => {
  const d = fromDb(time);

  if (isToday(legacyParse(time))) {
    return format(legacyParse(d), convertTokens(TIME_ONLY), { locale: getLocale(intl) });
  }
  if (isYesterday(legacyParse(time))) {
    return intl.formatMessage({ id: `yesterday` });
  }
  return format(
    legacyParse(d),
    convertTokens(SLASH_DATE_FORMAT),
    { locale: getLocale(intl) }
  );
};

export const toDateArray = (start_time, end_time, intl) => {
  const start = fromDb(start_time);
  const end = fromDb(end_time);

  if(isSameDay(legacyParse(start), legacyParse(end))) {
    return [{
      day: getDate(legacyParse(start)),
      month: format(
        legacyParse(start),
        convertTokens(MONTH_SHORT),
        { locale: getLocale(intl) }
      ),
    }];
  } else {
    return [{
      day: getDate(legacyParse(start)),
      month: format(
        legacyParse(start),
        convertTokens(MONTH_SHORT),
        { locale: getLocale(intl) }
      ),
    },{
      day: getDate(legacyParse(end)),
      month: format(legacyParse(end), convertTokens(MONTH_SHORT), { locale: getLocale(intl) }),
    }];
  }
};

export const hoursPerDay = (start_time, end_time, hours_per_day = 8) => {
  const start = fromDb(start_time);
  const end = fromDb(end_time);

  if(isSameDay(legacyParse(start), legacyParse(end))) {
    return [differenceInHours(legacyParse(end), legacyParse(start)), false];
  } else if(hours_per_day > 0) {
    return [hours_per_day, true];
  } else {
    return [];
  }
};

export const formatDateAndTime = (time, intl) => {
  const d= fromDb(time);

  return {
    date: format(legacyParse(d), convertTokens(DATE_ONLY), { locale: getLocale(intl) }),
    time: format(legacyParse(d), convertTokens(TIME_ONLY), { locale: getLocale(intl) })
  };
};

export const formatShort = (time, intl) => {
  const d= fromDb(time);

  return format(legacyParse(d), convertTokens(SHORT_FORMAT), { locale: getLocale(intl) });
};

export const formatLong = (time, intl) => {
  const d= fromDb(time);

  return format(legacyParse(d), convertTokens(LONG_FORMAT), { locale: getLocale(intl) });
};

export const formatDateLong = (time, intl) => {
  if(!time) return null;
  const d= fromDb(time);
  return format(legacyParse(d), convertTokens(DATE_ONLY), { locale: getLocale(intl) });
};

export const formatDateTimeLong = (time, intl) => {
  const d= fromDb(time);

  return format(legacyParse(d), convertTokens(DATE_TIME), { locale: getLocale(intl) });
};

export const formatDateShort = (time, intl) => {
  const d= fromDb(time);

  return format(
    legacyParse(d),
    convertTokens(SHORT_DATE_ONLY_FORMAT),
    { locale: getLocale(intl) }
  );
};

export const isAfterNow = (time) => {
  const d = fromDb(time);

  return isAfter(legacyParse(d), legacyParse(new Date()));
};

export const isBeforeNow = (time) => {
  const d = fromDb(time);

  return isBefore(legacyParse(d), legacyParse(new Date()));
};

export const toDb = (d) => format(legacyParse(d), convertTokens(DB_FORMAT));

export const toDbDate = (date) => format(legacyParse(date), convertTokens(DB_FORMAT_DATE));

export const weeksAgo = (weeks) => subWeeks(legacyParse(Date.now()), weeks);

export const monthsAgo = (months) => subMonths(legacyParse(Date.now()), months);

export const formatMonthYear = (time, intl) => {
  const d= fromDb(time);

  return format(legacyParse(d), convertTokens(MONTH_YEAR), { locale: getLocale(intl) });
};

export const formatDb = (time) => {
  const d= fromDb(time);
  return toDb(d);
};

export const rangeOverlap = ([start_a, end_a], [start_b, end_b]) =>
  areIntervalsOverlapping(
    { start: fromDb(start_a), end: fromDb(end_a) },
    { start: fromDb(start_b), end: fromDb(end_b) }
  );

export const guessGroupBy = (start_date, end_date) => {
  let result = 'day';
  const start = new Date(start_date);
  const end = new Date(end_date);

  const diff = differenceInDays(legacyParse(end), legacyParse(start));

  if(diff && diff > 14) {
    result = 'week';
  }

  if(diff && diff > 62) {
    result = 'month';
  }

  if(diff && diff > 730) {
    result = 'year';
  }

  return result;
};

export const guessIsWorkingHours = (start, end) => {
  const sameDay = isSameDay(legacyParse(start), legacyParse(end));

  if(sameDay) {
    if(getISODay(legacyParse(start)) >= 6) {
      return false;
    }

    if(
      (
        getHours(legacyParse(start)) < BEGINNING_OF_WORK_DAY &&
        getHours(legacyParse(end)) < BEGINNING_OF_WORK_DAY
      ) ||
      (
        getHours(legacyParse(start)) > END_OF_WORK_DAY &&
        getHours(legacyParse(end)) > END_OF_WORK_DAY
      )
    ) {
      return false;
    }
    return true;
  }

  // TODO: implement multi-day overlap with hours
  return true;

};

export const sameDayHelper = (_start, _end) => {
  const start = fromDb(_start);
  const end = fromDb(_end);

  const sameDay = isSameDay(legacyParse(start), legacyParse(end));
  const diffHours = differenceInHours(legacyParse(start), legacyParse(end));

  return {
    sameDay,
    diffHours,
  };
};
