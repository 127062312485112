// TODO: I hate this
import moment from 'moment';

import { dateAndTime } from './converters';
import {
  guessIsWorkingHours,
} from 'utils/date';

const DEFAULT_HOURS_PER_DAY = 8;

export const BEGINNING_OF_TIME = '1970-01-01 00:00:00.000';

export const getDefaultStartTime = base => moment(base)
  .set('hour', 10)
  .set('minute', 0)
  .set('second', 0)
  .set('millisecond', 0);

export const getDefaultEndTime = base => moment(base)
  .set('hour', 18)
  .set('minute', 0)
  .set('second', 0)
  .set('millisecond', 0);

export const getDefaultRegistrationEndTime = base => moment(base)
  .set('hour', 23)
  .set('minute', 59)
  .set('second', 0)
  .set('millisecond', 0);

export const allMinutes = new Array(60).fill(0).map((_, i) => i);

// Please please please let's try not to use this....
export const getNow = () => moment();

export const isSameDay = (a, b) => {
  return moment(a).isSame(b, 'day');
}

export const guessHoursPerDay = (start_date, start_time, end_date, end_time, hours_per_day) => {
  if(isSameDay(start_date, end_date)) {
    const start = dateAndTime(start_date, start_time);
    const end = dateAndTime(end_date, end_time);
    return Math.ceil(moment(end).diff(start, 'hours', true));
  } else {
    return hours_per_day || DEFAULT_HOURS_PER_DAY;
  }
};

export const guessWorkingHours = (start_date, start_time, end_date, end_time) => {
  const start = dateAndTime(start_date, start_time);
  const end = dateAndTime(end_date, end_time);

  return guessIsWorkingHours(start, end);
};

export const todayAtHourAndMinute = (hour, minute) => {
  return moment(moment().hour(hour || 0).minute(minute).second(0).millisecond(0));
}

export const isSameHour = (a, b) => {
  return moment(a).isSame(dateAndTime(a, b), 'hour');
}

export const isAnyDayOutsideRange = (min, max) => (date) => {
  return moment(date).isBefore(min, 'day') || moment(date).isAfter(max, 'day')
};

export const isAnyDayAfter = (reference) => (date) => {
  return moment(date).isAfter(reference, 'day');
};

export const isAnyDayBefore = (reference) => (date) => {
  return moment(date).isBefore(reference, 'day');
};

export const isAnyHourBefore = (reference) => (date) => {
  return moment(date).isBefore(dateAndTime(date, reference), 'hour');
};

export const isTimeAfter = (reference) => (date) => {
  return moment(date).isAfter(dateAndTime(date, reference));
};

export const todayOrEarlier = (date) => {
  return moment(date).isSameOrBefore(new Date(), 'day');
};

export const yesterdayOrEarlier = (date) => {
  return moment(date).isBefore(new Date(), 'day');
};

export const getHoursUntil = (date) => {
  const last = moment(date).hour();
  return new Array(last).fill(0).map((_, i) => i);
};

export const getHoursFrom = (date) => {
  const first = moment(date).hour() + 1;
  return new Array(24-first).fill(0).map((_, i) => (first+i));
};

export const getMinutesUntil = (date) => {
  const last = moment(date).minute();
  return new Array(last).fill(0).map((_, i) => i);
};

export const getMinutesFrom = (date) => {
  const first = moment(date).minute() + 1;
  return new Array(60-first).fill(0).map((_, i) => (first+i));
};

export const timeMax = (a, b) => {
  if(!a) return b;
  if(!b) return a;
  return moment.max(moment(a), moment(dateAndTime(a,b)));
};
