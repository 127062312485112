import React, { useState, useMemo, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { useHotkeys } from 'react-hotkeys-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DownloadOutlined } from '@ant-design/icons';

import {
  rangeOverlap,
  formatDb,
} from 'utils/date';

import useSetState from 'utils/useSetState';
import SdgLogo from 'components/SdgLogo';
import AuthorizedLink from 'containers/AuthorizedLink';

import {
  getInitiativesDownloadUrl,
  getInitiativesParticipationDownloadUrl,
} from 'actions/api';

import {
  Input,
  Button,
  Row,
  Col,
  Drawer,
  DatePicker,
  Checkbox,
  Tag,
  Empty,
  Menu,
  Dropdown,
} from 'antd';
import {
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const SUBTYPESEP = '__';
const STAKEHOLDERS_QUESTION_KEY = 'What type of stakeholder are you?';
const INITIATIVE_TYPES_WITH_PARTICIPATION = {
  volunteering: 'volunteering',
  donation: 'donation',
  training: 'training',
  collection: 'collection'
};

const getSorterByTranslation = (t, prefix = '') => (slug_a, slug_b) =>
   (t[`${prefix}${slug_a}`] || '').localeCompare(t[`${prefix}${slug_b}`] || '');

const getSorterByNumericalProp = (entityMap, propName = 'id') => (slug_a, slug_b) =>
  ( entityMap.get(slug_a)[propName] - entityMap.get(slug_b)[propName] );

const Filters = ({
  intl,
  data = [],
  pastData = [],
  sdgs: allSdgs,
  showInMap,
  toggleShowInMap,
  alreadyPassedText,
  emptyCardText,
  organization,
  showInitiativeDownload,
  renderChildren = () => null,
  isActiveInitiativesTab = false
}) => {
  const t = intl.messages;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [dateRangeValues, setDateRangeValues] = useState(['', '']);
  const [dateRange, setDateRange] = useState(null);

  const {
    set: filteredCategories,
    has: isCategoryFilterSet,
    toggle: toggleCategoryFilter,
    replaceAll: setFilteredCategories,
  } = useSetState([]);
  const categoryFilterSet = useMemo(() => filteredCategories.length > 0, [ filteredCategories ]);

  const {
    set: filteredCompetences,
    has: isCompetenceFilterSet,
    toggle: toggleCompetenceFilter,
    replaceAll: setFilteredCompetences,
  } = useSetState([]);
  const competenceFilterSet = useMemo(() => filteredCompetences.length > 0, [ filteredCompetences ]);

  const {
    set: filteredSdgs,
    has: isSdgFilterSet,
    toggle: toggleSdgFilter,
    replaceAll: setFilteredSdgs,
  } = useSetState([]);
  const sdgFilterSet = useMemo(() => filteredSdgs.length > 0, [ filteredSdgs ]);

  const {
    set: filteredTargetAudience,
    has: isTargetAudienceFilterSet,
    toggle: toggleTargetAudienceFilter,
    replaceAll: setFilteredTargetAudience,
  } = useSetState([]);
  const targetAudienceFilterSet = useMemo(() => filteredTargetAudience.length > 0, [ filteredTargetAudience ]);

  const {
    set: filteredType,
    has: isTypeFilterSet,
    toggle: toggleTypeFilter,
    replaceAll: setFilteredType,
  } = useSetState([]);
  const typeFilterSet = useMemo(() => filteredType.length > 0, [ filteredType ]);

  const {
    set: filteredStatus,
    has: isStatusFilterSet,
    toggle: toggleStatusFilter,
    replaceAll: setFilteredStatus,
  } = useSetState([]);
  const statusFilterSet = useMemo(() => filteredStatus.length > 0, [ filteredStatus ]);

  const {
    set: filteredPendingTask,
    has: isPendingTaskFilterSet,
    toggle: togglePendingTaskFilter,
    replaceAll: setFilteredPendingTask,
  } = useSetState([]);
  const pendingTaskFilterSet = useMemo(() => filteredPendingTask.length > 0, [ filteredPendingTask ]);

  const {
    set: filteredInitiativePrograms,
    has: isInitiativeProgramsFilterSet,
    toggle: toggleInitiativeProgramsFilter,
    replaceAll: setFilteredInitiativePrograms,
  } = useSetState([]);
  const initiativeProgramsFilterSet = useMemo(
    () => filteredInitiativePrograms.length > 0,
    [ filteredInitiativePrograms ]
  );

  const {
    set: filteredInitiativeTags,
    has: isInitiativeTagsFilterSet,
    toggle: toggleInitiativeTagsFilter,
    replaceAll: setFilteredInitiativeTags,
  } = useSetState([]);
  const initiativeTagsFilterSet = useMemo(
    () => filteredInitiativeTags.length > 0,
    [ filteredInitiativeTags ]
  );

  const {
    set: filteredLocation,
    has: isLocationFilterSet,
    toggle: toggleLocationFilter,
    replaceAll: setFilteredLocation,
  } = useSetState([]);
  const locationFilterSet = useMemo(() => filteredLocation.length > 0, [ filteredLocation ]);

  const {
    set: filteredParticipation,
    has: isParticipationFilterSet,
    toggle: toggleParticipationFilter,
    replaceAll: setFilteredParticipation,
  } = useSetState([]);
  const participationFilterSet = useMemo(() => filteredParticipation.length > 0, [ filteredParticipation ]);

  const {
    set: filteredInitiativePoints,
    has: isInitiativePointsFilterSet,
    toggle: toggleInitiativePointsFilter,
    replaceAll: setFilteredInitiativePoints,
  } = useSetState([]);
  const initiativePointsFilterSet = useMemo(() => filteredInitiativePoints.length > 0, [ filteredInitiativePoints ]);

  const {
    set: filteredStakeholders,
    has: isStakeholdersFilterSet,
    toggle: toggleStakeholdersFilter,
    replaceAll: setFilteredStakeholders,
  } = useSetState([]);
  const stakeholdersFilterSet = useMemo(() => filteredStakeholders.length > 0, [ filteredStakeholders ]);

  const {
    set: filteredPinnedInitiatives,
    has: isPinnedInitiativesFilterSet,
    toggle: togglePinnedInitiativesFilter,
    replaceAll: setFilteredPinnedInitiatives,
  } = useSetState([]);
  const pinnedInitiativesFilterSet = useMemo(() => filteredPinnedInitiatives.length > 0, [ filteredPinnedInitiatives ]);

  // Focus search field on Ctrl+F
  const searchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if(searchInputRef.current.focus) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  }, [ searchInputRef ]);

  const sdgMap = useMemo(() => {
    return new Map(allSdgs.map(sdg => [sdg.slug, sdg]));
  }, [ allSdgs ]);

  const organizationHasPoints = useMemo(() => {
    const orgFeatures = organization.features;
    return orgFeatures && orgFeatures.includes('points');
  }, [organization]);

  const organizationHasProgram = useMemo(() => {
    const orgFeatures = organization.features;
    return orgFeatures && orgFeatures.includes('program');
  }, [organization]);

  const organizationHasQuestions = useMemo(() => {
    return ((organization || {}).config || {}).initiative_questions &&
      Object.keys(((organization || {}).config || {}).initiative_questions).length > 0
  }, [organization]);

  const taxonomies = useMemo(() => {
    let categories = new Set();
    let competences = new Set();
    let sdgs = new Set();
    let targetAudience = new Set();
    let initiativePrograms = new Set();
    let initiativeTags = new Set();
    let status = new Set();
    let type = new Set();
    let pendingTask = new Set();
    let location = new Set();
    let stakeholders = new Set();
    const allData = [...data, ...pastData];
    let initiativePoints = [];
    let pinnedInitiatives = [];

    for(let item of allData) {
      item.categories.forEach(categories.add, categories);
      (item.competence || []).forEach(competences.add, competences);
      item.sdgs.forEach(sdgs.add, sdgs);
      (item.target_audience || []).forEach(targetAudience.add, targetAudience);
      (item.tags || []).forEach(initiativeTags.add, initiativeTags);
      status.add(item.status);
      type.add(item.subtype ? `${item.type}${SUBTYPESEP}${item.subtype}` : item.type);
      if(
        Number(item.participation_pending_count) ||
        Number(item.collaboration_pending_count) // NOTICE: It's not pending for collaboration if the initiative is finished or if I'm not the organizer, but thats considered in the BNE already
      ) {
        pendingTask.add('pending');
      }
      if (organizationHasProgram && item.program) {
        initiativePrograms.add(item.program);
      }
      if (item.is_in_person) {
        location.add('in_person');
      }
      if (item.is_remote) {
        location.add('remote');
      }
      if (organizationHasQuestions && Object.keys(item.questions).length > 0) {
        (item.questions[STAKEHOLDERS_QUESTION_KEY] || []).forEach(stakeholders.add, stakeholders);
      }
    }
    if (isActiveInitiativesTab) {
      pinnedInitiatives = ['is_pinned', 'is_not_pinned'];
    }
    const initiativeParticipation = ['has_participation', 'has_not_participation'];

    if (organizationHasPoints) {
      initiativePoints = ['has_points', 'has_not_points'];
    }

    return {
      categories: Array.from(categories).sort(getSorterByTranslation(t, 'category_')),
      competences: Array.from(competences).sort(getSorterByTranslation(t, 'competence_')),
      sdgs: Array.from(sdgs).sort(getSorterByNumericalProp(sdgMap, 'id')),
      targetAudience: Array.from(targetAudience).sort(getSorterByTranslation(t, 'target_audience_')),
      initiativePrograms: Array.from(initiativePrograms).sort(),
      initiativeTags: Array.from(initiativeTags).sort(),
      type: Array.from(type).sort(getSorterByTranslation(t)),
      status: Array.from(status).sort(getSorterByTranslation(t)),
      pendingTask: Array.from(pendingTask),
      location: Array.from(location).sort(getSorterByTranslation(t, 'initiative_')),
      stakeholders: Array.from(stakeholders).sort(),
      initiativeParticipation,
      initiativePoints,
      pinnedInitiatives
    }
  }, [
    data,
    pastData,
    sdgMap,
    t,
    organizationHasPoints,
    organizationHasProgram,
    organizationHasQuestions,
    isActiveInitiativesTab
  ]);

  const disableTaxonomyFilters = useMemo(() => (
    Object.keys(taxonomies).filter(taxonomy => taxonomies[taxonomy].length > 0).length === 0
  ), [ taxonomies ]);

  const skipTaxonomyFilters = useMemo(() => (
    !categoryFilterSet && !competenceFilterSet && !sdgFilterSet &&
    !targetAudienceFilterSet && !initiativeProgramsFilterSet && !initiativeTagsFilterSet &&
    !typeFilterSet && !statusFilterSet && !pendingTaskFilterSet && !locationFilterSet &&
    !initiativePointsFilterSet && !participationFilterSet && !stakeholdersFilterSet &&
    !pinnedInitiativesFilterSet
  ), [
    categoryFilterSet,
    competenceFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    initiativeProgramsFilterSet,
    initiativeTagsFilterSet,
    typeFilterSet,
    statusFilterSet,
    pendingTaskFilterSet,
    locationFilterSet,
    initiativePointsFilterSet,
    participationFilterSet,
    stakeholdersFilterSet,
    pinnedInitiativesFilterSet
  ]);

  const filteredData = useMemo(() => {
    // Bail out early if no filters are set
    if(!searchText &&
       !dateRange &&
       !categoryFilterSet &&
       !competenceFilterSet &&
       !sdgFilterSet &&
       !targetAudienceFilterSet &&
       !initiativeProgramsFilterSet &&
       !initiativeTagsFilterSet &&
       !typeFilterSet &&
       !statusFilterSet &&
       !pendingTaskFilterSet &&
       !locationFilterSet &&
       !participationFilterSet &&
       !initiativePointsFilterSet &&
       !stakeholdersFilterSet &&
       !pinnedInitiativesFilterSet
      ) return data;

    return data.filter(({
      title,
      type,
      subtype,
      status,
      description,
      address,
      organization_name,
      organization_slug,
      collaborators = [],
      start_time,
      end_time,
      categories = [],
      competences = [],
      sdgs = [],
      target_audience = [],
      tags = [],
      participation_pending_count,
      collaboration_pending_count,
      is_in_person,
      is_remote,
      contributor_count,
      participant_count,
      points,
      questions,
      pinned_initiative,
      program,
    }) => {
      const searchMatch = (
        !searchText ||
        (title || '').toLowerCase().includes(searchText) ||
        (address || '').toLowerCase().includes(searchText)
      );

      const dateMatch = (
        !dateRange ||
        rangeOverlap(
          [
            `${dateRange[0]}T00:00:00.000Z`,
            `${dateRange[1]}T23:59:59.999Z`,
          ],
          [
            formatDb(start_time), // NOTICE: this should solve timezones...
            formatDb(end_time),
          ]
        )
      );

      // NOTICE: Categories compose with OR
      const categoryMatch = (
        !categoryFilterSet ||
        categories.find(isCategoryFilterSet)
      );

      // NOTICE: Competences compose with OR
      const competenceMatch = (
        !competenceFilterSet ||
        competences.find(isCompetenceFilterSet)
      );

      // NOTICE: SDGs compose with OR
      const sdgMatch = (
        !sdgFilterSet ||
        sdgs.find(isSdgFilterSet)
      );

      const typeMatch = (
        !typeFilterSet ||
        isTypeFilterSet( subtype ? `${type}${SUBTYPESEP}${subtype}` : type)
      );

      const statusMatch = (
        !statusFilterSet ||
        isStatusFilterSet(status)
      );

      const pendingTaskMatch = (
        !pendingTaskFilterSet ||
        Number(participation_pending_count) ||
        Number(collaboration_pending_count) // NOTICE: It's not pending for collaboration  if the initiative is finished or if I'm not the organizer, but thats considered in the BNE already
      );

      // NOTICE: Target audiences compose with OR
      const targetAudienceMatch = (
        !targetAudienceFilterSet ||
        target_audience.find(isTargetAudienceFilterSet)
      );

      // NOTICE: Programs compose with OR
      const initiativeProgramsMatch = (
        !initiativeProgramsFilterSet ||
        isInitiativeProgramsFilterSet(program)
      );

      // NOTICE: Tags compose with AND
      const initiativeTagsMatch = (
        !initiativeTagsFilterSet ||
        filteredInitiativeTags.every(tag => tags.includes(tag))
      );

      const locationMatch = (
        !locationFilterSet ||
        (isLocationFilterSet('remote') && is_remote) ||
        (isLocationFilterSet('in_person') && is_in_person)
      );

      const initiativePointsMatch = (
        !initiativePointsFilterSet || (
          organizationHasPoints &&
          ((isInitiativePointsFilterSet('has_not_points') && (!points || points < 0)) ||
          (isInitiativePointsFilterSet('has_points') && points > 0))
        )
      );

      const hasContributors = contributor_count !== "0" && type === INITIATIVE_TYPES_WITH_PARTICIPATION.donation;
      const hasParticipants = participant_count !== "0" && (
        type === INITIATIVE_TYPES_WITH_PARTICIPATION.volunteering ||
        type === INITIATIVE_TYPES_WITH_PARTICIPATION.training ||
        type === INITIATIVE_TYPES_WITH_PARTICIPATION.collection
      );

      const initiativeParticipationMatch = (
        !participationFilterSet || (
          (isParticipationFilterSet('has_participation') && (hasContributors || hasParticipants)) ||
          (isParticipationFilterSet('has_not_participation') && !hasContributors && !hasParticipants)
        )
      );

      const stakeholdersMatch = (
        !stakeholdersFilterSet || (
          Object.keys(questions).length > 0 && questions[STAKEHOLDERS_QUESTION_KEY] &&
          questions[STAKEHOLDERS_QUESTION_KEY].find(isStakeholdersFilterSet)
        )
      );

      const isOwner = organization.slug === organization_slug;

      const initiativePinnedMatch = (
        !pinnedInitiativesFilterSet || (
          organization?.config?.can_pin_initiatives && isActiveInitiativesTab &&
          ((isPinnedInitiativesFilterSet('is_not_pinned') && !pinned_initiative) ||
          (isPinnedInitiativesFilterSet('is_pinned') && pinned_initiative))
        )
      );

      return searchMatch &&
        dateMatch &&
        (
          skipTaxonomyFilters || (
            (!categoryFilterSet || categoryMatch) &&
            (!competenceFilterSet || competenceMatch) &&
            (!sdgFilterSet || sdgMatch) &&
            (!targetAudienceFilterSet || targetAudienceMatch) &&
            (!initiativeProgramsFilterSet || initiativeProgramsMatch) &&
            (!initiativeTagsFilterSet || initiativeTagsMatch) &&
            (!typeFilterSet || typeMatch) &&
            (!statusFilterSet || statusMatch) &&
            (!pendingTaskFilterSet || (isOwner && pendingTaskMatch)) &&
            (!locationFilterSet || locationMatch) &&
            (!organizationHasQuestions || !stakeholdersFilterSet || stakeholdersMatch) &&
            (!organizationHasPoints || !initiativePointsFilterSet || initiativePointsMatch) &&
            (!participationFilterSet || initiativeParticipationMatch) &&
            (!organization?.config?.can_pin_initiatives || !pinnedInitiativesFilterSet || initiativePinnedMatch)
          )
        );
    })
  }, [
    data,
    searchText,
    dateRange,
    skipTaxonomyFilters,
    categoryFilterSet,
    competenceFilterSet,
    isCategoryFilterSet,
    isCompetenceFilterSet,
    isSdgFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    isTargetAudienceFilterSet,
    initiativeProgramsFilterSet,
    isInitiativeProgramsFilterSet,
    initiativeTagsFilterSet,
    isTypeFilterSet,
    typeFilterSet,
    isStatusFilterSet,
    statusFilterSet,
    pendingTaskFilterSet,
    locationFilterSet,
    isLocationFilterSet,
    organization,
    stakeholdersFilterSet,
    initiativePointsFilterSet,
    participationFilterSet,
    isInitiativePointsFilterSet,
    isParticipationFilterSet,
    isStakeholdersFilterSet,
    organizationHasQuestions,
    organizationHasPoints,
    isPinnedInitiativesFilterSet,
    pinnedInitiativesFilterSet,
    isActiveInitiativesTab,
    filteredInitiativeTags,
  ]);

  const filteredPastData = useMemo(() => {
    if(!pastData) return []
    // Bail out early if no filters are set
    if(!searchText &&
       !dateRange &&
       !categoryFilterSet &&
       !competenceFilterSet &&
       !sdgFilterSet &&
       !targetAudienceFilterSet &&
       !initiativeProgramsFilterSet &&
       !initiativeTagsFilterSet &&
       !locationFilterSet &&
       !participationFilterSet &&
       !initiativePointsFilterSet &&
       !stakeholdersFilterSet
     ) return pastData;

    return pastData.filter(({
      title,
      type,
      status,
      description,
      address,
      organization_name,
      collaborators = [],
      start_time,
      end_time,
      categories = [],
      competences = [],
      sdgs = [],
      target_audience = [],
      tags = [],
      participation_pending_count,
      collaboration_pending_count,
      is_in_person,
      is_remote,
      contributor_count,
      participant_count,
      points,
      questions,
      program,
    }) => {
      const searchMatch = (
        !searchText ||
        (title || '').toLowerCase().includes(searchText) ||
        (description || '').toLowerCase().includes(searchText) ||
        (address || '').toLowerCase().includes(searchText) ||
        (organization_name || '').toLowerCase().includes(searchText) ||
        collaborators.find(({ name }) => (name || '').toLowerCase().includes(searchText))
      );

      const dateMatch = (
        !dateRange ||
        rangeOverlap(
          [
            `${dateRange[0]}T00:00:00.000Z`,
            `${dateRange[1]}T23:59:59.999Z`,
          ],
          [
            formatDb(start_time), // NOTICE: this should solve timezones...
            formatDb(end_time),
          ]
        )
      );

      const categoryMatch = (
        !categoryFilterSet ||
        categories.find(isCategoryFilterSet)
      );

      const competenceMatch = (
        !competenceFilterSet ||
        competences.find(isCompetenceFilterSet)
      );

      const sdgMatch = (
        !sdgFilterSet ||
        sdgs.find(isSdgFilterSet)
      );

      const typeMatch = (
        !typeFilterSet ||
        [type].find(isTypeFilterSet)
      );

      const statusMatch = (
        !statusFilterSet ||
        [status].find(isStatusFilterSet)
      );

      const pendingTaskMatch = (
        !pendingTaskFilterSet ||
        Number(participation_pending_count) ||
        Number(collaboration_pending_count) // NOTICE: It's not pending for collaboration  if the initiative is finished or if I'm not the organizer, but thats considered in the BNE already
      );

      const targetAudienceMatch = (
        !targetAudienceFilterSet ||
        target_audience.find(isTargetAudienceFilterSet)
      );

      const initiativeProgramsMatch = (
        !initiativeProgramsFilterSet ||
        isInitiativeProgramsFilterSet(program)
      );

      const initiativeTagsMatch = (
        !initiativeTagsFilterSet ||
        tags.find(isInitiativeTagsFilterSet)
      );

      const locationMatch = (
        !locationFilterSet ||
        (isLocationFilterSet('remote') && is_remote) ||
        (isLocationFilterSet('in_person') && is_in_person)
      );

      const initiativePointsMatch = (
        !initiativePointsFilterSet || (
          organizationHasPoints &&
          ((isInitiativePointsFilterSet('has_not_points') && (!points || points < 0)) ||
          (isInitiativePointsFilterSet('has_points') && points > 0))
        )
      );

      const hasContributors = contributor_count !== "0" && type === INITIATIVE_TYPES_WITH_PARTICIPATION.donation;
      const hasParticipants = participant_count !== "0" && (
        type === INITIATIVE_TYPES_WITH_PARTICIPATION.volunteering ||
        type === INITIATIVE_TYPES_WITH_PARTICIPATION.training ||
        type === INITIATIVE_TYPES_WITH_PARTICIPATION.collection
      );

      const initiativeParticipationMatch = (
        !participationFilterSet || (
          (isParticipationFilterSet('has_participation') && (hasContributors || hasParticipants)) ||
          (isParticipationFilterSet('has_not_participation') && !hasContributors && !hasParticipants)
        )
      );

      const stakeholdersMatch = (
        !stakeholdersFilterSet || (
          Object.keys(questions).length > 0 && questions[STAKEHOLDERS_QUESTION_KEY] &&
          questions[STAKEHOLDERS_QUESTION_KEY].find(isStakeholdersFilterSet)
        )
      );

      return searchMatch &&
        dateMatch &&
        (
          skipTaxonomyFilters ||
          (categoryFilterSet && categoryMatch) ||
          (competenceFilterSet && competenceMatch) ||
          (sdgFilterSet && sdgMatch) ||
          (targetAudienceFilterSet && targetAudienceMatch) ||
          (initiativeProgramsFilterSet && initiativeProgramsMatch) ||
          (initiativeTagsFilterSet && initiativeTagsMatch) ||
          (typeFilterSet && typeMatch) ||
          (statusFilterSet && statusMatch) ||
          (pendingTaskFilterSet && pendingTaskMatch) ||
          (locationFilterSet && locationMatch) ||
          (organizationHasQuestions && stakeholdersFilterSet && stakeholdersMatch) ||
          (organizationHasPoints && initiativePointsFilterSet && initiativePointsMatch) ||
          (participationFilterSet && initiativeParticipationMatch)
        );
    })
  }, [
    pastData,
    searchText,
    dateRange,
    skipTaxonomyFilters,
    categoryFilterSet,
    competenceFilterSet,
    isCategoryFilterSet,
    isCompetenceFilterSet,
    isSdgFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    isTargetAudienceFilterSet,
    initiativeProgramsFilterSet,
    isInitiativeProgramsFilterSet,
    initiativeTagsFilterSet,
    isInitiativeTagsFilterSet,
    typeFilterSet,
    isTypeFilterSet,
    statusFilterSet,
    isStatusFilterSet,
    pendingTaskFilterSet,
    locationFilterSet,
    isLocationFilterSet,
    stakeholdersFilterSet,
    initiativePointsFilterSet,
    participationFilterSet,
    isInitiativePointsFilterSet,
    isParticipationFilterSet,
    isStakeholdersFilterSet,
    organizationHasQuestions,
    organizationHasPoints
  ]);

  return (
    <React.Fragment>
      <Row className='Initiative-filtersBar' type="flex" gutter={20}>
        <Col style={{ flex: 1 }}>
          <Input.Search
            type="text"
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={ t.initiative_search_placeholder }
            enterButton={ t.initiative_search }
            onSearch={(term) => setSearchText((term || '').toLowerCase())}
            onChange={(term) => !term.target.value && setSearchText('')}
            allowClear
            ref={searchInputRef}
            className="button"
          />
        </Col>
        <Col>
          <DatePicker.RangePicker
            open={datePickerOpen}
            onOpenChange={setDatePickerOpen}
            value={dateRangeValues}
            className='Initiative-datePicker'
            onChange={(dates, dateStrings) => {
              setDateRangeValues(dates);
              setDateRange(dateStrings.filter(Boolean).length === 0 ? null : dateStrings);
            }}
            renderExtraFooter={() =>
              <Button
                onClick={() => {
                  setDatePickerOpen(false);
                  setDateRangeValues(['', '']);
                  setDateRange(null);
                }}
                disabled={!dateRange}
                size={"small"}
              >{ t.clear }</Button>
            }
          />
        </Col>
        <Col>
          <Button
            className='Initiative-filtersButton'
            icon={<FilterOutlined />}
            disabled={disableTaxonomyFilters}
            onClick={() => setDrawerOpen(true)}
          />
        </Col>
        {!!toggleShowInMap &&
        <Col>
          <Button
            className="button"
            type="primary"
            onClick={toggleShowInMap}
          >
            { showInMap
              ? <><FontAwesomeIcon icon="th-list"/> {t.show_in_list}</>
              : <><FontAwesomeIcon icon="map-marked-alt"/> {t.show_in_map}</>
            }
          </Button>
        </Col>
        }
        {showInitiativeDownload && !!data.length &&
        <Col>
          <Dropdown overlay={
            <Menu>
              <Menu.Item key="1" icon={<DownloadOutlined />}>
                <AuthorizedLink
                  href={getInitiativesDownloadUrl(organization.slug)}
                  download={'initiatives.xlsx'}
                >
                  {intl.formatMessage({ id: 'initiative_download'})}
                </AuthorizedLink>
              </Menu.Item>
              <Menu.Item key="2" icon={<DownloadOutlined />}>
                <AuthorizedLink
                  href={getInitiativesParticipationDownloadUrl(organization.slug)}
                  download={'initiatives_participation.xlsx'}
                >
                  {intl.formatMessage({ id: 'initiative_download_participation'})}
                </AuthorizedLink>
              </Menu.Item>
            </Menu>
          }
          >
            <Button
              className="button"
              icon={<DownloadOutlined />}
              type="primary"
            >
              {intl.formatMessage({ id: 'download'})}
            </Button>
          </Dropdown>
        </Col>
        }
      </Row>
      <Drawer
        title={t.initiative_filter_title}
        width={350}
        placement="right"
        closable={true}
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
      >
        { taxonomies.categories.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.categories }</h4>
            <div>
              <Checkbox
                checked={!categoryFilterSet}
                onChange={() => setFilteredCategories([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.categories.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isCategoryFilterSet(slug)}
                  onChange={() => toggleCategoryFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`category_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.competences.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.competences }</h4>
            <div>
              <Checkbox
                checked={!competenceFilterSet}
                onChange={() => setFilteredCompetences([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.competences.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isCompetenceFilterSet(slug)}
                  onChange={() => toggleCompetenceFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`competence_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.sdgs.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.sdg }</h4>
            <div>
              <Checkbox
                checked={!sdgFilterSet}
                onChange={() => setFilteredSdgs([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.sdgs.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isSdgFilterSet(slug)}
                  onChange={() => toggleSdgFilter(slug)}
                  style={{ display: 'block' }}
                >
                  <SdgLogo
                    sdg={slug}
                    className="Initiative-taxonomy-filter-sdg-logo"
                  />
                  { t[`sdg_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.type.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.type }</h4>
            <div>
              <Checkbox
                checked={!typeFilterSet}
                onChange={() => setFilteredType([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.type.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isTypeFilterSet(slug)}
                  onChange={() => toggleTypeFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`filter_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        {
          <div className="Initiative-taxonomy-filter">
            <h4>{ intl.formatMessage({ id: `initiative_participation` }) }</h4>
            <div>
              <Checkbox
                checked={!participationFilterSet}
                onChange={() => setFilteredParticipation([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativeParticipation.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isParticipationFilterSet(slug)}
                  onChange={() => toggleParticipationFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { intl.formatMessage({ id: `initiative_${slug}` }) }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { organizationHasPoints && taxonomies.initiativePoints.length > 0 && (
          <div className="Initiative-taxonomy-filter">
            <h4>{ intl.formatMessage({ id: `initiative_participants_points` }) }</h4>
            <div>
              <Checkbox
                checked={!initiativePointsFilterSet}
                onChange={() => setFilteredInitiativePoints([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativePoints.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isInitiativePointsFilterSet(slug)}
                  onChange={() => toggleInitiativePointsFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { intl.formatMessage({ id: `initiative_${slug}` }) }
                </Checkbox>
              )) }
            </div>
          </div>
        )}

        { organizationHasQuestions && taxonomies.stakeholders.length > 0 && (
          <div className="Initiative-taxonomy-filter">
            <h4>{ intl.formatMessage({ id: `initiative_stakeholders` }) }</h4>
            <div>
              <Checkbox
                checked={!stakeholdersFilterSet}
                onChange={() => setFilteredStakeholders([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.stakeholders.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isStakeholdersFilterSet(slug)}
                  onChange={() => toggleStakeholdersFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { slug }
                </Checkbox>
              )) }
            </div>
          </div>
        )}
        { organization?.config?.can_pin_initiatives && taxonomies.pinnedInitiatives.length > 0 && isActiveInitiativesTab && (
          <div className="Initiative-taxonomy-filter">
            <h4>{ intl.formatMessage({ id: `filter_pinned_initiatives` }) }</h4>
            <div>
              <Checkbox
                checked={!pinnedInitiativesFilterSet}
                onChange={() => setFilteredPinnedInitiatives([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.pinnedInitiatives.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isPinnedInitiativesFilterSet(slug)}
                  onChange={() => togglePinnedInitiativesFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { intl.formatMessage({ id: `filter_initiative_${slug}` }) }
                </Checkbox>
              )) }
            </div>
          </div>
        )}
        { taxonomies.location.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.location }</h4>
            <div>
              <Checkbox
                checked={!locationFilterSet}
                onChange={() => setFilteredLocation([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.location.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isLocationFilterSet(slug)}
                  onChange={() => toggleLocationFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`initiative_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.status.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.status }</h4>
            <div>
              <Checkbox
                checked={!statusFilterSet}
                onChange={() => setFilteredStatus([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.status.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isStatusFilterSet(slug)}
                  onChange={() => toggleStatusFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[slug] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.pendingTask.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.pending_tasks }</h4>
            <div>
              <Checkbox
                checked={!statusFilterSet}
                onChange={() => setFilteredPendingTask([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.pendingTask.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isPendingTaskFilterSet(slug)}
                  onChange={() => togglePendingTaskFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`pending_task_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.targetAudience.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.target_audience }</h4>
            <div>
              <Checkbox
                checked={!targetAudienceFilterSet}
                onChange={() => setFilteredTargetAudience([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.targetAudience.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isTargetAudienceFilterSet(slug)}
                  onChange={() => toggleTargetAudienceFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`target_audience_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { (taxonomies.initiativePrograms || []).length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.program }</h4>
            <div>
              <Checkbox
                checked={!initiativeProgramsFilterSet}
                onChange={() => setFilteredInitiativePrograms([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativePrograms.map(name => (
                <Checkbox
                  key={name}
                  checked={isInitiativeProgramsFilterSet(name)}
                  onChange={() => toggleInitiativeProgramsFilter(name)}
                  style={{ display: 'block' }}
                >
                  { name }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.initiativeTags.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.tags }</h4>
            <div>
              <Checkbox
                checked={!initiativeTagsFilterSet}
                onChange={() => setFilteredInitiativeTags([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativeTags.map(name => (
                <Checkbox
                  key={name}
                  checked={isInitiativeTagsFilterSet(name)}
                  onChange={() => toggleInitiativeTagsFilter(name)}
                  style={{ display: 'block' }}
                >
                  { name }
                </Checkbox>
              )) }
            </div>
          </div>
        }

      </Drawer>
      { skipTaxonomyFilters ? null :
        <React.Fragment>
          <div className="Whitespace20"></div>
          <div>
            {
              filteredCategories.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.categories }: </span>
                  { filteredCategories.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="volcano"
                      onClose={() => toggleCategoryFilter(slug)}
                    >{ t[`category_${slug}`] }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredCompetences.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.competences }: </span>
                  { filteredCompetences.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="purple"
                      onClose={() => toggleCompetenceFilter(slug)}
                    >{ t[`competence_${slug}`] }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredSdgs.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.sdg }: </span>
                  { filteredSdgs.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color={sdgMap.get(slug).color}
                      onClose={() => toggleSdgFilter(slug)}
                    >{ t[`sdg_${slug}`] }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredTargetAudience.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.target_audience }: </span>
                  { filteredTargetAudience.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="green"
                      onClose={() => toggleTargetAudienceFilter(slug)}
                    >{ t[`target_audience_${slug}`] }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredType.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.type }: </span>
                  { filteredType.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="blue"
                      onClose={() => toggleTypeFilter(slug)}
                    >{ t[slug] }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredStatus.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.status }: </span>
                  { filteredStatus.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="red"
                      onClose={() => toggleStatusFilter(slug)}
                    >{ t[slug] }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredPendingTask.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.pending_tasks}: </span>
                  { filteredPendingTask.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="orange"
                      onClose={() => togglePendingTaskFilter(slug)}
                    >{ t[`pending_task_${slug}`] }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredInitiativePrograms.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.program }: </span>
                  { filteredInitiativePrograms.map(name =>
                    <Tag
                      key={name}
                      closable
                      color="lime"
                      onClose={() => toggleInitiativeProgramsFilter(name)}
                    >{ name }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredInitiativeTags.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.tags }: </span>
                  { filteredInitiativeTags.map(name =>
                    <Tag
                      key={name}
                      closable
                      color="magenta"
                      onClose={() => toggleInitiativeTagsFilter(name)}
                    >{ name }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredLocation.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ intl.formatMessage({ id: `location` }) }: </span>
                  { filteredLocation.map(name =>
                    <Tag
                      key={name}
                      closable
                      color="cyan"
                      onClose={() => toggleLocationFilter(name)}
                    >{ intl.formatMessage({ id: `initiative_${name}` }) }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredParticipation.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ intl.formatMessage({ id: `initiative_participation` }) }: </span>
                  { filteredParticipation.map(name =>
                    <Tag
                      key={name}
                      closable
                      color="geekblue"
                      onClose={() => toggleParticipationFilter(name)}
                    >{ intl.formatMessage({ id: `initiative_${name}` }) }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredInitiativePoints.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ intl.formatMessage({ id: `initiative_participants_points` }) } : </span>
                  { filteredInitiativePoints.map(name =>
                    <Tag
                      key={name}
                      closable
                      color="lime"
                      onClose={() => toggleInitiativePointsFilter(name)}
                    >{ intl.formatMessage({ id: `initiative_${name}` }) }</Tag>
                  ) }
                </div>
              )
            }
            {
              filteredStakeholders.length === 0
              ? null
              : (
                <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ intl.formatMessage({ id: `initiative_stakeholders` }) } : </span>
                  { filteredStakeholders.map(name =>
                    <Tag
                      key={name}
                      closable
                      color="gold"
                      onClose={() => toggleStakeholdersFilter(name)}
                    >{ name }</Tag>
                  ) }
                </div>
              )
            }
          </div>
          <div className="Whitespace20"></div>
        </React.Fragment>
      }
      { (!filteredData || filteredData.length < 1) && !showInMap
        ? <Empty
            className='big'
            image={'/images/empty-card.svg'}
            description={emptyCardText}
          />
        : renderChildren(filteredData, searchText)}
      { !filteredPastData.length ? null :
        <React.Fragment>
          <section className='initiativeDivider'>
            <figure className='line'/>
            <section className='alreadyPassed'>
              <span>{alreadyPassedText}</span>
              <figure>{filteredPastData.length}</figure>
            </section>
            <figure className='line'/>
          </section>
          {renderChildren(filteredPastData, searchText)}
        </React.Fragment>
      }
    </React.Fragment>
  );
}

export default injectIntl(Filters);
