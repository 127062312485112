// Classic debounce
export function debounce(func, wait, leading) {
	let timeout;
	return function(...args) {
		const context = this;
		const later = function() {
			timeout = null;
			if (!leading) func.apply(context, args);
		};
		const callNow = leading && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

