import React, { useCallback, useEffect, useState } from 'react';
import {
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Modal,
} from 'antd';

const SendCertificateModal = ({
  intl,
  showSendCertificateModal,
  onSend,
  onClose,
}) => {
  const t = intl.messages;
  const { loading, error } = useSelector(state => state.participant_certificate);

  const [sendingCertificate, setSendingCertificate] = useState(false);

  useEffect(
    () => {
      if (sendingCertificate && !loading) {
        if (error) {
          Modal.error({
            title: t.sent,
            content: t.participants_sendCertificate_status_failure,
            okText: t.participants_sendCertificate_ok,
          });
          setSendingCertificate(false);
        } else {
          Modal.success({
            title: t.sent,
            content: t.participants_sendCertificate_status_success,
            okText: t.participants_sendCertificate_ok,
          });
          setSendingCertificate(false);
          onClose();
        }
      }
    },
    [t, error, loading, onClose, sendingCertificate]
  );

  const handleSend = useCallback(
    () => {
      setSendingCertificate(true);
      onSend();
    },
    [onSend]
  );
  return (
    <Modal
      visible={showSendCertificateModal}
      title={t.participants_sendCertificate}
      onOk={handleSend}
      onCancel={onClose}
      okText={t.participants_sendCertificate_send}
      cancelText={t.participants_sendCertificate_cancel}
      confirmLoading={sendingCertificate}
    >
      <span>{t.participants_sendCertificate_description}</span>
    </Modal>
  );
}

SendCertificateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  showSendCertificateModal: PropTypes.bool.isRequired
};

export default injectIntl(SendCertificateModal);