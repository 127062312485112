import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import { defaultColors } from '../useColors.js';

const DEFAULT_PARAMETERS = {
  nodata: 'No data',
  smooth: false,
};

// TODO: Take out parameters and expose a way to get the params for a nivo-api call
const Lines = forwardRef(({
  slug,
  parameters = DEFAULT_PARAMETERS,
  data,
}, ref) => {
  const {
    nodata = DEFAULT_PARAMETERS.nodata,
    smooth = DEFAULT_PARAMETERS.smooth,
  } = parameters;

  const chartData = useMemo(() => ({
    labels: data.labels,
    datasets: data.values.map(value => ({
      ...value,
      fill: false,
      borderColor: defaultColors[0],
    })),
  }), [
    data,
  ]);

  const commonAxesOptions = useMemo(() => ({
    ticks: {
      beginAtZero: true,
      precision: 0,
    },
  }), []);

  const options = useMemo(() => ({
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ...commonAxesOptions,
      }],
      yAxes: [{
        ...commonAxesOptions,
      }]
    },
    ...(smooth && {lineTension: 0}),
  }), [
    smooth,
    commonAxesOptions,
  ]);

  return (
    <div className="DashboardComponent__card_content">
      {
        !data || data.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <Line
            ref={ref}
            data={chartData}
            height='300px'
            options={options}
            legend={{ position: 'bottom' }}
          />
      }
    </div>
  );
});

Lines.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.arrayOf( PropTypes.object ),
};

export default Lines;

