import { combineReducers } from 'redux-loop';
import { intlReducer } from 'react-intl-redux';
import { active_wait } from './active_wait';
import { auth } from './auth';
import { api_requests } from './api_requests';
import { organization } from './organization';
import { organization_tree } from './organization_tree';
import { initiatives } from './initiatives';
import { initiative_detail } from './initiative_detail';
import { profile } from './profile';
import { volunteers } from './volunteers';
import { taxonomies } from './taxonomies';
import { dashboard } from './dashboard';
import { initiative_form_done } from './initiative_form_done';
import { initiative_delete_done } from './initiative_delete_done';
import { profile_edit_done } from './profile_edit_done';
import { broadcast_message } from './broadcast_message';
import { rankings } from './rankings';
import { proposals } from './proposals';
import { collaborations } from './collaborations';
import { indicators } from './indicators';
import { course_detail } from './course_detail';
import { profile_request_failed } from './profile_request_failed';
import { user_detail } from './user_detail';
import { manager_approval } from './manager_approval';
import { app } from './app';
import { initiative_documents } from './initiative_documents';
import { initiative_images } from './initiative_images';
import { participant_certificate } from './participant_certificate';
import { chat } from './chat';

const reducers = (history) => combineReducers({
  intl: intlReducer,
  active_wait,
  auth,
  api_requests,
  app,
  organization,
  organization_tree,
  initiatives,
  initiative_detail,
  profile,
  volunteers,
  taxonomies,
  dashboard,
  initiative_form_done,
  initiative_delete_done,
  profile_edit_done,
  broadcast_message,
  rankings,
  proposals,
  collaborations,
  indicators,
  course_detail,
  profile_request_failed,
  user_detail,
  manager_approval,
  initiative_documents,
  initiative_images,
  participant_certificate,
  chat
});

export default reducers;