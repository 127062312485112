import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppContainer } from 'react-hot-loader';
import onBeforePrint from 'utils/onBeforePrint';
import config from './config';

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: config.SENTRY_SAMPLE_RATE,
});

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <App/>
    </AppContainer>,
    document.getElementById('root')
  );
};

let refreshing = false;
serviceWorker.unregister({
  onUpdate: (serviceWorkerRegistration) => {
    // TODO: Show reloading notification
    if(refreshing) return;
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      refreshing = true;
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload(true);
        }
      });
    }
  },
});

render(App);

// Register prerequisite print events
onBeforePrint();

if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
