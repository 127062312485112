module.exports = {
  'tag-palette': ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'],

  /* --- SDG --- */
  'sdg-background-colors': {
    'no-poverty': '#FAD3D8',
    'zero-hunger': '#F8EDD7',
    'good-health-and-wellbeing': '#C9E2C7',
    'quality-education': '#F3D2D5',
    'gender-equality': 'FCD9D4',
    'clean-water-and-sanitation': '#D4F2F9',
    'affordable-and-clean-energy': '#FEF3CE',
    'decent-work-and-economic-growth': '#ECD1D9',
    'industry-innovation-and-infrastructure': '#FCE1D5',
    'reduced-inequalities': '#F8D0E1',
    'sustainable-cities-and-communities': '#FEEBD4',
    'responsible-consumption-and-production': '#F2E8D5',
    'climate-action': '#D9E5DA',
    'life-below-water': '#D2EAF6',
    'life-on-land': '#DEF1DA',
    'peace-justice-and-strong-institutions': '#CDE1EC',
    'partnership-for-the-goals': '#D1DAE1'
  },
  'sdg-colors': {
    'no-poverty': '#FF334C',
    'zero-hunger': '#E7B551',
    'good-health-and-wellbeing': '#52984D',
    'quality-education': '#DF3B4D',
    'gender-equality': '#FF513D',
    'clean-water-and-sanitation': '#61BBD1',
    'affordable-and-clean-energy': '#F5C119',
    'decent-work-and-economic-growth': '#CE4D74',
    'industry-innovation-and-infrastructure': '#FF7E43',
    'reduced-inequalities': '#E11484',
    'sustainable-cities-and-communities': '#FDA029',
    'responsible-consumption-and-production': '#CA9B46',
    'climate-action': '#326F36',
    'life-below-water': '#36A1D8',
    'life-on-land': '#6BC059',
    'peace-justice-and-strong-institutions': '#1E77A7',
    'partnership-for-the-goals': '#2E6084'
    },
  /* ------ */

  /* --- COLORS FOR GROUPS --- */
  'group-colors': ['#00B46E', '#FF544A', '#FFA424', '#CA9058', '#5AC8FA', '#FFD83E', '#7371E9', '#489FFD', '#C9F2E5', '#FF456F', '#9AD96C'],
  /* ------ */
};
