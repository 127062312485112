import React, {
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
} from 'antd';

const Profile = ({
  intl,
  user,
  onboardingSteps = [],
}) => {
  const t = intl.messages;

  const target_audience = useMemo(
    () => user.target_audience.filter(({ member }) => member),
    [user],
  );
  const categories = useMemo(
    () => user.categories.filter(({ member }) => member),
    [user],
  );

  const stepSet = useMemo(() => {
    return new Set(onboardingSteps);
  }, [
    onboardingSteps,
  ]);

  const getValueForBoolean = useCallback(
    (value) => {
      if (value === true) {
        return t.yes;
      } else if (value === false) {
        return t.no;
      } else {
        return t.no_data;
      }
    },
    [t]
  );

  return (
    <Row gutter={[15, 15]}>
      { stepSet.has('target_audience') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.target_audience}
            </div>
            <div className="UserProfileModal__content">
              {target_audience.length === 0 ?
                <p>{t.no_data}</p>
                :
                target_audience.map(({ slug, name }) => (
                  <p key={slug}>
                    {t[`target_audience_${slug}`] || name}
                  </p>)
                )
              }
            </div>
          </div>
        </Col>
      }
      { stepSet.has('categories') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.categories}
            </div>
            <div className="UserProfileModal__content">
              {categories.length === 0 ?
                <p>{t.no_data}</p>
                :
                categories.map(({ slug, name }) => (
                  <p key={slug}>
                    {t[`category_${slug}`] || name}
                  </p>)
                )
              }
            </div>
          </div>
        </Col>
      }
      { stepSet.has('address') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.place_of_residence}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {user.details.address || t.no_data}
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('experience') &&
        <>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {t.professional_experience}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.professional_experience || t.no_data}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {t.volunteering_experience}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.volunteering_experience || t.no_data}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {t.motivations_for_social_work}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.social_work_motivations || t.no_data}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {t.talents_and_skills}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.taleccnts_and_skills || t.no_data}
                </p>
              </div>
            </div>
          </Col>
        </>
      }
      { stepSet.has('experience_education_and_skills') &&
        <>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {intl.formatMessage({ id: `professional_experience` })}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.professional_experience || intl.formatMessage({ id: `no_data` })}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {intl.formatMessage({ id: `user_profile_details_education` })}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.education || intl.formatMessage({ id: `no_data` })}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {intl.formatMessage({ id: `user_profile_details_technical_skills` })}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.technical_skills || intl.formatMessage({ id: `no_data` })}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="UserProfileModal__card">
              <div className="UserProfileModal__title">
                {intl.formatMessage({ id: `user_profile_details_leadership_skills` })}
              </div>
              <div className="UserProfileModal__content">
                <p>
                  {user.details.leadership_skills || intl.formatMessage({ id: `no_data` })}
                </p>
              </div>
            </div>
          </Col>
        </>
      }
      { stepSet.has('alias') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.user_profile_details_alias}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {user.details.alias || t.no_data}
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('specific_training') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.user_profile_details_specific_training}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {t.user_profile_details_specific_training_cap_ccp}: {getValueForBoolean(user.details.specific_training_cap_ccp)}
              </p>
              <p>
                {t.user_profile_details_specific_training_first_aid}: {getValueForBoolean(user.details.specific_training_first_aid)}
              </p>
              <p>
                {t.user_profile_details_specific_training_received_volunteering_training}: {getValueForBoolean(user.details.specific_training_received_volunteering_training)}
              </p>
              {user.details.specific_training_received_volunteering_training &&
                <p>
                  {t.user_profile_details_specific_training_received_volunteering_training_where}: {user.details.specific_training_received_volunteering_training_where || t.no_data}
                </p>
              }
            </div>
          </div>
        </Col>
      }
      { stepSet.has('availability') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.user_profile_details_availability}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {t.user_profile_details_availability_preferred_location}: {
                  user.details.availability_preferred_location && user.details.availability_preferred_location.length > 0
                  ? user.details.availability_preferred_location
                      .map(slug => t[`user_profile_details_availability_preferred_location_option_${slug}`])
                      .join(', ')
                  : t.no_data
                }
              </p>
              <p>
                {t.user_profile_details_availability_days}: {
                  user.details.availability_days && user.details.availability_days.length > 0
                  ? user.details.availability_days
                      .map(slug => t[`user_profile_details_availability_days_option_${slug}`])
                      .join(', ')
                  : t.no_data
                }
              </p>
              <p>
                {t.user_profile_details_availability_hours}: {
                  user.details.availability_hours
                  && user.details.availability_hours.length === 2
                  && user.details.availability_hours[0]
                  && user.details.availability_hours[1]
                  ? `${user.details.availability_hours[0]} - ${user.details.availability_hours[1]}`
                  : t.no_data
                }
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('availability_and_mobility') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {intl.formatMessage({ id: `user_profile_details_availability_and_mobility` })}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {user.details.availability_and_mobility || intl.formatMessage({ id: `no_data` })}
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('volunteering_motivation') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.user_profile_details_volunteering_motivation}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {t.user_profile_details_volunteering_motivation_reason}: {
                  user.details.volunteering_motivation_reason && user.details.volunteering_motivation_reason.length > 0
                  ? user.details.volunteering_motivation_reason
                      .map(slug => t[`user_profile_details_volunteering_motivation_option_${slug}`])
                      .join(', ')
                  : t.no_data
                }
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('motivations_and_expectations') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {intl.formatMessage({ id: `user_profile_details_motivations_and_expectations` })}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {user.details.motivations_and_expectations || intl.formatMessage({ id: `no_data` })}
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('machine_number') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.user_profile_details_machine_number}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {user.details.machine_number || t.no_data}
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('relation_with_org') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.user_profile_details_relation_with_org}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {
                  user.details.relation_with_org
                  ? t[`relation_with_org_${user.details.relation_with_org}`]
                  : t.no_data
                }
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('tshirt_size') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {t.user_profile_details_tshirt_size}
            </div>
            <div className="UserProfileModal__content">
              <p>
                { user.details.tshirt_size || t.no_data }
              </p>
            </div>
          </div>
        </Col>
      }
      { stepSet.has('date_of_birth') &&
        <Col xs={24} md={12}>
          <div className="UserProfileModal__card">
            <div className="UserProfileModal__title">
              {intl.formatMessage({ id: `user_profile_details_date_of_birth` })}
            </div>
            <div className="UserProfileModal__content">
              <p>
                {user.details.date_of_birth || intl.formatMessage({ id: `no_data` })}
              </p>
            </div>
          </div>
        </Col>
      }
    </Row>
  );
}

Profile.isProfileEmpty = (user) => {
  if(!user) {
    return true;
  }

  const target_audience = user.target_audience.filter(({ member }) => member);
  const categories = user.categories.filter(({ member }) => member);


  return target_audience.length === 0 &&
    categories.length === 0 &&
    !user.details.address &&
    !user.details.professional_experience &&
    !user.details.volunteering_experience &&
    !user.details.social_work_motivations &&
    !user.details.talents_and_skills
}

export default injectIntl(Profile);
