import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  Button,
  Form,
  Row,
  Col,
  Modal,
  Input,
} from 'antd';

const ButtonArea = ({
  intl,
  history,
  formMode,
  loading,
  onRejectProposal,
  onEditConfirm,
  onSaveAsDraft,
  unsetSaveAsDraft,
  submitForm,
  organization,
  initiative,
  cancelInitiative,
  location,
  cancelMode = 'cancel',
  onDeleteDraftInitiative
}) => {
  const t = intl.messages;
  const push = history.push;
  const [ modalShown, setModalShown ] = useState(false);
  const [ rejectReason, setRejectReason ] = useState('');
  const [ modalCancelShown, setModalCancelShown ] = useState(false)
  const [ cancelReason, setCancelReason ] = useState('');
  const [ showDraftModal, setShowDraftModal ] = useState(false)
  const [ draftComment, setDraftComment ] = useState('');

  const closeModal = useCallback(() => {
    setRejectReason('');
    setModalShown(false);
  }, []);

  const submitRejectModal = useCallback(() => {
    onRejectProposal(rejectReason);
  }, [ rejectReason, onRejectProposal ]);

  const closeCancelModal = useCallback(() => {
    setCancelReason('');
    setModalCancelShown(false);
  }, []);

  const handleCancelClick = useCallback(() => {
    setModalCancelShown(true);
  }, []);

  const submitCancel = useCallback((status) => {
    push(location.pathname.split('/').slice(0,2).join('/'))
    cancelInitiative(organization.slug, initiative.slug, cancelReason, status)
  }, [ organization, initiative, cancelInitiative, cancelReason, location, push]);

  const closeDraftModal = useCallback(() => {
    setDraftComment('');
    setShowDraftModal(false);
  }, []);

  const handleDraftClick = useCallback(() => {
    setShowDraftModal(true);
  }, []);

  const submitDraftModal = useCallback(() => {
    onSaveAsDraft(draftComment);
    submitForm();
  }, [ draftComment, onSaveAsDraft, submitForm ]);

  const handleSubmit = useCallback(
    () => {
      if (initiative.status === 'draft') {
        unsetSaveAsDraft();
      }
      submitForm();
    },
    [initiative, unsetSaveAsDraft, submitForm]
  );

  const showDeleteDraftConfirm = useCallback(() => {
    Modal.confirm({
      title: t.delete_initiative_draft_confirmation_modal,
      okText: t.delete_initiative_draft_ok_button,
      okType: 'danger',
      cancelText: t.delete_initiative_draft_no_button,
      onOk() {
        onDeleteDraftInitiative && onDeleteDraftInitiative();
      },
      onCancel() {},
    });
  }, [t, onDeleteDraftInitiative]);

  switch(formMode) {
    case 'proposal':
      return (
        <React.Fragment>
          <Row type="flex" gutter={20} align="middle">
            <Col>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                { t.accept_publish }
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                disabled={loading}
                onClick={() => setModalShown(true)}
              >
                { t.reject_proposal }
              </Button>
            </Col>
          </Row>
          <Modal
            visible={modalShown}
            title={t.reject_proposal}
            onCancel={closeModal}
            onOk={submitRejectModal}
            footer={[
              <Button key="cancel" onClick={closeModal}>{ t.cancel }</Button>,
              <Button key="submit" type="primary" disabled={!rejectReason} onClick={submitRejectModal}>{ t.reject_confirm }</Button>
            ]}
          >
            <p>{ t.reject_proposal_desc }</p>
            <Input.TextArea
              autoSize={ false }
              rows={6}
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </Modal>
        </React.Fragment>
      );
    case 'edit':
      return (
        <React.Fragment>
          <Row type="flex" justify="end">
              <Button
              onClick={handleCancelClick}
              type='danger'
              size="large"
              style={{ marginRight: 10 }}
            >
              { t.cancel_yes }
            </Button>
            <Button
              size="large"
              type="primary"
              disabled={loading}
              onClick={() => setModalShown(true)}
            >
              { t.save_edit }
            </Button>
          </Row>
          <Modal
            visible={modalShown}
            title={t.update_notify_participants}
            onCancel={closeModal}
            onOk={closeModal}
            footer={[
              <Button key="cancel" onClick={closeModal}>{ t.cancel_changes }</Button>,
              <span style={{marginRight: '30px'}}></span>,
              <Button
                key="no"
                htmlType="submit"
                form="initiative_form"
                onClick={() => {onEditConfirm(false); closeModal() }}
              >{ t.update_notify_participants_no }</Button>,
              <Button
                key="yes"
                htmlType="submit"
                form="initiative_form"
                type="primary"
                onClick={() => {onEditConfirm(true); closeModal() }}
              >{ t.update_notify_participants_yes }</Button>
            ]}
          >
            <p>{ t.update_notify_participants_desc }</p>
          </Modal>
          <Modal
            visible={modalCancelShown}
            title={t.cancel_confirmation}
            onCancel={closeCancelModal}
            onOk={submitCancel}
            footer={[
              <Button
                key="cancel_no"
                onClick={closeCancelModal}
              >
                { t.cancel_no }
              </Button>,
              <Button
                key="cancel_delete"
                type="primary"
                hidden={cancelMode === 'suspend'}
                onClick={() => submitCancel('suspended')}
              >
                { t.cancel_yes }
              </Button>,
              <Button
                key="cancel_yes"
                type="primary"
                disabled={cancelMode !== 'cancel' && !cancelReason}
                onClick={() => submitCancel(cancelMode === 'cancel' ? 'deleted' : undefined)}
                danger
              >
                { cancelMode === 'cancel' ? t.cancel_and_delete : t.cancel_yes }
              </Button>,
            ]}
          >
            <p>{ t[`cancel_confirmation_${cancelMode}_${initiative.type}_desc`] }</p>
            { cancelMode === 'cancel' ? null :
              <Input.TextArea
                autoSize={ false }
                rows={6}
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            }
          </Modal>
        </React.Fragment>
      );
    case 'create':
    case 'draft':
    default:
      return (
        <React.Fragment>
          <Row type="flex" justify="end" gutter={10}>
            {formMode === 'draft' && onDeleteDraftInitiative && (
              <Col>
                <Form.Item>
                  <Button
                    size="large"
                    type="danger"
                    disabled={loading}
                    loading={loading}
                    onClick={() => showDeleteDraftConfirm()}
                  >
                    { t.button_delete_draft }
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col>
              <Form.Item>
                <Button
                  size="large"
                  disabled={loading}
                  loading={loading}
                  onClick={handleDraftClick}
                >
                  { t.initiative_draft_save }
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  disabled={loading}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  { t.save_create }
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Modal
            visible={showDraftModal}
            title={t.initiative_draft_save}
            onCancel={closeDraftModal}
            onOk={submitDraftModal}
            footer={[
              <Button key="cancel" onClick={closeDraftModal}>{ t.cancel }</Button>,
              <Button key="submit" type="primary" loading={loading} onClick={submitDraftModal}>{ t.save }</Button>
            ]}
          >
            <p>{ t.initiative_draft_comments }</p>
            <Input.TextArea
              autoSize={ false }
              rows={3}
              value={draftComment}
              onChange={(e) => setDraftComment(e.target.value)}
            />
          </Modal>
        </React.Fragment>
      );
  };
};

export default injectIntl(withRouter(ButtonArea))
