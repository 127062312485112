import React, { useCallback, useState, useMemo, useRef } from 'react';

import colors from 'colors';
import Highlighter from 'react-highlight-words';
import { useHotkeys } from 'react-hotkeys-hook';
import { injectIntl } from 'react-intl';

import { A } from 'aplanet-ui-kit';

import PhoneInput from 'components/PhoneInput';
import UserProfileModal from 'containers/UserProfileModal';
import useForm from 'utils/useForm';
import { usePermissionList } from 'components/PermissionSwitch';

import './style.less';

import {
  Button,
  Modal,
  Table,
  Row,
  Col,
  Alert,
  Avatar,
  Input,
  InputNumber,
} from 'antd';
import {
  UserOutlined,
  EnterOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import validation from './validation';

const AddIndicatorResponseModal = ({
  intl,
  indicator,
  volunteers,
  organization,
  onOk,
  onClose,
  visible,
}) => {
  const t = intl.messages;

  const indicatorUnit = useMemo(
    () => indicator ? t[`unit_${indicator.unit}_symbol`] : '',
    [t, indicator]
  );

  const permissionList = usePermissionList();

  const [modalSearchText, setModalSearchText] = useState('');
  const [userDetail, setUserDetail] = useState(null);

  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    onOk(values);
    resetInputFields();
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
  });

  const getValue = useCallback(
    (name) => typeof values[name] === 'undefined' ? '' : values[name],
    [values]
  );

  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [errors, isDirty]
  );

  const searchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if (searchInputRef.current.focus) {
      searchInputRef.current.focus()
      e.preventDefault()
    }
  }, [searchInputRef])

  const getHighlighter = useCallback(
    (value) => (
      !modalSearchText
        ? value
        : <Highlighter
            highlightStyle={{
              backgroundColor: colors['@success-color'],
              padding: 0
            }}
            searchWords={[modalSearchText]}
            autoEscape
            textToHighlight={value}
          />
    ), [modalSearchText]
  );

  const hideAddButton = useMemo(
    () => !getValue('value') || !getValue('name') || !getValue('email'),
    [getValue],
  );

  const getAvatar = useCallback(
    (record) => (
      <Avatar
        size={38}
        shape="circle"
        src={record.avatar}
        name={record.name}
        icon={<UserOutlined />}
      />
    ), []
  );

  const columnsVolunteers = useMemo(() => ([
    {
      title: t.name ,
      dataIndex: 'name',
      key: 'name',
      filteredValue: modalSearchText
        ? [modalSearchText]
        : null,
      onFilter: (value, record) =>
        (record.name || '').toLowerCase().includes(value) ||
        (record.email || '').toLowerCase().includes(value) ||
        (record.phone || '').toLowerCase().includes(value),
      render: (name, record) => (
        <Row type="flex" gutter={10} justify="left" align="middle">
        <Col>
          { permissionList.has('can_manage_volunteers') && record.slug
            ? (
                <A onClick={() => setUserDetail(record.slug)}>
                  { getAvatar(record) }
                </A>
              )
            : getAvatar(record)
          }
        </Col>
        <Col>
          <Row> { getHighlighter(name) } </Row>
          <Row> { getHighlighter(record.email) } </Row>
          <Row> { getHighlighter(record.phone) } </Row>
        </Col>
      </Row>
      ),
    },
    {
      title: '',
      align: 'right',
      render: (text, record) => (
        <Button
          icon={<EnterOutlined />}
          onClick={() => {
            handleChange('name')(record.name || '');
            handleChange('email')(record.email || '');
            handleChange('phone')(record.phone || '');
          }}
          >
            {t.use}
        </Button>
      ),
    }
  ]), [t, getAvatar, modalSearchText, getHighlighter, handleChange, permissionList]);

  const resetInputFields = useCallback(
    () => {
      handleChange('value')('');
      handleChange('name')('');
      handleChange('email')('');
      handleChange('phone')('');
      setModalSearchText('');
    }, [handleChange]
  );

  const handleOnClose = useCallback(
    () => {
      resetInputFields();
      onClose();
    }, [resetInputFields, onClose]
  );

  return (
    <>
      <Modal
        visible={visible}
        title={t.add_new_indicator_title}
        centered={true}
        width={900}
        onCancel={handleOnClose}
        footer={false}
        className="IndicatorResponse__modal"
      >
        <Row>
          <Col>
            <InputNumber
              placeholder={indicatorUnit}
              size="large"
              name="value"
              value={getValue('value')}
              onChange={handleChange('value')}
              className="IndicatorResponse__modal_input_number"
              formatter={value => `${(value && value.replace(/^0+/, '')) || 0} ${indicatorUnit}`}
              parser={value => value.replace(/[^0-9]/g, '')}
            />
            <Input.Search
              type="text"
              prefix={<SearchOutlined />}
              placeholder={t.volunteer_search_placeholder}
              enterButton={t.volunteer_search}
              onSearch={(term) => setModalSearchText((term || '').toLowerCase())}
              onChange={(term) => !term.target.value && setModalSearchText('')}
              allowClear="allowClear"
              ref={searchInputRef}
            />
            <div className="Whitespace20"></div>
            <Table
              size="small"
              columns={columnsVolunteers}
              dataSource={volunteers.items}
              pagination={{ pageSize: 3, simple: true }}
            />
          </Col>
          <Row type="flex">
            <Col className='participantDetail'>
              <Input
                name="name"
                value={getValue('name')}
                onChange={handleChangeEvent}
                size='middle'
                placeholder={t.name}
                allowClear="allowClear"
              />
            </Col>
            <Col className='participantDetail'>
              <Input
                name="email"
                value={getValue('email')}
                onChange={handleChangeEvent}
                size='middle'
                placeholder={t.email}
                allowClear="allowClear"
              />
              {
                showError('email')
                ? <Alert message={t.form_error_invalid_email} type="error" showIcon />
                : null
              }
            </Col>
            <Col className='participantDetail'>
              <PhoneInput
                placeholder={ t.phone }
                name="phone"
                value={getValue('phone')}
                onChange={handleChange('phone')}
                country={organization.region}
              />
            </Col>
            <Col>
              <Button
                icon={<PlusOutlined />}
                type='primary'
                disabled={hideAddButton}
                onClick={handleSubmit}
              >
                {t.participants_addNewParticipant}
              </Button>
            </Col>
          </Row>
        </Row>
      </Modal>
      <UserProfileModal
        slug={userDetail}
        visible={!!userDetail}
        onClose={() => setUserDetail(null)}
      />
    </>
  )
}

export default injectIntl(AddIndicatorResponseModal);
