import React from 'react';
import { Router } from 'react-router';
import { IntlProvider } from 'react-intl-redux'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import configureStore, { history } from './store';
import './App.less';

import Root from './containers/Root';
import ScrollTop from './components/ScrollTop';

// Setup icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUp,
  faBook,
  faCaretDown,
  faChartPie,
  faClipboard,
  faCog,
  faCopy,
  faCrown,
  faEllipsisV,
  faEnvelope,
  faEye,
  faFileUpload,
  faGraduationCap,
  faHandHoldingUsd,
  faHandsHelping,
  faHandshake,
  faInfoCircle,
  faMapMarkedAlt,
  faMedal,
  faMoneyBillAlt,
  faPen,
  faPhone,
  faPlus,
  faQrcode,
  faShareAlt,
  faSitemap,
  faSlash,
  faThList,
  faThumbsUp,
  faUnlockAlt,
  faUserCog,
  faUsers,
  faUsersCog,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';

import {
  faBuilding,
  faEdit,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faArrowUp,
  faBook,
  faBuilding,
  faCaretDown,
  faChartPie,
  faClipboard,
  faCog,
  faCopy,
  faCrown,
  faEllipsisV,
  faEnvelope,
  faEdit,
  faEye,
  faFileUpload,
  faGraduationCap,
  faHandHoldingUsd,
  faHandsHelping,
  faHandshake,
  faInfoCircle,
  faMapMarkedAlt,
  faMedal,
  faMoneyBillAlt,
  faPen,
  faPhone,
  faPlus,
  faQrcode,
  faQuestionCircle,
  faShareAlt,
  faSitemap,
  faSlash,
  faThList,
  faThumbsUp,
  faUnlockAlt,
  faUserCog,
  faUsers,
  faUsersCog,
  fab,
  faWifi,
);

export const store = configureStore();

const App = () =>
  <Provider store={store}>
    <IntlProvider>
      <Router history={history}>
        <ScrollTop>
          <Switch>
            <Route path="/" component={Root}/>
          </Switch>
        </ScrollTop>
      </Router>
    </IntlProvider>
  </Provider>;

export default App;
