import React, {
  useMemo,
  useCallback,
} from 'react';
import './style.less';

import { stringToHash } from 'utils/hash';

const COLORS = ['yellow', 'blue', 'green'];

const CustomTag = ({
  name,
  onDelete = null,
  className = '',
}) => {
  const colorClass = useMemo(() => {
    return COLORS[stringToHash(name, COLORS.length)];
  }, [
    name,
  ]);

  const handleClose = useCallback(() => onDelete(name),
    [
      onDelete,
      name,
    ]
  );

  return (
    <span
      className={`
        CustomTag
        ${className || ''}
        ${!onDelete ? '' : 'CustomTag__deletable'}
        CustomTag__color_${colorClass}
      `}
    >
      { name }

      {
        !onDelete ? null : (
          <button
            className="CustomTag__deletebutton"
            onClick={handleClose}
          >
            &times;
          </button>
        )
      }
    </span>
  );
};

export default CustomTag;
