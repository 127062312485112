import { useContext, createContext } from 'react';
const Context = createContext(new Set());
const { Provider } = Context;
const usePermissionList = () => useContext(Context);

const PermissionSwitch = ({
  permission,
  children,
}) => {
  const permissionList = usePermissionList();

  const isActive = (typeof permission === 'function')
    ? permission(permissionList)
    : permissionList.has(permission);

  return isActive ? children : null;
}

export {
  Provider as PermissionProvider,
  usePermissionList,
  PermissionSwitch,
};
