import React from 'react';
import './style.less';

import {
  Button,
} from 'antd';

const FloatingButton = ({
  onClick,
  iconUrl
}) => {

  return (
    <Button
      onClick={onClick}
      shape='circle'
      type='primary'
      className='FloatingButton'
    >
      <img src={iconUrl} alt=''/>
    </Button>
  )
}

export default FloatingButton;
