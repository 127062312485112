import localForage from 'localforage';
import jsCookie from 'js-cookie'

import config from 'config';

const storage = localForage.createInstance({
  name: 'aplanet-user-front',
  version: 1.0,
  storename: 'keyvaluepairs',
  description: 'APlanet user frontend cache'
});

const ITEM_NAME = 'saved_state';

// Settings to store the orgazanition in a cookie
const stateName = 'organization_slug';

// NOTICE: if the domain is not in the URL, we need to use something else
const hostname = window.location.hostname;
const cookieDomain = hostname.endsWith(config.ORGANIZATION_COOKIE_DOMAIN)
  ? config.ORGANIZATION_COOKIE_DOMAIN
  : hostname;
const cookieName = config.ORGANIZATION_COOKIE_NAME;
const cookiePath = '/';
const cookieExpire = 365; // One year

export const loadState = () => {
  const cookieValue = jsCookie.get(cookieName);
  const baseState = cookieValue
    ? { [stateName]: cookieValue }
    : undefined;

  return storage
    .getItem(ITEM_NAME)
    .then(state => ({
      ...(state || {}),
      ...(baseState || {}),
    }))
    .catch(err => baseState);
};

export const saveState = (state) => {
  const cookieValue = (state || {})[stateName];
  const cookieLocal = cookieDomain === 'localhost';
  const cookieParams = {
    ...(cookieLocal ? {} : { domain: cookieDomain }),
    path: cookiePath,
    secure: !cookieLocal,
    sameSite: cookieLocal ? 'lax' : 'none',
  };
  if(cookieValue) {
    jsCookie.set(
      cookieName,
      cookieValue,
      {
        ...cookieParams,
        expires: cookieExpire,
      },
    )
  }
  // NOTICE: We do not remove the cookie here

  return storage
    .setItem(ITEM_NAME, state)
    .catch(err => undefined);
}

