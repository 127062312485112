import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import { injectIntl } from 'react-intl';

import {
  Input,
  Form,
  Modal,
  Button,
} from 'antd';

const ModalRejectReason = ({
  intl,
  visible,
  onClose,
  onSubmit,
  loading = false,
  uuid,
}) => {
  const t = intl.messages;

  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    onSubmit(
      uuid,
      comment,
    );
  }, [
    onSubmit,
    uuid,
    comment,
  ]);

  const handleCommentChange = useCallback((e) => {
    setComment(e.target.value || '');
  }, []);

  useEffect(() => {
    if(submitting && !loading) {
      setSubmitting(false);
      setComment('');
      onClose();
    }
  }, [
    submitting,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="ModalRejectReason"
      visible={visible}
      title={ t.external_manager_approval_reject_modal_title }
      onOk={handleSubmit}
      onCancel={onClose}
      footer={
        <div className="ModalRejectReason__footer">
          <Button
            className="ModalRejectReason__btn-footer"
            key="back"
            onClick={onClose}
          >
            { t.external_manager_approval_reject_modal_cancel }
          </Button>
          <Button
            className="ModalRejectReason__btn-footer"
            key="submit"
            type="primary"
            onClick={handleSubmit}
          >
            { t.external_manager_approval_reject_modal_ok }
          </Button>
        </div>
      }
    >
      <section>
        <p>
          { t.external_manager_approval_reject_modal_comment }
        </p>
        <Form onFinish={handleSubmit}>
          <Form.Item label={t.external_manager_approval_reject_modal_label} colon={false}>
            <Input.TextArea
              type="text"
              value={comment}
              onChange={handleCommentChange}
            />
          </Form.Item>
        </Form>
      </section>
    </Modal>
  )
};

export default injectIntl(ModalRejectReason);

