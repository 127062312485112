const approve = (
  token,
  uuid,
  comment,
) => ({
  type: 'MANAGER_APPROVAL_APPROVE',
  token,
  uuid,
  comment,
});

const reject = (
  token,
  uuid,
  comment,
) => ({
  type: 'MANAGER_APPROVAL_REJECT',
  token,
  uuid,
  comment,
});

const request = token => ({
  type: 'MANAGER_APPROVAL_REQUEST',
  token
});

export {
  approve,
  reject,
  request,
}
