import React, {Fragment, useCallback, useMemo, useRef, useState} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';

import ImageCrop from 'components/ImageCrop';
import { useFeatureList } from 'components/FeatureSwitch';
import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

import { CustomTextArea } from 'aplanet-ui-kit';
import {
  Card,
  Button,
  Select,
  Form,
  Input,
  Row,
  Col,
  Switch,
  Tooltip,
  Modal,
  List
} from 'antd';
import {
  UploadOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';


const GeneralInfo = ({
  intl,
  organization,
  getValue,
  showError,
  handleChangeEvent,
  handleChange,
  categories,
  targetAudience,
  values,
  formMode,
  canEditTitle,
  showCollaborative = true,
  hideCategories,
  hideTargetGroups,
}) => {
  const t = intl.messages;
  const featureList = useFeatureList();

  // Some local state for the image crop
  const [imageCropOpen, setImageCropOpen] = useState(false);
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const [modalInfoType, setModalInfoType] = useState('');

  const fileInput = useRef(null);
  const onFileChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    reader.onload = function(img) {
      if (fileInput) {
        fileInput.current.value = '';
      }
      handleChange('image')(img.target.result);
      setImageCropOpen(true);
    }

    reader.readAsDataURL(file);
  };

  const getCategoryOrTargetAud = useCallback((groupType) => {
    return {
      group1: groupType === 'category' ? 'category1' : 'targetAudience1',
      group2: groupType === 'category' ? 'category2' : 'targetAudience2',
      group3: groupType === 'category' ? 'category3' : 'targetAudience3'
    }
  }, []);

  const hasProgram = useMemo(() => {
    const orgFeatures = organization?.features;
    if(!orgFeatures) {
      return false;
    }

    return orgFeatures.includes('program');
  }, [
    organization,
  ]);

  const hasNoOption = useCallback((groupType, group) => {
    const { group1, group2, group3 } = getCategoryOrTargetAud(groupType);
    if (group === group3) {
      return true;
    }
    return group === group1 ? !values[group2] : !values[group3];
  }, [getCategoryOrTargetAud, values]);

  const checkDisabledGroupSelector = useCallback((groupType, group) => {
    const { group1, group2 } = getCategoryOrTargetAud(groupType);
    if (group === group1) {
      return false;
    }
    return group === group2 ? !values[group1] : !values[group1] || !values[group2];
  }, [getCategoryOrTargetAud, values]);

  const checkDisabledSelectorOption = useCallback((groupType, group, slug) => {
    const { group1, group2, group3 } = getCategoryOrTargetAud(groupType);
    if (group === group1) {
      return [values[group2], values[group3]].includes(slug);
    }
    if (group === group2) {
      return [values[group1], values[group3]].includes(slug);
    }
    if (group === group3) {
      return [values[group1], values[group2]].includes(slug);
    }
  }, [getCategoryOrTargetAud, values]);

  // For image upload in text area
  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(
    () => dispatch(_refreshAccessToken()),
    [ dispatch ]
  );
  const imageUploadUrl = useMemo(
    () => `${config.API_URL}/${organization.slug}/text_editor_image`,
    [organization]
  );

  const onCloseModalInfo = useCallback(() => {
    setModalInfoOpen(false);
    setModalInfoType('');
  }, []);

  const onOpenModalInfo = useCallback((type) => {
    setModalInfoType(type);
    setModalInfoOpen(true);
  }, []);

  const modalInfoData = useMemo(() => {
    const dataInfo = modalInfoType === 'category' ? categories : targetAudience;
    return dataInfo.map(({slug}) => slug).sort();
  }, [modalInfoType, categories, targetAudience]);

  const modalInfoTitle = useMemo(() => {
    if (modalInfoType) {
      return intl.formatMessage({ id: `${modalInfoType === 'category' ? 'categories' : 'target_audience'}` })
    }
  }, [intl, modalInfoType]);

  return (
    <Fragment>
    <Card className="InitiativeForm-card" title={t.initiative_general_info}>
      <Row type="flex">
        <Col xs={{
            span: 24,
            order: 2
          }} md={{
            span: 8,
            order: 1
          }}>
          <Form.Item
            label={t.initiative_image}
            colon={false}
          >
            <div className="InitiativeForm-image">
              {
                getValue('image') && !imageCropOpen
                  ? <img src={getValue('image')} alt=""/>
                  : null
              }
            </div>
            <div className="InitiativeForm-fileuploadwrap">
              <input className="InitiativeForm-fileupload" ref={fileInput} type="file" accept="image/*" onChange={onFileChange}/>
              <Button><UploadOutlined/>{t.initiative_image_upload}</Button>
            </div>
          </Form.Item>
        </Col>
        <Col xs={{
            span: 24,
            order: 1
          }} md={{
            span: 16,
            order: 2
          }}>
          <Form.Item
            hasFeedback
            validateStatus={showError('title')
              ? 'error'
              : ''}
            help={(formMode === 'create') && (showError('title') || t.initiative_title_max_characters)}
            label={t.title}
            colon={false}
            required
          >
              <Input
                placeholder={t.title_desc}
                disabled={formMode === 'edit' && !canEditTitle}
                name="title"
                value={getValue('title')}
                onChange={handleChangeEvent}
              />
          </Form.Item>

          { hasProgram && (
            <Form.Item
              hasFeedback
              validateStatus={showError('program')
                ? 'error'
                : ''}
              label={t.program}
              colon={false}
            >
                <Input
                  placeholder={t.program_desc}
                  name="program"
                  value={getValue('program')}
                  onChange={handleChangeEvent}
                />
            </Form.Item>
          )}

          <Form.Item
            validateStatus={showError('description')
              ? 'error'
              : ''}
            help={showError('description')}
            label={t.description}
            colon={false}
            required
          >
            <CustomTextArea
              intl={intl}
              placeholder={t.description_desc}
              value={getValue('description')}
              onChange={handleChange('description')}
              supportImages={true}
              imageUploadUrl={imageUploadUrl}
              api_requests={api_requests}
              refreshAccessToken={refreshAccessToken}
            />
          </Form.Item>

          { !showCollaborative || !featureList.has('collaborations') ? null :
            <Form.Item
              label={<span>{ t.collaborative_initiative } <Tooltip placement="bottomRight" title={t.collaborative_initiative_desc}><InfoCircleOutlined /></Tooltip></span>}
              colon={false}
              hasFeedback="hasFeedback"
              validateStatus={showError('collaborative') ? 'error' : ''}
              help={showError('collaborative')}
              labelCol={{
                xs: 24,
                sm: 18
              }}
              wrapperCol={{
                xs: 24,
                sm: 6
              }}
            >
              <Switch
                checkedChildren={t.yes}
                unCheckedChildren={t.no}
                checked={getValue('collaborative')}
                onChange={handleChange('collaborative')}
              />
            </Form.Item>
          }
          { !hideCategories && (
            <Form.Item
              label={(
                <span className="InitiativeForm__categories">
                  {t.categories}
                  <InfoCircleOutlined
                    onClick = {() => onOpenModalInfo('category')}
                  />
                </span>
              )}
              colon={false}
            >
              <Row type="flex" gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24
                }}>
                  {['category1', 'category2', 'category3'].map(categoryGroup => (
                    <Col key={categoryGroup} xs={24} sm={8}>
                      <Select
                        placeholder={t.category}
                        value={values[categoryGroup]}
                        name={categoryGroup}
                        onChange={handleChange(categoryGroup)}
                        disabled={checkDisabledGroupSelector('category', categoryGroup)}
                      >
                        { hasNoOption('category', categoryGroup) && (
                          <Select.Option value={null}>{t.no_category}</Select.Option>
                        )}
                        {categories.map(category => (
                          <Select.Option
                            key={category.slug}
                            value={category.slug}
                            disabled={checkDisabledSelectorOption('category', categoryGroup, category.slug)}
                          >
                            <span title={t[`category_${category.slug}`]}>{t[`category_${category.slug}`]}</span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  ))}
              </Row>
            </Form.Item>
          )}
          { !hideTargetGroups && (
            <Form.Item
              label={(
                <span className="InitiativeForm__targetAudience">
                  {t.target_audience}
                  <InfoCircleOutlined
                    onClick = {() => onOpenModalInfo('target_audience')}
                  />
                </span>
              )}
              colon={false}
            >
              <Row type="flex" gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24
                }}>
                  {['targetAudience1', 'targetAudience2', 'targetAudience3'].map(targetAudienceGroup => (
                    <Col key={targetAudienceGroup} xs={24} sm={8}>
                      <Select
                        placeholder={t.target_audience}
                        value={values[targetAudienceGroup]}
                        name={targetAudienceGroup}
                        onChange={handleChange(targetAudienceGroup)}
                        disabled={checkDisabledGroupSelector('targetAudience', targetAudienceGroup)}
                      >
                        { hasNoOption('targetAudience', targetAudienceGroup) && (
                          <Select.Option value={null}>{t.no_target_audience}</Select.Option>
                        )}
                        {targetAudience.map(item => (
                          <Select.Option
                            key={item.slug}
                            value={item.slug}
                            disabled={checkDisabledSelectorOption('targetAudience', targetAudienceGroup, item.slug)}
                          >
                            <span title={t[`target_audience_${item.slug}`]}>{t[`target_audience_${item.slug}`]}</span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  ))}
              </Row>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Card>
    {
      !imageCropOpen
        ? null
        : <ImageCrop t={t} src={getValue('image')} onCrop={(image) => {
              handleChange('image')(image);
              setImageCropOpen(false);
            }} onCancel={() => {
              handleChange('image')(null);
              setImageCropOpen(false)
            }}/>
    }
      <Modal
        visible={modalInfoOpen}
        title={modalInfoTitle}
        centered
        onCancel={onCloseModalInfo}
        footer={null}
      >
        <List
          layout="vertical"
          dataSource={modalInfoData}
          renderItem={slug => (
            <List.Item className="InitiativeForm-modalInfoListItem" key={slug}>
              {modalInfoType && (
                <div>
                  <div><strong>{intl.formatMessage({ id: `${modalInfoType}_${slug}` })}</strong></div>
                  {intl.formatMessage({ id: `${modalInfoType}_${slug}_desc` })}
                </div>
              )}
              
            </List.Item>
          )}
        />
      </Modal>
    </Fragment>
  );
};

export default injectIntl(GeneralInfo);
