import React from 'react';
import { injectIntl } from 'react-intl';

import SdgLogo from 'components/SdgLogo';

import {
  Button,
} from 'antd';

const IDENTITY = a => a;
const TABLE_LIMIT = 5;

const HourTable = ({
  title,
  emptyText,
  intl,
  entries = [],
  isSdg = false,
  isHours = false,
  nameProp = 'id',
  valueProp = 'value',
  otherValueProp = null,
  transformName = IDENTITY,
  onMore = null,
  showAll = false,
}) => {
  const t = intl.messages;

  return (
    <>
      <div className="UserProfileModal__card">
        <div className="UserProfileModal__title">
          <div
            className="UserProfileModal__title__title"
          >
            {title}
          </div>
          {!onMore || showAll || !entries || entries.length <= TABLE_LIMIT ? null :
            <div className="UserProfileModal__link UserProfileModal__pullright">
              <Button onClick={onMore} >
                {t.see_all}
              </Button>
            </div>
          }
        </div>
        <div className="UserProfileModal__content">
          {entries && entries.length > 0
            ?
            <>
              <div className="UserProfileModal__list" >
                {entries.filter((_, index) => !onMore || index < TABLE_LIMIT).map((entry) =>
                  <div
                    key={entry.id}
                  >
                    <div>
                      {!isSdg ? null :
                        <SdgLogo
                          sdg={entry.id}
                          className="UserProfileModal__sdglogo"
                        />
                      }
                      {transformName(entry[nameProp])}
                    </div>
                    <div>
                      {`${entry[valueProp]} h`}
                      {
                        !otherValueProp
                        ? null
                        : ` / ${entry[otherValueProp] || '-'} p`
                      }
                    </div>
                  </div>
                )}
              </div>
            </>
            :
            <>
              <div className="UserProfileModal__empty">{emptyText}</div>
            </>
          }
        </div>
      </div>
    </>
  );
};

export default injectIntl(HourTable);
