import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  rangeOverlap,
  formatDb,
} from 'utils/date';

import {
  Col,
  Row,
  Input,
  DatePicker,
} from 'antd';


const { Search } = Input;
const { RangePicker } = DatePicker;

const Filters = ({
  intl,
  data = [],
  setFilteredData,
}) => {
  const t = intl.messages;
  const [searchText, setSearchText] = useState('');
  const [dateRangeValues, setDateRangeValues] = useState(['', '']);
  const [dateRange, setDateRange] = useState(null);

  useEffect(
    () => {
      let result = [...data];
      result = result.filter(
        item => {
          const searchMatch = (
            !searchText ||
            (item.filename || '').toLowerCase().includes(searchText.toLowerCase()) ||
            (item.member_name || '').toLowerCase().includes(searchText.toLowerCase())
          );
    
          const dateMatch = (
            !dateRange ||
            rangeOverlap(
              [
                `${dateRange[0]}T00:00:00.000Z`,
                `${dateRange[1]}T23:59:59.999Z`,
              ],
              [
                formatDb(item.created_at), // NOTICE: this should solve timezones...
                formatDb(item.created_at),
              ]
            )
          );
          return searchMatch && dateMatch;
        }
      );
      setFilteredData(result);
    },
    [data, setFilteredData, searchText, dateRange]
  );

  const handleDateRangeChange = useCallback(
    (dates, dateStrings) => {
      setDateRangeValues(dates);
      setDateRange(
        dateStrings.filter(Boolean).length === 0 ? null : dateStrings
      );
    },
    []
  );

  return (
    <Row className="InitiativeDocuments-filters" gutter={20}>
      <Col span={12}>
        <Search
          size="large"
          placeholder={t.initiative_documents_search}
          onSearch={setSearchText}
          enterButton
          allowClear
        />
      </Col>
      <Col span={10}>
        <RangePicker
          className="InitiativeDocuments-filters-range"
          size="large"
          value={dateRangeValues}
          onChange={handleDateRangeChange}
        />
      </Col>
    </Row>
  )
}

export default injectIntl(Filters);