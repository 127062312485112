import React, {
  useCallback,
  useMemo,
} from "react";
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isEmpty } from "lodash";
import {
  Avatar,
  CustomInputSearch
} from 'aplanet-ui-kit';
import EmptySearch from "components/EmptySearch";

const ChatUsersList = ({
  intl,
	useChat,
  setShowChatUsersList,
  searchUser,
  setSearchUser,
}) => {
	const {
		users,
    setActiveConversation,
    setCurrentMessage,
    conversations,
    messages,
    setMessagesAsRead,
	} = useChat

  const usersList = useMemo(() => {
    const list = {}
    const orderedUsers = users
      .filter(u => u.username.toLowerCase().includes(searchUser.toLowerCase()))
      .sort((a, b) => a.username.localeCompare(b.username))
    orderedUsers.forEach(user => {
      const firstLetter = user.username.slice(0, 1).toLowerCase()
      if (list[firstLetter]) {
        list[firstLetter].push(user)
      } else {
        list[firstLetter] = [user]
      }
    });
    return list
  }, [
    searchUser,
    users,
  ])

  const openConversation = useCallback((user) => {
    const conversation = conversations.find(c => c.participants.length > 0 && c.participants[0].id === user.id)
    setCurrentMessage('')
    setShowChatUsersList(false)
    setActiveConversation(conversation?.id)
    if (messages[conversation?.id] && conversation.unreadCounter) {
      setMessagesAsRead(conversation?.id, messages[conversation?.id], conversation.unreadCounter)
    }
  }, [
    conversations,
    messages,
    setActiveConversation,
    setCurrentMessage,
    setMessagesAsRead,
    setShowChatUsersList,
  ])

	return (
    <div className='GlobalChat__chatUsersList'>
      <div className="GlobalChat__search">
        <CustomInputSearch
          value={searchUser}
          onChange={setSearchUser}
          placeholder={intl.formatMessage({ id: `chat_users_list_search_placeholder` })}
          allowClear
        />
      </div>
      {isEmpty(usersList) ? (
        <EmptySearch />
      ) : (
      Object.keys(usersList).map(firstLetter =>
        <div key={firstLetter.toUpperCase()}>
          <div className='GlobalChat__firstLetter'>{firstLetter.toUpperCase()}</div>
          {usersList[firstLetter].map(user => (
            <div
              key={user.id}
              className='GlobalChat__userListWrapper'
              onClick={() => openConversation(user)}
            >
              <Avatar src={user.avatar} name={user.username} />
              <div className='GlobalChat__userList'>{user.username}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
	)
}

ChatUsersList.propTypes = {
  searchUser: PropTypes.string,
	setSearchUser: PropTypes.func.isRequired,
	setShowChatUsersList: PropTypes.func.isRequired,
	useChat: PropTypes.object.isRequired,
};

export default injectIntl(ChatUsersList)