const TARGET = 'initiative_detail';
const PARTICIPATION_TARGET = 'participation_detail';
const PARTICIPATION_REJECT_TARGET = 'participation_reject';
const PARTICIPATION_ID_FIELD = 'participant_id';
const PARTICIPATION_NEWPARTICIPANT = 'participant_new';
const CONTRIBUTION_TARGET = 'contribution_detail';
const META_TARGET = 'initiative_meta';

const isTarget = (target) => [
  TARGET,
  PARTICIPATION_TARGET,
  PARTICIPATION_REJECT_TARGET,
  PARTICIPATION_NEWPARTICIPANT,
  CONTRIBUTION_TARGET,
  META_TARGET,
].includes(target);
const isGeneralTarget = (target) => target === TARGET;
const isNewParticipantTarget = (target) => target === PARTICIPATION_NEWPARTICIPANT
const isContributionTarget = (target) => target === CONTRIBUTION_TARGET;
//const isParticipationTarget = (target) => target === PARTICIPATION_TARGET;
//const isRejectTarget = (target) => target === PARTICIPATION_REJECT_TARGET;

const initialState = {
  data: null,
  loading: false,
  loading_member_id: null,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        data: action.method === 'GET' ? null : state.data,
        loading: true,
        loading_member_id: isGeneralTarget(action.target) ? null : action.member_id,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(action.response.target === META_TARGET) {
        if(
          action.response.body &&
          action.response.body.add_tags
        ) {
          return {
            ...state,
            data: {...state.data,
              tags: [
                ...state.data.tags,
                ...(
                  action.response.body.add_tags.filter(
                    tag => !state.data.tags.includes(tag)
                  )
                ),
              ],
            },
            loading: false,
            error: false,
          }
        }
        if(action.response.body.remove_tags) {
          return {
            ...state,
            loading: false,
            error: null,
            data: {
              ...(state.data),
              tags: (state.data.tags || []).filter(
                tag => !action.response.body.remove_tags.includes(tag),
              ),
            }
          }
        }
      }

      if(!action.response || !isTarget(action.response.target)) return state;
      if(isGeneralTarget(action.response.target) || isContributionTarget(action.response.target)) {
        return {
          data: action.response.result,
          loading: false,
          loading_member_id: null,
          error: false,
        };
      } else {
        const targetParticipation = action.response.result;
        if(
          !targetParticipation ||
          !state.data ||
          !['POST', 'PUT', 'DELETE'].includes(action.response.method)
        ) {
          // This is not a request for me, bailing out
          return state;
        }

        // New participant added
        if(['POST'].includes(action.response.method) && isNewParticipantTarget(action.response.target)){
          return {
            data: {
              ...state.data,
              participation: action.response.result.participation,
              participation_other: action.response.result.participation_other,
            },
            loading: false,
            loading_member_id: null,
            error: action.code,
          };
        }

        // NOTICE: Valid both fot PARTICIPATION_TARGET and PARTICIPATION_REJECT_TARGET
        const participation = state.data.participation || [];
        const participation_other = state.data.participation_other || [];
        const index = participation.findIndex((p) => p[PARTICIPATION_ID_FIELD] === targetParticipation[PARTICIPATION_ID_FIELD]);
        const index_other = !participation_other ? -1 : participation_other.findIndex((p) => p[PARTICIPATION_ID_FIELD] === targetParticipation[PARTICIPATION_ID_FIELD]);
        const { participation_pending_count } = state.data;

        return {
          data: {
            ...state.data,
            participation_pending_count: action.response.participationValidation ? (participation_pending_count - 1) : participation_pending_count,
            participation: index < 0 ? participation : [ ...participation.slice(0, index), targetParticipation, ...participation.slice(index+1) ],
            participation_other: (!participation_other || index_other < 0) ? participation_other : [ ...(participation_other.slice(0, index_other)), targetParticipation, ...(participation_other.slice(index_other+1)) ],
          },
          loading: false,
          loading_member_id: null,
          error: false,
        };
      }
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        loading_member_id: null,
        error: action.code
      };
    case 'INITIATIVE_INDICATOR_VALIDATED':
      return {
        ...state,
        data: {
          ...state.data,
          indicator_pending_count: state.data.indicator_pending_count - 1,
        }
      }
    default:
      return state;
  }
};

export {
  reducer as initiative_detail,
};
