import React from 'react';
import { injectIntl } from 'react-intl';
import Avatar from 'components/Avatar';

const Organization = ({
  intl,
  collaborators = [],
}) => {
  const t = intl.messages
  return (
    <section className='actionCollaborators'>
      { collaborators.map(collaborator => (
        <section className='actionCollaborators-collaborator'>
          <Avatar className='actionCollaborators-avatar' shape="square" src={collaborator.logo_small} />
          <section>
            <div className='actionCollaborators-collaborating'>{`${t.collaborating}:`}</div>
            <div className='actionCollaborators-name'>{collaborator.name}</div>
          </section>
        </section>
      ))}
    </section>
  )}

export default injectIntl(Organization);
