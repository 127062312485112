import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import colors from 'colors'
import Avatar from 'components/Avatar';
import { formatLong, formatDateLong } from 'utils/date';
import Highlighter from 'react-highlight-words'
import { useHotkeys } from 'react-hotkeys-hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Table,
} from 'antd';
import {
  FilterOutlined,
  UserOutlined,
  MessageOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { A } from 'aplanet-ui-kit';
import AuthorizedLink from 'containers/AuthorizedLink';

import { getParticipantsDownloadUrl } from 'actions/api';

import './style.less';

import {
  validateContribution,
  rejectContribution,
  broadcastMessage,
  resetBroadcastMessage,
  requestOrgVolunteers,
  addContribution,
} from 'actions/api';

import UserProfileModal from 'containers/UserProfileModal'
import { usePermissionList } from 'components/PermissionSwitch';

import useOrganizations from 'utils/useOrganizations'
import AddContributionModal from 'containers/AddContributionModal';
import SendMessageModal from 'components/SendMessageModal';
import InitiativeContributorsFilters from './InitiativeContributorsFilters';
import { isDeletedUser, getUserName } from 'utils/user';

const InitiativeContributors = ({
  intl,
  loading: loading_member_id,
  ...initiative
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const {
    organization,
  } = useOrganizations();

  const { done } = useSelector(state => state.broadcast_message);
  const { items: volunteersItems } = useSelector(state => state.volunteers);
  const {
    data: initiativeDetailData,
    loading: initiativeDetailLoading,
    error: initiativeDetailError,
  } = useSelector(state => state.initiative_detail);

  const { contributions } = initiativeDetailData;
  const permissionList = usePermissionList();
  const [userDetail, setUserDetail] = useState(null);

  // Contributions data
  const data = useMemo(() => contributions.map((c) => (
    {
      key: c.id,
      id: c.id,
      name: c.name,
      member_id: c.member_id,
      slug: c.slug, // TODO: If we accept contributions from third parties hide this field
      email: c.email,
      phone: c.phone,
      avatar: c.avatar,
      status: c.status,
      member_status: c.member_status,
      joined_at: c.joined_at,
      loading: c.member_id === loading_member_id,
      value: c.value,
      currency: c.currency,
      concept: c.concept,
      comment: c.comment,
      source: c.source,
      params: c.params,
      created: c.created_at
    }
  )), [ contributions, loading_member_id ]);

  useEffect(() => {
    if(done) {
      Modal.success({
        title: t.sent,
        content: t.broadcast_message_sent,
        onOk: () => dispatch(resetBroadcastMessage())
      });
    }
  }, [ done, dispatch, t ]);

  // Request volunteers to back
  useEffect(() => {
    dispatch(requestOrgVolunteers(organization.slug));
  }, [organization.slug, dispatch])

  // Handle name/contact filtering
  const handleContactFilter = useCallback((value, record) => {
    return (value === 'onlyPhone' && record.phone && !record.email)
    || (value === 'onlyEmail' && !record.phone && record.email);
  }, []);

  // Search text state
  const [searchText, setSearchText] = useState('')

  // Row selection functionality
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  // State for showing add contribution modal
  const [addContributionModal, setAddContributionModal] = useState(false);

  // Focus search field on Ctrl+F
  const searchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if (!addContributionModal && searchInputRef.current.focus) {
      searchInputRef.current.focus()
      e.preventDefault()
    }
  }, [searchInputRef, addContributionModal]);

  /* --- Filters --- */
  const [filtersConfigState, setFiltersConfigState] = useState({
    filteredContact: [],
    filteredStatus: [],
    filteredSources: []
  })

  const {
    filteredStatus,
    filteredSources,
    filteredContact,
  } = filtersConfigState;

  const [openFilters, setOpenFilters] = useState(false)

  const onCloseFilters = useCallback(() => {
    setOpenFilters(false);
  }, []);

  const onSetFilters = useCallback((filterConfig) => {
    setFiltersConfigState(prevState => ({
      ...prevState,
      ...filterConfig
    }));
  }, []);

  // Send message modal controller (open/close)
  const [sendMessageModal, setSendMessageModal] = useState(false)

  // Returns contributors selected
  const contributionsSelected = useMemo(() => (
    data.filter((p) => selectedRowKeys.includes(p.key))
  ), [ data, selectedRowKeys ]);

  const hasDeletedContributorsSelected = useMemo(() => (
    contributionsSelected.some(p => isDeletedUser({ status: p.member_status }))
  ), [contributionsSelected]);

  // Returns contributors selected id
  const contributionsSelectedId = useMemo(() => (
    contributionsSelected.map(p => p.id) // TODO
  ), [ contributionsSelected ]);

  const getContributionActions = useCallback((status, member_id, id, disable_buttons = false) => {
    switch(status) {
      case 'validated':
        return [ <span className="InitiativeContributors-validated">{ t.validated }</span> ];
      case 'rejected':
        return [ <span className="InitiativeContributors-rejected">{ t.rejected }</span> ];
      case 'pending':
      default:
        return [
          <Button
            className='acceptRequest'
            size="small"
            type="primary"
            shape='circle'
            icon={<CheckOutlined />}
            disabled={disable_buttons}
            onClick={() => dispatch(validateContribution(organization.slug, initiative.slug, id))}
          />,
          <Button
            className='rejectRequest'
            size="small"
            type='danger'
            shape='circle'
            icon={<CloseOutlined />}
            disabled={disable_buttons}
            onClick={() => dispatch(rejectContribution(organization.slug, initiative.slug, id))}
          />
        ];
    };
  }, [ t, organization, initiative, dispatch ]);

  // Validate all contributions
  const validateBulkContributions = useCallback(() => {
    contributionsSelected.forEach(contribution => {
      dispatch(validateContribution(organization.slug, initiative.slug, contribution.id));
    })
  }, [organization, initiative, dispatch, contributionsSelected])

  // Reject all contributions
  const rejectBulkContributions = useCallback(() => {
    contributionsSelected.forEach(contribution => {
      dispatch(rejectContribution(organization.slug, initiative.slug, contribution.id));
    })
  }, [organization, initiative, dispatch, contributionsSelected])

  const currency = ((initiativeDetailData || {}).donation_params || {}).currency || organization?.config?.currency || 'EUR';

  const onAddContributionToInitiative = useCallback((name, email, phone, amount, source, anonymous = false, comment = '') => {
    dispatch(addContribution(organization.slug, initiative.slug, name, email, phone, amount, source, anonymous, comment));
  }, [
    dispatch,
    organization.slug,
    initiative.slug
  ]);

  const onCloseContributionModal = useCallback(() => {
    setAddContributionModal(false);
  }, []);

  const nameCell = useCallback((name, record) => {
    const userData = { ...record, status: record.member_status };
    return (
    <React.Fragment>
      <Row type="flex" gutter={10} justify="left" align="middle">
        <Col>
          {
            permissionList.has('can_manage_volunteers') && record.slug && !isDeletedUser(userData)
            ? (
              <A
                onClick={() => setUserDetail(record.slug)}
              >
                { isDeletedUser(userData)
                    ? <Avatar icon={<DeleteOutlined />} />
                    : <Avatar
                        size={38}
                        shape="circle"
                        src={record.avatar}
                        name={getUserName(userData, intl)}
                        icon={<UserOutlined />}
                      />
                  }
              </A>
            ):(
              isDeletedUser(userData)
                ? <Avatar icon={<DeleteOutlined />} />
                : <Avatar
                    size={38}
                    shape="circle"
                    src={record.avatar}
                    name={getUserName(userData, intl)}
                    icon={<UserOutlined />}
                  />
            )
          }
        </Col>
        <Col>
          <Row>
          {
            !searchText
              ? getUserName(userData, intl)
              : <Highlighter highlightStyle={{
                    backgroundColor: colors['@success-color'],
                    padding: 0
                  }} searchWords={[searchText]} autoEscape textToHighlight={getUserName(userData, intl)}/>
          }
          </Row>
          <Row>
            { !record.email || isDeletedUser(userData) ? null :
              !searchText
                ? record.email
                : <Highlighter highlightStyle={{
                      backgroundColor: colors['@success-color'],
                      padding: 0
                    }} searchWords={[searchText]} autoEscape textToHighlight={record.email}/>
            }
          </Row>
          <Row>
            { !record.phone || isDeletedUser(userData) ? null :
              !searchText
                ? record.phone
                : <Highlighter highlightStyle={{
                      backgroundColor: colors['@success-color'],
                      padding: 0
                    }} searchWords={[searchText]} autoEscape textToHighlight={record.phone}/>
            }
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )}, [searchText, permissionList, intl]);

  const amountCell = useCallback((value, record) => {
    const {
      currency,
    } = record;

    return <span>{ value } { currency }</span>
  }, []);

  const sourceCell = useCallback((source, record) => {
    const { confirmationString = '' } = record.params || {};
    return (
      <React.Fragment>
        <span>
          { t[`donation_${source}`] }
        </span>
        {
          !confirmationString ? null :
          <span> #{ confirmationString }</span>
        }
      </React.Fragment>
    );
  }, [ t ]);

  // Colums for state table
  const columnsMain = useMemo(() => ([
    {
      title: t.name,
      dataIndex: 'name',
      key: 'name',
      filteredValue: searchText
        ? [searchText]
        : null,
      onFilter: (value, record) =>
        (record.name || '').toLowerCase().includes(value) ||
        (record.email || '').toLowerCase().includes(value) ||
        (record.phone || '').toLowerCase().includes(value),
      render: nameCell,
    },
    {
      title: '',
      dataIndex: 'contact',
      key: 'contact',
      filteredValue: (filteredContact.length > 0)
        ? filteredContact
        : null,
      onFilter: (value, record) => (
        handleContactFilter(value, record)
      ),
      className: 'InitiativeContributors__contactColumn',
      render: null,
      with: 0
    },
    {
      title: t.donation_amount,
      dataIndex: 'value',
      key: 'amount',
      render: amountCell,
    },
    {
      key: 'createdDate',
      dataIndex: 'created',
      title: t.donation_date,
      render: (createdDate) => formatDateLong(createdDate, intl)
    },
    {
      title: t.donation_source,
      dataIndex: 'source',
      key: 'source',
      filteredValue: filteredSources
      ? filteredSources
      : null,
      onFilter: (value, record) => (
        record.source === value
      ),
      render: sourceCell,
    },
    {
      title: (
        selectedRowKeys.length <= 1 ? null :
          <section>
            <Button
              className='acceptRequest bulk'
              size="small"
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => validateBulkContributions()}
            />
            <Button
              className='rejectRequest bulk'
              size="small"
              type='danger'
              icon={<CloseOutlined />}
              onClick={() => rejectBulkContributions()}
            />
          </section>
      ),
      filteredValue: filteredStatus
        ? filteredStatus
        : null,
      onFilter: (value, record) => (
        record.status === value
      ),
      dataIndex: 'status',
      key: 'actions',
      align: 'right',
      width: 32,
      render: (text, record) => (
        <div className="InitiativeContributors__actions">
          { getContributionActions(record.status, record.member_id, record.id, false) }
        </div>
      ),
    },
  ]), [
    t,
    searchText,
    nameCell,
    filteredContact,
    amountCell,
    filteredSources,
    sourceCell,
    selectedRowKeys.length,
    filteredStatus,
    handleContactFilter,
    intl,
    validateBulkContributions,
    rejectBulkContributions,
    getContributionActions
  ])

  const onCloseSendMessageModal = useCallback((message, messageType) => {
    setSendMessageModal(false);
  }, []);

  const onSendMessage = useCallback((message, messageType) => {
    dispatch(broadcastMessage(organization.slug, initiative.slug, message, messageType, contributionsSelectedId));
    onCloseSendMessageModal();
  }, [dispatch, initiative.slug, onCloseSendMessageModal, organization.slug, contributionsSelectedId]);

  const onDeselectRowKeys = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  return (
    <React.Fragment>
      <Button
        onClick={() => setAddContributionModal(true)}
        shape='circle'
        type='primary'
        className='addContributionButton'
      >
        <FontAwesomeIcon size="2x" icon="hand-holding-usd" />
      </Button>
      <AddContributionModal
        showAddContributionModal={addContributionModal}
        volunteersItems={volunteersItems}
        currency={currency}
        organizationRegion={organization.region}
        onAddContribution={onAddContributionToInitiative}
        onCancelModal={onCloseContributionModal}
        loading={initiativeDetailLoading}
        error={initiativeDetailError}
      />
      <Row type="flex">
        <Col xs={24} sm={12}>
          <h2>{initiative.title}</h2>
        </Col>
        <Col xs={24} sm={12}>
          <strong>{formatLong(initiative.start_time, intl)}</strong>
          &nbsp;{ t.to }&nbsp;
          <strong>{formatLong(initiative.end_time, intl)}</strong>
        </Col>
      </Row>
      <SendMessageModal
        showSendMessageModal={sendMessageModal}
        onCloseModal={onCloseSendMessageModal}
        onCancelModal={onCloseSendMessageModal}
        onSend={onSendMessage}
        participantsdWithPhone={contributionsSelected.filter(p => p.phone).length}
        participantsdWithEmail={contributionsSelected.filter(p => p.email).length}
        totalParticipants={contributionsSelected.length}
      />
      {
        <React.Fragment>
          <Row type="flex" gutter={20} align={'middle'}>
            <Col>
              <Button
                size='large'
                type='primary'
                disabled={hasDeletedContributorsSelected || selectedRowKeys.length < 1}
                icon={<MessageOutlined />}
                onClick={() => setSendMessageModal(true)}
              >
                {t.participants_sendMessage}
              </Button>
            </Col>
            <Col style={{
                flex: 1
              }}>
              <Input.Search
                type="text"
                prefix={<SearchOutlined
                style = {{ color: 'rgba(0,0,0,.25)' }}/>}
                placeholder={t.volunteer_search_placeholder}
                enterButton={t.volunteer_search}
                onSearch={(term) => setSearchText((term || '').toLowerCase())}
                onChange={(term) => !term.target.value && setSearchText('')}
                allowClear="allowClear"
                size="large"
                ref={searchInputRef}
              />
            </Col>
            <Col>
              <AuthorizedLink
                href={getParticipantsDownloadUrl(organization.slug, initiative.slug)}
                download={`${initiative.title}.xlsx`}
              >
                <Button
                  size="large"
                  icon={<DownloadOutlined />}
                  type="default"
                >
                </Button>
              </AuthorizedLink>
            </Col>
            <Col>
              <Button
                className='filterVolunteers'
                type="default"
                icon={<FilterOutlined />}
                onClick={() => setOpenFilters(true)}
              />
            </Col>
          </Row>
          <div className="Whitespace20"></div>
        </React.Fragment>
      }
      {
        <InitiativeContributorsFilters
          openFilters={openFilters}
          onCloseFilters={onCloseFilters}
          onDeselectRowKeys={onDeselectRowKeys}
          onSetFilters={onSetFilters}
        />
      }
      {
        <Table
          rowSelection={rowSelection}
          columns={columnsMain}
          dataSource={data}
        />
      }
      <UserProfileModal
        slug={userDetail}
        visible={!!userDetail}
        onClose={() => setUserDetail(null)}
      />
    </React.Fragment>
  )
}

export default injectIntl(InitiativeContributors);
