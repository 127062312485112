import React, { useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Dashboard from 'containers/Dashboard';
import Filters from 'containers/Reporting/Filters';
import MainLayout from 'components/MainLayout';
import useOrganizations from 'utils/useOrganizations';
import { toDbDate } from 'utils/date';

import {
  Button,
  Col,
  Row,
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

import './style.less';


const Reporting = ({ intl }) => {

  const t = intl.messages;
  const [filters, setFilters] = useState({
    start: toDbDate(moment().startOf('year')),
    end: toDbDate(moment().endOf('year')),
    tags: '',
  });
  const {
    organization,
  } = useOrganizations();
  const taxonomies = useSelector(state => state.taxonomies);

  const handleOnPrint = useCallback(
    () => {
      const oldTitle = document.title;
      document.title = organization.name;
      window.print();
      document.title = oldTitle;
    },
    [organization]
  );

  return (
    <MainLayout 
      errorMessage={intl.formatMessage({id: 'error_boundary_reporting_message'})}
    >
      <Row
        type="flex"
        align="middle"
        className='Reporting-header'
      >
        <Col>
          <h1>{t.reporting}</h1>
        </Col>
        <Col className="Reporting-header-filters">
          <Filters
            filters={filters}
            setFilters={setFilters}
            taxonomies={taxonomies}
          />
        </Col>
        <Col>
          <Button
            className="Reporting-header-print"
            icon={<PrinterOutlined />}
            onClick={handleOnPrint}
          >
            { t.print }
          </Button>
        </Col>
      </Row>
      <Dashboard filters={filters} />
    </MainLayout>
  )
}

export default injectIntl(Reporting);
