import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  Tabs,
  Input
} from 'antd';

const { TabPane } = Tabs;

const SendMessageModal = ({
    intl,
    showSendMessageModal,
    onCloseModal,
    onCancelModal,
    totalParticipants,
    onSend,
    participantsdWithPhone,
    participantsdWithEmail
}) => {
  const t = intl.messages;
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('email');

  const onClickSend = useCallback(() => {
    onSend(message, messageType);
    setMessage('');
  }, [message, messageType, onSend]);

  const onChangeTabs = useCallback((activeType) => {
    setMessageType(activeType)
    setMessage('')
  }, []);

  return (
    <Modal
      visible={showSendMessageModal}
      title={`${t.broadcast_message} (${t.total}: ${totalParticipants})`}
      centered
      width={900}
      onCancel={onCloseModal}
      footer={[
        <Button key="cancel" onClick={onCancelModal}>{ t.cancel }</Button>,
        <Button type="primary" onClick={onClickSend} key="ok">{ t.send }</Button>
      ]}
    >
      <Tabs animated={false} defaultActiveKey="email" tabPosition='bottom' onChange={(activeKey) => onChangeTabs(activeKey)}>
        <TabPane tab={`${t.send_email} (${participantsdWithEmail})`} key="email">
          <Input.TextArea
            autoSize={ false }
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </TabPane>
        <TabPane tab={`${t.send_sms} (${participantsdWithPhone})`} key="sms">
          <Input.TextArea
            placeholder={ t.max_chars }
            autoSize={ false }
            rows={4}
            value={message}
            maxLength={140}
            onChange={(e) => setMessage(e.target.value)}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
}

SendMessageModal.propTypes = {
  onCancelModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  participantsdWithEmail: PropTypes.number.isRequired,
  participantsdWithPhone: PropTypes.number.isRequired,
  showSendMessageModal: PropTypes.bool.isRequired,
  totalParticipants: PropTypes.number.isRequired,
};

export default injectIntl(SendMessageModal);
