import React from 'react';
import VolunteeringForm from './forms/Volunteering';
import DonationForm from './forms/Donation';
import CollectionForm from './forms/Collection';
import InternalForm from './forms/Internal';
import InformativeForm from './forms/Informative';

const FORM_PER_TYPE = {
  volunteering: VolunteeringForm,
  donation: DonationForm,
  collection: CollectionForm,
  internal: InternalForm,
  informative: InformativeForm,
  default: VolunteeringForm,
};

const InitiativeForm = (
  props
) => {
  const Form = FORM_PER_TYPE[props.type] || FORM_PER_TYPE.default;

  return (
    <Form {...props} />
  );
};

export default InitiativeForm;
