import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import {
  BrowserBanner,
  ErrorBoundaryFallback,
} from 'aplanet-ui-kit';

import Loading from 'containers/Loading';
import Routes from 'containers/Routes';
import { FeatureProvider } from 'components/FeatureSwitch';

import { saveState, loadState } from 'store/persistence';

import { PermissionProvider } from 'components/PermissionSwitch';
import GlobalChat from 'components/GlobalChat'
import config from 'config';
import updateTheme from '../../utils/updateTheme';

import { hydrateStore } from 'actions/store';
import { requestIdentity } from 'actions/auth';
import {
  patchStateWithTopLevelOrg,
} from 'utils/topLevelOrgInUrl';

import {
  requestProfile,
  requestTaxonomies,
  requestOrganizationTree,
} from 'actions/api';

const Root = ({
  intl,
  auth,
  organization,
  organization_tree,
  requestIdentity,
  requestProfile,
  requestTaxonomies,
  requestOrganizationTree,
  hydrateStore,
}) => {
  const [hydrated, setHydrated] = useState(false);
  const [refreshStarted, setRefreshStarted] = useState(false);
  const [requested, setRequested] = useState(false);

  // Save state upon auth/org change
  useEffect(() => {
    saveState({
      organization_slug: organization.slug,
    });
  }, [
    organization.slug,
  ]);

  // Load state when loading the app
  useEffect(() => {
    loadState()
      .then(state => patchStateWithTopLevelOrg(state)) // TODO: Replace state from URL?
      .then(state => hydrateStore(state))
      .then(() => setHydrated(true));
  }, [ hydrateStore ]);

  // Refresh access token when loading the app
  useEffect(() => {
    if(hydrated && !refreshStarted) {
      requestIdentity();
      setRefreshStarted(true);
    }
  }, [
    hydrated,
    refreshStarted,
    requestIdentity,
  ]);

  // Request the profile, taxonomies upon login / org changes
  useEffect(() => {
    const theme = organization.data?.config?.theme;
    updateTheme(theme);

    if(auth.logged_in && organization.data) {
      setRequested(true);
      requestProfile(organization.data.slug);
      requestTaxonomies(organization.data.slug);
      requestOrganizationTree(organization.data.slug);
    }
    if(auth.logged_in && !organization.data) {
      setRequested(false);
    }
  }, [
    auth.logged_in,
    organization.data,
    requestProfile,
    requestTaxonomies,
    requestOrganizationTree,
  ]);

  const featureSet = useMemo(() => {
    return new Set((organization.data || {}).features || config.DEFAULT_FEATURES);
  }, [
    organization.data,
  ]);

  const permissionSet = useMemo(() => {
    const permissionObject = (organization_tree.tree || {}).permissions || {};
    const permissions = Object.keys(permissionObject).filter(permission => permissionObject[permission]);
    return new Set(permissions || config.DEFAULT_PERMISSIONS)
  }, [ organization_tree.tree ]);

  const isReady = hydrated && refreshStarted && ( auth.logged_in || !auth.refreshing_token ) && (
    !auth.logged_in || !organization.data || (
      requested && !organization_tree.fetching
    )
  );

  return (
    <Sentry.ErrorBoundary
      fallback={
      <ErrorBoundaryFallback
        titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
        buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
        descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_default_message' })}
        customErrorImage="/images/error_image.png"
      />
    }>
      <>
        <GlobalChat/>
        <BrowserBanner intl={intl} />
        <ConfigProvider locale={intl.formats.antd}>
          <FeatureProvider value={featureSet}>
            <PermissionProvider value={permissionSet}>
              { isReady ? <Routes /> : <Loading.Big /> }
            </PermissionProvider>
          </FeatureProvider>
        </ConfigProvider>
      </>
    </Sentry.ErrorBoundary>
  );
}

const mapStateToProps = ({
  intl,
  auth,
  organization,
  organization_tree,
}) => ({
  intl,
  auth,
  organization,
  organization_tree,
});

export default connect(
  mapStateToProps,
  {
    requestProfile,
    requestTaxonomies,
    requestOrganizationTree,
    requestIdentity,
    hydrateStore,
  })(injectIntl(Root));

