import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Pie as PieChart, Doughnut } from 'react-chartjs-2';

import { defaultColors } from '../useColors.js';

const DEFAULT_PARAMETERS = {
  nodata: 'No data',
  type: 'pie',
};

const COMPONENT_PER_TYPE = {
  donut: Doughnut,
  pie: PieChart,
}

// TODO: Take out parameters and expose a way to get the params for a nivo-api call
const Pie = forwardRef(({
  slug,
  parameters = DEFAULT_PARAMETERS,
  data,
}, ref) => {
  const {
    nodata = DEFAULT_PARAMETERS.nodata,
    type = DEFAULT_PARAMETERS.type,
  } = parameters;

  const Chart = COMPONENT_PER_TYPE[type];

  const chartData = useMemo(() => ({
    labels: data.labels,
    datasets: [{
      data: data.values,
      backgroundColor: data.colors || defaultColors,
    }],
  }), [
    data,
  ]);

  return (
    <div className="DashboardComponent__card_content">
      {
        !data || data.values.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <Chart
            ref={ref}
            data={chartData}
            height='300px'
            options={{ maintainAspectRatio: false }}
            legend={{ position: 'bottom' }}
          />
      }
    </div>
  );
});

Pie.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.arrayOf( PropTypes.object ),
};

export default Pie;

