import React from 'react';
import {injectIntl} from 'react-intl';

import {
  Card,
  Select,
  Form,
} from 'antd';

const Competences = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  getValue,
  showError,
  errorRefs,
  handleChange,
}) => {
  const {competences} = taxonomies;

  return (
    <Card
      className="InitiativeForm-card"
      title={
        <span><span className="InitiativeForm-required-star">*</span>{intl.formatMessage({ id: `competences` })}</span>
      }
    >
      <div ref={errorRefs.competences}></div>
      <Form.Item
        hasFeedback="hasFeedback"
        validateStatus={showError('competences') ? 'error' : ''}
        help={showError('competences')}
      >
        <Select placeholder={intl.formatMessage({ id: `competences` })} value={getValue('competences')} onChange={handleChange('competences')} mode="multiple">
          {competences.map(competence => (
            <Select.Option key={competence.slug} value={competence.slug}>
              {intl.formatMessage({ id: `competence_${competence.slug}` })}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Card>
  );
}

export default injectIntl(Competences);
