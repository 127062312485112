import React, { useState, useMemo, useCallback } from 'react';
import {injectIntl} from 'react-intl';
import InitiativeTypeIcon from 'components/InitiativeTypeIcon';
import InitiativeTypeString from 'components/InitiativeTypeString';

import './style.less'

import {
  Input,
  Button,
  Row,
  Col,
} from 'antd';

const InitiativeTypeSelector = ({
  intl,
  organization,
  onSubmit,
  setInitiativeTitle,
  acceptedTypes = [],
  subtypeSep = '__',
}) => {
  const t = intl.messages;
  const [ type, setType ] = useState(null);
  const [ subtype, setSubType ] = useState(null);

  const types = useMemo(() => {
    return acceptedTypes.map(typeStr => (typeStr || '').split(subtypeSep))
  }, [
    acceptedTypes,
    subtypeSep,
  ]);

  const handleSubmit = useCallback(() => {
    onSubmit(type, subtype);
  }, [ type, subtype, onSubmit ]);

  const isSelected = useCallback((t, s) => {
    return type === t && (
      (!s && !subtype) || s === subtype
    );
  }, [
    type,
    subtype,
  ]);

  return (
    <React.Fragment>
      <section className='InitiativeTypeSelector-input'>
        <Input
          placeholder={t.new_action_name_placeholder}
          onChange={(term) => setInitiativeTitle(term.target.value)}
          maxLength={75}
        />
        <span>{t.form_error_max_title_length}</span>
      </section>
      <Row type="flex" className='InitiativeTypeSelector-types'>
        { types.map(([acceptedType, acceptedSubtype]) => (
          <Col
            className={`InitiativeTypeSelector-type ${acceptedType} ${isSelected(acceptedType, acceptedSubtype) ? 'active' : ''}`}
            onClick={() => {
              setType(acceptedType);
              setSubType(acceptedSubtype || null);
            }}
          >
            <InitiativeTypeIcon
              type={acceptedType}
              subtype={acceptedSubtype}
            />
            <InitiativeTypeString
              type={acceptedType}
              subtype={acceptedSubtype}
            />
          </Col>
        ))}
      </Row>
      <Button
        type='primary'
        className='InitiativeTypeSelector-submit'
        onClick={handleSubmit}
        disabled={!type}
      >
        { t.continue }
      </Button>
    </React.Fragment>
  );
}

export default injectIntl(InitiativeTypeSelector);
