import React from 'react';
import { injectIntl } from 'react-intl';

import { A } from 'aplanet-ui-kit';

import { Layout } from 'antd';

import './style.css';

const {
  Footer,
} = Layout;

const SignedOutFooter = ({ intl }) => {
  const t = intl.messages;
  return (
    <Footer className="Footer">
      <div className="Footer-block">
        <h3>{ t.need_help_send_a_message }</h3>
        <A href={`mailto:${t.contact_email_address}@aplanet.org`}>{ t.contact_email_address }@aplanet.org</A>
      </div>
      <div className="Footer-spacer"></div>
      <div className="Footer-block">
        <div><A href="https://aplanet.org" newWindow>{ t.about_aplanet }</A></div>
      </div>
    </Footer>
  );
}

export default injectIntl(SignedOutFooter);
