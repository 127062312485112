const TARGET = 'rankings';

const isTarget = (target) => target === TARGET;

const initialState = {
  data: null,
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state, // When loading keep same state
        loading: true,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      return {
        data: action.response.result,
        loading: false,
        error: false,
      };
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export {
  reducer as rankings,
};
