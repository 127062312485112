import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  Modal,
  Row,
  Button,
  Col,
  Input,
  Typography,
  Table,
  Tooltip
} from 'antd';
import {
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined
} from '@ant-design/icons';
import slugify from 'slugify'; // TODO: What the f*ck is this doing here without being wrapped? </rage>

const { Paragraph } = Typography;
const { warning } = Modal;
const REMOVE_REGEXP = /[^\w\s]/g;
const getSlug = (name) => slugify(name, { lower: true, remove: REMOVE_REGEXP });
const CohortAssignModal = ({
  intl,
  onClose,
  onSave,
  members,
  loading,
  error,
  cohorts = []
}) => {
  // Traductions
  const t = intl.messages;

  // Working cohorts & working members
  const [workingCohorts, setWorkingCohorts] = useState(cohorts.map(a => ({...a, key: a.slug})))
  const [workingMembers, setWorkingMembers] = useState(members.map(a => ({...a})))

  // Search cohort text
  const [searchText, setSearchText] = useState('')

  // Controlled pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Cohorts state managing (An object with how many members have each cohort)
  const cohortCounter = useMemo(() => {
    let cohortCounter = {};
    workingMembers.forEach(member => {
      member.cohorts.forEach(cohort => {
        cohortCounter[cohort] = cohortCounter[cohort] ? ++cohortCounter[cohort] : 1
      })
    });
    return cohortCounter;
  }, [workingMembers]);

  // Number of members who has the cohort text
  const nSelectedText = useCallback((cohort) => {
    const text = (workingMembers.length === 1)
    ? ''
    : (!cohortCounter[cohort.slug])
      ? ' (0)'
      : (cohortCounter[cohort.slug] === workingMembers.length)
        ? ' ('+ t.all + ')'
        : ' (' + cohortCounter[cohort.slug] + ')'
    return text
  }, [ cohortCounter, workingMembers, t ]);

  // Title for the modal
  const getTitle = () => {
    const volunteerText = (workingMembers.length > 1) ? t.volunteers_selected : t.volunteers_one_selected
    return t.manage_groups + ' (' + workingMembers.length + ' ' + volunteerText + ')'
  }

  const nameUsed = (newName) => {
    let used = false
    workingCohorts.forEach(cohort => {
      used = (cohort.name === newName)
    })
    return used
  }

  // Add new cohort (only on front, not saved)
  const [newGroupName, setNewGroupName] = useState('')
  const newCohort = () => {
    if(!newGroupName || newGroupName.length <= 0) return;
    if(nameUsed(newGroupName)) return warning({title: t.volunteers_nameUsed});
    const newName = newGroupName.trim();
    setWorkingCohorts([
      {
        name: newName,
        slug: getSlug(newName),
        created: true,
        key: `_new__${Date.now()}`,
      },
      ...workingCohorts
    ]);
    setNewGroupName('');
    setCurrentPage(1);
  }

  // Remove a cohort (only on front, not saved)
  const removeCohort = useCallback(cohort => {
    if(workingCohorts.indexOf(cohort) >= 0){
      setWorkingCohorts(workingCohorts.filter((c) => c.slug !== cohort.slug))
    }
  }, [ workingCohorts ]);

  // Add a cohort to a member (only on front, not saved)
  const addCohort = useCallback((cohort) => {
    setWorkingMembers(workingMembers.map(member => {
      if(member.cohorts.indexOf(cohort.slug) >= 0) return member
      return {
        ...member,
        cohorts: member.cohorts.concat(cohort.slug)
      }
    }))
  }, [ workingMembers ]);

  // Quit a chord from a member (only on front, not saved)
  const quitCohort = useCallback(cohort => {
    setWorkingMembers(workingMembers.map(member => {
      if(member.cohorts.indexOf(cohort.slug) < 0) return member
      return {
        ...member,
        cohorts: member.cohorts.filter((c) => c !== cohort.slug)
      }
    }))
  }, [ workingMembers ]);

  // Edit cohort
  const editCohort = useCallback((cohort, newName) => {
    // Copy of old cohort
    const oldCohort = {...cohort}

    // Set new working cohorts
    setWorkingCohorts(workingCohorts.map(workingCohort => {
      if (workingCohort.slug !== cohort.slug) return workingCohort
      return {
        ...workingCohort,
        slug: getSlug(newName),
        name: newName,
        edit: (!workingCohort.created && !workingCohort.edit) ? oldCohort.slug : undefined
      }
    }))

    // Set new working members
    setWorkingMembers(workingMembers.map(workingMember => {
      const toModifyIndex = workingMember.cohorts.indexOf(oldCohort.slug)
      if (toModifyIndex < 0) return workingMember
      return {
        ...workingMember,
        cohorts: workingMember.cohorts.map((cohort, index) => (
          (toModifyIndex === index) ? getSlug(newName) : cohort
        ))
      }
    }))
  }, [ workingCohorts, workingMembers ]);

  // Exit modal saving changes
  const saveChanges = () => {
    onSave(workingCohorts, workingMembers)
  }

  // Submiting managing
  const [ submitting, setSubmitting ] = useState(false);
  useEffect(() => {
    if(loading) {
      setSubmitting(true);
    }
  }, [ loading ]);

  useEffect(() => {
    if(submitting && !loading && !error) {
      // We are done, let's close shop
      onClose();
    }
  }, [ loading, submitting, error, onClose ]);

  // Table columns
  const columns = useMemo(() => ([
    {
      title: t.volunteers_groups,
      dataIndex: 'name',
      key: 'name',
      filteredValue: searchText
        ? [searchText]
        : null,
      onFilter: (value, record) => (record.name || '').toLowerCase().includes(value),
      render: (index, record) => (
        <Row type='flex' align='middle'>
          <span className='nMembersOnCohort'>{nSelectedText(record)}</span>
          <Paragraph className='editableCohort' editable={{onChange: (newName) => editCohort(record, newName)}}>{record.name}</Paragraph>
          <Tooltip placement="top" title={t.volunteers_remove}>
            <Button
              className={'removeCohort'}
              size='small'
              icon={<DeleteOutlined />}
              onClick={() => removeCohort(record)}
            />
          </Tooltip>
        </Row>
      )
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      width: 345,
      render: (index, record) => (
        <Row>
          <Button
            className={'addAllToCohort'}
            icon={
              (workingMembers.length > 1)
              ? <UsergroupAddOutlined />
              : <UserAddOutlined />
            }
            size='small'
            type='primary'
            disabled={cohortCounter[record.slug] === workingMembers.length}
            onClick={() => addCohort(record)}
          >
            {
              (workingMembers.length > 1)
              ? t.volunteers_addAll
              : t.volunteers_add
            }
          </Button>
          <Button
            className={'removeAllFromCohort'}
            icon={
              (workingMembers.length > 1)
              ? <UsergroupDeleteOutlined />
              : <UserDeleteOutlined />
            }
            size='small'
            type='danger'
            disabled={!cohortCounter[record.slug]}
            onClick={() => quitCohort(record)}
          >
          {
            (workingMembers.length > 1)
            ? t.volunteers_quitAll
            : t.volunteers_quit
          }
          </Button>
        </Row>
      )
    }
  ]), [
    workingMembers,
    searchText,
    addCohort,
    cohortCounter,
    editCohort,
    nSelectedText,
    quitCohort,
    removeCohort,
    t,
  ])

  return (
    <Modal
      visible
      className="CohortAssign-modal"
      title={getTitle()}
      centered
      onOk={onSave}
      onCancel={onClose}
      width={700}
      footer={[
        <Button onClick={onClose} key="cancel">{ t.cancel }</Button>,
        <Button type="primary" onClick={() => saveChanges()} loading={loading} key="ok">{ t.save }</Button>
      ]}
    >
      <Row>
        <Row>
          <Input.Search
            type="text"
            prefix={<SearchOutlined style = {{ color: 'rgba(0,0,0,.25)' }}/>}
            placeholder={t.volunteers_searchGroup}
            enterButton={t.search}
            onSearch={(term) => setSearchText((term || '').toLowerCase())}
            onChange={(term) => !term.target.value && setSearchText('')}
            allowClear="allowClear"
            size="medium"
          />
        </Row>
        <div className="Whitespace20"></div>
        <Row>
          <Table
            columns={columns}
            dataSource={workingCohorts}
            pagination={{
              current: currentPage,
              pageSize: 5,
              onChange: page => setCurrentPage(page),
            }}
          />
        </Row>
        <Row className="newGroupRow" type='flex' align='middle' justify='start'>
          <Col>
            <Input value={newGroupName} onChange={(term) => setNewGroupName(term.target.value.toString())} size='middle' placeholder={t.volunteers_nameForNewGroup} allowClear="allowClear"/>
          </Col>
          <Col>
            <Button
              className="newGroupButton"
              icon={<PlusOutlined />}
              size='middle'
              type='primary'
              disabled={!newGroupName || newGroupName.length <= 0}
              onClick={() => newCohort()}
            >
              {t.volunteers_newGroup}
            </Button>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default injectIntl(CohortAssignModal);
