import React from "react";
import { injectIntl } from "react-intl";
import { Layout, Spin } from "antd";

import { defaultOrganization } from 'reducers/organization';
import useOrganizations from 'utils/useOrganizations';

import './style.less';

const {
  Content,
} = Layout;

const Big = ({
  intl,
}) => {
  const t = intl.messages;
  const {
    organization = defaultOrganization,
  } = useOrganizations();

  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        <div className="Loading-back">
          { (organization || defaultOrganization).logo === '/images/aplanet.png'
          ? <Spin size="large" />
          : <img src={organization.logo} alt={t.loading} />
          }
        </div>
      </Content>
    </Layout>
  )
};

const Block = () => (
  <div className="Loading__container--block">
    <Spin size="large" />
  </div>
);

const Loading = {
  Big: injectIntl(Big),
  Block
};

export default Loading;
