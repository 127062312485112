import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const DELETE_ENDPOINT_REGEX = new RegExp('^[a-z0-9_-]+/initiative/([a-z0-9_-]+)$');
const initialState = null;

const reducer = (state = initialState, action) => {
  if(
    action.type === 'API_CALL_COMPLETE' &&
    action.response &&
    (
      DELETE_ENDPOINT_REGEX.test(action.response.endpoint) &&
      action.response.method === 'DELETE'
    )
  )
  {
    const url = action.response.result && action.response.result.status === 'rejected' ? `/proposal` : `/initiative`;
    return loop(state, Cmd.run(requestPush, { args: [url] }));
  }
  return state;
};

export {
  reducer as initiative_delete_done,
};
