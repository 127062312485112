import React, { useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';

import MainLayout from 'components/MainLayout';

import CustomButton from 'aplanet-ui-kit/dist/components/CustomButton';
import CustomTextArea from 'aplanet-ui-kit/dist/components/CustomTextArea';
import { updateOrganizationConfig } from 'actions/api';
import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

import {
  Row,
  Col,
  Space,
  Empty,
} from 'antd';

import './style.less';


const AboutOurProgram = ({
  intl,
  organization,
  updateOrganizationConfig,
}) => {
  const t = intl.messages;
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(organization.config.about_our_program);

  const handleOnCancel = useCallback(
    () => {
      setValue(organization.config.about_our_program);
      setEditMode(false);
    },
    [organization]
  );
  const handleOnSave = useCallback(
    () => {
      updateOrganizationConfig(organization.slug, { about_our_program: value });
      setEditMode(false);
    },
    [value, organization, updateOrganizationConfig]
  );

  // For image upload in text area
  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(
    () => dispatch(_refreshAccessToken()),
    [ dispatch ]
  );
  const imageUploadUrl = useMemo(
    () => `${config.API_URL}/${organization.slug}/text_editor_image`,
    [organization]
  );

  return (
    <MainLayout
      className="AboutOurProgram"
      errorMessage={intl.formatMessage({id: 'error_boundary_about_our_program_message'})}
    >
      <Row>
        <Col span={24}>
          <h1>{t.about_our_program}</h1>
        </Col>
      </Row>
      { editMode
        ? <>
            <Row type="flex" justify="end">
              <Space size="small">
                <CustomButton
                  onClick={handleOnCancel}
                >
                  { t.cancel }
                </CustomButton>
                <CustomButton
                  type='primary'
                  onClick={handleOnSave}
                >
                  { t.save }
                </CustomButton>
                </Space>
            </Row>
            <Row className="AboutOurProgram-content">
              <Col span={24}>
                <CustomTextArea
                  intl={intl}
                  value={value}
                  onChange={value => setValue(value)}
                  supportImages={true}
                  imageUploadUrl={imageUploadUrl}
                  api_requests={api_requests}
                  refreshAccessToken={refreshAccessToken}
                />
              </Col>
            </Row>
          </>
        : <>
            <Row type="flex" justify="end">
              <CustomButton
                type="primary"
                onClick={() => setEditMode(true)}
              >
                { t.about_our_program_edit }
              </CustomButton>
            </Row>
            <Row className="AboutOurProgram-content">
              <Col span={24}>
                { value
                  ? <ReactMarkdown
                      source={value}
                    />
                  : <Empty description={t.no_data} />
                }
              </Col>
            </Row>
          </>
      }
    </MainLayout>
  );
}

const mapStateToProps = ({
  organization,
}) => ({
  organization: organization.data,
});

export default injectIntl(
  connect(mapStateToProps, { updateOrganizationConfig })(AboutOurProgram)
);
