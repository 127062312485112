import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import {
  Layout,
  Row,
} from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader'

import useScreenSize from 'utils/useScreenSize';

import { toggleSidebar } from 'actions/app';

import './styles.less';

const { Header, Sider } = Layout;

const zIndex = 999;
const height = '120px';
const width = '100%';

const AppFramework = ({children, errorMessage, intl}) => {
  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  const {
    isSidebarCollapsed,
  } = useSelector(state => state.app);

  const isCollapsed = useMemo(() => {
    if(isSidebarCollapsed !== undefined) {
      return isSidebarCollapsed;
    }
    return ['xs', 'sm', 'md'].includes(screenSize);
  }, [
    screenSize,
    isSidebarCollapsed,
  ]);

  const onCollapse = useCallback((collapsed) => {
    dispatch(toggleSidebar());
  }, [
    dispatch,
  ]);

  return (
    <Row>
      <Header
        style={{
          position: 'fixed',
          zIndex,
          width,
          height,
        }}
      >
        <AppHeader/>
      </Header>
      <div
        style={{
          width,
          height,
        }}
      ></div>
      <Layout
        hasSider={true}
        className="main-layout"
      >
        <Sider
          theme="light"
          collapsible
          collapsed={isCollapsed}
          trigger={
            isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
          }
          onCollapse={onCollapse}
        >
          <Nav/>
        </Sider>
        <Layout hasSider={false}>
          {errorMessage ? (
            <Sentry.ErrorBoundary
              fallback={
              <ErrorBoundaryFallback
                titleErrorMessage={intl.formatMessage({ id: `error_boundary_title_message` })}
                buttonLabel={intl.formatMessage({ id: `error_boundary_reload_button` })}
                descriptionErrorMessage={errorMessage}
                customErrorImage="/images/error_image.png"
              />
            }>
              {children}
            </Sentry.ErrorBoundary>
          ) : (children)}
        </Layout>
      </Layout>
    </Row>
  )
}

export default injectIntl(AppFramework);
