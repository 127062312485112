import React from 'react';
import { injectIntl } from 'react-intl';

import SdgLogo from 'components/SdgLogo';

const Sdgs = ({ intl, sdgs = [] }) => {
  const t = intl.messages
  return (
    <div className='actionSdgs'>
      <div className='actionSdgs-title'>{t.impacted_sdgs}</div>
      {sdgs.map((sdg) => (
        <SdgLogo className='actionSdgs-sdg' sdg={sdg} square={true} />
      ))}
    </div>
  );
};

export default injectIntl(Sdgs);
