import { loop, Cmd } from 'redux-loop';


const TARGET = 'indicators';
const INDIVIDUAL_TARGET = 'indicator_detail';
const INITIATIVE_TARGET = 'initiative_detail';
const ID_FIELD = 'id';

const isTarget = (target) => [TARGET, INDIVIDUAL_TARGET, INITIATIVE_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  items: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;

      return {
        items: action.method === 'GET' ? [] : state.items,
        loading: true,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      if (isGeneralTarget(action.response.target)) {
        return {
          items: action.response.result,
          loading: false,
          error: false,
        };
      } else if (action.response.target === INITIATIVE_TARGET) {
        return {
          items: action.response.result.indicators || [],
          loading: false,
          error: false,
        }
      }
      const targetIndicator = action.response.result;
      if(!targetIndicator || !['POST', 'PUT', 'DELETE'].includes(action.response.method)) {
        // This is not a request for me, bailing out
        return state;
      }

      const index = state.items.findIndex((indicator) => indicator[ID_FIELD] === targetIndicator[ID_FIELD]);
      if(index >= 0 || action.response.method === 'POST') {
        let indicators;
        switch(action.response.method) {
          case 'POST':
            indicators = index >= 0 ?
              [ targetIndicator, ...state.items.slice(0, index), ...state.items.slice(index+1) ] :
              [ targetIndicator, ...state.items ];
            break;
          case 'DELETE':
            indicators = [ ...state.items.slice(0, index), ...state.items.slice(index+1) ];
            break;
          default:
            indicators = [ ...state.items.slice(0, index), targetIndicator, ...state.items.slice(index+1) ];
            break;
        }

        const nextState = {
          items: indicators,
          loading: false,
          error: false,
        };

        if(action.response.indicatorValidation) {
          return loop(
            nextState,
            Cmd.action({ ...action, type: 'INITIATIVE_INDICATOR_VALIDATED' })
          );
        }
        return nextState;
      } else {
        return {
          ...state,
          loading: false,
          error: false,
        };
      }
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export {
  reducer as indicators,
};
