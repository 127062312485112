import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Select,
  Modal,
  Row,
  Table,
  Alert
} from 'antd';
import {
  PlusOutlined,
  SearchOutlined,
  UserOutlined
} from '@ant-design/icons';
import { A } from 'aplanet-ui-kit';
import { useHotkeys } from 'react-hotkeys-hook';
import Highlighter from 'react-highlight-words';

import { EMAIL_REGEXP } from 'utils/regex';
import PhoneInput from 'components/PhoneInput';
import { usePermissionList } from 'components/PermissionSwitch';
import Avatar from 'components/Avatar';
import colors from 'colors';
import UserProfileModal from 'containers/UserProfileModal'

const DEFAULT_SOURCE = 'inhand';
const ALL_SOURCES = [ 'paypal', 'transfer', 'external', 'inhand' ];

const DECIMAL_SEPARATOR = {
  'es': ',',
  'es-ES': ',',
  'pt-PT': ',',
  'pt': ',',
  'pt-BR': ',',
  'fr-FR': ',',
  'ca-ES': ',',
  default: '.',
};

const TRUE_DECIMAL_SEPARATOR = '.';
const NOT_NUMBER_OR_DECIMAL = new RegExp('[^\\d.]', 'g');

const removeAdditionalDots = (str) => {
  let n = 0;
  return (str || '').replace(
    /\./g,
    () => n++ > 0 ? '' : TRUE_DECIMAL_SEPARATOR
  );
};

const AddContributionModal = ({
    intl,
    showAddContributionModal,
    onAddContribution,
    onCancelModal,
    volunteersItems,
    organizationRegion,
    currency = 'EUR',
    error,
    loading
}) => {
  const permissionList = usePermissionList();
  const separator = DECIMAL_SEPARATOR[intl.locale] || DECIMAL_SEPARATOR.default;

  // Focus search field on Ctrl+F
  const modalSearchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if (modalSearchInputRef.current && modalSearchInputRef.current.focus) {
      modalSearchInputRef.current.focus()
      e.preventDefault()
    }
  }, [modalSearchInputRef]);

  const [userDetail, setUserDetail] = useState(null);
  const [modalSearchText, setModalSearchText] = useState('');
  const [modalAnonymous, setModalAnonymous] = useState(false);
  const [modalAmount, setModalAmount] = useState(0);
  const [modalSource, setModalSource] = useState(DEFAULT_SOURCE);
  const [modalComment, setModalComment] = useState('');
  const [participantNameInput, setParticipantNameInput] = useState('');
  const [participantEmailInput, setParticipantEmailInput] = useState('');
  const [participantPhoneInput, setParticipantPhoneInput] = useState('');
  const [addingContribution, setAddingContribution] = useState(false);

  const closeContributionModal = useCallback(() => {
    setModalAmount(0);
    setModalSource(DEFAULT_SOURCE);
    setModalComment('');
    setModalAnonymous(false);
    setModalSearchText('');
    setParticipantNameInput('');
    setParticipantEmailInput('');
    setParticipantPhoneInput('');
    onCancelModal();
  }, [onCancelModal]);

  useEffect(() => {
    if(!loading && addingContribution){
      if(error){
        const errorMsg = error === 400
          ? intl.formatMessage({ id: `donation_added_error_400` })
          : intl.formatMessage({ id: `donation_added_error_default` })
        Modal.error({
          title: intl.formatMessage({ id: `donation_added_error` }),
          content: errorMsg
        });
      }else{
        Modal.success({
          title: intl.formatMessage({ id: `donation_added` })
        });
      }
      setAddingContribution(false);
      closeContributionModal();
    }
  }, [addingContribution, intl, closeContributionModal, loading, error]);

  const onAddContributionToInitiative = useCallback((participantName, participantEmail, participantPhone) => {
    setAddingContribution(true);
    onAddContribution(
      participantName,
      participantEmail,
      participantPhone,
      modalAmount,
      modalSource,
      modalAnonymous,
      modalComment
    );
  }, [
    modalAmount,
    modalAnonymous,
    modalComment,
    modalSource,
    onAddContribution,
  ]);

  const nameCell = useCallback((name, record) => (
    <React.Fragment>
      <Row type="flex" gutter={10} justify="left" align="middle">
        <Col>
          { permissionList.has('can_manage_volunteers') && record.slug
            ? (
              <A
                onClick={() => setUserDetail(record.slug)}
              >
                <Avatar
                  size={38}
                  shape="circle"
                  src={record.avatar}
                  name={record.name}
                  icon={<UserOutlined />}
                />
              </A>
            ):(
              <Avatar
                size={38}
                shape="circle"
                src={record.avatar}
                name={record.name}
                icon={<UserOutlined />}
              />
            )
          }
        </Col>
        <Col>
          <Row>
          {
            !modalSearchText
              ? name
              : <Highlighter highlightStyle={{
                    backgroundColor: colors['@success-color'],
                    padding: 0
                  }} searchWords={[modalSearchText]} autoEscape textToHighlight={name}/>
          }
          </Row>
          <Row>
            { !record.email ? null :
              !modalSearchText
                ? record.email
                : <Highlighter highlightStyle={{
                      backgroundColor: colors['@success-color'],
                      padding: 0
                    }} searchWords={[modalSearchText]} autoEscape textToHighlight={record.email}/>
            }
          </Row>
          <Row>
            { !record.phone ? null :
              !modalSearchText
                ? record.phone
                : <Highlighter highlightStyle={{
                      backgroundColor: colors['@success-color'],
                      padding: 0
                    }} searchWords={[modalSearchText]} autoEscape textToHighlight={record.phone}/>
            }
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  ), [modalSearchText, permissionList]);

  const columnsVolunteers = useMemo(() => ([
    {
      title: intl.formatMessage({ id: `name` }),
      dataIndex: 'name',
      key: 'name',
      filteredValue: modalSearchText
        ? [modalSearchText]
        : null,
      onFilter: (value, record) =>
        (record.name || '').toLowerCase().includes(value) ||
        (record.email || '').toLowerCase().includes(value) ||
        (record.phone || '').toLowerCase().includes(value),
      render: nameCell,
    },
    {
      title: '',
      align: 'right',
      render: (text, record) => (
        <Button
          icon={<PlusOutlined />}
          type='primary'
          onClick={() => {
            onAddContributionToInitiative(record.name, record.email, record.phone);
          }}
          disabled={!modalAmount}
        >
          {intl.formatMessage({ id: `donation_register_this_user` })}
        </Button>
      ),

    }
  ]), [intl, modalSearchText, nameCell, modalAmount, onAddContributionToInitiative]);

  return (
    <>
      <Modal
        visible={showAddContributionModal}
        title={intl.formatMessage({ id: `contribution_add_new_title` })}
        centered
        onCancel={closeContributionModal}
        footer={false}
        className="InitiativeContributors__modal"
        width="unset"
      >
        <Row
          type="flex"
          gutter={20}
        >
          <Col xs={24} md={10}>
            <InputNumber
              placeholder={intl.formatMessage({ id: `donation_amount` })}
              size="large"
              value={modalAmount}
              onChange={num => {
                const amount = num ? num.toString() : 0;
                setModalAmount(amount);
              }}
              min={0}
              className="InitiativeContributors__modal_input_number"
              formatter={
                value => `${(value?.replace(/^0+/, '').replace(TRUE_DECIMAL_SEPARATOR, separator)) || 0} ${currency}`
              }
              parser={value => removeAdditionalDots(
                value.replace(/[^0-9|.|,]/g, '').replace(separator, TRUE_DECIMAL_SEPARATOR).replace(NOT_NUMBER_OR_DECIMAL, '')
              )}
            />
            <Select
              value={modalSource}
              onChange={setModalSource}
              className="InitiativeContributors__modal_source_select"
            >
              {
                ALL_SOURCES.map(source => (
                  <Select.Option
                    value={source}
                    key={source}
                  >
                    { intl.formatMessage({ id: `donation_${source}` }) }
                  </Select.Option>
                ))
              }
            </Select>
            <Input.TextArea
              value={modalComment}
              onChange={(e) => setModalComment(e.target.value)}
              placeholder={intl.formatMessage({ id: `donation_comment` })}
              className="InitiativeContributors__modal_comment"
              rows={4}
            />
            <Checkbox
              checked={modalAnonymous}
              onChange={() => setModalAnonymous(!modalAnonymous)}
              className="InitiativeContributors__modal_anonymous"
            >
              { intl.formatMessage({ id: `donation_anonymous` }) }
            </Checkbox>
          </Col>
          <Col xs={24} md={14}>
            <Input.Search
              type="text"
              prefix={<SearchOutlined style = {{ color: 'rgba(0,0,0,.25)' }}/>} placeholder={intl.formatMessage({ id: `volunteer_search_placeholder` })}
              enterButton={intl.formatMessage({ id: `volunteer_search` })}
              onSearch={(term) => setModalSearchText((term || '').toLowerCase())}
              onChange={(term) => !term.target.value && setModalSearchText('')}
              allowClear="allowClear"
              ref={modalSearchInputRef}
            />
            <div className="Whitespace20"></div>
            <Table
              size="small"
              columns={columnsVolunteers}
              dataSource={volunteersItems}
              pagination={{ pageSize: 3, simple: true }}
            />
          </Col>
        </Row>
        <Row type='flex'>
          <Col className='newParticipantName'>
            <Input
              value={participantNameInput}
              onChange={(term) => setParticipantNameInput(term.target.value.toString())}
              size='middle'
              placeholder={intl.formatMessage({ id: `name` })}
              allowClear="allowClear"
            />
          </Col>
          <Col className='newParticipantEmail'>
            <Input
              value={participantEmailInput}
              onChange={(term) => setParticipantEmailInput(term.target.value.toString())}
              size='middle'
              placeholder={intl.formatMessage({ id: `email` })}
              allowClear="allowClear"
            />
            {
              (participantEmailInput.length > 0 && !EMAIL_REGEXP.test(participantEmailInput))
              ? <Alert className='emailError' message={intl.formatMessage({ id: `form_error_invalid_email` })} type="error" showIcon />
              : null
            }
          </Col>
          <Col className='newParticipantPhone'>
            <PhoneInput
              placeholder={intl.formatMessage({ id: `phone` })}
              value={participantPhoneInput}
              onChange={(phone) => setParticipantPhoneInput(phone)}
              country={organizationRegion}
            />
          </Col>
          <Col
            className='newParticipantButton'
          >
            <Button
              icon={<PlusOutlined />}
              type='primary'
              disabled={
                !modalAmount
                || !participantNameInput
                || (participantEmailInput && !EMAIL_REGEXP.test(participantEmailInput))
                || (!participantEmailInput && !participantPhoneInput)
              }
              onClick={() => onAddContributionToInitiative(participantNameInput, participantEmailInput, participantPhoneInput)}
            >
              {intl.formatMessage({ id: `donation_add_new_member` })}
            </Button>
          </Col>
        </Row>
      </Modal>
      <UserProfileModal
        slug={userDetail}
        visible={!!userDetail}
        onClose={() => setUserDetail(null)}
      />
    </>
  );
}

AddContributionModal.propTypes = {
  error: PropTypes.number,
  currency: PropTypes.string,
  loading: PropTypes.bool,
  onAddContribution: PropTypes.func.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  organizationRegion: PropTypes.string.isRequired,
  showAddContributionModal: PropTypes.bool.isRequired,
  volunteersItems: PropTypes.array.isRequired,
};

export default injectIntl(AddContributionModal);
