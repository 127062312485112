import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';

const requestData = async token => {
  const response = await fetch(
    `${config.API_URL}/manager_approval/${token}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  const result = await parseResponse(response);
  return result;
};

const requestSuccess = data => ({
  type: 'MANAGER_APPROVAL_REQUEST_COMPLETE',
  data,
});

const requestFail = ({ code, text }) => ({
  type: 'MANAGER_APPROVAL_REQUEST_FAILED',
  code,
  text,
});

const requestSubmit = async (
  type = 'approve',
  token,
  uuid,
  comment,
) => {
  const response = await fetch(
    `${config.API_URL}/manager_approval/${token}/${type}/${uuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        comment,
      })
    }
  );

  const result = await parseResponse(response);
  return result;
};

const submitSuccess = (response) => ({
  type: 'MANAGER_APPROVAL_SUBMIT_COMPLETE',
  response,
});

const submitFail = ({ code, text }) => ({
  type: 'MANAGER_APPROVAL_SUBMIT_FAILED',
  code,
  text,
});

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  let uuid;
  switch (action.type) {
    case 'MANAGER_APPROVAL_REQUEST':
      return loop(
        {
          ...state,
          loading: true
        },
        Cmd.run(requestData, {
          successActionCreator: requestSuccess,
          failActionCreator: requestFail,
          args: [ action.token ]
        })
      );
    case 'MANAGER_APPROVAL_REQUEST_COMPLETE':
       return {
        ...state,
        data: action.data,
        loading: false,
        error: null,
      };
    case 'MANAGER_APPROVAL_REQUEST_FAILED':
      return {
        ...state,
        loading: false,
        error: action.response
      };
    case 'MANAGER_APPROVAL_APPROVE':
    case 'MANAGER_APPROVAL_REJECT':
      return loop(
        {
          ...state,
          loading: true,
          error: null,
        },
        Cmd.run(requestSubmit, {
          successActionCreator: submitSuccess,
          failActionCreator: submitFail,
          args: [
            action.type === 'MANAGER_APPROVAL_REJECT' ? 'reject' : 'approve',
            action.token,
            action.uuid,
            action.comment,
          ]
        })
      );
    case 'MANAGER_APPROVAL_SUBMIT_COMPLETE':
      uuid = action.response.uuid;
      return {
        ...state,
        data: {
          ...state.data || {},
          thisRequest: ((state.data || {}).thisRequest || {}).uuid === uuid
            ? action.response
            : ((state.data || {}).thisRequest || {}),
          allRequests: ((state.data || {}).allRequests || []).map(
            request => (
              request.uuid === uuid
              ? action.response
              : request
            )
          )
        },
        loading: false,
      };
    case 'MANAGER_APPROVAL_SUBMIT_FAILED':
      return {
        ...state,
        loading: true,
        error: action.code,
      };
    default:
      return state;
  }
};

export { reducer as manager_approval };
