import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import InitiativeCard from 'components/InitiativeCard';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import FloatingButton from 'components/FloatingButton'

import { isAfterNow } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';
import useNow from 'utils/useNow';
import Filters from './Filters';
import InitiativesMapView from 'components/InitiativesMapView';
import MainLayout from 'components/MainLayout';

import { usePermissionList } from 'components/PermissionSwitch';

import {
  requestInitiatives,
} from 'actions/api';

import './style.less';

const CANCELED_STATUSES = new Set([ 'suspended', 'canceled' ]);

const Initiative = ({
  intl,
  initiatives,
  taxonomies,
  requestInitiatives,
  match,
  history,
}) => {
  const permissionList = usePermissionList();

  const [showInMap, setShowInMap] = useState(false);

  const {
    organization,
  } = useOrganizations();

  const activeTabKey = useMemo(() => match.params.tab, [match]);

  const toggleShowInMap = useCallback(
    () => setShowInMap(!showInMap),
    [showInMap, setShowInMap],
  );

  useEffect(() => {
    requestInitiatives(organization.slug);
  }, [
    organization.slug,
    requestInitiatives,
  ]);

  const now = useNow();

  const t = intl.messages;

  const {
    items: allInitiatives,
  } = initiatives;

  const activeInitiatives = useMemo(() =>
    allInitiatives.filter((initiative) =>
      // Not finished & not canceled
      isAfterNow(initiative.end_time)
      && !CANCELED_STATUSES.has(initiative.status)
      && initiative.status !== 'draft'
    ),[ // eslint-disable-line react-hooks/exhaustive-deps
      allInitiatives,
      now,
    ]);

  const isPinnedInitiative = useCallback((initiative) => (
    initiative.pinned_initiative
      && organization?.config?.can_pin_initiatives
      && isAfterNow(initiative.end_time)
      && !CANCELED_STATUSES.has(initiative.status)
      && initiative.status !== 'draft'
  ), [// eslint-disable-line react-hooks/exhaustive-deps
    organization, now
  ]);

  const sortedActiveInitiatives = useMemo(() =>
    activeInitiatives.sort((a, b) =>
      (isPinnedInitiative(b))-(isPinnedInitiative(a))
  ), [isPinnedInitiative, activeInitiatives]);

  const pastInitiatives = useMemo(() =>
    allInitiatives.filter((initiative) =>
			(
        // Past & pending tasks
        (!isAfterNow(initiative.end_time) && (initiative.participation_pending_count > 0)) ||
        // In course, suspended & pending tasks
        (!isAfterNow(initiative.start_time) &&  isAfterNow(initiative.end_time) && (initiative.participation_pending_count > 0) && CANCELED_STATUSES.has(initiative.status))
      ) && initiative.status !== 'draft'
  ),[ // eslint-disable-line react-hooks/exhaustive-deps
    allInitiatives,
    now,
  ]);

  const historyInitiatives = useMemo(() =>
    allInitiatives.filter((initiative) =>
      (
        // Past & no pending tasks
        (!isAfterNow(initiative.end_time) && initiative.participation_pending_count <= 0) ||
        // In course, suspended & no pending tasks
        (!isAfterNow(initiative.start_time) &&  isAfterNow(initiative.end_time) && CANCELED_STATUSES.has(initiative.status) && initiative.participation_pending_count <= 0)
      ) && initiative.status !== 'draft'
  ),[ // eslint-disable-line react-hooks/exhaustive-deps
    allInitiatives,
    now,
  ]);

  const draftInitiatives = useMemo(
    () => allInitiatives.filter(initiative => initiative.status === 'draft'),
    [allInitiatives]
  );

  const renderInitiatives = useCallback(
    (initiatives, searchText) => (
      showInMap
      ? <InitiativesMapView
          initiatives={initiatives.filter(initiative => initiative.latitude)}
          organization={organization}
          searchText={searchText}
        />
      : <section className='initiativeContainer'>
          {initiatives.map(initiative => (
            <InitiativeCard 
              key={initiative.id}
              from={'initiative'}
              activeNow={true}
              organization={organization}
              searchText={searchText}
              isPinned={isPinnedInitiative(initiative)}
              {...initiative}
            />
          ))}
        </section>
    ), [showInMap, organization, isPinnedInitiative]
  );

  return (
    <MainLayout
      errorMessage={intl.formatMessage({id: 'error_boundary_initiatives_message'})}
    >
      { permissionList.has('can_manage_initiatives') && (
        <Link to="/create">
          <FloatingButton iconUrl='images/white-plus.svg'/>
        </Link>
      )}
      <Tabs onChange={key => history.push(`/initiative/tab/${key}`)} activeKey={activeTabKey} defaultActiveKey="active" renderTabBar={(props) =>
        <section>
          <h1 className='initiativesTitle'>{t.my_initiatives}</h1>
          <section className='initiativesTabs'>
            { props.panes.map((tab) => (
              !tab ? null :
              <span
                className={'tab ' + (props.activeKey === tab.key ? 'active' : '')}
                key={tab.key}
                onClick={() => props.onTabClick(tab.key)}>
                  {tab.props.tab}
              </span>
            ))}
          </section>
        </section>
      }>
        <Tabs.TabPane tab={ t.active } key="active">
          <Filters
            data={sortedActiveInitiatives}
            pastData={pastInitiatives}
            sdgs={taxonomies.sdgs}
            showInMap={showInMap}
            toggleShowInMap={toggleShowInMap}
            renderChildren={renderInitiatives}
            alreadyPassedText={t.already_passed_initiatives}
            emptyCardText={t.emptyInitiatives}
            organization={organization}
            isActiveInitiativesTab
            showInitiativeDownload
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={ t.history } key="history">
          <Filters
            data={historyInitiatives}
            sdgs={taxonomies.sdgs}
            showInMap={showInMap}
            toggleShowInMap={toggleShowInMap}
            renderChildren={renderInitiatives}
            alreadyPassedText={t.already_passed_initiatives}
            emptyCardText={t.emptyInitiatives}
            organization={organization}
            showInitiativeDownload
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={ t.initiative_draft } key="draft">
          <Filters
            data={draftInitiatives}
            sdgs={taxonomies.sdgs}
            showInMap={showInMap}
            toggleShowInMap={toggleShowInMap}
            renderChildren={renderInitiatives}
            alreadyPassedText={t.already_passed_initiatives}
            emptyCardText={t.emptyInitiatives}
            organization={organization}
            showInitiativeDownload
          />
        </Tabs.TabPane>
      </Tabs>
    </MainLayout>
  )
}

const mapStateToProps = ({
  initiatives,
  taxonomies,
}) => ({
  initiatives,
  taxonomies,
});

export default injectIntl(connect(mapStateToProps, { requestInitiatives })(Initiative));
