import React from 'react';
import { useSelector } from 'react-redux';
import Chat from './Chat'

const USER_ROLE_SYSTEM = 'system'

const GlobalChat = () => {
	const {data} = useSelector(state => state.profile)
	const {data: organization} = useSelector(state => state.organization);
	if(!data || !organization?.features?.includes("chat") || data?.role === USER_ROLE_SYSTEM) return null
	else return (
		<Chat/>
	)
}
export default GlobalChat
