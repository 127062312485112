import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Bar as VerticalBar, HorizontalBar } from 'react-chartjs-2';

import formatNumber from '../../../utils/formatNumber';

const DEFAULT_PARAMETERS = {
  nodata: 'No data',
  layout: 'vertical',
  stacked: false,
  locale: undefined,
};

const COMPONENT_PER_LAYOUT = {
  horizontal: HorizontalBar,
  vertical: VerticalBar,
};

// TODO: Take out parameters and expose a way to get the params for a nivo-api call
const Bars = forwardRef(({
  slug,
  parameters = DEFAULT_PARAMETERS,
  data,
}, ref) => {
  const {
    labels = [],
    values: datasets = [],
  } = data;

  const {
    nodata = DEFAULT_PARAMETERS.nodata,
    layout = DEFAULT_PARAMETERS.layout,
    stacked = DEFAULT_PARAMETERS.stacked,
    locale = DEFAULT_PARAMETERS.locale,
  } = parameters;

  const Bar = COMPONENT_PER_LAYOUT[layout];

  const commonAxesOptions = useMemo(() => ({
    stacked,
    ticks: {
      beginAtZero: true,
      precision: 0,
      callback: value => {
        if(isNaN(value)) {
          return value;
        }
        return formatNumber(value, { locale });
      }
    },
  }), [
    stacked,
    locale,
  ]);

  const options = useMemo(() => ({
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ...commonAxesOptions,
      }],
      yAxes: [{
        ...commonAxesOptions,
      }]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          const value = tooltipItem.value === 'NaN' ? '-' : tooltipItem.value;
          return `${label}: ${value}`;
        },
      },
    }
  }), [
    commonAxesOptions,
  ]);

  return (
    <div className="DashboardComponent__card_content">
      {
        !data.values || data.values.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <Bar
            ref={ref}
            data={{ labels, datasets }}
            height='300px'
            options={options}
            legend={{ position: 'bottom' }}
          />
      }
    </div>
  );
});

Bars.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.arrayOf( PropTypes.object ),
};

export default Bars;

