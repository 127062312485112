const TARGET = 'collaborations';
const INDIVIDUAL_TARGET = 'initiative_detail';
const ID_FIELD = 'id'; // TODO: Change to slug if we ever stop exposing numerical ids

const isTarget = (target) => [TARGET, INDIVIDUAL_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;
//const isIndividualTarget = (target) => target === INDIVIDUAL_TARGET;

const initialState = {
  items: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        items: state.items, // When loading keep same items
        loading: true,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      if(isGeneralTarget(action.response.target)) {
        return {
          items: action.response.result,
          loading: false,
          error: false,
        };
      } else {
        const targetInitiative = action.response.result;
        if(!targetInitiative || !['POST', 'PUT', 'DELETE'].includes(action.response.method)) {
          // This is not a request for me, bailing out
          return state;
        }

        const index = state.items.findIndex((initiative) => initiative[ID_FIELD] === targetInitiative[ID_FIELD]);
        if(index >= 0) {
          return {
            items: [ ...state.items.slice(0, index), targetInitiative, ...state.items.slice(index+1) ],
            loading: false,
            error: false,
          };
        } else {
          return {
            ...state,
            loading: false,
            error: false,
          };
        }
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export {
  reducer as collaborations,
};
