import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
} from 'antd';

const Buttons = ({
  intl,
  onDelete,
  onEdit,
  canEdit,
  type,
}) => {
  const t = intl.messages;

  return !canEdit ? null : (
    <React.Fragment>
      <Button type='default' onClick={onEdit} className='editAction'>
        <img src='/images/icon-edit.svg' alt={t.initiative_detail_edit_button} />
        <span>{t.initiative_detail_edit_button}</span>
      </Button>
    </React.Fragment>
  );
};

export default injectIntl(Buttons);
