import React from 'react';
import { injectIntl } from 'react-intl';

const Competences = ({ intl, competences = [] }) => {
  const t = intl.messages;
  return (
    competences.length < 1 ? null :
    <section className='actionCompetences'>
      <div className='actionCompetences-title'>{ t.competences }</div>
      { competences.map((competence) => (
        <div className='actionCompetences-competence'>{ t[`competence_${competence}`] }</div>
      ))}
    </section>
  );
};

export default injectIntl(Competences);
