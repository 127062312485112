import React from 'react';
import { injectIntl } from 'react-intl';
import Avatar from 'components/Avatar';
import { getUserName, isDeletedUser } from 'utils/user';

import {
  DeleteOutlined,
} from '@ant-design/icons';


const Submitter = ({
  intl,
  submitter,
}) => {
  const t = intl.messages;
  return (
    <section className='actionSubmitter'>
      { [submitter].map(submitter => (
        <section className='actionSubmitter-submitter'>
          { isDeletedUser(submitter)
            ? <Avatar className='actionSubmitter-avatar' shape="square" icon={<DeleteOutlined />} />
            : <Avatar className='actionSubmitter-avatar' shape="square" src={submitter.avatar} />
          }
          <section>
            <div className='actionSubmitter-submittedBy'>{`${t.submitted_by}:`}</div>
            <div className='actionSubmitter-name'>{getUserName(submitter, intl)}</div>
          </section>
        </section>
      ))}
    </section>
  )}

export default injectIntl(Submitter);
