import moment from 'moment';
import { fromDb, toDb } from 'utils/date';
import { isSameDay } from './time';

const DONATION_PREFIX = 'donation_';
const TYPES_WITH_PARTICIPANTS = ['volunteering', 'training'];
export const QUESTION_SEPARATOR = '__??__';

export const dateAndTime = (date, time) => {
  const d = moment(date);
  const t = moment(time);
  return (['hour', 'minute', 'second', 'millisecond'].reduce((carry, field) => carry[field]( t[field]() ) , d)).toDate();
}

// TODO: This is very strange and made to work in production environments only
//       Where the server really is UTC
const dateAndTimeToUTC = (date, time) => {
  //const currentOffset = new Date().getTimezoneOffset();
  const d = moment(date);
  const t = moment(time);
  const dateOffset = moment(d).toDate().getTimezoneOffset();
  return (['hour', 'minute', 'second', 'millisecond'].reduce(
    (carry, field) => carry[field](
      ['hour', 'minute'].includes(field)
      ? t[field]()
      : 0
    ),
  d)).add(dateOffset, 'minutes').toDate();
}

export const initiativeFromDbToForm = (db = {}) => {
  const {
    categories = [],
    start_time,
    end_time,
    registration_end_time,
    donation_params = {},
    target_audience = [],
    questions: questionsObj = {},
    ...rest
  } = db;

  const donation_props = Object.fromEntries(
    new Map(
      Object.keys(donation_params).map(prop => [ `${DONATION_PREFIX}${prop}`, donation_params[prop] ])
    )
  );

  if (donation_props.donation_br_transfer_account) {
    donation_props.donation_br_transfer_account_first_part = donation_props.donation_br_transfer_account.slice(0, 5);
    donation_props.donation_br_transfer_account_second_part = donation_props.donation_br_transfer_account.slice(5);
  }

  const questions = Object.keys(questionsObj)
    .map(question => (questionsObj[question] || []).map(answer => `${question}${QUESTION_SEPARATOR}${answer}`))
    .reduce((acc, arr) => acc.concat(arr), []);

  return {
    ...rest,
    ...donation_props,
    questions,
    category1: categories[0],
    category2: categories[1],
    category3: categories[2],
    targetAudience1: target_audience[0],
    targetAudience2: target_audience[1],
    targetAudience3: target_audience[2],
    start_date: start_time ? moment(fromDb(start_time)) : undefined, // TODO: Set date and time only
    start_time: start_time ? moment(fromDb(start_time)) : undefined,
    end_date: end_time ? moment(fromDb(end_time)) : undefined,
    end_time: end_time ? moment(fromDb(end_time)) : undefined,
    registration_end_date: registration_end_time ? moment(fromDb(registration_end_time)) : undefined,
    registration_end_time: registration_end_time ? moment(fromDb(registration_end_time)) : undefined,
    multiple_dates: (start_time && end_time && !isSameDay(fromDb(start_time), fromDb(end_time))) || undefined,
  };
};

export const initiativeFromFormToDb = (form, type) => {
  const {
    category1,
    category2,
    category3,
    multiple_dates,
    start_date,
    start_time,
    end_date,
    end_time,
    registration_end_date,
    registration_end_time,
    targetAudience1,
    targetAudience2,
    targetAudience3,
    max_participants: maybeMaxParticipants,
    questions: maybeQuestionsFlat,
    donation_br_transfer_account_first_part,
    donation_br_transfer_account_second_part,
    ...rest
  } = form;

  const categories = [category1, category2, category3].filter(a => !!a);
  const target_audience = [targetAudience1, targetAudience2, targetAudience3].filter(a => !!a);
  const max_participants = (
    maybeMaxParticipants === null ||
    maybeMaxParticipants === ''
  ) && TYPES_WITH_PARTICIPANTS.includes(type)
    ? -1
    : maybeMaxParticipants;

  let questions = {};

  if(maybeQuestionsFlat && maybeQuestionsFlat.length > 0) {
    maybeQuestionsFlat.forEach((flat = '') => {
      const [ question, answer ] = flat.split(QUESTION_SEPARATOR);

      if(question && answer) {
        questions[question] = questions[question] || [];
        questions[question].push(answer);
      }
    });
  }

  let body = {};
  body.donation_params = {};
  if (type === 'donation' && donation_br_transfer_account_first_part && donation_br_transfer_account_second_part) {
    body.donation_params.br_transfer_account = `${donation_br_transfer_account_first_part}${donation_br_transfer_account_second_part}`;
  }
  Object.keys(rest).forEach(prop => {
    if(prop.startsWith(DONATION_PREFIX)) {
      body.donation_params[prop.slice(DONATION_PREFIX.length)] = rest[prop];
    } else {
      body[prop] = rest[prop];
    }
  });


  return {
    ...body,
    questions,
    max_participants,
    categories,
    start_time: start_date && start_time ? toDb(dateAndTimeToUTC(start_date, start_time)) : undefined,
    end_time: end_date && end_time ? toDb(dateAndTimeToUTC(end_date, end_time)) : undefined,
    registration_end_time: registration_end_date && registration_end_time ? toDb(dateAndTimeToUTC(registration_end_date, registration_end_time)) : undefined,
    target_audience,
  };
};

