import React, {Fragment, useEffect, useRef, useMemo, useCallback} from 'react';
import {injectIntl} from 'react-intl';

import {
  initiativeFromFormToDb,
  initiativeFromDbToForm,
} from '../converters';
import { isAfterNow } from 'utils/date';
import useForm from 'utils/useForm';
import GeneralInfo from '../cards/GeneralInfo';
import Sdgs from '../cards/Sdgs';
import Contact from '../cards/Contact';
import Dates from '../cards/Dates';
import Donation from '../cards/Donation';
import Questions from '../cards/Questions';
import Activity from '../Activity';
import Cohorts from '../cards/Cohorts';
import FeedbackUrl from '../cards/FeedbackUrl';
import { usePermissionList } from 'components/PermissionSwitch';
import InitiativeTypeIcon from 'components/InitiativeTypeIcon';
import InitiativeTypeString from 'components/InitiativeTypeString';

import ButtonArea from '../ButtonArea';
import validation from '../validation';
import '../style.less';

import {
  Alert,
  Form,
  Row,
  Col,
} from 'antd';

const SET_NAMES = [
  'questions',
  'sdgs',
  'donation_sources',
];

const InitiativeForm = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  cancelInitiative,
  formMode: forceFormMode,
  type: initiativeType,
  subtype,
  hideCategories,
  hideTargetGroups,
  onDeleteDraftInitiative
}) => {
  const t = intl.messages;
  const cancelMode = useMemo(() => {
    if(isAfterNow(defaultValues.start_time) || (
      defaultValues?.participation_pending_count === '0'
      && defaultValues?.collaboration_pending_count === '0'
      && defaultValues?.indicator_pending_count === '0'
      && defaultValues?.participant_count === '0'
    )) {
      return 'cancel';
    }
    return 'suspend';
  }, [ defaultValues ]);

  // Check if this user can override some limitations
  const permissionList = usePermissionList();
  const canOverride = permissionList.has('can_override_limits');
  const canEditTitle = canOverride || permissionList.has('can_manage_initiatives');
  const canOverrideDates = canOverride ||
    ((organization.config || {}).can_override_date_limits || []).includes('admin');
  const hasQuestions =  useMemo(() => {
    return Object.keys((organization.config || {}).initiative_questions || {}).length >= 1;
  }, [
    organization,
  ]);

  const errorRefs = {
    title: useRef(null),
    sdgs: useRef(null),
    dates: useRef(null),
    donation: useRef(null),
    contact: useRef(null),
  };

  const formMode = forceFormMode || (!defaultValues
    ? 'create'
    : (
      defaultValues.status === 'proposed'
      ? 'proposal'
      : 'edit')
  );

  const defaults = useMemo(() => initiativeFromDbToForm(defaultValues), [defaultValues]);

  const submitForm = () => {
    const payload = {
      ...initiativeFromFormToDb(values, 'donation'),
      type: 'donation',
      subtype,
    };
    onSubmit(payload);
  };

  const validateForm = useMemo(() =>
    validation(
      t,
      'donation',
      formMode,
      canOverrideDates,
      organization.config.max_number_of_sdgs,
      organization.region
    ),
    [
      t,
      formMode,
      canOverrideDates,
      organization,
    ]
  );

  // Basic state for the whole form + submitting
  const {
    values,
    handleChange,
    handleChangeEvent,
    runValidation,
    handleSubmit,
    isDirty,
    errors
  } = useForm({
    callback: submitForm,
    defaultValues: {
      ...(formMode === 'create' ? defaults : {}),
      donation_currency: defaults.donation_currency,
      category1: defaults.category1,
      category2: defaults.category2,
      category3: defaults.category3,
      targetAudience1: defaults.targetAudience1,
      targetAudience2: defaults.targetAudience2,
      targetAudience3: defaults.targetAudience3,
    },
    validate: validateForm,
    validationDefaults: defaults,
    setNames: SET_NAMES,
    defaultSetValues: {
      questions: defaults.questions || [],
      sdgs: defaults.sdgs || [],
      donation_sources: defaults.donation_sources || [],
    }
  });

  const getValue = useCallback((name) => (
    typeof values[name] === 'undefined'
    ? defaults[name]
    : values[name]), [values, defaults]);

  const showError = useCallback((name) => (!isDirty(name) && errors[name]) || '', [isDirty, errors]);

  const { categories, targetAudience } = taxonomies;

  const onEditConfirm = useCallback((notify_participants) => {
    handleChange('notify_participants')(notify_participants);
  }, [handleChange]);

  const onSaveAsDraft = useCallback((draft_comments) => {
    handleChange('status')('draft');
    handleChange('draft_comments')(draft_comments);
  }, [handleChange]);

  const unsetSaveAsDraft = useCallback(() => {
    handleChange('status')();
    handleChange('draft_comments')();
  }, [handleChange]);

  useEffect(() => {
    // Run validation when we open a form that is not creation
    if (formMode !== 'create') {
      runValidation()
    }
  }, [formMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errors._firstError && errorRefs[errors._firstError] && errorRefs[errors._firstError].current) {
      errorRefs[errors._firstError].current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, [// eslint-disable-line react-hooks/exhaustive-deps
    errors]);

  const params = {
    getValue,
    showError,
    handleChangeEvent,
    handleChange,
    values,
    formMode,
    errorRefs,
    errors,
    categories,
    targetAudience,
    taxonomies,
    organization,
    canOverride,
    canEditTitle,
    hideCategories,
    hideTargetGroups,
  };

  return (
    <Fragment>
      <Row type='flex' align='middle' justify='center'>
        <InitiativeTypeIcon
          type={initiativeType}
          subtype={subtype}
          className='InitiativeForm__icon'
        />
        <h2 className='InitiativeForm__title'>
          <InitiativeTypeString
            type={initiativeType}
            subtype={subtype}
            mode={formMode}
          />
        </h2>
      </Row>
      <div className="Whitespace20"></div>
    <Form id="initiative_form" onFinish={handleSubmit}>
      <div
        style={{
          position: 'relative',
          top: '-64px'
          }}
        ref={errorRefs.title}>
      </div>
      <div style={{ height: '20px' }}></div>

        <GeneralInfo {...params} />
        <Donation {...params} />
        <Sdgs {...params} />
        <Dates
          {...params}
          canOverride={canOverrideDates}
        />
        <Contact {...params} />
        <Cohorts {...params} />
        <FeedbackUrl {...params} />
        {
          !hasQuestions ? null :
          <Questions {...params} />
        }

      <div style={{ height: '20px' }}></div>

      { defaultValues.status === 'draft' &&
        <Row>
          <Col span={24}>
            <h4>{t.initiative_modified_by}:</h4>
          </Col>
          <Col span={24}>
            <Activity records={defaultValues.activity} />
          </Col>
        </Row>
      }
      <Row type="flex" justify="end">
        <ButtonArea
          cancelMode={cancelMode}
          cancelInitiative={cancelInitiative}
          organization={organization}
          initiative={defaultValues}
          formMode={formMode}
          loading={loading}
          onRejectProposal={onRejectProposal}
          onEditConfirm={onEditConfirm}
          onSaveAsDraft={onSaveAsDraft}
          unsetSaveAsDraft={unsetSaveAsDraft}
          submitForm={handleSubmit}
          onDeleteDraftInitiative={onDeleteDraftInitiative}
        />
      </Row>
      <div style={{ height: '10px' }}></div>
      {
        !error
          ? null
          : <Alert type="error" message={t[`initiative_form_error_${error}`] || t.initiative_form_error_default}/>
      }
      <div style={{ height: '10px' }}></div>
    </Form>
  </Fragment>
  );
}

export default injectIntl(InitiativeForm);
