import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import { isPassed, totalCalendarDays } from 'utils/date';
import { getUserName, isDeletedUser } from 'utils/user';

import { PushpinFilled } from "@ant-design/icons";

import Avatar from 'components/Avatar';

import {
  DeleteOutlined,
} from '@ant-design/icons';

const DEFAULT_IMAGE = 'https://aplanet-static.ams3.digitaloceanspaces.com/assets/default_initiative_image.jpg';

const InitiativeCardCover = ({
  cover,
  dateArray,
  end_time,
  handleClickCard,
  hasPendingTasks,
  intl,
  isCanceled,
  isCollaborative,
  isFromInitiative,
  isOneDay,
  organization_logo_small,
  organization_name,
  registration_end_time,
  start_time,
  submitter,
  isPinned = false,
  type,
  isParticipantionComplete,
  points,
  program,
  hasPoints,
  hasProgram,
}) => {
  const timeTagText = useMemo(() => {
    if (isPassed(end_time)) {
      return intl.formatMessage({ id: `passed` });
    }

    const daysLeftToStart = totalCalendarDays(new Date(), start_time);

    if (daysLeftToStart === 0) {
      return intl.formatMessage({ id: `today` });
    }
    
    const daysLeftToEnd = totalCalendarDays(new Date(), end_time);

    if (daysLeftToStart < 0 && (daysLeftToEnd > 0 || daysLeftToEnd === 0)) {
      return intl.formatMessage({ id: `ongoing` });
    }

    if (type !== 'informative' && type !== 'internal') {
      // WARNING: Ahora todas las colaborativas son de tipo voluntariado, pero en un futuro no. En un futuro cuando haces request de colaboraciones en /Collaboration deberá venir tambien su type
      if ((type === 'volunteering' || isCollaborative) && daysLeftToStart < 7) {
        return intl.formatMessage({ id: `days_left_volunteering` }, { value: daysLeftToStart });
      }
      if (type === 'donation' && daysLeftToEnd < 7) {
        return intl.formatMessage({ id: `days_left_donation` }, { value: daysLeftToEnd });
      }
    }

  }, [
    end_time,
    isCollaborative,
    start_time,
    intl,
    type
  ])


  return (
    <div className="InitiativeCard-cover" onClick={handleClickCard}>
      { isCanceled
        ? <section className='InitiativeCard-date canceled'>{intl.formatMessage({ id: `canceled` })}</section>
        : isOneDay
          ?
            <section className='InitiativeCard-date oneDay'>
              <span className='day'>{dateArray[0].day}</span>
              <span className='month'>{dateArray[0].month}</span>
            </section>
          :
            <section className='InitiativeCard-date interval'>
              <section className='dayStart'>
                <span className='day-start'>{dateArray[0].day}</span>
                <span className='month-start'>{dateArray[0].month}</span>
              </section>
              <figure className='divider'></figure>
              <section className='endDay'>
                <span className='day-end'>{dateArray[1].day}</span>
                <span className='month-end'>{dateArray[1].month}</span>
              </section>
            </section>
      }
      <section className='rightTags top'>
        { !isCanceled && timeTagText && (
          <figure className={`InitiativeCard-${isPassed(end_time) ? 'passed' : 'daysleft'}`}>{timeTagText}</figure>
        )}
        { hasPendingTasks && (
          <figure className='InitiativeCard-pending'>{intl.formatMessage({ id: `pending_tasks` })}</figure>
        )}
        { ((registration_end_time && isPassed(registration_end_time) && !isPassed(end_time)) || isParticipantionComplete) && (
            <figure className='InitiativeCard-inscriptionClosed'>{intl.formatMessage({ id: `inscriptions_closed` })}</figure>
        )}
        { (!!submitter && !isFromInitiative) && (
          <figure className='InitiativeCard-submitter'>
              { isDeletedUser(submitter)
                ? <Avatar className='submitter-avatar' size={22} icon={<DeleteOutlined />} />
                : <Avatar className='submitter-avatar' size={22} name={getUserName(submitter, intl)} src={submitter.avatar} />
              }
              <span className='submitter-name'>{getUserName(submitter, intl)}</span>
          </figure>
        )}
        { (isCollaborative && !isFromInitiative) && (
          <figure className='InitiativeCard-collaborator'>
            <Avatar className='submitter-avatar' size={22} name={organization_name}  src={organization_logo_small} />
            <span className='submitter-name'>{organization_name}</span>
          </figure>
        )}
      </section>
      <section className='leftTags'>
        { hasProgram && (
          <div title={program} className="programWrapper">
            <span>{ program }</span>
          </div>
        ) }
        { isPinned && (
          <div title={intl.formatMessage({ id: 'initiative_pinned_initiative' })} className="pinWrapper">
            <PushpinFilled />
          </div>
        ) }
      </section>
      <section className='rightTags bottom'>
        { hasPoints && (
            <figure className='InitiativeCard-points'>
              {intl.formatMessage({ id: 'initiative_card_points' }, { value: points })}
            </figure>
          )}
      </section>
      <img alt="" src={ cover || DEFAULT_IMAGE } />
    </div>
  );
};

export default injectIntl(InitiativeCardCover);
