import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

// TODO: Make your own InitiativeContributors container and change this!!!
import InitiativeContributors from 'containers/InitiativeContributors';
import InitiativeCollaborators from 'containers/InitiativeCollaborators';
import InitiativeIndicators from 'containers/InitiativeIndicators';
import InitiativeTags from 'containers/InitiativeTags';
import InitiativeDocuments from 'containers/InitiativeDocuments';
import InitiativeImages from 'containers/InitiativeImages';
import When from '../cards/When';
import Description from '../cards/Description';
import Categories from '../cards/Categories';
import Groups from '../cards/Groups';
import Contact from '../cards/Contact';
import DonationCard from '../cards/Donation';
import { useFeatureList } from 'components/FeatureSwitch';
import { usePermissionList } from 'components/PermissionSwitch';
import Organizer from '../cards/Organizer';
import PendingCountBadge from 'components/PendingCountBadge';
import Questions from '../cards/Questions';

import { Tabs, Row, Col } from 'antd';

const Donation = ({
  intl,
  push,
  slug,
  action,
  organization,
  initiative,
  admin_profile,
  taxonomies,
  requestInitiativeDetail,
  cancelInitiative,
  requestCollaboration,
  updateCollaboration,
  cancelCollaborationRequest,
  stopCollaboration,
  isOrganizer,
  isCollaborator,
  loading,
  loading_member_id,
  canEdit,
  initiativeDone,
  location,
}) => {
  const t = intl.messages;
  const featureList = useFeatureList();
  const permissionList = usePermissionList();
  const activeKey = useMemo(() => {
    return location.pathname.split('/').splice(-1,1).toString()
  }, [ location.pathname ]);

  const hasQuestions = useMemo(() => {
    return ((organization || {}).config || {}).initiative_questions &&
      initiative &&
      initiative.questions &&
      Object.keys(initiative.questions).length >= 1
  }, [
    organization,
    initiative,
  ]);

  return (
    <Tabs
      className='initiativeTabs'
      defaultActiveKey="details"
      onChange={key => push(`/initiative/${slug}/${key}`)}
      activeKey={activeKey}
      renderTabBar={(props) =>
        <Row type="flex" className='actionTabs'>
          { props.panes.map((tab) => (
            !tab ? null :
            <Col
              className={'tab ' + (props.activeKey === tab.key ? 'active' : '')}
              onClick={() => props.onTabClick(tab.key)}>
                {tab.props.tab}
                <PendingCountBadge initiative={initiative} tab={tab} />
            </Col>
          ))}
        </Row>
    }>
      <Tabs.TabPane tab={ t.details } key="details">
        <Row type="flex" gutter={50} className='actionDetails'>
          <Col xs={24} md={18}>
            <Row>
              <DonationCard
                active={!initiativeDone}
                loading={loading_member_id}
                {...initiative}
              />
            </Row>
            <Row>
              <Description {...initiative} />
            </Row>
            <Row>
              <Categories {...initiative} />
            </Row>
            <Row>
              <Groups taxonomies={taxonomies} {...initiative} />
            </Row>
            {
              !hasQuestions ? null :
              <Row>
                <Questions {...initiative} />
              </Row>
            }
          </Col>
          <Col xs={24} md={6}>
            <When {...initiative} />
            <Contact {...initiative} />
            <Organizer {...initiative} />
            {
              !canEdit && (!initiative.tags || initiative.tags.length === 0)
              ? null
              : (
                <InitiativeTags
                  loading={loading}
                  initiative_slug={initiative.slug}
                  {...initiative}
                  canEdit={canEdit}
                />
              )
            }
          </Col>
        </Row>
      </Tabs.TabPane>
      { (!isOrganizer && !isCollaborator) || !permissionList.has('can_manage_participation') ? null :
        <Tabs.TabPane tab={ t.contributors } key="contributors">
          <InitiativeContributors
            loading={loading_member_id}
            {...initiative}
          />
        </Tabs.TabPane>
      }
      {
          !isOrganizer || (
            !initiative.collaborative &&
            (initiative.collaborators || []).length === 0
          ) ||
          !permissionList.has('can_manage_collaborations')
        ? null : (
          <Tabs.TabPane tab={ t.collaborators } key="collaborators">
            <InitiativeCollaborators loading={loading} {...initiative} />
          </Tabs.TabPane>
        )
      }
      { (isOrganizer || isCollaborator) && featureList.has('impact') &&
        <Tabs.TabPane tab={ t.indicators } key="indicators">
          <InitiativeIndicators
            initiative={initiative}
            organization={organization} />
        </Tabs.TabPane>
      }
      { (isCollaborator || isOrganizer) && !organization?.config?.hide_initiative_images &&
      <Tabs.TabPane tab={ t.initiative_images } key="images">
        <InitiativeImages
          canEdit={canEdit}
          initiative={initiative}
          organization={organization}
        />
      </Tabs.TabPane>
      }
      { (isCollaborator || isOrganizer) && !organization?.config?.hide_initiative_documents &&
      <Tabs.TabPane tab={ t.initiative_documents } key="documents">
        <InitiativeDocuments
          canEdit={canEdit}
          initiative={initiative}
          organization={organization}
        />
      </Tabs.TabPane>
      }
    </Tabs>
  )
}

export default injectIntl(withRouter(Donation))
