const TARGET = 'broadcast_message';

const isTarget = (target) => target === TARGET;

const initialState = {
  done: false,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_RESET':
      if(!isTarget(action.target)) return state;
      return initialState;
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        done: false,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      return {
        done: true,
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        done: false,
        loading: false,
        error: action.code || true,
      };
    default:
      return state;
  }
};

export {
  reducer as broadcast_message,
};
