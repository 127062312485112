import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { injectIntl } from 'react-intl';

import './style.css';

// Leaflet shoddywork incoming
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

const MapWithPins = ({ intl, latitude, longitude, zoom, pins = [] }) => {
  const t = intl.messages;
  const position = [ latitude, longitude ];
  return (
    <Map
      center={position}
      zoom={zoom}
      className="MapWithPins"
      gestureHandling={true}
      gestureHandlingOptions={{
        duration: 2000,
        text: {
          touch: t.map_scroll_touch,
          scroll: t.map_scroll_scroll,
          scrollMac: t.map_scroll_scrollMac,
        }
      }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      { pins.map(({ latitude: pin_lat, longitude: pin_lon, text }, index) =>
      <Marker key={index} position={[pin_lat, pin_lon]}>
        <Popup>
          { text }
        </Popup>
      </Marker>
      )}
    </Map>
  );
}

export default injectIntl(MapWithPins);
