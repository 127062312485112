import React from 'react';
import { injectIntl } from 'react-intl';

const Program = ({
  intl,
  program,
}) => {
  const t = intl.messages;
  return (
    <section className='actionProgram'>
      <div className='actionProgram-title'>
        { t.program }
      </div>
      <div className="actionProgram-program">
        { program }
      </div>
    </section>
  );
};

export default injectIntl(Program);
