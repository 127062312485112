import React, { useMemo } from 'react';

const PendingCountBadge = ({
  initiative,
  tab,
}) => {
  const PENDING_COUNTS = useMemo(
    () => ({
      'participants' : initiative.participation_pending_count,
      'collaborators': initiative.collaboration_pending_count,
      'indicators' : initiative.indicator_pending_count,
    }), [initiative]
  );

  const count = useMemo(
    () => PENDING_COUNTS[tab.key],
    [PENDING_COUNTS, tab.key]
  );

  return Number(count) ? <figure className='badge'>{count}</figure> : null
}

export default PendingCountBadge;
