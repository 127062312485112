import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import { formatDateTimeLong } from 'utils/date';

import {
  Table,
} from 'antd';


const Activity = ({
  intl,
  records,
}) => {
  const t = intl.messages;

  const columns = useMemo(
    () => [
      {
        title: t.initiative_activity_member_name,
        dataIndex: 'member_name',
        key: 'member_name',
      },
      {
        title: t.initiative_activity_member_email,
        dataIndex: 'member_email',
        key: 'member_email',
      },
      {
        title: t.initiative_activity_date,
        dataIndex: 'created_at',
        render: (date) => formatDateTimeLong(date, intl)
      },
      {
        title: t.initiative_activity_comments,
        dataIndex: 'params',
        render: params => params.comments || '-'
      }
    ],
    [t, intl]
  );

  return (
    <Table
      columns={columns}
      dataSource={records}
      rowKey='id'
    />
  );
}

export default injectIntl(Activity);