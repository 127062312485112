import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { Tag } from 'antd';
import colors from 'colors';
import { stringToHash } from 'utils/hash'

const Groups = ({
  intl,
  taxonomies,
  cohorts,
  collaboration_status
}) => {
  const t = intl.messages;
  const {
    cohorts: allCohorts
  } = taxonomies;

  const cohortSlugToName = useMemo(() => {
    let cohortMap = {};
    allCohorts.forEach(({ slug, name }) => {
      cohortMap[slug] = name;
    });
    return (slug) => cohortMap[slug] || false;
  }, [ allCohorts ]);

  const data = (cohorts || []).map(cohortSlugToName).filter(Boolean);

  return (
    collaboration_status === 'pending' || collaboration_status === 'rejected' || data.length < 1 ? null :
    <div className='actionGroups'>
      <div className='actionGroups-title'>{t.groups}</div>
      {data.length === 0
      ? t.none
      : data.map(item =>
        <Tag color={colors['tag-palette'][stringToHash(item, 11)]}>{ item }</Tag>
      )}
    </div>
    );
};

export default injectIntl(Groups);
