export const idField = 'id';
export const parentIdField = 'parent_id';
export const childrenField = 'children';

const mergeObject = (obj, el) => ({ ...obj, ...el });

export const flattenTree = (tree) => {
  if(!tree) return {};
  if(!tree[childrenField] || tree[childrenField].length === 0) {
    return {
      [tree[idField]]: tree,
    };
  }
  return {
    [tree[idField]]: { ...tree, [childrenField]: tree[childrenField].map(child => child[idField]) },
    ...(tree[childrenField].map(child => flattenTree(child)).reduce(mergeObject, {})),
  };
};

export const getParentage = (tree, parents = []) => {
  if(!tree) return {};
  if(!tree[childrenField] || tree[childrenField].length === 0) {
    return {
      [tree[idField]]: parents,
    };
  }
  return {
    [tree[idField]]: parents,
    ...(tree[childrenField].map(child => getParentage(child, [ ...parents, tree[idField] ])).reduce(mergeObject, {})),
  };
};

// Finds the top-most left-most element in a tree that matches predicate
// Based on https://stackoverflow.com/a/9133690
export const findTree = (tree = [], f) => {
  let stack = [], node;
  stack.push(...tree);

  while (stack.length > 0) {
    node = stack.pop();
    if (f(node)) {
      return node;
    } else if(node[childrenField] && node[childrenField].length) {
      stack.push(...node[childrenField]);
    }
  }

  return null;
}

