import React, {
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useSetState from 'utils/useSetState'

import {
  Checkbox,
  Drawer,
  Tag
} from 'antd';

const ALL_SOURCES = [ 'paypal', 'transfer', 'external', 'inhand' ];

const InitiativeContributorsFilters = ({
  intl,
  openFilters,
  onCloseFilters,
  onDeselectRowKeys,
  onSetFilters
}) => {
  const t = intl.messages;

  // Status (validated, rejected, pending) filter variables
  const filterStatus = useMemo(() => [
    {slug: 'validated', t: t.contribution_validated},
    {slug: 'pending', t: t.contribution_pending},
    {slug: 'rejected', t: t.contribution_rejected},
  ], [ t ]);

  const {
   set: filteredStatus,
   has: isStatusFilterSet,
   toggle: toggleStatusFilter,
   replaceAll: setFilteredStatus
  } = useSetState([])
  const statusFilterSet = useMemo(() => filteredStatus.length > 0, [filteredStatus])

  // Source (transfer, paypal, inhand, external) filter variables
  const filterSources = useMemo(() => ALL_SOURCES.map(slug => ({
    slug,
    t: t[`donation_${slug}`],
  })), [ t ]);
  const {
    set: filteredSources,
    has: isSourceFilterSet,
    toggle: toggleSourceFilter,
    replaceAll: setFilteredSource
  } = useSetState([])
  const sourceFilterSet = useMemo(() => filteredSources.length > 0, [filteredSources])
  
  // Contact (all methods, only phone, only email) filter variables
  const filterContacts = useMemo(() => {
    return [
      {slug: 'onlyPhone', t: t.volunteers_onlyPhone},
      {slug: 'onlyEmail', t: t.volunteers_onlyEmail}
    ];
  }, [t]);
  const {
    set: filteredContact,
    has: isContactFilterSet,
    toggle: toggleContactFilter,
    replaceAll: setFilteredContact
  } = useSetState([])
  const contactFilterSet = useMemo(() => filteredContact.length > 0, [filteredContact])

  // Flag for skiping filtering
  const skipTaxonomyFilters = useMemo(() => (
    !sourceFilterSet && !statusFilterSet && !contactFilterSet
  ),
  [sourceFilterSet, statusFilterSet, contactFilterSet])

  useEffect(() => {
    onSetFilters({
      filteredStatus,
      filteredSources,
      filteredContact
    });
  }, [
    filteredStatus,
    filteredSources,
    filteredContact,
    onSetFilters
  ]);

  return(
    <>
      { skipTaxonomyFilters ? null :
        <div>
          { !sourceFilterSet ? null :
            <div className="Initiative-filter">
              <span className="Initiative-filter-title">{ t.donation_source }: </span>
              { filteredSources.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="gold"
                  onClose={() => toggleSourceFilter(slug)}
                >{ t[`donation_${slug}`] }</Tag>
              ) }
            </div>
          }
          { !statusFilterSet ? null :
            <div className="Initiative-filter">
              <span className="Initiative-filter-title">{ t.donation_status }: </span>
              { filteredStatus.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="blue"
                  onClose={() => toggleStatusFilter(slug)}
                >{ t[slug] }</Tag>
              ) }
            </div>
          }
          { !contactFilterSet ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.contact_details }: </span>
              { filteredContact.map(slug =>
                  <Tag
                    key={slug}
                    closable
                    color="volcano"
                    onClose={() => toggleContactFilter(slug)}
                  >{ filterContacts.filter(item => item.slug === slug)[0].t }</Tag>
              )}
            </div>
          }
          <div className="Whitespace20"></div>
        </div>
      }
      <Drawer
          title={t.participants_filtersTitle}
          width={350}
          placement="right"
          closable={true}
          onClose={() => onCloseFilters()}
          visible={openFilters}
      >
        <div className="Participants-taxonomy-filter">
          <h4>{t.donation_source}</h4>
          <div>
            <Checkbox
              checked={!sourceFilterSet}
              onChange={() => {
                onDeselectRowKeys()
                setFilteredSource([])
              }}
              style={{ display: 'block' }}
            >
              { t.all }
            </Checkbox>
            { filterSources.map(source => (
              <Checkbox
                key={source.slug}
                checked={isSourceFilterSet(source.slug)}
                onChange={() => {
                  onDeselectRowKeys()
                  setFilteredSource([source.slug])
                }}
                style={{ display: 'block' }}
              >
                { source.t }
              </Checkbox>
            )) }
          </div>
        </div>
        <div className="Participants-taxonomy-filter">
          <h4>{t.donation_status}</h4>
          <div>
            <Checkbox
              checked={!statusFilterSet}
              onChange={() => {
                onDeselectRowKeys()
                setFilteredStatus([])
              }}
              style={{ display: 'block' }}
            >
              { t.all }
            </Checkbox>
            { filterStatus.map(status => (
              <Checkbox
                key={status.slug}
                checked={isStatusFilterSet(status.slug)}
                onChange={() => {
                  onDeselectRowKeys()
                  setFilteredStatus([status.slug])
                }}
                style={{ display: 'block' }}
              >
                { status.t }
              </Checkbox>
            )) }
          </div>
        </div>
        <div className="Participants-taxonomy-filter">
          <h4>{ t.contact_details }</h4>
          <div>
            <Checkbox
              checked={!contactFilterSet}
              onChange={() => {
                onDeselectRowKeys()
                setFilteredContact([])
              }}
              style={{ display: 'block' }}
            >
              { t.all }
            </Checkbox>
            { filterContacts.map(type => (
              <Checkbox
                key={type.slug}
                checked={isContactFilterSet(type.slug)}
                onChange={() => {
                  onDeselectRowKeys()
                  setFilteredContact([type.slug])
                }}
                style={{ display: 'block' }}
              >
                { type.t }
              </Checkbox>
            )) }
          </div>
        </div>
      </Drawer>
    </>
  );
}

InitiativeContributorsFilters.propTypes = {
  onCloseFilters: PropTypes.func.isRequired,
  onDeselectRowKeys: PropTypes.func.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  openFilters: PropTypes.bool.isRequired
};

export default injectIntl(InitiativeContributorsFilters);
