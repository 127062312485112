import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import './style.less';

import {
  Tooltip,
  Progress,
} from 'antd';

const MARGIN_OVER_TOP = 0;

const DonationProgress = ({
  total: totalStr,
  validated: validatedStr,
  limit: limitStr,
  currency,
  active,
  intl,
  mode,
}) => {
  const t = intl.messages;
  const total = useMemo(() => parseFloat(totalStr, 10), [ totalStr ]);
  const validated = useMemo(() => parseInt(validatedStr, 10), [ validatedStr ]);
  const limit = useMemo(() => {
    const limit = limitStr ? parseInt(limitStr, 10) : 0;
    if(limit >= total || !active) {
      return limit;
    }
    return total * (1 + MARGIN_OVER_TOP);
  }, [ limitStr, total, active ]);

  const percent = useMemo(() => {
    return (
      Math.ceil( 100 * total / limit )
    ) || 0;
  }, [ total, limit ]);

  const successPercent = useMemo(() => {
    if(!validated) return 0;
    return Math.ceil( 100 * validated / limit );
  }, [ validated, limit ]);

  const status = useMemo(() => {
    if(active) {
      return 'active';
    }
    return (limit > total) ? 'exception' : 'success';
  }, [ active, limit, total ]);

  const currencyFormatter = useMemo(() => {
    return new Intl.NumberFormat(intl.locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0
    })
  }, [ intl, currency ]);

  return (
    <div>
      <Tooltip
        title={`${percent}% - ${successPercent}% ${t.confirmed}`}
      >
        { mode !== 'detail' ? null :
          <span className='progress-text-top'><strong>{`${percent}%`}</strong>{` - ${successPercent}% ${t.confirmed}`}</span>
        }
        <Progress
          className='donationProgressBar'
          percent={percent}
          successPercent={successPercent}
          status={status}
          showInfo={false}
        />
        { mode !== 'card' ? null :
          <span className='progress-text'>{`${percent}% - ${currencyFormatter.format(total)} ${t.contribution_raised}`}</span>
        }
      </Tooltip>
    </div>
  );
}

export default injectIntl(DonationProgress);
