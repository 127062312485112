const TAXONOMIES_TARGET = 'taxonomies';
const TAXONOMIES_COHORT_TARGET = 'taxonomies_cohort'
const TAXONOMIES_COURSE_TARGET = 'taxonomies_course'
const TAXONOMIES_COURSE_DETAIL_TARGET = 'course_detail'
const ID_FIELD = 'id'; // TODO: Change to slug if we ever stop exposing numerical ids

const isTarget = (target) => [TAXONOMIES_TARGET, TAXONOMIES_COHORT_TARGET, TAXONOMIES_COURSE_TARGET, TAXONOMIES_COURSE_DETAIL_TARGET].includes(target);
const isTaxonomiesTarget = (target) => target === TAXONOMIES_TARGET
const isTaxonomiesCohortTarget = (target) => target === TAXONOMIES_COHORT_TARGET
const isTaxonomiesCourseTarget = (target) => target === TAXONOMIES_COURSE_TARGET // GET / POST / DELETE
const isTaxonomiesCourseDetailTarget = (target) => target === TAXONOMIES_COURSE_DETAIL_TARGET

const initialState = {
  sdgs: [],
  categories: [],
  targetAudience: [],
  cohorts: [],
  competences: [],
  courses: [],
  tags: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: true,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      // Insert new course
      if(isTaxonomiesCourseTarget(action.response.target) && ['POST'].includes(action.response.method)){
        return {
          ...state,
          courses: [...state.courses, action.response.result],
          loading: false,
          error: false,
        }
      }

      // Edit course
      if(isTaxonomiesCourseTarget(action.response.target) && ['PUT', 'DELETE'].includes(action.response.method)){
        const targetCourse = action.response.result
        const index = state.courses.findIndex((course) => course[ID_FIELD] === targetCourse[ID_FIELD]);
        return {
          ...state,
          courses: [ ...state.courses.slice(0, index), targetCourse, ...state.courses.slice(index+1) ],
          loading: false,
          error: false,
        }
      }

      // Teacher, student or initiative added or removed
      if(isTaxonomiesCourseDetailTarget(action.response.target) && ['PUT', 'DELETE'].includes(action.response.method)){
        const targetCourse = action.response.result.course
        const index = state.courses.findIndex((course) => course[ID_FIELD] === targetCourse[ID_FIELD]);
        return {
          ...state,
          courses: [ ...state.courses.slice(0, index), targetCourse, ...state.courses.slice(index+1) ],
          loading: false,
          error: false,
        };
      }

      // taxonomies_cohort target && put: editing a cohort
      if(isTaxonomiesCohortTarget(action.response.target) && ['PUT'].includes(action.response.method)) {
        const newCohorts = state.cohorts.map(cohort =>
          (cohort.id === action.response.result.id) ? action.response.result : cohort
        )
        return {
          ...state,
          cohorts: newCohorts || state.cohorts,
          loading: false,
          error: false,
        }
      }

      // taxonomies target: getting cohorts or setting new cohorts
      if(isTaxonomiesTarget(action.response.target)){
        return {
          sdgs: action.response.result.sdg || state.sdgs,
          categories: action.response.result.category || state.categories,
          targetAudience: action.response.result.target_audience || state.targetAudience,
          cohorts: action.response.result.cohort || state.cohorts,
          competences: action.response.result.competence || state.competences,
          courses: action.response.result.course || state.course,
          tags: action.response.result.tag || state.tag,
          loading: false,
          error: false,
        }
      }

      // Target not found
      return state
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: action.code,
      };
    case 'ADD_TAG_TO_TAXONOMIES':
      if (!state.tags.includes(action.tag)) {
        return {
          ...state,
          tags: [...state.tags, action.tag],
        };
      }
      return state;
    default:
      return state;
  }
};

export {
  reducer as taxonomies,
};
