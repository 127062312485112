import React, {
	useState,
	useEffect,
	useCallback
} from "react";
import { Collapse, Spin } from 'antd';

import { useChat } from 'hooks';
import ChatConversationWindow from './components/ChatConversationWindow';
import ChatUsersListWindow from "./components/ChatUsersListWindow";
import ChatsList from "./components/ChatsList";
import ChatHeader from "./components/ChatHeader";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import './style.less'

const { Panel } = Collapse;

const MESSAGE_DIRECTION_OUTGOING = 'outgoing'
const MESSAGE_STATUS_SENT = 'sent'
const CHATS_LIST_KEY = 'chatsList'
const CHAT_CONVERSATION_KEY = 'chatConversation'

const Chat = () => {
	
	const [showUsersList, setShowUsersList] = useState(false)
	const [expandChat, setExpandChat] = useState([])
	const [searchUser, setSearchUser] = useState('')
	const [expandChatWindow, setExpandChatWindow] = useState([CHAT_CONVERSATION_KEY])

	const chat = useChat()
	const {
		conversations,
		activeConversation,
		activeUser,
		loggedIn,
		users,
		sendMessage,
		setCurrentMessage,
		sendTyping,
		getOrganizationUsers,
		getOrganizationDialogs,
		setActiveConversation,
	} = chat

		const totalUnreadCount = conversations.reduce((accumulator, currentValue) => accumulator + currentValue.unreadCounter, 0)

		useEffect(() => {
			if(loggedIn) {
				getOrganizationUsers()
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [loggedIn]);

		// NOTICE: Refresh users every 2 minutes. This is a workaround to get user updates (new joins, username or avatar) from QuickBlox.
		useEffect(() => {
			if(loggedIn) {
				let interval = setInterval(() => {
					getOrganizationUsers()
				}, (1000 * 60 * 2))
				return () => clearInterval(interval)
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [loggedIn]);

		useEffect(() => {
			if(users.length) {
				getOrganizationDialogs()
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [users.length]);

    const handleChange = (value) => {
			setCurrentMessage(value);
			if ( activeConversation ) {
					sendTyping(activeConversation?.id)
			}
    }

    const handleSend = (text) => {
			const message = {
				content: text,
				senderId: activeUser.id,
				direction: MESSAGE_DIRECTION_OUTGOING,
				status: MESSAGE_STATUS_SENT
			}
			if(activeConversation) {
				sendMessage(message)
			}
    }

		const onClickNewChat = useCallback((e) => {
			e.stopPropagation()
			setSearchUser("")
			setActiveConversation("")
			setShowUsersList(true)
    }, [setActiveConversation]);

    return ( 
			<div className="GlobalChat" >
				<Collapse
					className={`
						GlobalChat__chat
						${totalUnreadCount > 0 && !expandChat.includes(CHATS_LIST_KEY) ? 'has-unread' : ''}
					`}
					expandIconPosition="right"
					activeKey={expandChat}
					onChange={(activeKey)=> setExpandChat(activeKey)}
				>
					<Panel
						key={CHATS_LIST_KEY}
						header={
							<ChatHeader
								avatar={activeUser.avatar}
								username={activeUser.username}
								totalUnreadCount={totalUnreadCount}
								onClickNewChat={onClickNewChat}
								isExpanded={expandChat.includes(CHATS_LIST_KEY)}
								hasNewChat
								isMyChat
							/>
						}
					>
						{conversations.length ? (
								<ChatsList
									useChat={chat}
									setExpandChatWindow={setExpandChatWindow}
									expandChatWindow={expandChatWindow}
									setShowUsersList={setShowUsersList}
								/>
							) : (
								<Spin/>
							)
						}
					</Panel>
				</Collapse>
				{activeConversation && !showUsersList ? (
					<ChatConversationWindow
						handleChange={handleChange}
						handleSend={handleSend}
						useChat={chat}
						expandChatWindow={expandChatWindow}
						setExpandChatWindow={setExpandChatWindow}
					/>
				): null}
				{showUsersList && !activeConversation ? (
					<ChatUsersListWindow
						useChat={chat}
						setShowChatUsersList={setShowUsersList}
						searchUser={searchUser}
						setSearchUser={setSearchUser}
					/>
				) : null}
			</div>
		)
}

export default Chat