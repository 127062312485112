import React from 'react';
import Avatar from 'components/Avatar';

const Organization = ({
  organization_name,
  organization_logo_small,
}) => {
  return (
    <section className='actionOrganization'>
      <Avatar className='actionOrganization-avatar' shape="square" src={organization_logo_small} />
      <span className='actionOrganization-name'>{organization_name}</span>
    </section>
    );
};

export default Organization;
