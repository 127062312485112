import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import EditInitiative from 'containers/EditInitiative';
import MainLayout from 'components/MainLayout';

import Donation from './types/Donation';
import Internal from './types/Internal';
import Volunteering from './types/Volunteering';
import Informative from './types/Informative';
import Training from './types/Training';
import Collection from './types/Collection';
import { isAfterNow } from 'utils/date';
import useNow from 'utils/useNow';
import { usePermissionList } from 'components/PermissionSwitch';
import ActionHeader from './cards/ActionHeader'
import {
  requestInitiativeDetail,
  cancelInitiative,
  requestCollaboration,
  updateCollaboration,
  cancelCollaborationRequest,
  stopCollaboration,
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations'

import './style.less';

const EDITABLE_STATUSES = ['open', 'proposed', 'draft'];
const CANCELED_STATUSES = [ 'suspended', 'canceled' ];

const DETAIL_PER_TYPE = {
  volunteering: Volunteering,
  donation: Donation,
  internal: Internal,
  informative: Informative,
  training: Training,
  collection: Collection,
  default: Volunteering,
};

// toDo (Improve this redirection)
const getUrlBase = (collaboration_status, status, from) => {
  if((status && (status === 'proposed' || status === 'rejected')) || from === 'proposal') {
    return 'proposal';
  }

  if(from === 'collaboration'){
    return 'collaboration'
  }

  switch(collaboration_status) {
    case 'organizer':
    case 'accepted':
      return 'initiative';
    case 'pending':
    case 'rejected':
    case null:
    case undefined:
    default:
      return 'collaboration';
  }
};

const InitiativeDetail = ({
  intl,
  match,
  history,
  location,
  profile,
  initiative_detail,
  taxonomies,
  requestInitiativeDetail,
  cancelInitiative,
  requestCollaboration,
  updateCollaboration,
  cancelCollaborationRequest,
  stopCollaboration,
}) => {
  const push = history.push;
  const replace = history.replace;
  const { slug, action } = match.params;

  const {
    organization,
  } = useOrganizations();

  const now = useNow();

  const permissionList = usePermissionList();

  const [ requested, setRequested ] = useState(false);

  useEffect(() => {
    requestInitiativeDetail(organization.slug, slug);
    setRequested(true);
  }, [organization.slug, slug, requestInitiativeDetail]); // TODO: Pull to refresh

  const {
    data: initiative,
    loading,
    loading_member_id,
    error,
  } = initiative_detail;

  const isCanceled = useMemo(() => CANCELED_STATUSES.includes(initiative?.status), [initiative]);
  // Are we organizers? or collaborators?
  const isOrganizer = initiative && initiative.collaboration_status === 'organizer';
  const isCollaborator = initiative && initiative.collaboration_status === 'accepted';

  useEffect(() => {
    if(!initiative || !requested) {
      return
    }
    const path = location.pathname;
    const { collaboration_status, status } = initiative || {};
    const from = path.split('/')[1]
    const urlBase = getUrlBase(collaboration_status, status, from);
    if(!path.startsWith(`/${urlBase}`)) {
      replace( action ?  `/${urlBase}/${slug}/${action}` : `/${urlBase}/${slug}` );
    }
  }, [ location.pathname, initiative, requested, action, replace, slug ]);

  const {
    data: admin_profile,
  } = profile;

  const {
    type: initiative_type,
  } = initiative || {};

  const Detail = DETAIL_PER_TYPE[initiative_type] || DETAIL_PER_TYPE.default;

  // Can we edit?
  const initiativeDone = useMemo(() => {
    return initiative && !isAfterNow(initiative.end_time);
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    now,
    initiative,
  ]);

  if(!initiative || !requested) {
    return (
    <MainLayout>
    </MainLayout>
    );
  };

  const isProposal = initiative.status === 'proposed';
  const isDraft = initiative.status === 'draft';
  const isRejected = initiative.status === 'rejected';

  const canEdit = (
    permissionList.has('can_override_limits') || (
      permissionList.has('can_manage_initiatives') &&
      EDITABLE_STATUSES.includes(initiative.status) &&
      (
        !initiativeDone ||
        isProposal ||
        isDraft
      )
    )
  );

  // Special case
  if(isOrganizer && canEdit && action === 'edit') {
    return (
      <EditInitiative
        loading={loading}
        error={error}
        initiative={initiative}
        organization={organization}
      />
    );
  };

  if(isProposal) {
    push(`/initiative/${slug}/edit`);
    return null;
  }

  if(isRejected) {
    push(`/proposal`);
    return null;
  }

  if(action === 'edit') {
    // Trying to edit something you shouldnt't ?
    push(`/initiative/${slug}`);
  }

  if (initiative.status === 'draft' && action !== 'edit') {
    // Draft initiatives should only open in edit mode
    push(`/initiative/${slug}/edit`);
  }

  const props = {
    push,
    slug,
    action,
    organization,
    initiative,
    taxonomies,
    requestInitiativeDetail,
    cancelInitiative,
    requestCollaboration,
    updateCollaboration,
    cancelCollaborationRequest,
    stopCollaboration,
    admin_profile,
    isOrganizer,
    isCollaborator,
    loading,
    loading_member_id,
    canEdit,
    initiativeDone,
    isCanceled,
  };

  return (
    <MainLayout
      errorMessage={intl.formatMessage({id: 'error_boundary_initiative_details_message'})}
    >
      <>
        <ActionHeader {...props} />
        <Detail {...props} />
      </>
    </MainLayout>
  )
}

const mapStateToProps = ({
  taxonomies,
  profile,
  initiative_detail = {},
}) => ({
  taxonomies,
  profile,
  initiative_detail,
});

export default connect(
  mapStateToProps,
  {
    requestInitiativeDetail,
    cancelInitiative,
    requestCollaboration,
    updateCollaboration,
    cancelCollaborationRequest,
    stopCollaboration,
  })(
    withRouter(
      injectIntl(InitiativeDetail)
    )
  )
