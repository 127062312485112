import React from 'react';
import { injectIntl } from 'react-intl';

const Points = ({
  intl,
  points,
}) => {
  const t = intl.messages;
  return (
    <section className='actionPoints'>
      <div className='actionPoints-title'>
        { t.initiativedetail_points }
      </div>
      <div className="actionPoints-points">
        { points }
      </div>
    </section>
  );
};

export default injectIntl(Points);
