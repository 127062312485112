import React, {Fragment, useEffect, useRef, useMemo, useCallback} from 'react';
import {injectIntl} from 'react-intl';

import {
  initiativeFromFormToDb,
  initiativeFromDbToForm,
} from '../converters';
import useForm from 'utils/useForm';
import { formatDateLong, isAfterNow } from 'utils/date';
import { getUserName } from 'utils/user';

import GeneralInfo from '../cards/GeneralInfo';
import Sdgs from '../cards/Sdgs';
import Contact from '../cards/Contact';
import Dates from '../cards/Dates';
import Location from '../cards/Location';
import Competences from '../cards/Competences';
import Points from '../cards/Points';
import Beneficiaries from '../cards/Beneficiaries';
import Cohorts from '../cards/Cohorts';
import Restrictions from '../cards/Restrictions';
import FeedbackUrl from '../cards/FeedbackUrl';
import ParticipationMethod from '../cards/ParticipationMethod';
import Questions from '../cards/Questions';
import Activity from '../Activity';
import { usePermissionList } from 'components/PermissionSwitch';
import InitiativeTypeIcon from 'components/InitiativeTypeIcon';
import InitiativeTypeString from 'components/InitiativeTypeString';

import ButtonArea from '../ButtonArea';
import validation from '../validation';
import '../style.less';

import {
  Form,
  Row,
  Alert,
  Col,
} from 'antd';

const SET_NAMES = [
  'questions',
  'sdgs',
  'competences',
  'cohorts',
]; // TODO: <- implement categories as a set?

const InitiativeForm = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  cancelInitiative,
  formMode: forceFormMode,
  type: initiativeType,
  subtype,
  hideCategories,
  hideTargetGroups,
  onDeleteDraftInitiative
}) => {
  const t = intl.messages;

  const cancelMode = useMemo(() => {
    if(isAfterNow(defaultValues.start_time) || (
      defaultValues?.participation_pending_count === '0'
      && defaultValues?.collaboration_pending_count === '0'
      && defaultValues?.indicator_pending_count === '0'
      && defaultValues?.participant_count === '0'
    )) {
      return 'cancel';
    }
    return 'suspend';
  }, [ defaultValues ]);

  const hasPoints = useMemo(() => {
    const orgFeatures = organization.features;
    return orgFeatures && orgFeatures.includes('points')
  }, [
    organization,
  ]);

  // Check if this user can override some limitations
  const permissionList = usePermissionList();
  const canOverride = permissionList.has('can_override_limits');
  const canEditTitle = canOverride || permissionList.has('can_manage_initiatives');
  const canOverrideDates = canOverride ||
    ((organization.config || {}).can_override_date_limits || []).includes('admin');
  const hasQuestions =  useMemo(() => {
    return Object.keys((organization.config || {}).initiative_questions || {}).length >= 1;
  }, [
    organization,
  ]);

  const showWorkingHours = (organization.config || {}).consider_working_hours;

  const errorRefs = {
    title: useRef(null),
    sdgs: useRef(null),
    dates: useRef(null),
    address: useRef(null),
    competences: useRef(null),
    contact: useRef(null),
    feedback_url: useRef(null),
    participation_method: useRef(null),
  };

  const formMode = forceFormMode || (!defaultValues
    ? 'create'
    : (
      defaultValues.status === 'proposed'
      ? 'proposal'
      : 'edit')
  );
  const defaults = useMemo(() => initiativeFromDbToForm(defaultValues), [defaultValues]);

  const submitForm = () => {
    const payload = {
      ...initiativeFromFormToDb(values, initiativeType),
      type: initiativeType,
      subtype,
    };
    onSubmit(payload);
  };

  const validateForm = useMemo(() =>
    validation(
      t,
      initiativeType,
      formMode,
      canOverrideDates,
      organization.config.max_number_of_sdgs,
    ),
    [
      t,
      initiativeType,
      formMode,
      canOverrideDates,
      organization,
    ]
  );

  // Basic state for the whole form + submitting
  const {
    values,
    handleChange,
    handleChangeEvent,
    runValidation,
    handleSubmit,
    isDirty,
    errors
  } = useForm({
    callback: submitForm,
    defaultValues: {
      ...(formMode === 'create' ? defaults : {}),
      category1: defaults.category1,
      category2: defaults.category2,
      category3: defaults.category3,
      targetAudience1: defaults.targetAudience1,
      targetAudience2: defaults.targetAudience2,
      targetAudience3: defaults.targetAudience3,
    },
    validate: validateForm,
    validationDefaults: defaults,
    setNames: SET_NAMES,
    defaultSetValues: {
      questions: defaults.questions || [],
      sdgs: defaults.sdgs || [],
      competences: defaults.competences || [],
      cohorts: defaults.cohorts || [],
    }
  });

  const getValue = useCallback((name) => (
    typeof values[name] === 'undefined'
    ? defaults[name]
    : values[name]), [values, defaults]);

  const showError = useCallback((name) => (!isDirty(name) && errors[name]) || '', [isDirty, errors]);

  const {
    categories,
    targetAudience,
  } = taxonomies;

  const onEditConfirm = useCallback((notify_participants) => {
    handleChange('notify_participants')(notify_participants);
  }, [handleChange]);

  const onSaveAsDraft = useCallback((draft_comments) => {
    handleChange('status')('draft');
    handleChange('draft_comments')(draft_comments);
  }, [handleChange]);

  const unsetSaveAsDraft = useCallback(() => {
    handleChange('status')();
    handleChange('draft_comments')();
  }, [handleChange]);

  useEffect(() => {
    // Run validation when we open a form that is not creation
    if (formMode !== 'create') {
      runValidation()
    }
  }, [formMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errors._firstError && errorRefs[errors._firstError] && errorRefs[errors._firstError].current) {
      errorRefs[errors._firstError].current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, [// eslint-disable-line react-hooks/exhaustive-deps
    errors]);

  const params = {
    getValue,
    showError,
    handleChangeEvent,
    handleChange,
    values,
    formMode,
    errorRefs,
    errors,
    categories,
    targetAudience,
    taxonomies,
    organization,
    canOverride,
    canEditTitle,
    hideCategories,
    hideTargetGroups,
  };

  return (
    <Fragment>
      <Row type='flex' align='middle' justify='center'>
        <InitiativeTypeIcon
          type={initiativeType}
          subtype={subtype}
          className='InitiativeForm__icon'
        />
        <h2 className='InitiativeForm__title'>
          <InitiativeTypeString
            type={initiativeType}
            subtype={subtype}
            mode={formMode}
          />
        </h2>
      </Row>
      { formMode === 'proposal'
          ? <div className="InitiativeForm__proposalHeader">
              <Row type='flex' align='bottom' justify='center'>
                <p>
                  { intl.formatMessage(
                      { id: `proposed_by` },
                      { value: getUserName(defaults.submitter, intl) }
                    )}
                </p>
                {(getValue('proposal_params') || {}).submitter_helps_organize && (
                  <p className="InitiativeForm__proposerHelp">
                    ({ intl.formatMessage(
                        { id: `proposer_wants_to_help` }
                      )})
                  </p>
                )}
    
              </Row>
              <Row type='flex' align='top' justify='center'>
                <p>
                  { intl.formatMessage(
                      { id: `proposed_on` },
                      { value: formatDateLong(defaults.created_at, intl) }
                    )}
                </p>
              </Row>
            </div>
        : null
      }
      <div className="Whitespace20"></div>
      <Form id="initiative_form" onFinish={handleSubmit}>
        <div
          style={{
            position: 'relative',
            top: '-64px'
            }}
          ref={errorRefs.title}>
        </div>
        <div style={{ height: '20px' }}></div>

        <GeneralInfo {...params} />
        <Sdgs {...params} />
        <Dates
          {...params}
          canOverride={canOverrideDates}
          calculatePerDiem
          showWorkingHours={showWorkingHours}
          showRegistrationEnd
        />
        <Location {...params} />
        <Competences {...params} />
        {
          !hasPoints ? null :
          <Points {...params} />
        }
        <Beneficiaries {...params} />
        <Contact {...params} shownToUsers />
        <Cohorts {...params} />
        <Restrictions {...params} />
        <FeedbackUrl {...params} />
        <ParticipationMethod {...params} />
        {
          !hasQuestions ? null :
          <Questions {...params} />
        }

        <div style={{ height: '20px' }}></div>

        { defaultValues.status === 'draft' &&
          <Row>
            <Col span={24}>
              <h4>{t.initiative_modified_by}:</h4>
            </Col>
            <Col span={24}>
              <Activity records={defaultValues.activity} />
            </Col>
          </Row>
        }
        <Row type="flex" justify="end">
          <ButtonArea
            cancelMode={cancelMode}
            cancelInitiative={cancelInitiative}
            organization={organization}
            initiative={defaultValues}
            formMode={formMode}
            loading={loading}
            onRejectProposal={onRejectProposal}
            onEditConfirm={onEditConfirm}
            onSaveAsDraft={onSaveAsDraft}
            unsetSaveAsDraft={unsetSaveAsDraft}
            submitForm={handleSubmit}
            onDeleteDraftInitiative={onDeleteDraftInitiative}
          />
        </Row>

        <div style={{ height: '10px' }}></div>

        {
          !error
            ? null
            : <Alert type="error" message={t[`initiative_form_error_${error}`] || t.initiative_form_error_default}/>
        }
        <div style={{ height: '10px' }}></div>
      </Form>
    </Fragment>
  );
}

export default injectIntl(InitiativeForm);
