import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import Loading from 'containers/Loading';
import NotFound from 'containers/NotFound';
import AccountsRedirect from 'containers/AccountsRedirect';
import Reporting from 'containers/Reporting';
import Rankings from 'containers/Rankings';
import Proposal from 'containers/Proposal';
import InitiativeDetail from 'containers/InitiativeDetail';
import CreateInitiative from 'containers/CreateInitiative';
import Initiative from 'containers/Initiative';
import Collaboration from 'containers/Collaboration';
import Volunteer from 'containers/Volunteer';
import Course from 'containers/Course';
import NotSubscribed from 'containers/NotSubscribed';
import ExternalManagerApproval from 'containers/ExternalManagerApproval';
import AboutOurProgram from 'containers/AboutOurProgram';

import { useFeatureList } from 'components/FeatureSwitch';
import { usePermissionList } from 'components/PermissionSwitch';

const ProtectedRoute = ({
  isLoggedIn,
  hasOrganizationRequirement,
  ...props
}) => (
  isLoggedIn
  ? (
    hasOrganizationRequirement
    ? <Route {...props} />
    : <Redirect to="/organizations" />
  ) :
    <Redirect to="/signin" />
);

const Routes = ({
  auth,
  organization,
}) => {
  const featureList = useFeatureList();
  const permissionList = usePermissionList();

  const defaultRoute = !auth.logged_in
    ? '/signin'
    : !organization.data
      ? '/organizations'
      : permissionList.has('can_read_reports') && featureList.has('dashboard')
        ? '/reporting'
        : '/initiative';

  return (
    <Switch>
      <Redirect exact from="/" to={defaultRoute} />
      <Route path="/loading" component={Loading.Big} />
      <Route path="/signin" component={AccountsRedirect} />
      <Route path="/recover_password/:token" component={AccountsRedirect} />
      <Route path="/confirm_email/:token" component={AccountsRedirect} />
      <Route path="/signup/:token" component={AccountsRedirect} />
      <Route path="/recover_password" component={AccountsRedirect} />
      <Route path="/not_subscribed" component={NotSubscribed} />
      <Route path="/logout" component={AccountsRedirect} />
      <Route path="/manager_approval/:token" component={ExternalManagerApproval} />

      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={true}
        path="/organizations"
        component={AccountsRedirect}
      />

      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/profile"
        component={AccountsRedirect}
      />

      { !permissionList.has('can_read_reports') || !featureList.has('dashboard')
        ? <Redirect from="/reporting" to="/"/>
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/reporting"
            component={Reporting}
          />
      }
      { !permissionList.has('can_read_reports') || !featureList.has('rankings')
        ? null
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/rankings"
            component={Rankings}
          />
      }
      { !permissionList.has('can_manage_initiatives')
        ? <Redirect from="/create" to="/"/>
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/create/:type?"
            component={CreateInitiative}
          />
      }
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/initiative/tab/:tab"
        component={Initiative}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/initiative/:slug/:action?"
        component={InitiativeDetail}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/proposal/:slug/:action?"
        component={InitiativeDetail}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/initiative"
        component={Initiative}
      />
      { !featureList.has('collaborations')
        ? null
        : !permissionList.has('can_manage_collaborations')
        ? <Redirect from="/collaboration/:slug" to="/signin"/>
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/collaboration/:slug/:action?"
            component={InitiativeDetail}
          />
      }
      { !featureList.has('collaborations')
        ? null
        : !permissionList.has('can_manage_collaborations')
        ? <Redirect from="/collaboration" to="/"/>
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/collaboration"
            component={Collaboration}
          />
      }
      { !featureList.has('proposals')
        ? null
        : !permissionList.has('can_manage_proposals')
        ? <Redirect from="/proposal" to="/"/>
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/proposal"
            component={Proposal}
          />
      }
      { !featureList.has('education')
        ? null
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/course"
            component={Course}
          />
      }
      { !permissionList.has('can_manage_volunteers') || !featureList.has('community')
        ? null
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/volunteer"
            component={Volunteer}
          />
      }
      { !featureList.has('about_our_program')
        ? null
        : <ProtectedRoute
            isLoggedIn={auth.logged_in}
            hasOrganizationRequirement={!!organization.data}
            path="/about-our-program"
            component={AboutOurProgram}
          />
      }
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/organization"
        component={AccountsRedirect}
      />
      <Route component={NotFound} />
    </Switch>
    )
}

const mapStateToProps = ({
  auth,
  organization,
}) => ({
  auth,
  organization,
});

export default withRouter(connect(mapStateToProps, {})(Routes));
