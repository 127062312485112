import React from 'react';
import {injectIntl} from 'react-intl';

import {
  Card,
  Form,
  InputNumber,
  Switch,
} from 'antd';

const DEFAULT_POINTS = 10;

const Points = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  showError,
  getValue,
  handleChange,
  formMode,
  canOverride,
}) => {
  const t = intl.messages;

  return (
    <Card
      className="InitiativeForm-card"
      title={ t.initiativeform_points }
    >
      <Form.Item
        label={ t.initiativeform_points_has_points }
        colon={false}
        labelCol={{
          xs: 24,
          sm: 18,
          md: 12
        }} wrapperCol={{
          xs: 24,
          sm: 6
        }}
      >
        <Switch
          checkedChildren={ t.initiativeform_points_yes }
          unCheckedChildren={ t.initiativeform_points_no }
          checked={getValue('points') >= 0}
          onChange={() => handleChange('points')(
            getValue('points') >= 0
            ? -1
            : DEFAULT_POINTS
          )}
        />
      </Form.Item>
      <Form.Item
        label={t.initiativeform_points_number_of_points}
        colon={false}
        hasFeedback="hasFeedback"
        validateStatus={showError('points')
          ? 'error'
          : ''}
        help={showError('points')}
        labelCol={{
          xs: 24,
          sm: 18,
          md: 12
        }} wrapperCol={{
          xs: 24,
          sm: 6
        }}
      >
        <InputNumber
          name="points"
          value={getValue('points') >= 0
            ? getValue('points')
            : ''
          }
          min={0}
          onChange={handleChange('points')}
          placeholder={''}
          disabled={getValue('points') < 0}
        />
      </Form.Item>
    </Card>
  );
}

export default injectIntl(Points);
