import React, {useMemo, useState, useCallback, useRef} from 'react'
import { Checkbox, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './style.less'
import { injectIntl } from 'react-intl';
import {useHotkeys} from 'react-hotkeys-hook'
import Avatar from 'components/Avatar';
import { ReactComponent as CheckmarkLogo } from './images/checkmark.svg';
import { ReactComponent as LockLogo } from './images/icon-locked.svg';

const ItemList = (({
  intl,
  withBulk,
  withSearch,
  itemTitle,
  itemContent,
  items,
  onClick,
  organization,
  activeSubCategory,
  itemDetail = {},
  sortContent,
  scrollControl,
  titles,
  highlightActive = true,
}) => {
  // Traductions
  const t = intl.messages;

  // Search text state
  const [searchText, setSearchText] = useState('')
  // Focus search field on Ctrl+F
  const searchInputRef = useRef()
  useHotkeys('ctrl+f', (e) => {
    if (searchInputRef.current.focus) {
      searchInputRef.current.focus()
      e.preventDefault()
    }
  }, [searchInputRef])

  const sortedFilteredItems = useMemo(() => {
    // If any sort function, sort
    if(sortContent){
      items.sort(sortContent[activeSubCategory])
    }
    // If no searchText return items sorted
    if(!searchText && searchText === '') return items
    // Filter items by searchText
    return items.filter((item) => {
      const name = (item.name || '').toLowerCase()
      const title = (item.title || '').toLowerCase()
      return name.includes(searchText) || title.includes(searchText)
    })
  },[activeSubCategory, items, searchText, sortContent])

  // Bulk state
  const [itemCheckList, setItemCheckList] = useState([])

  const indeterminateCheck = useMemo(() => (
    !!itemCheckList.length && itemCheckList.length < items.length
  ),[itemCheckList.length, items.length])

  const allChecked = useMemo(() => (
    !!itemCheckList.length && itemCheckList.length === items.length
  ),[itemCheckList.length, items.length])

  const checkAll = useCallback((e) => {
    setItemCheckList(e.target.checked ? items.map((item) => item.slug) : [])
  },[items])

  const handleCheckbox = useCallback((e, item) => {
    const index = itemCheckList.indexOf(item.slug)
    if(index < 0){
      setItemCheckList([ ...itemCheckList, item.slug ])
    }else{
      setItemCheckList([ ...itemCheckList.slice(0, index), ...itemCheckList.slice(index+1) ])
    }
  },[itemCheckList])

  const handleClick = useCallback((item) => {
    if(onClick){
      onClick(organization.slug, item.slug)
    }
  },[onClick, organization.slug])

  const isChecked = useCallback((item) => (
    itemCheckList.indexOf(item.slug) >= 0
  ),[itemCheckList])

  const getItemFromSlug = useCallback((slug) => (
    items.filter((item) => item.slug === slug)[0]
  ),[items])

  const itemsSelected = useMemo(() => {
    const selected = []
    itemCheckList.forEach((item) => selected.push(getItemFromSlug(item)))
    return selected
  },[getItemFromSlug, itemCheckList])

  const handleActionClick = useCallback((content, item, secondAction) => {
    if(scrollControl){
      scrollControl.current.scrollTop = 0
    }
    return secondAction
      ? content.secondAction[activeSubCategory](itemDetail.slug, item.slug)
      : content.action[activeSubCategory](itemDetail.slug, item.slug)
  },[activeSubCategory, itemDetail, scrollControl])

  const renderBulkAction = useCallback((content) => {
    if(itemsSelected.length <= 0) return null
    return (
      <React.Fragment>
        <Button
          type='default'
          onClick={() => itemsSelected.forEach((item) => handleActionClick(content, item, false))}
        >
          {t.add}
        </Button>
        <Button
          type='default'
          onClick={() => itemsSelected.forEach((item) => handleActionClick(content, item, true))}
        >
          {t.course_remove}
        </Button>
      </React.Fragment>

    )
  },[handleActionClick, itemsSelected, t])

  const renderItemsContent = useCallback((item) => {
    if(!itemContent || itemContent.length < 1) return null

    return itemContent.map((content) => {
      if(content.type === 'number'){
        return <span className={item[content.name]}>{item[content.name]}</span>
      }else if(content.type === 'action'){
        const secondAction = content.second[activeSubCategory](item)
        return <Button
                  type={secondAction ? 'primary' : 'default'}
                  onClick={() => handleActionClick(content, item, secondAction)}
                >
                  {!secondAction ? null : <CheckmarkLogo/>}
                  {!secondAction ? content.name : content.secondName}
                </Button>
      }else{
        return null
      }
    })
  },[itemContent, activeSubCategory, handleActionClick])

  const renderItems = useMemo(() => (
    <section className='ItemList-list'>
      { sortedFilteredItems && sortedFilteredItems.map((item) => (
          <article className={`ItemList-item ${highlightActive && itemDetail && itemDetail.id === item.id ? 'active' : ''}`} onClick={() => handleClick(item)}>
          <section className='ItemList-itemTitle'>
            { item.status !== 'closed' ? null :
              <LockLogo/>
            }
            { !withBulk ? null :
              <Checkbox
                onChange={(e) => handleCheckbox(e, item)}
                checked={isChecked(item)}
              />
            }
            { !titles.avatar ? null :
              <Avatar className='ItemList-avatar' size={40} name={(item.name || item.title)}  src={item.avatar} />
            }
            <section className='ItemList-itemInfo'>
              <span className='ItemList-itemFirstTitle'>
                {titles.title.map((title, i) => (
                  i === 0 ? item[title] : item[title] ? ` - ${item[title]}` : ''
                ))}
              </span>
              { !titles.secondTitle ? null :
                <span className='ItemList-itemSecondTitle'>
                  {titles.secondTitle.map((title, i) => (
                    i === 0 ? item[title] : item[title] ? ` - ${item[title]}` : ''
                  ))}
                </span>
              }
              { !titles.thirdTitle ? null :
                <span className='ItemList-itemThirdTitle'>
                  {titles.thirdTitle.map((title, i) => (
                    i === 0 ? item[title] : item[title] ? ` - ${item[title]}` : ''
                  ))}
                </span>
              }
            </section>
          </section>
          <section className='ItemList-itemContent'>
            {renderItemsContent(item)}
          </section>
        </article>
      ))}
    </section>
  ),[
    handleClick,
    sortedFilteredItems,
    renderItemsContent,
    withBulk,
    handleCheckbox,
    isChecked,
    titles,
    itemDetail,
    highlightActive,
  ])

  const renderItemList = useMemo(() => (
    <React.Fragment>
      { !withSearch ? null :
        <Input.Search
          size='large'
          className='ItemList-searchox'
          type="text"
          prefix={<SearchOutlined style = {{ color: 'rgba(0,0,0,.25)' }}/>}
          placeholder={t.search}
          enterButton={t.search}
          onSearch={(term) => setSearchText((term || '').toLowerCase())}
          onChange={(term) => !term.target.value && setSearchText('')}
          allowClear="allowClear"
          ref={searchInputRef}
        />
      }
      <section className='ItemList-topBar'>
        <section>
          { !withBulk ? null :
            <Checkbox
              indeterminate={indeterminateCheck}
              onChange={checkAll}
              checked={allChecked}
            />
          }
          <span className='ItemList-topBar-title'>{itemTitle}</span>
        </section>
        <section className='ItemList-itemContent'>
          { !itemContent || itemContent.length < 1 ? null : itemContent.map((content) => (
            content.type === 'action'
            ? !withBulk ? null : renderBulkAction(content)
            : <span className='ItemList-itemContent-title'>{(t[content.name] || content.name)}</span>
          ))}
        </section>
      </section>
      { renderItems }
    </React.Fragment>
  ),[withBulk, indeterminateCheck, checkAll, allChecked, itemTitle, itemContent, withSearch, t, renderItems, renderBulkAction])

  return renderItemList
})

export default injectIntl(ItemList)
