import React, {
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import './style.less';

import {
  Button,
  Col,
  Row,
} from 'antd';

import FullScreenNoticeLayout from 'components/FullScreenNoticeLayout';

import T from 'components/T';
import config from 'config';

const NotSubscribed = ({
  organization,
  intl,
  history,
  auth,
}) => {
  const t = intl.messages;
  const replace = history.replace;
  const push = history.push;

  const {
    logged_in,
  } = auth || {};

  useEffect(() => {
    if(!logged_in) {
      return replace('/signin');
    }
  }, [logged_in, replace]);

  const atlasBaseUrl = useMemo(() => {
    return organization?.config?.atlas?.custom_redirect_urls?.admin || config.ATLAS_APP_URL;
  }, [
    organization,
  ]);

  const handleChange = useCallback(() => window.location.href = atlasBaseUrl, [ atlasBaseUrl ]);
  const handleLogout = useCallback(() => push('/logout'), [push]);

  return (
    <FullScreenNoticeLayout>
      <h2 className="NotSubscribed__title">{ t.notsubscribed_title }</h2>
      <div className="NotSubscribed__desc">
        <T notsubscribed_desc={{ organization_name: (organization.data || {}).name }} />
      </div>
      <Row
        type="flex"
        gutter={8}
        className="NotSubscribed__buttons"
      >
        <Col>
          <Button
            key="submit"
            type="primary"
            onClick={handleChange}
          >
            { t.notsubscribed_go_to_sustainability }
          </Button>
        </Col>
        <Col>
          <Button
            key="submit"
            type="default"
            onClick={handleLogout}
           >
            { t.notsubscribed_change_account }
          </Button>
        </Col>
      </Row>
    </FullScreenNoticeLayout>
  )
}

const mapStateToProps = ({
  organization,
  auth,
}) => ({
  organization,
  auth,
});

export default injectIntl(
  connect(
    mapStateToProps,
  )(
    withRouter(
      NotSubscribed
    )
  )
);
