import React, { useState, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import QrModal from './QrModal';
import config from 'config';
import PhoneInput from 'components/PhoneInput';
import useOrganizations from 'utils/useOrganizations';
import { EMAIL_REGEXP } from 'utils/regex';

import {
  A,
  useCustomRequest,
} from 'aplanet-ui-kit';

import {
  Button,
  Row,
  Upload,
  Card,
  Typography,
  Input,
  Col,
  Modal,
  Alert
} from 'antd';
import {
  QrcodeOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';

const { Paragraph } = Typography;
const { success, error } = Modal;

const NewVolunteers = ({
  intl,
  api_requests,
  refreshAccessToken,
  inviteFunction,
  volunteers
}) => {
  const {
    organization,
  } = useOrganizations();

  // Traduction
  const t = intl.messages;

  // Qr modal show/hide
  const [qrModalOpen, setQrModalOpen] = useState(false);

  // Generate signup link
  const signup_link = useMemo(() => {
    let baseUrl = `${config.VOLUNTEER_APP_URL}/`;
    const organizationCustomUrlsConfig = (organization.config || {}).custom_redirect_urls;
    if (organizationCustomUrlsConfig?.user) {
      baseUrl = organizationCustomUrlsConfig.user.endsWith('/')
        ? organizationCustomUrlsConfig.user
        : `${organizationCustomUrlsConfig.user}/`;
    }
    if(organization.signup_token) {
      return `${baseUrl}signup/${organization.signup_token}`;
    }
    return `${baseUrl}/signup`;
  }, [organization]);


  const customRequest = useCustomRequest({
    api_requests,
    refreshAccessToken,
  });

  // Invite new volunteer
  const [newVolunteerName, setNewVolunteerName] = useState('')
  const [newVolunteerEmail, setNewVolunteerEmail] = useState('')
  const [newVolunteerPhone, setNewVolunteerPhone] = useState('')
  const [inviting, setInviting] = useState(false)
  const inviteNewVolunteer = () => {
    inviteFunction(organization.slug, newVolunteerName, newVolunteerEmail, newVolunteerPhone)
    setNewVolunteerName('')
    setNewVolunteerEmail('')
    setNewVolunteerPhone('')
    setInviting(true)
  }

  // Listen volunteers response to show error/success
  useEffect(() => {
    if(volunteers && !volunteers.loading && inviting){
      if(volunteers.error){
        let errorMsg = volunteers.error === 400
          ? t.participants_userAddedErrorMissingInfo
          : volunteers.error === 409
            ? t.participants_userAddedErrorInfoRepeated
            : volunteers.error === 412
              ? t.participants_userAddedErrorAlready
              : null

        error({
          title: t.volunteers_userAddedError,
          content: errorMsg
        })
      }else{
        success({
          title: t.volunteers_memberInvited,
          content: t.volunteers_memberInvitedText
        })
      }
      setInviting(false)
    }
  }, [ volunteers, inviting, t ])

  // Upload feedback
  const uploadFeedback = (changes) => {
    if(changes.file.status === 'done') success({
      title: t.volunteers_uploadedCorrect,
      content: t.volunteers_uploadedText
    })
  }

  return (
    <React.Fragment>
      <Row type="flex" justify='space-around' className="Volunteer-heading">
        <Card className='addOptionCard templateOption' title={t.volunteers_addByTemplate}>
          <Upload
            accept=".csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            action={`${config.API_URL}/${organization.slug}/volunteer/file`}
            customRequest={customRequest}
            headers={{
              'Authorization': `Bearer ${api_requests.access_token}`
            }}
            onChange={(changes) => uploadFeedback(changes)}
          >
            <Button className='uploadTemplate' type='primary'><UploadOutlined />{ t.import_users }</Button>
          </Upload>
          <div><A href={`/samples/import_template_${intl.locale}.xlsx`} download>{ t.download_template }</A></div>
        </Card>
        <Card className='addOptionCard' title={t.volunteers_addByLinkQr}>
          { t.share_this_link }
          <Paragraph className='copyableLink' copyable>{ signup_link }</Paragraph>
          <div className="Whitespace20"></div>
          <Button
            type="primary"
            icon={<QrcodeOutlined />}
            onClick={() => setQrModalOpen(true)}
          >{ t.qr_open }</Button>
        </Card>
        <Card className='addOptionCard byEmail' title={t.volunteers_addByEmail}>
          <Row type='flex' align='middle' justify='start' >
            <Col className='newVolunteerName'>
              <Input value={newVolunteerName} onChange={(term) => setNewVolunteerName(term.target.value)} size='middle' placeholder={t.volunteers_volunteerName} allowClear="allowClear"/>
            </Col>
            <Col className='newVolunteerEmail'>
              <Input value={newVolunteerEmail} onChange={(term) => setNewVolunteerEmail(term.target.value)} size='middle' placeholder={t.volunteers_volunteerEmail} allowClear="allowClear"/>
              {
                (newVolunteerEmail.length > 0 && !EMAIL_REGEXP.test(newVolunteerEmail))
                ? <Alert className='emailError' message={t.form_error_invalid_email} type="error" showIcon />
                : null
              }
            </Col>
            <Col className='newVolunteerPhone'>
              <PhoneInput
                placeholder={ t.phone }
                value={newVolunteerPhone}
                onChange={(phone) => setNewVolunteerPhone(phone)}
                country={organization.region}
              />
            </Col>
            <Col className='newVolunteerButton'>
              <Button
                disabled={
                  !newVolunteerName
                  || (newVolunteerEmail && !EMAIL_REGEXP.test(newVolunteerEmail))
                  || (!newVolunteerEmail && !newVolunteerPhone)
                }
                onClick={() => inviteNewVolunteer()}
                icon={<PlusOutlined />}
                size='middle'
                type='primary'
              >
                  {t.volunteers_inviteVolunteer}
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
      { !qrModalOpen ? null :
        <QrModal
          url={signup_link}
          organization={organization}
          onClose={() => setQrModalOpen(false)}
        />
      }
    </React.Fragment>
  );
}

export default injectIntl(NewVolunteers);
