import React from 'react';
import { injectIntl } from 'react-intl';

const Beneficiaries = ({
  intl,
  beneficiaries,
}) => {
  return (
    <section className='actionBeneficiaries'>
      <div className='actionBeneficiaries-title'>
        { intl.formatMessage({ id: 'initiativedetail_beneficiaries' }) }
      </div>
      <div className="actionBeneficiaries-points">
        { beneficiaries }
      </div>
    </section>
  );
};

export default injectIntl(Beneficiaries);
