import React, { useEffect, useState, useMemo } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  Col,
  Empty,
  Row,
  Spin,
  Tabs,
} from 'antd';

import './style.less';

import {
  requestRankings,
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

import RankingMember from 'components/RankingMember';
import RankingInitiative from 'components/RankingInitiative';
import RankingGroup from 'components/RankingGroup';
import UserProfileModal from 'containers/UserProfileModal';
import { usePermissionList } from 'components/PermissionSwitch';
import MainLayout from 'components/MainLayout';
import Filters from 'containers/Rankings/Filters';

const Rankings = ({
  intl
}) => {
  const dispatch = useDispatch();
  const t = intl.messages;

  const taxonomies = useSelector(state => state.taxonomies);
  const {
    data: results,
    loading,
  }= useSelector(state => state.rankings);

  const [filters, setFilters] = useState({
    tags: '',
  });

  const {
    organization,
  } = useOrganizations();

  useEffect(() => {
    dispatch(requestRankings(organization.slug, filters));
  }, [
    organization.slug,
    dispatch,
    filters
  ]);

  const permissionList = usePermissionList();
  const [userDetail, setUserDetail] = useState(null);

  const hasPoints = useMemo(() => {
    const orgFeatures = organization.features;
    return orgFeatures && orgFeatures.includes('points');
  }, [organization]);

  return (
    <MainLayout
      errorMessage={intl.formatMessage({id: 'error_boundary_ranking_message'})}
    >
      <Row
        type="flex"
        align="middle"
        className="Rankings-header"
      >
        <Col>
          <h1>{t.rankings}</h1>
        </Col>
        { !!taxonomies?.tags.length &&
        <Col className="Rankings-header-filters">
          <Filters
            filters={filters}
            setFilters={setFilters}
            taxonomies={taxonomies}
          />
        </Col>
        }
      </Row>
      <div>
        <div className="Whitespace20"></div>
        { loading && !results ? <Spin size="large" /> : null }
        { !loading && !results ? <Empty /> : null }
        { !loading && results ?
          <div>
            <h2>{ t.ranking_community }</h2>
            <RankingMember
              data={results.member_ranking}
              showPoints={hasPoints}
              onMemberClick={
                permissionList.has('can_manage_volunteers')
                ? ( (member) => setUserDetail(member.slug) )
                : null
              }
            />
            <div className="Whitespace20"></div>

            <h2>{ t.ranking_initiatives }</h2>
            <RankingInitiative data={results.initiative_ranking} />
            <div className="Whitespace20"></div>

            { !results.group_ranking || results.group_ranking.length === 0
              ? null
              : (
                <React.Fragment>
                  <h2>{ t.ranking_groups }</h2>
                  <RankingGroup data={results.group_ranking} />
                  <div className="Whitespace20"></div>
                </React.Fragment>
              )
            }

            <h2>{ t.ranking_skills }</h2>
            <Tabs
              defaultActiveKey="0"
              tabPosition="left"
              size="small"
              className="Rankings-tabs"
            >
              { results.competence_ranking.map(({ ranking, slug }, index) => (
                <Tabs.TabPane
                  key={String(index)}
                  tab={t[`competence_${slug}`]}
                >
                  <RankingMember
                    showInitiatives={false}
                    data={ranking}
                    onMemberClick={
                      permissionList.has('can_manage_volunteers')
                      ? ( (member) => setUserDetail(member.slug) )
                      : null
                    }
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
            <div className="Whitespace"></div>
          </div>
          : null
        }
      </div>
      <UserProfileModal
        slug={userDetail}
        visible={!!userDetail}
        onClose={() => setUserDetail(null)}
      />
    </MainLayout>
  )
}

export default injectIntl(Rankings);
