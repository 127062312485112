import React, { useEffect, useMemo, useCallback, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { default as DashboardCard } from 'components/Dashboard/Card';
import {
  Row,
  Col,
  Empty,
  Tooltip,
  Button,
} from 'antd';
import './style.less';
import './print.less';
import { guessGroupBy } from 'utils/date';
import {
  requestDashboard,
} from 'actions/api';
import Avatar from 'components/Avatar';
import SdgMiniLogo from 'components/SdgMiniLogo';
import colors from 'colors';
import stringHash  from 'string-hash'
import InitiativeTypeIcon from 'components/InitiativeTypeIcon';

import { ReactComponent as AdminIcon } from 'assets/icons/crown.svg';
import { ReactComponent as UserIcon } from 'assets/icons/volunteer.svg';

import useOrganizations from 'utils/useOrganizations';

const DEFAULT_INITIATIVE_TYPES = ['volunteering'];
const SUBTYPESEP = '_'; // NOTICE: The same one the backend uses...

// BEWARE: This is the worst code ever written

// Card blocks
// NOTICE: This still sucks, but AT LEAST it's explicit in which blocks to show
//         So when we add a new block we will NOT show it in old frontends
const CARD_BLOCKS = {
  // TODO: When everybody has updated their frontend change 'participation' to a
  //       better simpler name like 'members' both here and in the BE
  top: [
    'initiatives',
    'participation',
    'participation_hours',
    'contribution',
    'beneficiaries',
  ],
  activity_evolution: [
    'initiative_evolution',
    'participation_evolution',
  ],
  sdg: [ // NOTICE: Not used, because we render these one by one :(
    'sdg_per_initiative',
    'sdg',
  ],
  community_evolution: [
    'member_evolution',
    'engagement_evolution',
  ],
  development: [
    'categories',
    'competences',
  ],
};

// Some helper components
const EmptyData = () => (
  <Empty
      className='big'
      image={'/images/icon-empty-data.svg'}
    />
);

const ActionsFooter = injectIntl(({
  intl,
  action,
  initiativeTypeSet,
}) => {
  const t = intl.messages;
  const types = Array.from(initiativeTypeSet);
  return (
    <section className='Dasboard-number-bottom'>
      {
        types.map(typeStr => {
          const [
            type,
            subtype = null,
          ] = (typeStr || '').split(SUBTYPESEP);
          return (
            <section
              ket={type}
              className={`Dasboard-number-${type} Dasboard-number-${typeStr}`}
              title={ t[subtype ? `card_${type}_${subtype}` : `card_${type}`] }
            >
              <InitiativeTypeIcon
                type={type}
                subtype={subtype}
              />
              <span>{action[typeStr] ? action[typeStr].count : '0'}</span>
            </section>
          );
        })
      }
    </section>
  );
});

const MembersFooter = ({
  extras = {},
}) => {
  return (
    <section className='Dasboard-number-bottom'>
      {Object.keys(extras).map(e => {
        if(e === '__comparison') return null
        const {
          role,
          count,
        } = extras[e];
        return (
          <section className='Dasboard-number-org'>
            {
              role === 'admin'
              ? <AdminIcon />
              : <UserIcon />
            }
            <span>{ count }</span>
          </section>
        )
      })}
    </section>
  );
};

const HoursFooter = ({
  extras = {},
}) => {
  return (
    <section className='Dasboard-number-bottom'>
      {Object.keys(extras).map(e => {
        if(e === '__comparison') return null
        return (
          <section className='Dasboard-number-org'>
            <Avatar
              size={27}
              className='org-avatar'
              src={extras[e].logo_small}
              name={extras[e].name}
            />
            <span>{`${extras[e].hours}h`}</span>
          </section>
        )
      })}
    </section>
  );
};

const Dashboard = ({
  intl,
  filters
}) => {
  const dispatch = useDispatch();
  const t = intl.messages;

  const {
    cohorts
  } = useSelector(state => state.taxonomies);
  const {
    data: dashboardData
  } = useSelector(state => state.dashboard);

  const {
    organization,
  } = useOrganizations();

  const currency = (organization.config.currency || 'EUR')
  const initiativeTypeSet = useMemo(() => {
    const types = (organization.config || {}).initiative_types || DEFAULT_INITIATIVE_TYPES;
    const aliases = (organization.config || {}).initiative_type_aliases || {};
    const typesWithSubtypes = types.map(type => {
      if(!aliases[type]) {
        return [ [type, null] ];
      }
      return aliases[type].map(subtype => type === subtype ? [type, null] : [type, subtype]);
    }).reduce((acc, el) => acc.concat(el), []);
    return new Set(
      typesWithSubtypes.map(([type, subtype]) => {
        if(!subtype) return type;
        return `${type}${SUBTYPESEP}${subtype}`;
      })
    );
  }, [
    organization,
  ]);

  const getCohortName = useMemo(() => {
    const cohortNameMap = new Map(cohorts.map(({ slug, name }) => [ slug, name ]));

    return (slug) => slug === '__nogroup' ? t.volunteers_noGroups : cohortNameMap.get(slug);
  }, [ t, cohorts ]);

  // Dashboard template and data
  const template = useMemo(() => (dashboardData || {}).template || [], [dashboardData]);
  const data = useMemo(() => (dashboardData || {}).data || [], [dashboardData]);

  let parsedData = useMemo(() => {
    const parsedData = {};

    Object.keys(data).forEach(key => {
      const datum = data[key];

      if(!datum) return;

      const {
        series = [],
        keys = [],
        samples = [],
      } = datum;

      switch (key) {
        case 'sdg':
          parsedData[key] = {
            labels: (datum.series || []).map(serie => t[`sg_${serie}_short`] || t[`sdg_${serie}`] || serie ),
            values: samples[0].values,
            colors: series.map(sdg => colors['sdg-colors'][sdg]),
          };
          break;
        case 'initiative_evolution': {
          const chartColors = {
            volunteering: '#7FBCFF',
            informative: '#FFE066',
            donation: '#8A88E2',
            internal: '#70E183',
            training: '#AFB8B8',
            collection: '#CA9058',
          };
          const values = series.map((serie, index) => {
            return {
              label: t[`${serie}`] || serie,
              backgroundColor: chartColors[serie],
              data: samples.map(({ values }) => values[index]),
            }
          });
          parsedData[key] = {
            labels: keys,
            values,
          };
          break;
        }
        case 'participation_evolution': {
          const chartColors = {
            accepted: '#33C38B',
            rejected: '#FF6A6A',
            no_hours: '#FFB44C',
            not_going: '#A2ACAC',
          };
          const values = series.map((serie, index) => {
            return {
              label: t[`dashboard_${serie}`] || serie,
              backgroundColor: chartColors[serie],
              data: samples.map(({ values }) => values[index]),
            }
          });
          parsedData[key] = {
            labels: keys,
            values,
          };
          break;
        }
        case 'engagement_evolution':
        case 'member_evolution': {
          const chartColors = {
            active: '#33C38B',
            blocked: '#FF6A6A',
            invited: '#FFB44C',
          };
          const values = series.map((serie, index) => {
            return {
              label: t[`dashboard_${serie}`] || serie,
              backgroundColor: chartColors[serie],
              data: samples.map(({ values }) => values[index]),
            }
          });
          parsedData[key] = {
            labels: keys,
            values,
          };
          break;
        }
        case 'competences':
        case 'categories': {
          const translateKey = (key) => ( t[`category_${key}_short`] || t[`category_${key}`] || t[`competence_${key}_short`] || t[`competence_${key}`] || key )
          const colorsAssigned = {}
          datum.series.forEach(serie => {
            colorsAssigned[serie] = serie === '__nogroup' ? colors['@no-group-color'] : colors['group-colors'][stringHash(serie) % colors['group-colors'].length]
          })

          const values = series.map((serie, index) => {
            return {
              label: getCohortName(serie),
              backgroundColor: colorsAssigned[serie],
              data: samples.map(({ values }) => values[index]),
            }
          });
          parsedData[key] = {
            labels: keys.map(key => translateKey(key)),
            values,
          };
          break;
        }
        default:
          parsedData[key] = datum
      }
    })

    return parsedData;
  }, [ data, t, getCohortName ]);

  // Request Dashboards
  useEffect(() => {
    const groupBy = guessGroupBy(filters.start, filters.end);

    dispatch(requestDashboard(
      organization.slug,
      { ...filters, groupBy }
    ));
  }, [organization.slug, dispatch, filters]);

  // Currency formater
  const currencyFormatter = useMemo(() => {
    return new Intl.NumberFormat(intl.locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0
    })
  }, [ intl, currency ]);

  const numberToString = useCallback((number) => (
    number > 3 || number < 1
    ? ''
    : number === 3
      ? 'three'
      : number === 2
        ? 'two'
        : 'one'
  ),[])

  const renderComparation = useCallback((props) => {
    if(!props.extras || !props.extras.__comparison) return null

    const sign = props.extras.__comparison.isIncrease ? '+' : '-'
    let style = props.extras.__comparison.isIncrease ?  'Dashboard-number-subtitle green' : 'Dashboard-number-subtitle red'
    let text = ''

    if(props.extras.__comparison.isEqual){
      text = t.lastPeriodEqual
      style = 'Dashboard-number-subtitle gray'
    }else if(props.extras.__comparison.percent === 'Infinity'){
      text = t.lastPeriodEmpty
      style = 'Dashboard-number-subtitle gray'
    }else if(props.extras.__comparison.percent >= 200){
      text = `${sign}${props.extras.__comparison.percent/100}x ${t.lastPeriod}`
    }else{
      text = `${sign}${props.extras.__comparison.percent}% ${t.lastPeriod}`
    }

    return (
      <span className={style}>
        {text}
      </span>
  )},[t])

  const renderNumberDashboard = useCallback((type, props) => (
    <section className='Dashboard-number'>
      <section className='Dasboard-number-top'>
        <section className='Dashboard-number-left'>
          {props.renderContent()}
        </section>
        <section className='Dashboard-number-right'>
          <span className='Dashboard-number-title'>
            { t[`dashboard_${type}`] }
          </span>
          {renderComparation(props)}
        </section>
      </section>
      {
        type === 'actions'
        ? (
            <ActionsFooter
              action={props.extras}
              initiativeTypeSet={initiativeTypeSet}
            />
          )
        : type === 'members'
        ? (
            <MembersFooter
              extras={props.extras}
            />
          )
        : (
            <HoursFooter
              extras={props.extras}
            />
        )
      }
    </section>
  ),[
    t,
    renderComparation,
    initiativeTypeSet,
  ]);

  const renderContributionDashboard = useCallback((props) => (
    <section className='Dashboard-contribution'>
      <section className='Dasboard-contribution-top'>
        <span>{props.extras && props.extras[`total-${currency}`]  ? currencyFormatter.format(props.extras[`total-${currency}`].value) : '0'}</span>
      </section>
      <section className='Dasboard-contribution-bottom'>
        <span className='Dasboard-contribution-text'>{ t.dashboard_contributed }</span>
      </section>
    </section>
  ),[
    t,
    currency,
    currencyFormatter,
  ]);

  const renderBeneficiariesDashboard = useCallback((props) => {
    return <section className='Dashboard-number'>
      <section className='Dasboard-number-top'>
        <section className='Dashboard-number-left'>
          <output>
            { Object.values(props.extras)
              .map(({total_beneficiaries}) => total_beneficiaries)
              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
            }
          </output>
        </section>
        <section className='Dashboard-number-right'>
          <span className='Dashboard-number-title'>
            { t[`dashboard_beneficiaries`] }
          </span>
        </section>
      </section>
    </section>
  },[
    t,
  ]);

  const renderTable = useCallback((props) => (
    <React.Fragment>
      {!parsedData.sdg_per_initiative || parsedData.sdg_per_initiative.length < 1
        ? <section className='emptyTable'>
            <EmptyData />
          </section>
        : parsedData.sdg_per_initiative.map((action, i) => (
        <article className={`Dashboard-table-action ${numberToString(action.sdgs.length)}`}>
          <span className='Dashboard-table-action-ranking'>{`#${i+1}`}</span>
          <section className='Dashboard-table-action-sdg'>
            {action.sdgs.map(sdg =>
              <SdgMiniLogo key={sdg} sdg={sdg}/>
            )}
          </section>
          <Tooltip placement="topLeft" mouseEnterDelay={0.7} title={action.initiative_title}>
            <span className='Dashboard-table-action-name'>{action.initiative_title}</span>
          </Tooltip>
          <span className='Dashboard-table-action-hours'>{`${action.hours}h`}</span>
        </article>
      ))}
    </React.Fragment>
  ),[
    parsedData.sdg_per_initiative,
    numberToString,
  ])

  const [dashboardSwitcher, setDashboardSwitcher] = useState('competences')

  const renderHoursPerAction = useMemo(() => (
    template.filter((temp) => temp.id === 'sdg').map(({
      id,
      parameters,
      size,
      title,
      type,
    }) => (
      <DashboardCard
        preprocessed
        type={type}
        data={parsedData[id]}
        parameters={{
          nodata: <EmptyData />,
        }}
        render={
          (props) => {
            return (
              <section className={`Dashboard-fullGraph`}>
                <Row className='Dashboard-fullGraph-top' type="flex" align="middle" justify="space-between">
                  <span className='Dashboard-fullGraph-title'>{t.hoursPerAction}</span>
                  <Button
                    className="Dashboard-graph-download"
                    onClick={props.handleDownload}
                  >
                    {t.download}
                  </Button>
                </Row>
                <section className='Dashboard-fullGraph-bottom'>
                  <section className='Dashboard-leftSide'>
                    {template.filter((temp) => temp.id === 'sdg_per_initiative').map(({
                      id,
                      parameters,
                      size,
                      title,
                      type,
                    }) => (
                      <DashboardCard
                        key={id}
                        id={id}
                        type={type}
                        size={size}
                        parameters={{
                          ...parameters,
                          nodata: <EmptyData />,
                          locale: intl.locale,
                          sameValueMsg: t.dashboard_number_same_value,
                          increaseMsg:  t.dashboard_number_increased_by,
                          decreaseMsg:  t.dashboard_number_decreased_by,
                          colorProp: 'color',
                        }}
                        data={parsedData[id] || []}
                        render={(props) => renderTable(props)}
                      />
                    ))}
                  </section>
                  <section className='DashboardComponent Dashboard-rightSide'>
                    { props.renderContent() }
                  </section>
                </section>
              </section>
            )
          }
        }
      />
    ))
  ),[
    parsedData,
    intl,
    t,
    template,
    renderTable,
  ])

  const renderersById = {
    initiatives: (props) => renderNumberDashboard('actions', props),
    participation: (props) => renderNumberDashboard('members', props),
    participation_hours: (props) => renderNumberDashboard('hours', props),
    contribution: (props) => organization.config.initiative_types.includes('donation') && renderContributionDashboard(props),
    beneficiaries: (props) => renderBeneficiariesDashboard(props),
  };

  return (
    <div>
      <section className='Dashboard-topInfo'>
        {template.filter((temp) => CARD_BLOCKS.top.includes(temp.id)).map(({
          id,
          dataId,
          parameters,
          size,
          title,
          type,
        }) => (
          <DashboardCard
              key={id}
              id={id}
              type={type}
              size={size}
              parameters={{
                ...parameters,
                nodata: <EmptyData />,
                locale: intl.locale,
                sameValueMsg: t.dashboard_number_same_value,
                increaseMsg:  t.dashboard_number_increased_by,
                decreaseMsg:  t.dashboard_number_decreased_by,
              }}
              data={parsedData[dataId] || parsedData[id] || []}
              render={renderersById[id]}
            />
        ))}
      </section>
      <section className='Dashboard-cards'>
        {template.filter((temp) => CARD_BLOCKS.activity_evolution.includes(temp.id)).map(({
          id,
          parameters,
          size,
          title,
          type,
        }) => (
          <DashboardCard
            preprocessed
            type={type}
            data={parsedData[id]}
            parameters={{
              nodata: <EmptyData />,
            }}
            render={
              (props) => {
                return (
                  <section className='Dashboard-midGraph-bottom'>
                    <section className="Dashboard-midGraph responsive">
                      <Row className='Dashboard-midGraph-top' type="flex" align="middle" justify="space-between">
                        <span className='Dashboard-midGraph-title'>
                          {id === 'participation_evolution' && t.participation}
                          {id === 'initiative_evolution' && t.dashboard_actionsPerPeriod}
                        </span>
                        <Button
                          className="Dashboard-graph-download"
                          onClick={props.handleDownload}
                        >
                          {t.download}
                        </Button>
                      </Row>
                      { props.renderContent() }
                    </section>
                  </section>
                )
              }
            }
          />
        ))}
        {renderHoursPerAction}
        {template.filter((temp) => CARD_BLOCKS.community_evolution.includes(temp.id)).map(({
          id,
          parameters,
          size,
          title,
          type,
        }) => (
          <DashboardCard
            preprocessed
            type={type}
            data={parsedData[id]}
            parameters={{
              nodata: <EmptyData />,
            }}
            render={
              (props) => {
                return (
                  <section className='Dashboard-midGraph-bottom'>
                    <section className="Dashboard-midGraph responsive">
                      <Row className='Dashboard-midGraph-top' type="flex" align="middle" justify="space-between">
                        <span className='Dashboard-midGraph-title'>
                          {id === 'member_evolution' && t.dashboard_communityMembers}
                          {id === 'engagement_evolution' && t.dashboard_active_active}
                        </span>
                        <Button
                          className="Dashboard-graph-download"
                          onClick={props.handleDownload}
                        >
                          {t.download}
                        </Button>
                      </Row>
                      { props.renderContent() }
                    </section>
                  </section>
                )
              }
            }
          />
        ))}
        {template.filter((temp) => temp.id === dashboardSwitcher).map(({
          id,
          parameters,
          size,
          title,
          type,
        }) => (
          <DashboardCard
            key={id}
            preprocessed
            type={type}
            data={parsedData[id]}
            parameters={{
              nodata: <EmptyData />,
            }}
            render={
              (props) => {
                const maxContainerHeight = `
                  ${Math.max(parsedData[id].values.length, 3) * 25 }vh
                `;
                return (
                  <section className={`Dashboard-fullGraph ${dashboardSwitcher}`} style={{maxHeight: maxContainerHeight}}>
                    <Row className="Dashboard-fullGraph-top" type="flex" justify="space-between">
                      <Col className="Dashboard-fullGraph-title">{t.developmentByGroup}</Col>
                      <Col className="Dashboard-switcher">
                          <span className="Switch-competences" onClick={() => setDashboardSwitcher('competences')}>{t.competences}</span>
                          { organization.config.hide_categories
                            ? null
                            : <span className="Switch-categories" onClick={() => setDashboardSwitcher('categories')}>{t.categories}</span>
                          }
                      </Col>
                      <Button
                        className="Dashboard-graph-download"
                        onClick={props.handleDownload}
                      >
                        {t.download}
                      </Button>
                    </Row>
                    <section className="Dashboard-fullGraph-bottom">
                      { props.renderContent() }
                    </section>
                  </section>
                )
              }
            }
          />
        ))}
      </section>
    </div>
  )
}

export default injectIntl(Dashboard);
