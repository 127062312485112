import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Table,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import Avatar from 'components/Avatar';

import { A } from 'aplanet-ui-kit';

import './style.less';

const RankingMember = ({
  intl,
  showInitiatives = true,
  data = [],
  onMemberClick,
  showPoints = false
}) => {
  const t = intl.messages;
  const [ pageOffset, setPageOffset ] = useState(0);

  const handleTableChange = useCallback(({ current, pageSize }) => {
    setPageOffset( (current-1) * pageSize );
  }, []);

  const columns = useMemo(() => ([
    {
      title: null,
      dataIndex: 'rank',
      key: 'rank',
      render: (rank, record, index) => <div>{ `#${pageOffset + index + 1}` }</div>,
    },
    {
      title: t.name,
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <React.Fragment>
          <Row type="flex" gutter={10}>
            <Col>
              {
                onMemberClick
                ? (
                <A
                  onClick={() => onMemberClick(record)}
                >
                  <Avatar
                    size="small"
                    shape="circle"
                    name={name}
                    src={record.avatar}
                    icon={<UserOutlined />}
                  />
                </A>
                ) : (
                <Avatar
                  size="small"
                  shape="circle"
                  name={name}
                  src={record.avatar}
                  icon={<UserOutlined />}
                />
                )
              }
            </Col>
            <Col>{ name }</Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t.email,
      dataIndex: 'email',
      key: 'email',
      render: (email, record) => (
        <div>{ record.email }</div>
      ),
    },
    {
      title: t.completed_hours,
      dataIndex: 'hours',
      key: 'hours',
      sorter: (a, b) => (a.hours - b.hours),
      sortDirections: ['descend', 'ascend'],
    },
    showPoints ? ({
      title: t.ranking_member_points,
      dataIndex: 'points',
      key: 'points',
      sorter: (a, b) => (a.points - b.points),
      sortDirections: ['descend', 'ascend'],
    }) : undefined,
    showInitiatives ? ({
      title: t.initiatives,
      dataIndex: 'initiatives',
      key: 'initiatives',
      sorter: (a, b) => (a.initiatives - b.initiatives),
      sortDirections: ['descend', 'ascend'],
    }) : undefined,
  ].filter(Boolean)), [ showInitiatives, t, pageOffset, onMemberClick, showPoints ]);

  const dataSource = useMemo(() => data.map((entry, index) => (
    {
      key: index,
      rank: entry.rank,
      name: entry.name,
      slug: entry.slug,
      email: entry.email,
      avatar: entry.avatar,
      initiatives: entry.initiatives,
      hours: entry.hours,
      points: entry.points
    }
  )), [data]);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowClassName={(_, index) => index%2 ? 'Table-row-odd' : 'Table-row-even'}
      onChange={handleTableChange}
    />
  );
};

RankingMember.propTypes = {
  data: PropTypes.array,
  onMemberClick: PropTypes.object.isRequired,
  showInitiatives: PropTypes.bool,
  showPoints: PropTypes.bool
};

export default injectIntl(RankingMember);
