import React, { useState, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import useOrganizations from 'utils/useOrganizations';

import {
  Modal,
  Input,
  Button,
  Switch,
  Collapse
} from 'antd';

const { Panel } = Collapse;

const BlockModal = ({
  intl,
  onBlock,
  onClose,
  url,
  loading,
  error,
  slugs = [],
}) => {
  const {
    organization,
  } = useOrganizations();

  const [ blockReason, setBlockReason ] = useState('');
  const [ submitting, setSubmitting ] = useState(false);
  const [ sendEmail, setSendEmail ] = useState(true);

  const closeModal = useCallback(() => {
    setBlockReason('');
    onClose();
  }, [ onClose ]);

  const submitModal = useCallback(() => {
    slugs.forEach(slug => {
      onBlock(organization.slug, slug, blockReason);
    });
    setSubmitting(true);
  }, [
    blockReason,
    onBlock,
    organization,
    slugs,
  ]);

  useEffect(() => {
    if(submitting && !loading && !error) {
      // We are done, let's close shop
      onClose();
    }
  }, [ loading, submitting, error, onClose ]);

  const onClickSendEmailSwitch = useCallback(() => {
    setBlockReason('');
    setSendEmail(!sendEmail);
  }, [sendEmail]);

  if(!slugs || slugs.length === 0) return null;
  const confirmationText = slugs.length > 1
    ? intl.formatMessage({ id: `volunteers_confirmMultiBlock` })
    : intl.formatMessage({ id: `block_confirmation` });

  return (
    <Modal
      visible
      className="blockModal"
      title={confirmationText}
      onCancel={closeModal}
      onOk={submitModal}
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={closeModal}
        >{intl.formatMessage({ id: `block_no` })}</Button>,
        <Button
          key="submit"
          type="primary"
          disabled={(sendEmail && !blockReason) || loading}
          onClick={submitModal}
        >{intl.formatMessage({ id: `block_yes` })}</Button>
      ]}
    >
      <p>{intl.formatMessage({ id: `block_confirmation_desc` })}</p>
      <Collapse collapsible="header" activeKey={sendEmail && 'send_email'} ghost>
        <Panel
          showArrow={false}
          key='send_email'
          header={(
            <div
              className="blockModal__switchSendEmailWrapper"
              onClick={() => onClickSendEmailSwitch()}
            >
              <Switch
                  checkedChildren={intl.formatMessage({ id: `block_send_email_yes` })}
                  unCheckedChildren={intl.formatMessage({ id: `block_send_email_no` })}
                  checked={sendEmail}
                />
              <label>{intl.formatMessage({ id: `block_send_email${slugs.length > 1 ? '_many' : ''}` })}</label>
            </div>
          )}
        >
          <Input.TextArea
            autoSize={ false }
            rows={6}
            value={blockReason}
            onChange={(e) => setBlockReason(e.target.value)}
          />
        </Panel>
      </Collapse>
    </Modal>
  );
}

export default injectIntl(BlockModal);

