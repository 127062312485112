import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { injectIntl } from 'react-intl';

import useOrganizations from 'utils/useOrganizations';
import { EMAIL_REGEXP } from 'utils/regex';

import Avatar from 'components/Avatar';
import PhoneInput from 'components/PhoneInput';
import Highlighter from 'react-highlight-words'

import {
  Modal,
  Input,
  Button,
  Table,
  Row,
  Col,
  Alert,
} from 'antd';
import {
  UserOutlined,
  PlusOutlined,
  SearchOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';

import colors from 'colors';

const AssignManagerModal = ({
  intl,
  onAssignManager,
  onClose,
  url,
  loading,
  error,
  slugs = [],
  managers = [],
}) => {
  const t = intl.messages;

  const {
    organization,
  } = useOrganizations();

  const searchInputRef = useRef()

  const [ submitting, setSubmitting ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ phone, setPhone ] = useState('');

  const handleClose = useCallback(() => {
    setSearch('');
    setName('');
    setEmail('');
    setPhone('');
    onClose();
  }, [
    onClose,
  ]);

  const handleSubmit = useCallback((
    slugs,
    name,
    email,
    phone,
    action = 'assign',
  ) => {
    slugs.forEach(slug => {
      onAssignManager(
        organization.slug,
        slug,
        name,
        email,
        phone,
        action,
      );
    });
    setSubmitting(true);
  }, [
    onAssignManager,
    organization,
  ]);

  const selectedMembers = useMemo(
    () => managers.filter(
      manager => slugs.includes(manager.slug)
    ),
    [managers, slugs]
  );

  const handleUnassign = useCallback(
    (manager) => {
      const managedMemberSlugs = selectedMembers
        .filter(member => member.manager_id === manager.id)
        .map(member => member.slug);

      handleSubmit(
        managedMemberSlugs,
        manager.name,
        manager.email,
        manager.phone,
        'remove',
      );
    },
    [selectedMembers, handleSubmit]
  );

  const getIsAssignDisabled = useCallback(
    (managerId) => selectedMembers.length === 1 && !!selectedMembers.find(
      member => member.manager_id === managerId
    ),
    [selectedMembers]
  );

  const getIsUnassignDisabled = useCallback(
    (managerId) => !selectedMembers.find(
      member => member.manager_id === managerId
    ),
    [selectedMembers]
  );

  // Colums for managers table
  const columns = useMemo(() => ([
    {
      title: t.name,
      dataIndex: 'name',
      key: 'name',
      filteredValue: search
        ? [search]
        : null,
      onFilter: (value, record) =>
        (record.name || '').toLowerCase().includes(value) ||
        (record.email || '').toLowerCase().includes(value) ||
        (record.phone || '').toLowerCase().includes(value),
      render: (name, record) => (
        <React.Fragment>
          <Row type="flex" gutter={10} justify="left" align="middle">
            <Col>
              {
                <Avatar
                  size={38}
                  shape="circle"
                  src={record.avatar}
                  name={record.name}
                  icon={<UserOutlined />}
                />
              }
            </Col>
            <Col>
              <Row type="flex" gutter={2}>
                <Col>
                  {
                    !search
                      ? name
                      : <Highlighter
                          highlightStyle={{
                            backgroundColor: colors['@text-selection-bg'],
                            padding: 0
                          }}
                          searchWords={[search]}
                          autoEscape
                          textToHighlight={name}
                        />
                  }
                </Col>
              </Row>
              <Row>
                { !record.email ? null :
                  !search
                    ? record.email
                    : <Highlighter
                        highlightStyle={{
                          backgroundColor: colors['@text-selection-bg'],
                          padding: 0
                        }}
                        searchWords={[search]}
                        autoEscape
                        textToHighlight={record.email}
                      />
                }
              </Row>
              <Row>
                { !record.phone ? null :
                  !search
                    ? record.phone
                    : <Highlighter
                        highlightStyle={{
                          backgroundColor: colors['@text-selection-bg'],
                          padding: 0
                        }}
                        searchWords={[search]}
                        autoEscape
                        textToHighlight={record.phone}
                      />
                }
              </Row>
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: '',
      align: 'right',
      render: (text, record) => (
        <Row>
          <Button
            className={'addAllToCohort'}
            icon={<UserAddOutlined />}
            size='small'
            type='primary'
            disabled={getIsAssignDisabled(record.id)}
            onClick={() => handleSubmit(slugs, record.name, record.email, record.phone)}
          >
            { slugs.length === 1
              ? t.assign_manager_assign
              : t.assign_manager_assign_all
            }
          </Button>
          <Button
            className={'removeAllFromCohort'}
            icon={<UserDeleteOutlined />}
            size='small'
            type='danger'
            disabled={getIsUnassignDisabled(record.id)}
            onClick={() => handleUnassign(record, 'remove')}
          >
            { slugs.length === 1
              ? t.assign_manager_unassign
              : t.assign_manager_unassign_all
            }
          </Button>
        </Row>
      ),
    }
  ]), [
    t,
    search,
    handleSubmit,
    getIsAssignDisabled,
    getIsUnassignDisabled,
    handleUnassign,
    slugs,
  ]);

  useEffect(() => {
    if(submitting && !loading && !error) {
      // We are done, let's close shop
      onClose();
    }
  }, [
    loading,
    submitting,
    error,
    onClose,
  ]);

  if(!slugs || slugs.length === 0) return null;

  return (
    <Modal
      visible
      title={t.assign_manager}
      centered={true}
      width={900}
      onCancel={handleClose}
      footer={false}
    >
        <Input.Search
          type="text"
          prefix={<SearchOutlined style = {{ color: 'rgba(0,0,0,.25)' }}/>} placeholder={t.assign_manager_search_placeholder}
          enterButton={t.assign_manager_search_button}
          onSearch={(term) => setSearch((term || '').toLowerCase())}
          onChange={(e) => !e.target.value && setSearch('')}
          allowClear="allowClear"
          ref={searchInputRef}
        />
        <div className="Whitespace20"></div>
        <Table
          columns={columns}
          dataSource={managers}
          pagination={{ pageSize: 3 }}
        />
        <Row type='flex'>
          <Col className='newParticipantName'>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value.toString())}
              size='middle'
              placeholder={t.name}
              allowClear="allowClear"
            />
          </Col>
          <Col className='newParticipantEmail'>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value.toString())}
              size='middle'
              placeholder={t.email}
              allowClear="allowClear"
            />
            {
              (email.length > 0 && !EMAIL_REGEXP.test(email))
              ? <Alert className='emailError' message={t.form_error_invalid_email} type="error" showIcon />
              : null
            }
          </Col>
          <Col className='newParticipantPhone'>
            <PhoneInput
              placeholder={ t.phone }
              value={phone}
              onChange={(phone) => setPhone(phone)}
              country={organization.region}
            />
          </Col>
          <Col className='newParticipantButton'>
            <Button
              icon={<PlusOutlined />}
              type='primary'
              disabled={!name || (email && !EMAIL_REGEXP.test(email)) || (!email && !phone)}
              onClick={() => {
                handleSubmit(
                  slugs,
                  name,
                  email,
                  phone,
                )
              }}
            >
              { t.assign_manager_new_manager }
            </Button>
          </Col>
        </Row>
    </Modal>
  );
}

export default injectIntl(AssignManagerModal);

