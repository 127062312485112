import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Row,
  Tag,
} from 'antd';
import {
  FilterOutlined,
} from '@ant-design/icons';
import useSetState from 'utils/useSetState';


const Filters = ({
  intl,
  filters,
  setFilters,
  taxonomies,
}) => {
  const t = intl.messages;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const {
    set: filteredTags,
    has: isTagsFilterSet,
    toggle: toggleTagsFilter,
    replaceAll: setFilteredTags,
  } = useSetState([]);
  const tagsFilterSet = useMemo(
    () => filteredTags.length > 0,
    [ filteredTags ]
  );

  useEffect(
    () => {
      if (filters.tags !== filteredTags.join(',')) {
        setFilters({
          ...filters,
          tags: filteredTags.join(','),
        });
      }
    },
    [filters, setFilters, filteredTags]
  );

  return (
    <div className="Filters">
      <Row className='Filters-top' type="flex" align="middle">
        <Col>
          <figure className='Filters-divider'/>
        </Col>
        <Col>
          <Button
            className='Filters-drawerButton'
            icon={<FilterOutlined />}
            onClick={() => setDrawerOpen(true)}
          />
        </Col>
        <Drawer
          title={t.ranking_filter_title}
          width={350}
          placement="right"
          closable={true}
          onClose={() => setDrawerOpen(false)}
          visible={drawerOpen}
        >
          { taxonomies.tags.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.tags }</h4>
            <div>
              <Checkbox
                checked={!tagsFilterSet}
                onChange={() => setFilteredTags([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.tags.map(name => (
                <Checkbox
                  key={name}
                  checked={isTagsFilterSet(name)}
                  onChange={() => toggleTagsFilter(name)}
                  style={{ display: 'block' }}
                >
                  { name }
                </Checkbox>
              )) }
            </div>
          </div>
        }
        </Drawer>
      </Row>
      <Row
        type="flex"
        align="middle"
        className='Filters-selected'
      >
        {
          filteredTags.length === 0
          ? null
          : (
            <div className="Initiative-filter">
              <span className="Initiative-filter-title">{ t.tags }: </span>
              { filteredTags.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="magenta"
                  onClose={() => toggleTagsFilter(slug)}
                >{ slug }</Tag>
              ) }
            </div>
          )
        }
      </Row>
    </div>
  );
}

export default injectIntl(Filters);
