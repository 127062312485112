import React, {useCallback, useRef, useState, useMemo} from 'react';
import { injectIntl } from 'react-intl';
import {
  message,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Popover,
  Switch,
} from 'antd';
import { formatDateLong, isAfterNow } from 'utils/date';
import SdgLogo from 'components/SdgLogo';
import { Link } from 'react-router-dom';
import Avatar from 'components/Avatar';
import Collaboration from './Collaboration';
import Buttons from './Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PushpinFilled } from "@ant-design/icons";
import { withRouter } from 'react-router';
import {
  useDispatch
} from 'react-redux';
import {
  updatePinnedFeaturedInitiative
} from 'actions/api';

const PINNED_FEATURED_INITIATIVE = {
  pinned_initiative: 'pinned_initiative',
  featured_initiative: 'featured_initiative'
};

const ActionHeader = ({
  intl,
  push,
  slug,
  action,
  organization,
  initiative,
  admin_profile,
  taxonomies,
  requestInitiativeDetail,
  cancelInitiative,
  requestCollaboration,
  updateCollaboration,
  cancelCollaborationRequest,
  stopCollaboration,
  isOrganizer,
  isCollaborator,
  loading,
  loading_member_id,
  canEdit,
  location,
  isCanceled,
  history,
}) => {

  const dispatch = useDispatch();
  const t = intl.messages;
  const DEFAULT_IMAGE = 'https://aplanet-static.ams3.digitaloceanspaces.com/assets/default_initiative_image.jpg';
  const inputRef = useRef(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [showSdgPopover, setshowSdgPopover] = useState(false);
  const urlFrom = location.pathname.split('/')[1]
  const hideRating = (organization?.config || {}).hide_rating;

  const copy = useCallback(() => {
    inputRef.current.select();
    document.execCommand('copy');
    message.success(t.copy_success);
  }, [ t ]);

  const backButton = useMemo(() => (
    {
      collaboration: {link: '/collaboration', text: t.back_to_collaborations},
      proposal: {link: '/proposal', text: t.back_to_proposals},
      initiative: {
        link: '#',
        text: t.back_to_actions,
        onClick: history.goBack,
      }
    }[urlFrom]),
    [
      t,
      urlFrom,
      history,
    ]
  );

  const onTogglePinOrFeatureInitiative = useCallback((type) => {
    Modal.confirm({
      title: initiative[type]
        ? intl.formatMessage({ id: `action_header_no_${type}_title` })
        : intl.formatMessage({ id: `action_header_${type}_title` }),
      okText: initiative[type]
        ? intl.formatMessage({ id: `action_header_${type}_unmark` })
        : intl.formatMessage({ id: `action_header_${type}_mark` }),
      okType: 'primary',
      cancelText: intl.formatMessage({ id: `action_header_${type}_cancel` }),
      onOk() {
        dispatch(updatePinnedFeaturedInitiative(organization.slug, initiative.slug, { [type]: !initiative[type] }));
      },
      onCancel() {},
    });
  }, [intl, initiative, dispatch, organization.slug]);

  return (
    <>
      <section className='actionTopHeader'>
        <Link to={backButton.link} onClick={backButton.onClick}>
          <section className='backToActions'>
            <img src='/images/chevron-left.svg' alt={t.back_to_actions}/>
            <span>{backButton.text}</span>
          </section>
        </Link>
        <Row type="flex">
          { Object.keys(PINNED_FEATURED_INITIATIVE).map(actionType => {
            const configured = actionType === PINNED_FEATURED_INITIATIVE.pinned_initiative
              ? organization?.config?.can_pin_initiatives
              : organization?.config?.can_feature_initiatives;
            return configured && isAfterNow(initiative.end_time) && !isCanceled
              && (isOrganizer || initiative.collaboration_status === 'accepted') && (
              <>
                <Col className={`actionTopHeader__${actionType}Icon ${initiative[actionType] ? 'checked' : ''}`}>
                  {actionType === PINNED_FEATURED_INITIATIVE.pinned_initiative ? (<PushpinFilled />) : (<FontAwesomeIcon icon="medal" />)}
                </Col>
                <Col className={`actionTopHeader__${actionType}Switch`}>
                  <Switch
                      checkedChildren={intl.formatMessage({ id: `initiative_${actionType}` })}
                      unCheckedChildren={intl.formatMessage({ id: `initiative_not_${actionType}` })}
                      checked={initiative[actionType]}
                      onChange={() => onTogglePinOrFeatureInitiative(actionType)}
                    />
                </Col>
              </>
            )})
          }
          { !isAfterNow(initiative.end_time) && !canEdit ? null : isOrganizer ?
            <Buttons
              type={initiative.type}
              loading={loading}
              canEdit={canEdit}
              onEdit={() => push(`/initiative/${initiative.slug}/edit`)}
              onDelete={(reason) => cancelInitiative(organization.slug, initiative.slug, reason)}
              {...initiative}
            /> :
            <Collaboration
              organization={organization}
              defaultEmail={(admin_profile || {}).email || ''}
              onRequest={(...params) => requestCollaboration(organization.slug, initiative.slug, ...params)}
              onUpdate={(...params) => updateCollaboration(organization.slug, initiative.slug, ...params)}
              onCancel={() => cancelCollaborationRequest(organization.slug, initiative.slug)}
              onStop={() => stopCollaboration(organization.slug, initiative.slug, organization.slug)}
              isCanceled={isCanceled}
              {...initiative}
            />
          }

          {
            (
              !isAfterNow(initiative.end_time)
              || organization.status !== 'active'
              || ['pending', 'rejected'].includes(initiative.collaboration_status)
              || isCanceled
            ) ? null : (
              <Button
                type='default'
                className='shareAction'
                onClick={() => setShareModalOpen(true)}
              >
                <img src='/images/icon-share.svg' alt={t.share}/>
                <span>{t.share}</span>
              </Button>
            )
          }
        </Row>
      </section>
      <section className='actionHeader'>
        <Col xl={14} lg={12} md={24} className='actionCover'>
          <img src={initiative.cover || DEFAULT_IMAGE} alt=''/>
          {!hideRating && !!initiative.initiative_score_count && (
            <figure className='actionScore'>
              <span>{Number(initiative.initiative_score).toPrecision(2)}</span><img src='/images/star.svg' alt=''/>
            </figure>
          )}
        </Col>
        <Col xl={10} lg={12} md={24} className='actionTopInfo'>
          <Row>
            <Col span={24} className='date'>
            {`${t.action_header_start_date} ${formatDateLong(initiative.start_time, intl)}`}
            </Col>
            <span span={24} className='title'>{initiative.title}</span>
          </Row>
          <section className='sdgs'>
            <Popover
              placement="bottom"
              trigger="hover"
              onVisibleChange={visible => setshowSdgPopover(visible)}
              visible={showSdgPopover && initiative.sdgs.length > 3}
              content={
                <div className="sdgs-popover-content">
                  {initiative.sdgs.slice(3).map((sdg) => (
                    <SdgLogo className='sdg' sdg={sdg} square={true} />
                  ))}
                </div>
              }
            >
              {initiative.sdgs.slice(0, 3).map((sdg) => (
                <SdgLogo className='sdg' sdg={sdg} square={true} />
              ))}
            </Popover>
          </section>
          <section className='organizer'>
            <Avatar className='avatar' shape="circle" src={initiative.organization_logo_small}/>
            {!hideRating && !!initiative.organization_score_count && (
              <figure className='organizerRate'>
                <span>{Number(initiative.organization_score).toPrecision(2)}</span><img src='/images/star.svg' alt=''/>
              </figure>
            )}
          </section>
        </Col>
      </section>
      <Modal
        visible={shareModalOpen}
        title={t.share}
        centered
        onCancel={() => setShareModalOpen(false)}
        width={700}
        footer={false}
      >
        <Row
          type="flex"
          gutter={5}
        >
          <Col style={{ flex: 1 }}>
            <Input
              ref={inputRef}
              value={`${initiative.share_url}/${organization.signup_token}`}
            />
          </Col>
          <Col>
            <Button onClick={copy}>
              <FontAwesomeIcon icon="copy" />
            </Button>
          </Col>
        </Row>
    </Modal>
    </>
  )}


export default injectIntl(withRouter(ActionHeader));
