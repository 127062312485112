import { Chart } from 'react-chartjs-2';

// Rerender chartjs graphs for print screens
const beforePrint = () => {
  for (const id in Chart.instances) {
    Chart.instances[id].resize();
  }
}

const onBeforePrint = () => {
  if (window.matchMedia) {
    let mediaQueryList = window.matchMedia('print');
    mediaQueryList.addListener((mql) => {
      if (mql.matches) {
        beforePrint();
      }
    })
  }

  window.onbeforeprint = beforePrint;
}

export default onBeforePrint;