import React, {useCallback} from 'react';
import { injectIntl } from 'react-intl';

const Courses = ({ intl, courses = [] , taxonomies}) => {
  const t = intl.messages;

  const getNameBySlug = useCallback((slug) => (
    taxonomies.courses.filter((course) => course.slug === slug)[0].name
  ),[taxonomies.courses])

  return (
    courses.length < 1 ? null :
    <section className='actionCompetences'>
      <span className='actionCompetences-title'>{ t.courses }</span>
      { courses.map((course) => (
        <span className='actionCompetences-competence'>{getNameBySlug(course)}</span>
      ))}
    </section>
  );
};

export default injectIntl(Courses);
