import React, { useRef, useCallback } from "react";
import { injectIntl } from "react-intl";

import {
  BEGINNING_OF_TIME,
  getDefaultStartTime,
  getDefaultEndTime,
  getDefaultRegistrationEndTime,
  allMinutes,
  getNow,
  guessHoursPerDay,
  guessWorkingHours,
  isSameDay,
  isSameHour,
  isAnyDayOutsideRange,
  isAnyDayAfter,
  isAnyDayBefore,
  isAnyHourBefore,
  todayOrEarlier,
  yesterdayOrEarlier,
  getHoursUntil,
  getHoursFrom,
  getMinutesUntil,
  getMinutesFrom,
  todayAtHourAndMinute,
  isTimeAfter,
  timeMax,
} from "../time";

import {
  Card,
  Form,
  TimePicker,
  DatePicker,
  Switch,
  Row,
  Col,
} from "antd";

const Dates = ({
  intl,
  handleChange,
  formMode,
  showError,
  getValue,
  canOverride,
  calculatePerDiem,
  showWorkingHours,
  showRegistrationEnd,
}) => {
  const t = intl.messages;

  // Some local state for the image crop
  const errorRefs = {
    dates: useRef(null),
  };

  const onChangeStartDate = useCallback((start_date) => {
    const sameDay = isSameDay(
      start_date,
      getValue("end_date")
    );
    handleChange("start_date")(start_date);
    handleChange("start_time")(
      getDefaultStartTime(start_date)
    );
    if (getValue("end_date") && getValue("end_time")) {
      if (
        isAnyDayAfter(getValue("end_date"))(start_date) ||
        sameDay
      ) {
        handleChange("end_date")(null);
        handleChange("end_time")(null);
        if (calculatePerDiem) {
          handleChange("hours_per_day")(null);
          handleChange("multiple_dates")(null);
        }
        if (showWorkingHours) {
          handleChange("is_working_hours")(false);
        }
      } else {
        if (calculatePerDiem) {
          handleChange("hours_per_day")(
            guessHoursPerDay(
              start_date,
              getDefaultStartTime(start_date),
              getValue("end_date"),
              getValue("end_time"),
              getValue("hours_per_day")
            )
          );
          handleChange("multiple_dates")(!sameDay);
        }
        if (showWorkingHours) {
          handleChange("is_working_hours")(
            guessWorkingHours(
              start_date,
              getDefaultStartTime(start_date),
              getValue("end_date"),
              getValue("end_time")
            )
          );
        }
      }
    }
  }, [calculatePerDiem, getValue, handleChange, showWorkingHours]);

  const onChangeStartTime = useCallback((start_time) => {
    handleChange("start_date")(getValue("start_date"));
    handleChange("start_time")(start_time);
    if (getValue("end_date") && getValue("end_time")) {
      if (
        isSameDay(
          getValue("start_date"),
          getValue("end_date")
        ) &&
        isTimeAfter(getValue("end_time"))(start_time)
      ) {
        handleChange("end_time")(null);
        if (calculatePerDiem) {
          handleChange("hours_per_day")(null);
          handleChange("multiple_dates")(null);
        }
        if (showWorkingHours) {
          handleChange("is_working_hours")(false);
        }
      } else {
        if (calculatePerDiem) {
          handleChange("hours_per_day")(
            guessHoursPerDay(
              getValue("start_date"),
              start_time,
              getValue("end_date"),
              getValue("end_time"),
              getValue("hours_per_day")
            )
          );
        }
        if (showWorkingHours) {
          handleChange("is_working_hours")(
            guessWorkingHours(
              getValue("start_date"),
              start_time,
              getValue("end_date"),
              getValue("end_time")
            )
          );
        }
      }
    }
  }, [calculatePerDiem, getValue, handleChange, showWorkingHours]);


  const onChangeEndDate = useCallback((end_date) => {
    const sameDay = isSameDay(
      getValue("start_date"),
      end_date
    );
    const end_time = sameDay
      ? timeMax(
          getDefaultEndTime(end_date),
          getValue("start_time")
        )
      : getDefaultEndTime(end_date);
    handleChange("end_date")(end_date);
    handleChange("end_time")(end_time);
    if (calculatePerDiem) {
      handleChange("hours_per_day")(
        guessHoursPerDay(
          getValue("start_date"),
          getValue("start_time"),
          end_date,
          end_time,
          getValue("hours_per_day")
        )
      );
      handleChange("multiple_dates")(!sameDay);
    }
    if (showWorkingHours) {
      handleChange("is_working_hours")(
        guessWorkingHours(
          getValue("start_date"),
          getValue("start_time"),
          end_date,
          end_time
        )
      );
    }

    if (
      showRegistrationEnd &&
      (!getValue("registration_end_date") ||
        isAnyDayAfter(end_date)(
          getValue("registration_end_date")
        ))
    ) {
      handleChange("registration_end_date")(end_date);
      handleChange("registration_end_time")(end_time);
    }
  }, [calculatePerDiem, getValue, handleChange, showRegistrationEnd, showWorkingHours]);

  const onChangeEndTime = useCallback((end_time) => {
    handleChange("end_date")(getValue("end_date"));
    handleChange("end_time")(end_time);
    if (calculatePerDiem) {
      handleChange("hours_per_day")(
        guessHoursPerDay(
          getValue("start_date"),
          getValue("start_time"),
          getValue("end_date"),
          end_time,
          getValue("hours_per_day")
        )
      );
    }
    if (showWorkingHours) {
      handleChange("is_working_hours")(
        guessWorkingHours(
          getValue("start_date"),
          getValue("start_time"),
          getValue("end_date"),
          end_time
        )
      );
    }
    if (
      showRegistrationEnd &&
      getValue("registration_end_time") &&
      isSameDay(
        getValue("end_date"),
        getValue("registration_end_date")
      ) &&
      isTimeAfter(getValue("registration_end_time"))(end_time)
    ) {
      handleChange("registration_end_time")(null);
    }
  }, [calculatePerDiem, getValue, handleChange, showRegistrationEnd, showWorkingHours]);

  const onChangeRegistrationEndDate = useCallback((registration_end_date) => {
    const sameDay = isSameDay(
      getValue("end_date"),
      registration_end_date
    );
    const registration_end_time =
      sameDay && getValue("end_time")
        ? getValue("end_time")
        : getDefaultRegistrationEndTime(registration_end_date);
    handleChange("registration_end_date")(registration_end_date);
    handleChange("registration_end_time")(registration_end_time);
  }, [getValue, handleChange]);

  const onChangeRegistrationEndTime = useCallback((registration_end_time) => {
    handleChange("registration_end_date")(
      getValue("registration_end_date")
    );
    handleChange("registration_end_time")(registration_end_time);
  }, [getValue, handleChange]);

  return (
    <Card className="InitiativeForm-card" title={t.initiative_dates}>
      <div className="InitiativeFormDates">
        <div ref={errorRefs.dates}></div>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={t.starts_on} colon={false} required="required">
              <Row type="flex" gutter={[10, 10]}>
                <Col xs={24} sm={12} md={8} lg={12} xl={8}>
                  <Form.Item
                    hasFeedback="hasFeedback"
                    validateStatus={showError("start_date") ? "error" : ""}
                    help={showError("start_date")}
                  >
                    <DatePicker
                      placeholder={t.start_date}
                      value={getValue("start_date")}
                      disabled={
                        formMode === "edit" &&
                        !canOverride &&
                        todayOrEarlier(getValue("start_date"))
                      }
                      disabledDate={canOverride ? null : yesterdayOrEarlier}
                      onChange={onChangeStartDate}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                  <Form.Item
                    hasFeedback="hasFeedback"
                    validateStatus={showError("start_date") ? "error" : ""}
                  >
                    <TimePicker
                      format={"HH:mm"}
                      placeholder={t.start_time}
                      value={getValue("start_time")}
                      disabled={
                        !getValue("start_date") ||
                        (formMode === "edit" &&
                          !canOverride &&
                          todayOrEarlier(getValue("start_date")))
                      }
                      onChange={onChangeStartTime}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={t.ends_on} colon={false} required="required">
              <Row type="flex" gutter={[10, 10]}>
                <Col xs={24} sm={12} md={8} lg={12} xl={8}>
                  <Form.Item
                    hasFeedback="hasFeedback"
                    validateStatus={showError("end_date") ? "error" : ""}
                    help={showError("end_date")}
                  >
                    <DatePicker
                      placeholder={t.end_date}
                      value={getValue("end_date")}
                      disabled={!getValue("start_date")}
                      disabledDate={isAnyDayBefore(getValue("start_date"))}
                      onChange={onChangeEndDate}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                  <Form.Item
                    hasFeedback="hasFeedback"
                    validateStatus={showError("end_date") ? "error" : ""}
                  >
                    <TimePicker
                      format={"HH:mm"}
                      placeholder={t.end_time}
                      value={getValue("end_time")}
                      disabled={!getValue("end_date")}
                      disabledHours={() =>
                        isSameDay(getValue("start_date"), getValue("end_date"))
                          ? getHoursUntil(getValue("start_time"))
                          : []
                      }
                      disabledMinutes={(hour) => {
                        const selectedHour = todayAtHourAndMinute(hour, 0);
                        if (
                          !isSameDay(getValue("start_date"), getValue("end_date"))
                        ) {
                          return [];
                        }
                        if (
                          isAnyHourBefore(getValue("start_time"))(selectedHour)
                        ) {
                          return allMinutes;
                        }
                        if (isSameHour(getValue("start_time"), selectedHour)) {
                          return getMinutesUntil(getValue("start_time"));
                        }
                        return [];
                      }}
                      onChange={onChangeEndTime}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        {!showWorkingHours ? null : (
          <Row type="flex">
            <Col xs={24}>
              <Form.Item
                className="InitiativeFormDates__workingHours"
                hasFeedback="hasFeedback"
                validateStatus={showError("is_working_hours") ? "error" : ""}
                help={showError("hours_per_day")}
                label={t.during_working_hours_question}
                colon={false}
                labelAlign="left"
                layout="inline"
                labelCol={{
                  xs: 24,
                  sm: 20,
                  md: 10,
                  lg: 8,
                  xl: 4,
                }}
                wrapperCol={{
                  xs: 24,
                  sm: 4,
                  md: 14,
                  lg: 16,
                  xl: 20,
                }}
                required="required"
              >
                <Switch
                  checkedChildren={t.yes}
                  unCheckedChildren={t.no}
                  checked={getValue("is_working_hours")}
                  onChange={handleChange("is_working_hours")}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!showRegistrationEnd ? null : (
          <Form.Item
            className="InitiativeFormDates__registrationEnd"
            label={t.registration_end_time}
            required
            colon={false}
            labelAlign="left"
          >
            <Row type="flex" gutter={[16, 10]}>
              <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                <DatePicker
                  placeholder={t.picker_registration_end_date}
                  value={getValue("registration_end_date")}
                  disabled={!getValue("end_date")}
                  disabledDate={isAnyDayOutsideRange(
                    canOverride ? BEGINNING_OF_TIME : getNow(),
                    getValue("end_date")
                  )}
                  onChange={onChangeRegistrationEndDate}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                <TimePicker
                  format={"HH:mm"}
                  placeholder={t.picker_registration_end_time}
                  value={getValue("registration_end_time")}
                  disabled={!getValue("registration_end_date")}
                  disabledHours={() =>
                    isSameDay(
                      getValue("end_date"),
                      getValue("registration_end_date")
                    )
                      ? getHoursFrom(getValue("end_time"))
                      : []
                  }
                  disabledMinutes={(hour) =>
                    isSameDay(
                      getValue("end_date"),
                      getValue("registration_end_date")
                    ) &&
                    isSameHour(
                      getValue("end_time"),
                      todayAtHourAndMinute(hour, 0)
                    )
                      ? getMinutesFrom(getValue("end_time"))
                      : []
                  }
                  onChange={onChangeRegistrationEndTime}
                />
              </Col>
            </Row>
          </Form.Item>
        )}
      </div>
    </Card>
  );
};

export default injectIntl(Dates);
