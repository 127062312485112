import React, { useCallback } from 'react';
import {injectIntl} from 'react-intl';

import AddressSearch from 'components/AddressSearch';

import {
  Card,
  Form,
  Input,
  Switch,
  Row,
  Col,
} from 'antd';

const Location = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  getValue,
  handleChange,
  handleChangeEvent,
  showError,
  errorRefs,
}) => {
  const t = intl.messages;

  const handleToggleLocation = useCallback(
    (field) => {
      const currentValue = getValue(field);
      if (currentValue) {
        handleChange(
          field === 'is_in_person' ? 'is_remote' : 'is_in_person'
        )(true);

        if (field === 'is_in_person') {
          handleChange('address')(null);
          handleChange('address_detail')(null);
          handleChange('latitude')(null);
          handleChange('longitude')(null);
        } else {
          handleChange('remote_url')(null);
        }
      }
      handleChange(field)(!currentValue);
    },
    [getValue, handleChange]
  );

  return (
    <Card
      className="InitiativeForm-card"
      title={<span> <span className="InitiativeForm-required-star">*</span> {t.location}</span>}
    >
      <Row>
        <Col span={24}>
          <span>{t.initiative_in_person_or_remote_label}</span>
        </Col>
      </Row>
      <Row
        type="flex"
        align="middle"
        gutter={10}
        className="InitiativeForm-location-toggle"
      >
        <Col span={2}>
          <label>{t.initiative_in_person}</label>
        </Col>
        <Col>
          <Switch
            checkedChildren={t.yes}
            unCheckedChildren={t.no}
            checked={getValue('is_in_person')}
            onChange={() => handleToggleLocation('is_in_person')}
          />
        </Col>
      </Row>
      <Row
        type="flex"
        align="middle"
        gutter={10}
        className="InitiativeForm-location-toggle"
      >
        <Col span={2}>
          <label>{t.initiative_remote}</label>
        </Col>
        <Col>
          <Switch
            checkedChildren={t.yes}
            unCheckedChildren={t.no}
            checked={getValue('is_remote')}
            onChange={() => handleToggleLocation('is_remote')}
          />
        </Col>
      </Row>
      { getValue('is_in_person')
        ? <>
            <div ref={errorRefs.address}></div>
            <AddressSearch
              label={t.search_address}
              organization={organization}
              placeholder={t.search_address}
              error={showError('address')}
              addressValue={getValue('address')}
              latitude={getValue('latitude')}
              longitude={getValue('longitude')}
              onChangeLocation={({latitude, longitude, text}) => {
                handleChange('latitude')(parseFloat(latitude));
                handleChange('longitude')(parseFloat(longitude));
                handleChange('address')(text);
              }}
            />
            <Form.Item
              hasFeedback="hasFeedback"
              validateStatus={showError('address_detail') ? 'error' : ''}
              help={showError('address_detail')}
              label={ t.address_detail }
            >
              <Input.TextArea
                placeholder={t.address_detail_placeholder}
                name="address_detail"
                rows={2}
                value={getValue('address_detail')}
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </>
        : null
      }
      { getValue('is_remote')
        ? <Form.Item
            hasFeedback
            validateStatus={showError('remote_url') ? 'error' : ''}
            help={showError('remote_url')}
            label={ t.initiative_remote_url }
          >
            <Input
              placeholder={t.initiative_remote_url_placeholder}
              name="remote_url"
              rows={2}
              value={getValue('remote_url')}
              onChange={handleChangeEvent}
            />
          </Form.Item>
        : null
      }
    </Card>
  );
}

export default injectIntl(Location);
