import React from 'react';
import { injectIntl } from 'react-intl';

const InitiativeTypeString = injectIntl(({
  intl,
  mode,
  type,
  subtype,
  className = '',
}) => {
  const t = intl.messages;
  return (
    <span>
      {
        mode
        ? (
          // There is a mode, like 'create' or 'edit'
          t[`${type}_${subtype}_title_${mode}`] ||
          t[`${type}_title_${mode}`]
        ) : (
          // Standard text
          t[`card_${type}_${subtype}`] ||
          t[`card_${type}`]
        )
      }
    </span>
  );
});

export default InitiativeTypeString;

