import React from 'react';
import { injectIntl } from 'react-intl';
import { formatDateAndTime } from 'utils/date';

const When = ({
  intl,
  start_time,
  end_time,
  hours_per_day,
  is_working_hours,
  showWorkingHours = false,
}) => {
  const t = intl.messages;
  const startTime = formatDateAndTime(start_time, intl)
  const endTime = formatDateAndTime(end_time, intl)
  return (
    <section className='actionWhen'>
      <div className='actionWhen-title'>{t.when}</div>
      <div>{`${startTime.date}, ${startTime.time}`}</div>
      <div>{`${endTime.date}, ${endTime.time}`}</div>
      {
        !showWorkingHours || !is_working_hours
        ? null
        : (
          <div className="actionWhen-workinghours">
            { t.during_working_hours }
          </div>
        )
      }
    </section>
  );
};

export default injectIntl(When);
