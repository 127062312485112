import React, { useMemo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Rate,
  Table,
} from 'antd';
import { parseScore, formatScore } from 'utils/score';
import T from 'components/T';

import './style.less';

const TYPES_WITH_HOURS = [
  'volunteering',
  'training',
];

const RankingInitiative = ({
  intl,
  data = [],
}) => {
  const t = intl.messages;
  const [ pageOffset, setPageOffset ] = useState(0);

  const handleTableChange = useCallback(({ current, pageSize }) => {
    setPageOffset( (current-1) * pageSize );
  }, []);

  const columns = useMemo(() => ([
    {
      title: null,
      dataIndex: 'rank',
      key: 'rank',
      render: (rank, record, index) => <div>{ `#${pageOffset + index + 1}` }</div>,
    },
    {
      title: t.title,
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => (
        <div>{ title }</div>
      ),
    },
    {
      title: t.role,
      dataIndex: 'role',
      key: 'role',
      render: (role, record) => (
        <div>{ t[`organization_role_${role}`] }</div>
      ),
    },
    {
      title: t.participants,
      dataIndex: 'participant_count',
      key: 'participant_count',
      sorter: (a, b) => (a.participant_count - b.participant_count),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t.hours_total,
      dataIndex: 'hours',
      key: 'hours',
      sorter: (a, b) => (a.hours - b.hours),
      sortDirections: ['descend', 'ascend'],
      render: (hours, record) => (
        <div>
          {
            TYPES_WITH_HOURS.includes(record.type)
            ? hours
            : '-'
          }
        </div>
      ),
    },
    {
      title: t.initiativedetail_beneficiaries,
      dataIndex: 'beneficiaries',
      key: 'beneficiaries',
      sorter: (a, b) => (a.beneficiaries - b.beneficiaries),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t.initiativedetail_points,
      dataIndex: 'points',
      key: 'points',
      sorter: (a, b) => (a.points - b.points),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t.rating,
      dataIndex: 'score',
      key: 'score',
      sorter: (a, b) => (parseFloat(a.score || '0.00') - parseFloat(b.score || '0.00')),
      sortDirections: ['descend', 'ascend'],
      render: (score, record) => (
        <Row type="flex" gutter={16}>
          <Col>
            <Rate
              disabled
              allowHalf
              value={parseScore(score)}
            />
          </Col>
          <Col>
            <div className="RankingInitiative-score">{ formatScore(score) }</div>
            <div className="RankingInitiative-scorecount">
              <T review_pl={{ itemCount: record.score_count }} />
            </div>
          </Col>
        </Row>
      ),
    },
  ]), [ t, pageOffset ]);

  const dataSource = useMemo(() => data.map((entry, index) => ({
      key: index,
      rank: entry.rank,
      title: <Link to={`initiative/${entry.slug}/details`}>{ entry.title }</Link>,
      slug: entry.slug,
      type: entry.type,
      role: entry.role,
      hours: entry.hours,
      participant_count: entry.participant_count,
      score: entry.score,
      score_count: entry.score_count,
      points: entry.points === -1 ? 0 : entry.points,
      beneficiaries: entry.beneficiaries || 0,
  })), [data]);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowClassName={(_, index) => index%2 ? 'Table-row-odd' : 'Table-row-even'}
      onChange={handleTableChange}
    />
  );
};

export default injectIntl(RankingInitiative);
