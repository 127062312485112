import React from 'react';
import {injectIntl} from 'react-intl';

import {
  Card,
  Form,
  Input,
} from 'antd';

const FeedbackUrl = ({
  intl,
  loading,
  error,
  getValue,
  handleChange,
  handleChangeEvent,
  showError,
  errorRefs,
}) => {
  const t = intl.messages;

  return (
    <Card
      className="InitiativeForm-card"
      title={<span>{t.feedback_url}</span>}
    >
      <div ref={errorRefs.feedback_url}></div>
      <Form.Item hasFeedback="hasFeedback" validateStatus={showError('feedback_url')
          ? 'error'
          : ''}
          help={showError('feedback_url')}
      >
        <Input
          placeholder={t.feedback_url_placeholder}
          name="feedback_url"
          value={getValue('feedback_url')}
          onChange={handleChangeEvent}
          maxLength={255}
        />
      </Form.Item>
    </Card>
  );
}

export default injectIntl(FeedbackUrl);
