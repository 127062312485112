import React, {useState} from 'react';
import { injectIntl } from 'react-intl';
import {
  Modal,
} from 'antd';
import MapWithPins from 'components/MapWithPins';
import { A } from 'aplanet-ui-kit';

const DEFAULT_ZOOM = 13;

const Address = ({
  intl,
  address,
  address_detail,
  latitude,
  longitude,
  is_in_person,
  is_remote,
  remote_url,
}) => {
  const t = intl.messages;
  const [mapShowed, setMapShowed] = useState(false)
  return (
    <section className='actionAddress'>
      <div className='actionAddress-title'>{t.where}</div>
      { is_in_person
        ? <>
            <div>{address}</div>
            {address_detail ? <span className="actionAddress-detail">{address_detail}</span> : null }
            <div className='actionAddress-showMap' onClick={() => setMapShowed(true)}>{t.showMap}</div>
            <Modal visible={mapShowed} centered="centered" title={t.where} onCancel={() => setMapShowed(false)} footer={null}>
              <MapWithPins
                latitude={latitude}
                longitude={longitude}
                zoom={DEFAULT_ZOOM}
                pins={[{
                  latitude,
                  longitude,
                  text: address,
                }]}
              />
            </Modal>
          </>
        : null
      }
      { is_remote
        ? <>
            <div>{t.initiative_remote}</div>
            { remote_url
              ? <A
                  className='actionAddress-showMap'
                  href={remote_url}
                >
                  {t.initiative_remote_url}
                </A>
              : null
            }
          </>
        : null
      }
    </section>
  );
};

export default injectIntl(Address);
