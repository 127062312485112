import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Avatar from 'components/Avatar';
import { getAggregateForIndicator } from 'containers/InitiativeIndicators';
import { getUserName, isDeletedUser } from 'utils/user';

import {
  Progress,
  Col,
  Row,
  Empty,
  Tooltip,
  List,
} from 'antd';

import {
  UserOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import './style.less';


const InitiativeStats = ({
  intl,
  taxonomies,
  volunteers,
  initiative_detail,
  ...initiative
}) => {
  const t = intl.messages;

  const [selectedIndicator, setSelectedIndicator] = useState();

  useEffect(
    () => {
      if (!selectedIndicator && initiative.indicators.length) {
        setSelectedIndicator(initiative.indicators[0]);
      }
    },
    [initiative.indicators, selectedIndicator, setSelectedIndicator]
  );

  const totalHoursValidated = useMemo(() => {
    let totalHours = 0
    initiative_detail.data.participation.forEach((participant) => {
      if(!participant || !participant.valid_hours) {
        return;
      }
      totalHours += Number(participant.valid_hours)
    })
    return totalHours
  }, [initiative_detail])

  /*
  const hourDeviationPercent = useMemo(() => {
    const own = totalHoursValidated || 0;
    const avg = initiative.median_hours || initiative.average_hours || 1;
    return (own - avg) * 100 / avg
  }, [totalHoursValidated, initiative.median_hours, initiative.average_hours]);
  */

  const nParticipants = useMemo(() => {
    return initiative_detail.data.participation.length
  }, [initiative_detail])

  const participantsAttended = useMemo(() => {
    return initiative_detail.data.participation.filter((p) => p.participant_status === 'accepted' && p.total_hours > 0).length
  }, [initiative_detail])

  const attendedPercentage = useMemo(() => {
    return Math.round(100*participantsAttended/nParticipants)
  }, [participantsAttended, nParticipants])

  const participantsNoHours = useMemo(() => {
    return initiative_detail.data.participation.filter((p) => p.participant_status === 'accepted' && p.total_hours === 0).length
  }, [initiative_detail])

  const participantsBlocked = useMemo(() => {
    return initiative_detail.data.participation.filter((p) => p.participant_status === 'rejected').length
  }, [initiative_detail])

  const blockedPercentage = useMemo(() => {
    return Math.round(100*participantsBlocked/nParticipants)
  }, [participantsBlocked, nParticipants])

  const participantsNotGoing = useMemo(() => {
    return initiative_detail.data.participation.filter((p) => p.participant_status === 'not_going').length
  }, [initiative_detail])

  const sortDescendentHoursParticipants = useCallback((a, b) => {
    if(a.valid_hours > b.valid_hours){
      return -1
    }else if(a.valid_hours < b.valid_hours){
      return 1
    }else{
      return 0
    }
  }, []);

  const hoursPerCohort = useMemo(() => {
    let cohortsHours = {}
    initiative_detail.data.participation.forEach((participant) => {
      if(participant.cohorts && participant.cohorts.length > 0){
        participant.cohorts.forEach((cohort) => {
          let firstCohort = Number(cohortsHours[cohort]);
          cohortsHours[cohort] = firstCohort ? firstCohort + Number(participant.valid_hours) : Number(participant.valid_hours)
        })
      }
    })
    return cohortsHours
  }, [initiative_detail])

  const sortDescendentHoursCohorts = useCallback((a, b) => {
    const aHours = hoursPerCohort[a.slug] ? hoursPerCohort[a.slug] : 0
    const bHours = hoursPerCohort[b.slug] ? hoursPerCohort[b.slug] : 0
    if(aHours > bHours){
      return -1
    }else if(aHours < bHours){
      return 1
    }else{
      return 0
    }
  }, [ hoursPerCohort ]);

  const parcipantsPerCohort = useMemo(() => {
    let cohortsParticipants = {}
    initiative_detail.data.participation.forEach((participant) => {
      if(participant.cohorts && participant.cohorts.length > 0){
        participant.cohorts.forEach((cohort) => {
          cohortsParticipants[cohort] = cohortsParticipants[cohort] ? Number(cohortsParticipants[cohort]) + 1 : 1
        })
      }
    })
    return cohortsParticipants
  }, [initiative_detail]);

  const cohortSlugToName = useCallback((slug) => (
    taxonomies.cohorts.filter((cohort) => cohort.slug === slug)[0].name
  ),[taxonomies.cohorts]);

  return (
    <React.Fragment>
      <Row className='actionStats-topInfo' gutter={[25, 25]}>
        <Col span={6} className='actionStats-nParticipants'>
          <span className='number'>{nParticipants}</span>
          <section>
            <span className='title'>
              { nParticipants > 1 ? t.participants_registered : t.participant_registered }
            </span>
            {parseInt(initiative.rank_participants) === 1
              ? <React.Fragment>
                  <span className='stat gold'>{'Top 1'}</span><img src='/images/trophy.svg' alt=''/>
                </React.Fragment>
              : <React.Fragment>
                  <span className='stat'>{`Top ${initiative.rank_participants}`}</span>
                </React.Fragment>
            }
          </section>
        </Col>
        <Col span={6} className='actionStats-nHours'>
          <span className='number'>{totalHoursValidated}</span>
          <section>
            <span className='title'>{t.hours}</span>
            {parseInt(initiative.rank_hours) === 1
              ? <React.Fragment>
                  <span className='stat gold'>{'Top 1'}</span><img src='/images/trophy.svg' alt=''/>
                </React.Fragment>
              : <React.Fragment>
                  <span className='stat'>{`Top ${initiative.rank_hours}`}</span>
                </React.Fragment>
            }
          </section>
        </Col>
        { !!initiative.beneficiaries &&
        <Col span={6} className='actionStats-nBeneficiaries'>
          <span className='number'>{initiative.beneficiaries}</span>
          <section>
            <span className='title'>{intl.formatMessage({ id: 'initiativedetail_beneficiaries' })}</span>
          </section>
        </Col>
        }
        <Col span={6} className='actionStats-ranking'>
          <span className='title'>{t.ranking}<br/>{t.initiative}</span>
          <span className='number'>{`#${initiative.rank}`}</span>
        </Col>
      </Row>

      <Row className='actionStats-card'>
        <Row>
          <span className='title'>{t.participantsAttendance}</span>
        </Row>
        <Row className='content'>
          <Col lg={12} md={24} sm={24} className='leftSide'>
            { !attendedPercentage && !blockedPercentage
              ? <Empty className='big' image={'/images/icon-empty-data.svg'}/>
              : <Progress
                className='progress'
                width={200} strokeWidth={8}
                type="dashboard"
                percent={Number(attendedPercentage) + Number(blockedPercentage)}
                successPercent={attendedPercentage}
                strokeColor={'rgba(255, 96, 96, 1)'}
                format={(percent, successPercent) =>
                  <React.Fragment>
                    <span className='porcentageText'>{attendedPercentage+'%'}</span>
                    <span className='attendanceText'>{t.attendance}</span>
                  </React.Fragment>
              }/>
            }
            <section className='progressData'>
              <article className='attended'>
                <span className='number'>{participantsAttended}</span>
                <span className='number-description'>{t.with_hours}</span>
              </article>
              <figure className='separator'/>
              <article className='noHours'>
                <span className='number'>{participantsNoHours}</span>
                <span className='number-description'>{t.no_hours}</span>
              </article>
              <figure className='separator'/>
              <article className='blocked'>
                <span className='number'>{participantsBlocked}</span>
                <span className='number-description'>{t.rejected}</span>
              </article>
              <figure className='separator'/>
              <article className='notGoing'>
                <span className='number'>{participantsNotGoing}</span>
                <span className='number-description'>{t.not_going}</span>
              </article>
            </section>
          </Col>
          { !initiative_detail || !initiative_detail.data || !initiative_detail.data.participation || initiative_detail.data.participation.length < 1
            ? <Col lg={12} md={0} sm={0} className='rightSide empty'>
                <Empty className='big' image={'/images/icon-empty-data.svg'}/>
              </Col>
            : <Col lg={12} md={0} sm={0} className='rightSide'>
                {initiative_detail.data.participation.map((participant) => (
                  <Row className='participant' type="flex" justify="space-between">
                    <Col span={18}>
                      <Row align="middle" gutter={10}>
                        <Col>
                        { isDeletedUser(participant)
                          ? <Avatar
                              className='avatar-deleted'
                              icon={<DeleteOutlined />}
                            />
                          : <Avatar
                              className='avatar'
                              size={40}
                              shape="circle"
                              src={participant.avatar}
                              name={getUserName(participant, intl)}
                              icon={<UserOutlined />}
                            />
                        }
                        </Col>
                        <Col className='name'>{getUserName(participant, intl)}</Col>
                      </Row>
                    </Col>
                    <Col className={`state ${participant.participant_status} with${participant.total_hours}hours `}>{
                      participant.participant_status === 'not_going'
                      ? t.not_going
                      : participant.participant_status === 'rejected'
                        ? t.rejected
                        : participant.total_hours > 0
                          ? t.with_hours
                          : t.no_hours
                    }</Col>
                  </Row>
                ))}
            </Col>
          }
        </Row>
      </Row>
      <section className='actionStats-hoursContributed'>
        <section className='hoursByPerson'>
          <span className='title'>{t.hoursByPerson}</span>
          { !initiative_detail || !initiative_detail.data || !initiative_detail.data.participation || initiative_detail.data.participation.length < 1
            ? <section className='participants empty'>
                <Empty className='big' image={'/images/icon-empty-data.svg'}/>
              </section>
            : <section className='participants'>
              { initiative_detail.data.participation.sort(sortDescendentHoursParticipants).map((participant, i) => (
                  i === 0
                  ? <article className='participant'>
                      <span className='topNumber'>{`#${i+1}`}</span>
                      { isDeletedUser(participant)
                        ? <Avatar
                            className='avatar-deleted'
                            icon={<DeleteOutlined />}
                          />
                        : <Avatar
                            className='avatar'
                            size={80}
                            shape="circle"
                            src={participant.avatar}
                            name={getUserName(participant, intl)}
                            icon={<UserOutlined />}
                          />
                      }
                      <section className='participantInfo'>
                        <section>
                          <img src='/images/trophy.svg' alt='top1' />
                          <Tooltip placement="topLeft" mouseEnterDelay={0.7} title={getUserName(participant, intl)}>
                            <span className='name'>{getUserName(participant, intl)}</span>
                          </Tooltip>
                        </section>
                        <span className='groupName'>{(participant.cohorts || []).map((cohort, i) =>
                            (i+1 === participant.cohorts.length)
                            ? <span>{`${cohortSlugToName(cohort)}`}</span>
                            : <span>{`${cohortSlugToName(cohort)}, `}</span>
                        )}</span>
                      </section>
                    <span className='hours'>{`${participant.valid_hours}h`}</span>
                  </article>
                  : <Row className='participant' type="flex" justify="space-between">
                      <Col span={18}>
                        <Row align="middle">
                          <Col className='topNumber'>{`#${i+1}`}</Col>
                          <Col>
                            { isDeletedUser(participant)
                              ? <Avatar
                                  className='avatar-deleted'
                                  icon={<DeleteOutlined />}
                                />
                              : <Avatar
                                  className='avatar'
                                  size={40}
                                  shape="circle"
                                  src={participant.avatar}
                                  name={getUserName(participant, intl)}
                                  icon={<UserOutlined />}
                                />
                            }
                          </Col>
                          <Col className='name'>
                            <Tooltip placement="topLeft" mouseEnterDelay={0.7} title={getUserName(participant, intl)}>
                              {getUserName(participant, intl)}
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                      <Col className='hours'>
                        {`${participant.valid_hours}h`}
                      </Col>
                    </Row>
              ))}
            </section>
          }
        </section>
        <section className='hoursByGroup'>
          <span className='title'>{t.hoursByGroup}</span>
          <section className='groups'>
          { taxonomies.cohorts.sort(sortDescendentHoursCohorts).map((cohort, i) => (
            i === 0
            ? <article className='group'>
                <span className='topNumber'>{`#${i+1}`}</span>
                <section className='groupInfo'>
                  <section>
                    <img src='/images/trophy.svg' alt='' />
                    <Tooltip placement="topLeft" mouseEnterDelay={0.7} title={cohort.name}>
                      <span className='name'>{cohort.name}</span>
                    </Tooltip>
                  </section>
                  <span className='members'>{`${parcipantsPerCohort[cohort.slug] ? parcipantsPerCohort[cohort.slug] : '0'} ${t.members}`}</span>
                </section>
                <span className='hours'>{hoursPerCohort[cohort.slug] ? hoursPerCohort[cohort.slug] : 0}{'h'}</span>
              </article>
            : <article className='group'>
                <span className='topNumber'>{`#${i+1}`}</span>
                <Tooltip placement="topLeft" mouseEnterDelay={0.7} title={cohort.name}>
                  <span className='name'>{cohort.name}</span>
                </Tooltip>
                <span className='hours'>{hoursPerCohort[cohort.slug] ? hoursPerCohort[cohort.slug] : 0}{'h'}</span>
              </article>
          ))}
          </section>
        </section>
      </section>
      <Row className='actionStats-card indicators-card'>
        <Row>
          <span className='title'>{t.indicators}</span>
        </Row>
        <Row className='content'>
          <Col lg={12} md={24} sm={24}>
            <List
              dataSource={initiative.indicators}
              renderItem={indicator => (
                <List.Item
                  className={(selectedIndicator && indicator.id === selectedIndicator.id) ? "active" : ""}
                  onClick={() => setSelectedIndicator(indicator)}
                >
                  <List.Item.Meta
                    title={indicator.name}
                    description={t[`aggregate_${indicator.aggregate}`]}
                  />
                  <div>{`${getAggregateForIndicator(indicator)} ${t[`unit_${indicator.unit}_symbol`]}`}</div>
                </List.Item>
              )}
            />
          </Col>
          <Col lg={12} md={24} sm={24}>
            <List
              dataSource={
                selectedIndicator
                ? selectedIndicator.participations.filter(item => item.status === 'validated')
                : []
              }
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      isDeletedUser(item, 'member_status')
                      ? <Avatar icon={<DeleteOutlined />} />
                      : <Avatar src={item.member_avatar} />
                    }
                    title={getUserName(item, intl, 'member_name', 'member_status')}
                  />
                  <div>{`${item.value} ${t[`unit_${item.unit}_symbol`]}`}</div>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Row>
    </React.Fragment>
)}

const mapStateToProps = ({
  taxonomies,
  volunteers,
  initiative_detail,
  rankings
}) => ({
  taxonomies,
  volunteers,
  initiative_detail,
  rankings
});

export default injectIntl(
  connect(mapStateToProps)(InitiativeStats)
);
