import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import config from 'config';
import OfficialDocuments from 'components/InitiativeDocuments/Official';
import UserDocuments from 'components/InitiativeDocuments/User';
import {
  requestInitiativeAttachments,
  updateInitiativeAttachment,
  deleteInitiativeAttachment,
} from 'actions/api';
import { addNewAttachmentDocument } from 'actions/initiative';

import {
  Row,
  Col,
  Tabs,
} from 'antd';
import Loading from 'containers/Loading';


const InitiativeDocuments = ({
  intl,
  organization,
  initiative,
  fetching,
  documents,
  requestInitiativeAttachments,
  addNewAttachmentDocument,
  updateInitiativeAttachment,
  deleteInitiativeAttachment,
}) => {
  const t = intl.messages;

  useEffect(
    () => {
      requestInitiativeAttachments(
        'document', organization.slug, initiative.slug
      );
    },
    [ organization, initiative, requestInitiativeAttachments ]
  );

  const officialDocuments = useMemo(
    () => documents.filter(document => document.status === 'published'),
    [documents]
  );
  const userDocuments = useMemo(
    () => documents.filter(
      document => !document.is_official && document.status === 'draft'
    ),
    [documents]
  );

  const uploadURL = useMemo(
    () => `${config.API_URL}/${organization.slug}/initiative/${initiative.slug}/attachment/document`,
    [organization, initiative]
  );

  const handleOnUploadSuccess = useCallback(
    (document) => addNewAttachmentDocument(document),
    [addNewAttachmentDocument]
  );

  const handleOnDelete = useCallback(
    (documentId) => deleteInitiativeAttachment(
      'document', organization.slug, initiative.slug, documentId
    ),
    [organization, initiative, deleteInitiativeAttachment]
  );

  const handleOnApprove = useCallback(
    (documentId) => updateInitiativeAttachment(
      'document', organization.slug, initiative.slug, documentId,
      { status: 'published' },
    ),
    [organization, initiative, updateInitiativeAttachment]
  );

  const handleOnUnpublish = useCallback(
    (documentId) => updateInitiativeAttachment(
      'document', organization.slug, initiative.slug, documentId,
      { status: 'draft' },
    ),
    [organization, initiative, updateInitiativeAttachment]
  );

  return (
    <>
      { fetching
        ? <Row type="flex" justify="center">
            <Loading.Block />
          </Row>
        : <Row>
            <Col span={24}>
              <Tabs
                defaultActiveKey="official"
              >
                <Tabs.TabPane tab={t.initiative_documents_official} key="official">
                  <OfficialDocuments
                    documents={officialDocuments}
                    uploadURL={uploadURL}
                    onUploadSuccess={handleOnUploadSuccess}
                    onDelete={handleOnDelete}
                    onUnpublish={handleOnUnpublish}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t.initiative_documents_user} key="user">
                  <UserDocuments
                    documents={userDocuments}
                    onApprove={handleOnApprove}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
      }
    </>
  );
}

const mapStateToProps = ({
  initiative_documents,
}) => ({
  fetching: initiative_documents.fetching,
  documents: initiative_documents.items,
});

export default connect(
  mapStateToProps,
  {
    requestInitiativeAttachments,
    addNewAttachmentDocument,
    updateInitiativeAttachment,
    deleteInitiativeAttachment,
  }
)(injectIntl(InitiativeDocuments))
