
const objectToQueryParameters = obj => {
  let qs = '';
  Object.keys(obj).forEach(
    (key, index) => {
      if (index === 0) {
        qs += `${key}=${obj[key]}`;
      } else {
        qs += `&${key}=${obj[key]}`;
      }
    }
  )
  return qs;
};

export default objectToQueryParameters;
