import React, { useMemo } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import InitiativeCard from 'components/InitiativeCard';

import './style.less';

// Leaflet shoddywork incoming
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

const InitiativesMapView = ({
  initiatives,
  organization,
  searchText,
}) => {
  const position = useMemo(
    () => {
      const { default_latitude, default_longitude } = organization;
      const { latitude, longitude } = initiatives[0] || {};
      return [
        default_latitude || latitude || 0,
        default_longitude || longitude || 0
      ];
    }, [organization, initiatives]
  );

  return (
    <Map
      className="indicatorMap"
      center={position}
      zoom={7}
      gestureHandling={true}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {initiatives.map(initiative => (
        <Marker
          position={[initiative.latitude, initiative.longitude]}
        >
          <Popup>
            <InitiativeCard
              from={'initiative'}
              activeNow={true}
              organization={organization}
              searchText={searchText}
              {...initiative}
            />
          </Popup>
        </Marker>
      ))}
    </Map>
  );
};

export default InitiativesMapView;
