import React, { useMemo, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Table,
} from 'antd';

import './style.less';

const RankingGroup = ({
  intl,
  data = [],
}) => {
  const t = intl.messages;
  const [ pageOffset, setPageOffset ] = useState(0);

  const handleTableChange = useCallback(({ current, pageSize }) => {
    setPageOffset( (current-1) * pageSize );
  }, []);

  const columns = useMemo(() => ([
    {
      title: null,
      dataIndex: 'rank',
      key: 'rank',
      render: (rank, record, index) => <div>{ `#${pageOffset + index + 1}` }</div>,
    },
    {
      title: t.name,
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <div>{ name }</div>
      ),
    },
    {
      title: t.participants,
      dataIndex: 'participant_count',
      key: 'participant_count',
      sorter: (a, b) => (a.participant_count - b.participant_count),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t.hours_total,
      dataIndex: 'hours',
      key: 'hours',
      sorter: (a, b) => (a.hours - b.hours),
      sortDirections: ['descend', 'ascend'],
    },
  ]), [ t, pageOffset ]);

  const dataSource = useMemo(() => data.map((entry, index) => ({
      key: index,
      rank: entry.rank,
      name: entry.name,
      slug: entry.slug,
      hours: entry.hours,
      participant_count: entry.participant_count,
  })), [data]);

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowClassName={(_, index) => index%2 ? 'Table-row-odd' : 'Table-row-even'}
      onChange={handleTableChange}
    />
  );
};

export default injectIntl(RankingGroup);
