import React, { useState, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useOrganizations from 'utils/useOrganizations';

import {
  Modal,
  Input,
  Button,
} from 'antd';

const RejectModal = ({
  intl,
  initiative,
  onReject,
  onClose,
  loading,
  records = [],
}) => {
  const t = intl.messages;
  const {
    organization,
  } = useOrganizations();

  const [ rejectReason, setRejectReason ] = useState('');
  const [ submitting, setSubmitting ] = useState(false);

  const closeModal = useCallback(() => {
    setRejectReason('');
    onClose();
  }, [
    onClose,
  ]);

  const submitModal = useCallback(() => {
    records.forEach(record => {
      if (record.status === 'accepted'){
        onReject(organization.slug, initiative.slug, record.member_id, rejectReason);
      }
    });
    setSubmitting(true);
  }, [
    rejectReason,
    onReject,
    organization,
    initiative,
    records,
  ]);

  useEffect(() => {
    if(submitting && !loading) {
      // We are done, let's close shop
      onClose();
    }
  }, [ loading, submitting, onClose ]);

  const confirmationText = records.length > 1 ? t.reject_confirmation_many : t.reject_confirmation
  return (
    <Modal
      visible
      title={confirmationText}
      onCancel={closeModal}
      onOk={submitModal}
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={closeModal}
        >{ t.reject_no }</Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!rejectReason || loading}
          onClick={submitModal}
        >{ t.reject_yes }</Button>
      ]}
    >
      <p>{ t.reject_confirmation_desc }</p>
      <Input.TextArea
        autoSize={ false }
        rows={6}
        value={rejectReason}
        onChange={(e) => setRejectReason(e.target.value)}
      />
    </Modal>
  );
}

RejectModal.propTypes = {
  initiative: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  records: PropTypes.array
};

export default injectIntl(RejectModal);

