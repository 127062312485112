import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import {
  indicatorFromFormToDb,
  indicatorFromDbToForm,
} from './converters';

import { isAnyDayBefore } from 'components/InitiativeForm/time'; // TODO: Get this from utils repo

import useForm from 'utils/useForm';

import * as unitsData from 'samples/units.json'; // TODO: Remove this when Indicators implemented in backend

import validation from './validation';
import './style.less';

const IndicatorForm = ({
  hasParticipation,
  intl,
  loading,
  error,
  onSubmit,
  defaultValues,
  ...props
}) => {
  const t = intl.messages;
  const units = [...unitsData.default]; // TODO: Modify this when Indicators implemented in backend

  const numberFormatterRegex = /\B(?=(\d{3})+(?!\d))/g;
  const numberParserRegexp = ['en', 'en-US'].includes(intl.locale) ? /\$\s?|(,*)/g : /\$\s?|(\.*)/g ;

  const errorRefs = {
    name: useRef(null),
    unit: useRef(null),
    initial_value: useRef(null),
    start_date: useRef(null),
    current_value: useRef(null),
    target_value: useRef(null),
    end_date: useRef(null),
    ask_participants: useRef(null),
    aggregate: useRef(null),
  };

  const submitForm = () => {
    const payload = indicatorFromFormToDb(values);
    onSubmit(payload);
  };

  const validateForm = useMemo(() => validation(t, props.formMode), [ t, props.formMode ]);

  const defaults = defaultValues;

  defaultValues = indicatorFromDbToForm(defaultValues);

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    validationDefaults: {},
    setNames: [],
    defaultValues: defaultValues,
    defaultSetValues: {}
  });

  const showError = useCallback((name) => (!isDirty(name) && errors[name]) || '', [errors, isDirty]);

  const getValue = useCallback((name) => values[name] !== undefined ? values[name] : defaults[name], [values, defaults]);

  const getDatePickerValue = useCallback((name) => props.formMode === 'edit' && values[name] ? values[name] : defaults[name], [values, defaults, props.formMode]);

  useEffect(() => {
    if(errors._firstError && errorRefs[errors._firstError]) {
      errorRefs[errors._firstError].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    errors,
  ]);

  const availableUnits = ['kilogram', 'kilometre', 'litre', 'number' , 'metre', 'square-metre', 'euro', 'brl'];
  const aggregationMethods = ['sum', 'average', 'max', 'min'];

  const handleAskParticipants = (val, e) => {
    handleChange('ask_participants')(val);
  };

  return (
    <Form id="indicator_form" onFinish={ handleSubmit }>
      <FormItem className="IndicatorForm-field-id">
        <Input name={ 'id' } value={ getValue('id') }/>
      </FormItem>
      <Row type="flex" justify="space-between" gutter={10}>
        <Col span={18}>
          <div ref={errorRefs.name}></div>
          <FormItem
            required
            hasFeedback
            validateStatus={ showError('name') ? 'error' : '' }
            help={ showError('name') }
            label={ t.indicators_name }
            colon={ false }
            className="IndicatorForm-field-name"
          >
            <Input
              name={ 'name' }
              value={ getValue('name') }
              onChange={handleChangeEvent}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <div ref={errorRefs.unit}></div>
          <Form.Item
            required
            hasFeedback
            validateStatus={ showError('unit') ? 'error' : '' }
            help={ showError('unit') }
            className="IndicatorForm-field-units"
          >
            <Select
              name="unit"
              placeholder={t.unit}
              defaultValue={getValue('unit')}
              onChange={ handleChange('unit') }
              disabled={ props.formMode === 'edit' }
            >
              {units.map((unit) => {
                if(!availableUnits.includes(unit.slug)){
                  return null;
                }
                return (
                  <Select.Option
                    key={unit.slug}
                    value={unit.slug}
                  >
                    { t[`unit_${unit.slug}`] }
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" gutter={10} key='initial'>
        <Col span={18}>
          <div ref={errorRefs['initial_value']}></div>
          <Form.Item
            hasFeedback
            required
            validateStatus={ showError('initial_value') ? 'error' : '' }
            help={ showError('initial_value') }
            colon={ false }
            label= { t.indicators_initial_value }
            className="IndicatorForm-field-value"
          >
            <InputNumber
              name={ 'initial_value' }
              min={ 0 }
              formatter={ value => `${value}`.replace(numberFormatterRegex, t.thousands_separator) }
              parser={ value => value.replace(numberParserRegexp, '') }
              value={ getValue('initial_value') }
              onChange={ handleChange('initial_value') }
            />
          </Form.Item>
        </Col>
        { getValue('target_value') &&
        <Col span={6}>
          <div ref={errorRefs['start_date']}></div>
          <Form.Item
            hasFeedback
            required
            validateStatus={ showError('start_date') ? 'error' : '' }
            help={ showError('start_date') }
            className="IndicatorForm-date-picker"
          >
            <DatePicker
              placeholder={ t.date_format_hint }
              defaultValue={ getDatePickerValue('start_date')}
              onChange={ handleChange('start_date') }
            />
          </Form.Item>
        </Col>
        }
      </Row>
      <Row type="flex" justify="space-between" gutter={10} key='target'>
        <Col span={18}>
          <div ref={errorRefs['target_value']}></div>
          <Form.Item
            hasFeedback
            validateStatus={ showError('target_value') ? 'error' : '' }
            help={ showError('target_value') }
            colon={ false }
            label={ t.indicators_target_value }
            className="IndicatorForm-field-value"
          >
            <InputNumber
              name={ 'target_value' }
              min={ 0 }
              formatter={ value => `${value}`.replace(numberFormatterRegex, t.thousands_separator) }
              parser={ value => value.replace(numberParserRegexp, '') }
              value={ getValue('target_value') }
              onChange={ handleChange('target_value') }
            />
          </Form.Item>
        </Col>
        { getValue('target_value') &&
        <Col span={6}>
          <div ref={errorRefs['end_date']}></div>
          <Form.Item
            hasFeedback
            required
            validateStatus={ showError('end_date') ? 'error' : '' }
            help={ showError('end_date') }
            className="IndicatorForm-date-picker"
          >
            <DatePicker
              disabledDate={ isAnyDayBefore(getValue('start_date')) }
              placeholder={ t.date_format_hint }
              defaultValue={ getDatePickerValue('end_date')}
              onChange={ handleChange('end_date') }
            />
          </Form.Item>
        </Col>
        }
      </Row>
      {props.formMode === 'edit'
        ? <Row type="flex" justify="space-between" gutter={10} key='current'>
            <Col span={18}>
              <div ref={errorRefs['current_value']}></div>
              <Form.Item
                hasFeedback
                required
                validateStatus={ showError('current_value') ? 'error' : '' }
                help={ showError('current_value') }
                colon={ false }
                label={ t.indicators_current_value }
                className="IndicatorForm-field-value"
              >
                <InputNumber
                  name={ 'current_value' }
                  min={ 0 }
                  formatter={ value => `${value}`.replace(numberFormatterRegex, t.thousands_separator) }
                  parser={ value => value.replace(numberParserRegexp, '') }
                  value={ getValue('current_value') }
                  onChange={ handleChange('current_value') }
                />
              </Form.Item>
            </Col>
          </Row>
        : null
      }
      {
        !hasParticipation ? null : (
          <>
            <Row type="flex" justify="space-between" gutter={10}>
              <Col span={8}>
                <Form.Item
                  colon={ false }
                  label={ t.indicators_ask_participant }
                  className="IndicatorForm-field-ask"
                >
                  <Switch
                    checked={getValue('ask_participants')}
                    onChange={handleAskParticipants}
                  />
                </Form.Item>
              </Col>
            </Row>
            { getValue('ask_participants') && (
              <Row type="flex" justify="space-between" gutter={10}>
                <Col span={18}>
                <div ref={errorRefs.aggregate}></div>
                  <Form.Item
                    required
                    hasFeedback
                    colon={ false }
                    label={ t.aggregation_method }
                    validateStatus={ showError('aggregate') ? 'error' : '' }
                    help={ showError('aggregate') }
                    className="IndicatorForm-field-value"
                  >
                    <Select
                      name="aggregate"
                      placeholder={t.aggregation_method}
                      defaultValue={getValue('aggregate')}
                      onChange={ handleChange('aggregate') }
                    >
                      {aggregationMethods.map(method => (
                        <Select.Option
                          key={method}
                          value={ method }
                        >
                          { t[`aggregate_${method}`] }
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )
      }
    </Form>
  );
};

export default injectIntl(IndicatorForm);
