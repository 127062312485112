import { EMAIL_REGEXP } from 'utils/regex';

const fullValidation = (t, formMode) => ({
  value,
  name,
  email,
}) => {
  let errors = {};

  if (!value) {
    errors.value = t.form_error_required;
    errors._firstError = errors._firstError || 'value';
  }

  if (!name) {
    errors.name = t.form_error_required;
    errors._firstError = errors._firstError || 'name';
  }

  if (!email) {
    errors.email = t.form_error_required;
    errors._firstError = errors._firstError || 'email';
  } else if (!EMAIL_REGEXP.test(email)) {
    errors.email = t.form_error_invalid_email;
    errors._firstError = errors._firstError || 'email';
  }

  return errors;
}

export default fullValidation;
