import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react'

import {
  injectIntl,
} from 'react-intl';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  formatShort,
  formatDateShort,
  sameDayHelper,
} from 'utils/date';

import {
  approve,
  reject,
  request,
} from 'actions/managerApproval';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultOrganization } from 'reducers/organization';

import Loading from 'containers/Loading';
import Avatar from 'components/Avatar';
import T from 'components/T';
import ModalRejectReason from './screens/ModalRejectReason';

import { A } from 'aplanet-ui-kit';

import {
  Row,
  Col,
  Table,
  Button,
  Modal,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import './style.less'

const ExternalManagerApproval = ({
  intl,
  match,
}) => {
  const t = intl.messages;
  const { token } = match.params;

  const dispatch = useDispatch();
  const {
    loading,
    //error,
    data,
  } = useSelector(state => state.manager_approval);

  const [requested, setRequested] = useState(false);
  const [rejectUuid, setRejectUuid] = useState(null);

  const {
    manager,
    members = [],
    organization = defaultOrganization,
    thisRequest,
    allRequests = [],
  } = (data || {});

  // Request data
  useEffect(() => {
    if(requested) {
      return;
    }
    dispatch( request(token) );
    setRequested(true);
  }, [
    dispatch,
    token,
    requested,
  ]);

  const handleApprove = useCallback((uuid) => {
    dispatch(
      approve(
        token,
        uuid,
      )
    )
  }, [
    dispatch,
    token,
  ]);

  const handleReject = useCallback((uuid, comment) => {
    dispatch(
      reject(
        token,
        uuid,
        comment,
      )
    )
  }, [
    dispatch,
    token,
  ]);

  const entries = useMemo(() => {
    const memberMap = new Map();
    allRequests.forEach((request) => {
      if(memberMap.has(request.slug)) {
        memberMap.get(request.slug).push(request);
      } else {
        memberMap.set(request.slug, [ request ]);
      }
    });

    // TODO: Pick the user to show first
    return members
      .sort((a, b) => (a.name - b.name))
      .map(member => ({
        ...member,
        requests: memberMap.get(member.slug) || [],
      }))
  }, [
    members,
    allRequests,
  ]);

  const columns = useMemo(() => ([
    {
      title: t.external_manager_approval_request_title,
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => {
        return (
          <div>
            <div
              className="ManagerApproval__entry_initiative_title"
            >
              { title }
            </div>
            <div>
              <span>{ record.contact_person }</span>
              {
                !record.contact_email ? null :
                <A
                  href={`mailto:${record.contact_email}`}
                  className="ManagerApproval__entry_initiative_contact_icon"
                >
                  <FontAwesomeIcon
                    color="#888"
                    icon="envelope"
                  />
                </A>
              }
              {
                !record.contact_phone ? null :
                <A
                  href={`tel:${record.contact_phone}`}
                  className="ManagerApproval__entry_initiative_contact_icon"
                >
                  <FontAwesomeIcon
                    color="#888"
                    icon="phone"
                  />
                </A>
              }
            </div>
          </div>
        );
      },
    },
    {
      title: t.external_manager_approval_request_times,
      dataIndex: 'start_time',
      key: 'time',
      render: (start_time, { end_time, }) => {
        const {
          sameDay,
        } = sameDayHelper(start_time, end_time)
        return (
          <span>
            {
              sameDay
              ? formatShort(start_time, intl)
              : <T
                  external_manager_approval_request_times_range={{
                    start: formatDateShort(start_time, intl),
                    end: formatDateShort(end_time, intl),
                  }}
                />
            }
          </span>
        );
      }
    },
    {
      title: t.external_manager_approval_request_duration,
      dataIndex: 'start_time',
      key: 'duration',
      render: (start_time, { end_time, hours_per_day, }) => {
        const {
          sameDay,
          diffHours,
        } = sameDayHelper(start_time, end_time)
        return (
          <span>
            {
              sameDay
              ? <T
                  external_manager_approval_request_duration_once={{
                    count: diffHours,
                  }}
                />
              : '-'
              /*
                <T
                  external_manager_approval_request_duration_per_day={{
                    count: hours_per_day,
                  }}
                />
              */
            }
          </span>
        );
      }
    },
    {
      title: t.external_manager_approval_request_status,
      dataIndex: 'manager_approval_status',
      key: 'status',
      render: (status, { uuid, reject_reason }) => {
        switch(status) {
          case 'not_needed':
          case 'approved':
            return (
              <span
                className="ManagerApproval__status__approved"
              >
                { t.external_manager_approval_request_status_approved }
              </span>
            );
          case 'rejected':
            return (
              <span
                className="ManagerApproval__status__rejected"
              >
                { t.external_manager_approval_request_status_rejected }
                {
                  !reject_reason ? null :
                  <React.Fragment>
                    { ' ' }
                    <Button
                      size="small"
                      shape='circle'
                      onClick={() => Modal.info({
                        title: t.external_manager_approval_request_status_reject_reason,
                        content: reject_reason,
                      })}
                    >
                      <FontAwesomeIcon
                        icon="clipboard"
                      />
                    </Button>
                  </React.Fragment>
                }
              </span>
            );
          case 'pending':
          default:
            return (
              <span>
                <Button
                  className='acceptRequest'
                  size="small"
                  type="primary"
                  shape='circle'
                  icon={<CheckOutlined />}
                  disabled={loading}
                  onClick={() => handleApprove(uuid)}
                />,
                <Button
                  className='rejectRequest'
                  size="small"
                  type='danger'
                  shape='circle'
                  icon={<CloseOutlined />}
                  disabled={loading}
                  onClick={() => setRejectUuid(uuid)}
                />
              </span>
            );
        }
      }
    },
  ]), [
    t,
    intl,
    loading,
    handleApprove,
  ]);

  if(!requested || (loading && members.length === 0)) {
    return (
      <Loading.Big />
    );
  }

  return(
    <section className='ManagerApproval'>
      <Row>
        <img
          className='ManagerApproval__org-logo'
          src={organization.logo}
          alt={organization.name}
        />
      </Row>
      <h1 className="ManagerApproval__title">
        { t.external_manager_approval_entries }
        <small>
          { t.external_manager_approval_entries_for }
          { ' ' }
          <Avatar
            size={22}
            name={manager.name}
            src={manager.avatar}
          />
          { ' ' }
          { manager.name }
        </small>
      </h1>

      <div
        className="ManagerApproval__body"
      >
        {
          entries.map(({
            name,
            slug,
            avatar,
            email,
            requests = [],
          }) => {
            return (
              <div
                key={slug}
                className="ManagerApproval__entry"
              >
                <div>
                  <Row
                    type="flex"
                    className="ManagerApproval__entry_header"
                    gutter={16}
                  >
                    <Col>
                      <Avatar
                        size={55}
                        name={name}
                        src={avatar}
                      />
                    </Col>
                    <Col>
                      <h2
                        className="ManagerApproval__entry_title"
                      >
                        { name }
                      </h2>
                      <div>
                        { email }
                      </div>
                    </Col>
                  </Row>
                </div>
                <Table
                  columns={columns}
                  dataSource={requests}
                  size="middle"
                  pagination={false}
                  className="ManagerApproval__entry_table"
                  rowClassName={(record) =>
                    record.uuid === thisRequest.uuid ? 'ManagerApproval__current' : ''
                  }
                />
              </div>
            );
          })
        }
      </div>
      <ModalRejectReason
        visible={!!rejectUuid}
        uuid={rejectUuid}
        onClose={() => setRejectUuid(null)}
        onSubmit={handleReject}
        loading={loading}
      />
    </section>
  );
}

export default injectIntl(ExternalManagerApproval);

