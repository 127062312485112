import React from 'react';
import {
  Card,
} from 'antd';

import './style.less';

const Container = ({
  children,
}) => {
  return (
    <div className="SelectableCard__container">
      { children }
    </div>
  );
};

const SelectableCard = ({
  title,
  slug,
  icon,
  type,
  children,
  onSelect,
  selected,
}) => {

  return (
    <Card
      title={title}
      onClick={() => onSelect(type)}
      className={selected ? 'active SelectableCard' : 'inactive SelectableCard'}
    >
      <div className="SelectableCard__icon">{icon}</div>
      <div>{children}</div>
    </Card>
  );
};

SelectableCard.Container = Container;

export default SelectableCard;
