import React, {useCallback} from 'react';
import {injectIntl} from 'react-intl';

import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Alert,
} from 'antd';

const Contact = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  handleChange,
  handleChangeEvent,
  values,
  errors,
  getValue,
  showError,
  errorRefs,
  shownToUsers,
}) => {
  const t = intl.messages;

  const handleEmailChangeEvent = useCallback((e) => {
    e.persist();
    handleChange(e.target.name)((e.target.value || '').toLowerCase().trim());
  }, [handleChange]);

  const handlePhoneChangeEvent = useCallback((e) => {
    e.persist();
    handleChange(e.target.name)((e.target.value || '').replace(/[^0-9+ ()-]/g, '').replace(/ +/g, ' '));
  }, [handleChange]);

  return (
    <Card
      className="InitiativeForm-card"
      title={ shownToUsers ? t.contact_details_public : t.contact_details_private }
    >
      <div ref={errorRefs.contact}></div>
      <Row>
        <Col xs={24} md={{
            span: 20,
            push: 4
          }} lg={{
            span: 22,
            push: 2
          }}>
          { !shownToUsers ? null :
            <div className="InitiativeForm-clarification">{t.shown_to_users}</div>
          }
        </Col>
      </Row>
      <Form.Item label={t.name} colon={false} hasFeedback="hasFeedback" validateStatus={showError('contact_person')
          ? 'error'
          : ''} help={showError('contact_person')} labelCol={{
          xs: 24,
          sm: 12,
          md: 8,
          lg: 6
        }} wrapperCol={{
          xs: 24,
          sm: 12
        }} required="required">
        <Input placeholder={t.name} name="contact_person" value={getValue('contact_person')} onChange={handleChangeEvent}/>
      </Form.Item>
      <Form.Item label={t.email} colon={false} hasFeedback="hasFeedback" validateStatus={showError('contact_email')
          ? 'error'
          : ''} help={showError('contact_email')} labelCol={{
          xs: 24,
          sm: 12,
          md: 8,
          lg: 6
        }} wrapperCol={{
          xs: 24,
          sm: 12
        }} required="required">
        <Input placeholder={t.email} name="contact_email" value={getValue('contact_email')} onChange={handleEmailChangeEvent}/>
      </Form.Item>
      <Form.Item label={t.phone} colon={false} hasFeedback="hasFeedback" validateStatus={showError('contact_phone')
          ? 'error'
          : ''} help={showError('contact_phone')} labelCol={{
          xs: 24,
          sm: 12,
          md: 8,
          lg: 6
        }} wrapperCol={{
          xs: 24,
          sm: 12
        }}>
        <Input placeholder={t.phone} type="tel" name="contact_phone" value={getValue('contact_phone')} onChange={handlePhoneChangeEvent}/>
      </Form.Item>
      { ( getValue('proposal_params') || {}).submitter_helps_organize &&
        <Alert message={t.proposer_will_help} type="warning" />
      }
    </Card>
  );
}

export default injectIntl(Contact);
