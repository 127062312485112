import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Alert
} from 'antd';
import {
  UserOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { A } from 'aplanet-ui-kit';
import Highlighter from 'react-highlight-words'
import { useHotkeys } from 'react-hotkeys-hook'

import { EMAIL_REGEXP } from 'utils/regex';
import { usePermissionList } from 'components/PermissionSwitch';
import PhoneInput from 'components/PhoneInput';
import UserProfileModal from 'containers/UserProfileModal'
import colors from 'colors'
import Avatar from 'components/Avatar';


const AddParticipantsModal = ({
    intl,
    showAddParticipantModal,
    volunteersItems,
    onCancelModal,
    onAddParticipant,
    organizationRegion,
    initiativeDone,
    participants,
    loading,
    error
}) => {
  const t = intl.messages;

  const permissionList = usePermissionList();

  const [participantNameInput, setParticipantNameInput] = useState('');
  const [participantEmailInput, setParticipantEmailInput] = useState('');
  const [participantPhoneInput, setParticipantPhoneInput] = useState('');
  const [modalSearchText, setModalSearchText] = useState('');
  const [userDetail, setUserDetail] = useState(null);
  const [addingParticipant, setAddingParticipant] = useState(false);

  const modalSearchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if (modalSearchInputRef.current && modalSearchInputRef.current.focus) {
      modalSearchInputRef.current.focus();
      e.preventDefault();
    }
  }, [modalSearchInputRef]);

  useEffect(() => {
    if(!loading && addingParticipant){
      if(error){
        const errorMsg = error === 400
          ? t.participants_userAddedErrorMissingInfo
          : error === 409
            ? t.participants_userAddedErrorInfoRepeated
            : error === 412
              ? t.participants_userAddedErrorAlready
              : null;
        Modal.error({
          title: t.participants_userAddedError,
          content: errorMsg
        })
      }else{
        Modal.success({
          title: t.participants_userAdded
        })
      }
      setAddingParticipant(false)
    }
  }, [ error, loading, addingParticipant, t ])

  const addParticipantToInitiative = useCallback((name, email, phone) => {
    setParticipantNameInput('')
    setParticipantEmailInput('')
    setParticipantPhoneInput('')
    setAddingParticipant(true);
    onAddParticipant(name, email, phone);
  }, [onAddParticipant]);

  const onCancelAddParticipants = useCallback(() => {
    setModalSearchText('');
    setParticipantNameInput('');
    setParticipantEmailInput('');
    setParticipantPhoneInput('');
    onCancelModal();
  }, [onCancelModal]);

  const addParticipantCell = useCallback((text, record) => {
    const participant = participants.find(participant => participant.member_id === record.id);

    if (!participant){
        return <Button
          icon={<PlusOutlined />}
          type='primary'
          onClick={() => addParticipantToInitiative(record.name, record.email, record.phone)}
        >
          {t.participants_addNewParticipant}
        </Button>
    }
    switch(participant.status) {
      case 'not_going':
        return <div className="InitiativeCollectionContributor-status-notgoing">{ t.not_going }</div>
      case 'rejected':
        return <div className="InitiativeCollectionContributor-status-rejected">{ t.rejected }</div>
      case 'accepted':
      default:
        return <div className="InitiativeCollectionContributor-status-participating">{ initiativeDone ? t.participated : t.participating }</div>
    }
  }, [addParticipantToInitiative, initiativeDone, participants, t]);

  const nameCell = useCallback((name, record) => (
    <React.Fragment>
      <Row type="flex" gutter={10} justify="left" align="middle">
        <Col>
          { permissionList.has('can_manage_volunteers') && record.slug
            ? (
              <A onClick={() => setUserDetail(record.slug)} >
                <Avatar
                  size={38}
                  shape="circle"
                  src={record.avatar}
                  name={record.name}
                  icon={<UserOutlined />}
                />
              </A>
            ) : (
              <Avatar
                size={38}
                shape="circle"
                src={record.avatar}
                name={record.name}
                icon={<UserOutlined />}
              />
            )
          }
        </Col>
        <Col>
          <Row type="flex" gutter={2}>
            <Col>
              { record.is_newbie &&
                  <img className="typeIcon typeIcon-img" src='/images/icon-new.svg' alt={t.newbie} />
              }
            </Col>
            <Col>
              { !modalSearchText
                  ? name
                  : <Highlighter highlightStyle={{
                        backgroundColor: colors['@text-selection-bg'],
                        padding: 0
                      }} searchWords={[modalSearchText]} autoEscape textToHighlight={name}/>
              }
            </Col>
          </Row>
          <Row>
            { record.email && (
              !modalSearchText
                ? record.email
                : <Highlighter highlightStyle={{
                      backgroundColor: colors['@text-selection-bg'],
                      padding: 0
                    }} searchWords={[modalSearchText]} autoEscape textToHighlight={record.email}/>
            )}
          </Row>
          <Row>
            { record.phone && (
              !modalSearchText
                ? record.phone
                : <Highlighter highlightStyle={{
                      backgroundColor: colors['@text-selection-bg'],
                      padding: 0
                    }} searchWords={[modalSearchText]} autoEscape textToHighlight={record.phone}/>
            )}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  ), [permissionList, t, modalSearchText]);

  // Colums for volunteers table
  const columnsVolunteers = useMemo(() => ([
    {
      title: t.name,
      dataIndex: 'name',
      key: 'name',
      filteredValue: modalSearchText
        ? [modalSearchText]
        : null,
      onFilter: (value, record) =>
        (record.name || '').toLowerCase().includes(value) ||
        (record.email || '').toLowerCase().includes(value) ||
        (record.phone || '').toLowerCase().includes(value),
        render: (value, record) => nameCell(value, record),
    },
    {
      title: '',
      align: 'right',
      render: addParticipantCell,
    }
  ]), [
    t,
    modalSearchText,
    addParticipantCell,
    nameCell,
  ]);

  return (
    <>
      <Modal
        visible={showAddParticipantModal}
        title={t.participants_addNewParticipantTitle}
        centered={true}
        width={900}
        onCancel={onCancelAddParticipants}
        footer={false}
      >
        <Input.Search
          type="text"
          prefix={<SearchOutlined style = {{ color: 'rgba(0,0,0,.25)' }}/>}
          placeholder={t.volunteer_search_placeholder}
          enterButton={t.volunteer_search}
          onSearch={(term) => setModalSearchText((term || '').toLowerCase())}
          onChange={(term) => !term.target.value && setModalSearchText('')}
          allowClear="allowClear"
          ref={modalSearchInputRef}
        />
        <div className="Whitespace20"></div>
        <Table
          columns={columnsVolunteers}
          dataSource={volunteersItems}
          pagination={{ pageSize: 3 }}
        />
        <Row type='flex'>
          <Col className='newParticipantName'>
            <Input
              value={participantNameInput}
              onChange={(term) => setParticipantNameInput(term.target.value.toString())}
              size='middle'
              placeholder={t.name}
              allowClear="allowClear"
            />
          </Col>
          <Col className='newParticipantEmail'>
            <Input
              value={participantEmailInput}
              onChange={(term) => setParticipantEmailInput(term.target.value.toString())}
              size='middle'
              placeholder={t.email}
              allowClear="allowClear"
            />
            {
              (participantEmailInput.length > 0 && !EMAIL_REGEXP.test(participantEmailInput))
              ? <Alert className='emailError' message={t.form_error_invalid_email} type="error" showIcon />
              : null
            }
          </Col>
          <Col className='newParticipantPhone'>
            <PhoneInput
              placeholder={ t.phone }
              value={participantPhoneInput}
              onChange={(phone) => setParticipantPhoneInput(phone)}
              country={organizationRegion}
            />
          </Col>
          <Col className='newParticipantButton'>
            <Button
              icon={<PlusOutlined />}
              type='primary'
              disabled={
                !participantNameInput
                || (participantEmailInput && !EMAIL_REGEXP.test(participantEmailInput))
                || (!participantEmailInput && !participantPhoneInput)
              }
              onClick={() => {
                addParticipantToInitiative(participantNameInput, participantEmailInput, participantPhoneInput)
              }}
            >
              {t.participants_addNewParticipant}
            </Button>
          </Col>
        </Row>
      </Modal>
      <UserProfileModal
        slug={userDetail}
        visible={!!userDetail}
        onClose={() => setUserDetail(null)}
      />
    </>
  );
}

AddParticipantsModal.propTypes = {
  error: PropTypes.number,
  initiativeDone: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onAddParticipant: PropTypes.func.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  organizationRegion: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired,
  showAddParticipantModal: PropTypes.bool.isRequired,
  volunteersItems: PropTypes.array.isRequired,
};

export default injectIntl(AddParticipantsModal);