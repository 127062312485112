import React from 'react';
import { injectIntl } from 'react-intl';

import { A } from 'aplanet-ui-kit';

const FeedbackUrl = ({
  intl,
  feedback_url,
  collaboration_status,
}) => {

  const t = intl.messages;

  return (
    !feedback_url || collaboration_status === 'pending' || collaboration_status === 'rejected' ? null :
    <section className='actionFeedback'>
      <div className='actionFeedback-title'>{ t.detail_feedback }</div>
      <section>
        <A className='actionFeedback-link' href={feedback_url} newWindow>{`${t.detail_go_to_survey} →`}</A>
      </section>
    </section>
  )}

export default injectIntl(FeedbackUrl);
