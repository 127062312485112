import { isAfterNow } from 'utils/date';
import iban from 'iban';
import { dateAndTime } from './converters';

const EMAIL_REGEXP = new RegExp('^[a-z0-9._%+-]{1,64}@(?:[a-z0-9-]{1,63}.){1,125}[a-z]{2,63}$');
const URL_REGEXP = new RegExp('(http(s)?:\/\/){1}(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}([-a-zA-Z0-9@:%_+.~#?&//=]*)'); // eslint-disable-line no-useless-escape

// Includes spaces
const getMaxCharDigitRegExp = (maxNumber) =>  new RegExp(`^[a-zA-Z ]{1,${maxNumber}}$`);
const getExactNumDigitRegExp = (number) =>  new RegExp(`^[0-9]{${number}}$`);

const CPF_REGEXP = new RegExp('^([0-9]{3}\.){2}[0-9]{3}-[0-9]{2}$'); // eslint-disable-line no-useless-escape
const CNPJ_REGEXP = new RegExp('^([0-9]{2}(\.([0-9]{3})){2})\/([0-9]){4}\-([0-9]){2}$'); // eslint-disable-line no-useless-escape

const fullValidation = (t, type, formMode, canOverrideDates, maxSDGs, organizationRegion) => ({
  title,
  description,
  sdgs = [],
  start_date,
  start_time,
  end_date,
  end_time,
  hours_per_day,
  address,
  latitude,
  longitude,
  competences = [],
  contact_person,
  contact_email,
  feedback_url,
  participation_method,
  donation_sources = [],
  donation_target_amount,
  donation_min_amount,
  donation_max_amount,
  donation_br_transfer_bank,
  donation_br_transfer_agency,
  donation_br_transfer_cpf,
  donation_br_transfer_cnpj,
  donation_br_transfer_account_first_part,
  donation_br_transfer_account_second_part,
  donation_transfer_recipient,
  donation_transfer_bank,
  donation_transfer_iban,
  donation_external_url,
  donation_inhand_instructions,
  is_in_person,
  is_remote,
  remote_url,
}) => {
  const needsToStartInTheFuture = formMode !== 'edit' && formMode !== 'draft' && !canOverrideDates;
  const needsToEndInTheFuture = formMode !== 'draft' && !canOverrideDates;
  let errors = {};

  // Some fields required for all initiative types (1 of 2)
  if(!title) {
    errors.title = t.form_error_required;
    errors._firstError = errors._firstError || 'title';
  } else if(title.length > 75) {
    errors.title = t.form_error_max_title_length;
    errors._firstError = errors._firstError || 'title';
  }

  if(!description) {
    errors.description = t.form_error_required;
    errors._firstError = errors._firstError || 'title';
  }

  if(sdgs.length > (maxSDGs || 3)) {
    // Poor man's template rendering...
    errors.sdgs = (t.form_error_max_sdgs || '').replace('{num}', maxSDGs || 3);
    errors._firstError = errors._firstError || 'sdgs';
  }

  if(!start_date || !start_time) {
    errors.start_date = t.form_error_required;
    errors._firstError = errors._firstError || 'dates';
  } else if(needsToStartInTheFuture && !isAfterNow(dateAndTime(start_date, start_time))) {
    errors.start_date = t[`form_error_start_date_${formMode}_after_now`];
    errors._firstError = errors._firstError || 'dates';
  }

  if(!end_date || !end_time) {
    errors.end_date = t.form_error_required;
    errors._firstError = errors._firstError || 'dates';
  } else if(needsToEndInTheFuture && !isAfterNow(dateAndTime(end_date, end_time))) {
    errors.end_date = t[`form_error_end_date_${formMode}_after_now`];
    errors._firstError = errors._firstError || 'dates';
  }

  if (['volunteering', 'training', 'informative', 'collection'].includes(type)) {
    if (!is_in_person && !is_remote) {
      errors.is_in_person = t.form_error_required;
      errors._firstError = errors._firstError || 'is_in_person';
    }

    if (is_in_person) {
      if(!address) {
        errors.address = t.form_error_required;
        errors._firstError = errors._firstError || 'address';
      }

      if(typeof latitude != 'number' || typeof longitude != 'number') {
        errors.address = t.form_error_wrong_coords;
        errors._firstError = errors._firstError || 'address';
      }
    }

    if (is_remote && remote_url && !URL_REGEXP.test(remote_url)) {
      errors.remote_url = t.form_error_invalid_url;
      errors._firstError = errors._firstError || 'remote_url';
    }
  }

  // Fields only required for type === 'volunteering' (1 of 2)
  if(type === 'volunteering' || type === 'training') {
    if(!hours_per_day) {
      errors.hours_per_day = t.form_error_required;
      errors._firstError = errors._firstError || 'dates';
    }

    if(competences.length < 1) {
      errors.competences = t.form_error_required;
      errors._firstError = errors._firstError || 'competences';
    }
  }

  // Fields only required for type === 'donation' (1 of 1)
  if(type === 'donation') {
    if(!donation_target_amount) {
      errors.donation_target_amount = t.form_error_required;
      errors._firstError = errors._firstError || 'donation';
    } else {
      if(donation_min_amount && donation_min_amount > donation_target_amount) {
        errors.donation_min_amount = t.form_error_donation_min_amount;
        errors._firstError = errors._firstError || 'donation';
      }
      if(donation_max_amount && donation_max_amount < donation_target_amount) {
        errors.donation_max_amount = t.form_error_donation_max_amount;
        errors._firstError = errors._firstError || 'donation';
      }
    }
    if(!donation_sources || donation_sources.length < 1) {
      errors.donation_sources = t.form_error_required;
      errors._firstError = errors._firstError || 'donation';
    } else {
      // We do have donation sources let's check them
      if(donation_sources.includes('transfer') && organizationRegion !== 'BR') {
        if(!donation_transfer_recipient) {
          errors.donation_transfer_recipient = t.form_error_required;
          errors._firstError = errors._firstError || 'donation';
        }
        if(!donation_transfer_bank) {
          errors.donation_transfer_bank = t.form_error_required;
          errors._firstError = errors._firstError || 'donation';
        }
        if(!donation_transfer_iban) {
          errors.donation_transfer_iban = t.form_error_required;
          errors._firstError = errors._firstError || 'donation';
        } else if(!iban.isValid(donation_transfer_iban)) {
          errors.donation_transfer_iban = t.form_error_donation_iban;
          errors._firstError = errors._firstError || 'donation';
        }
      }

      if(donation_sources.includes('transfer') && organizationRegion === 'BR') {
        if(!donation_br_transfer_bank) {
          errors.donation_br_transfer_bank = t.form_error_required;
          errors._firstError = errors._firstError || 'donation';
        }

        if(donation_br_transfer_bank && !getMaxCharDigitRegExp(20).test(donation_br_transfer_bank)) {
          errors.donation_br_transfer_bank = t.form_error_donation_bank;
          errors._firstError = errors._firstError || 'donation';
        }

        if(!donation_br_transfer_cpf && !donation_br_transfer_cnpj) {
          errors.donation_br_transfer_cpf = t.form_error_invalid_cpf_cnpj;
          errors.donation_br_transfer_cnpj = t.form_error_invalid_cpf_cnpj;
          errors._firstError = errors._firstError || 'donation';
        }

        if(donation_br_transfer_cpf && !CPF_REGEXP.test(donation_br_transfer_cpf)) {
          errors.donation_br_transfer_cpf = t.form_error_invalid_cpf;
          errors._firstError = errors._firstError || 'donation';
        }

        if(donation_br_transfer_cnpj && !CNPJ_REGEXP.test(donation_br_transfer_cnpj)) {
          errors.donation_br_transfer_cnpj = t.form_error_invalid_cnpj;
          errors._firstError = errors._firstError || 'donation';
        }

        if(donation_br_transfer_agency && !getExactNumDigitRegExp(4).test(donation_br_transfer_agency)) {
          errors.donation_br_transfer_agency = t.form_error_donation_agency;
          errors._firstError = errors._firstError || 'donation';
        }

        if(!donation_br_transfer_account_first_part) {
          errors.donation_br_transfer_account = t.form_error_required;
          errors._firstError = errors._firstError || 'donation';
        }

        if(!donation_br_transfer_account_second_part) {
          errors.donation_br_transfer_account = t.form_error_required;
          errors._firstError = errors._firstError || 'donation';
        }

        if(donation_br_transfer_account_first_part && !getExactNumDigitRegExp(5).test(donation_br_transfer_account_first_part)) {
          errors.donation_br_transfer_account = t.form_error_donation_account;
          errors._firstError = errors._firstError || 'donation';
        }

        if(donation_br_transfer_account_second_part && !getExactNumDigitRegExp(1).test(donation_br_transfer_account_second_part)) {
          errors.donation_br_transfer_account = t.form_error_donation_account;
          errors._firstError = errors._firstError || 'donation';
        }
      }

      if(donation_sources.includes('external') && !URL_REGEXP.test(donation_external_url)) {
        errors.donation_external_url = t.form_error_donation_link;
        errors._firstError = errors._firstError || 'donation';
      }
      if(donation_sources.includes('inhand') && !donation_inhand_instructions) {
        errors.donation_inhand_instructions = t.form_error_required;
        errors._firstError = errors._firstError || 'donation';
      }
    }
  }

  // More fields required for every type (2 of 2)
  if(!contact_person) {
    errors.contact_person = t.form_error_required;
    errors._firstError = errors._firstError || 'contact';
  }

  if(!contact_email) {
    errors.contact_email = t.form_error_required;
    errors._firstError = errors._firstError || 'contact';
  } else if(!EMAIL_REGEXP.test(contact_email)) {
    errors.contact_email = t.form_error_invalid_email;
    errors._firstError = errors._firstError || 'contact';
  }

  // Fields only required for type === 'volunteering' (2 of 2)
  if(type === 'volunteering' || type === 'training') {
    if(feedback_url && !URL_REGEXP.test(feedback_url)) {
      errors.feedback_url = t.form_error_feedback_url;
      errors._firstError = errors._firstError || 'feedback_url';
    }
    if(!participation_method) {
      errors.participation_method = t.form_error_required;
      errors._firstError = errors._firstError || 'participation_method';
    }
  }

  return errors;
};

export default fullValidation;
