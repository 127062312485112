import React from 'react';
import { injectIntl } from 'react-intl';

const Categories = ({ intl, categories = [] }) => {
  const t = intl.messages;
  return (
    categories.length < 1 ? null :
    <div className='actionCategories'>
      <div className='actionCategories-title'>{ t.categories }</div>
        {categories.map((category, index) => (
          <div key={category} className="actionCategories-category">
            { t[`category_${category}`]}
          </div>
        ))
        }
    </div>
  );
};

export default injectIntl(Categories);
