import React, { useEffect, useState, useMemo } from 'react';
import { OpenStreetMapProvider, GoogleProvider } from 'leaflet-geosearch';

import MapWithPins from 'components/MapWithPins';
import config from 'config';
import { debounce } from 'utils/debounce';

import { Form, Select } from 'antd';

import './style.css';

const provider = config.GOOGLE_MAPS_KEY
  ? new GoogleProvider({
    params: {
      key: config.GOOGLE_MAPS_KEY,
    },
  })
  : new OpenStreetMapProvider({});

const SEARCH_DEBOUNCE = 500; // 0.5 seconds

const DEFAULT_LAT = 40.4083539;
const DEFAULT_LON = -3.692422;
const DEFAULT_ZOOM = 18;

const AddressSearch = ({
  organization = {},
  addressValue,
  latitude,  // WARNING: only used for initial position
  longitude, // WARNING: only used for initial position
  onChangeLocation,
  error,
  placeholder = 'Search...',
  label = null,
}) => {
  const [position, setPosition] = useState({
    latitude: latitude || organization.default_latitude || DEFAULT_LAT,
    longitude: longitude || organization.default_longitude || DEFAULT_LON,
    zoom: DEFAULT_ZOOM,
    text: '',
  });

  useEffect(() => {
    // Monkey patching FTW
    // This only works on Google Maps
    const params = provider.options.params;
    provider.options.params = { ...params, region: (organization.region || '') };
  }, [organization]);

  const [results, setResults] = useState([]);

  const doChooseAddress = async (result) => {
    try {
      const { x, y, label } = JSON.parse(result);
      const newPosition = { ...position, latitude: y, longitude: x, text: label };
      setPosition(newPosition);
      onChangeLocation(newPosition);
    } catch(err) {
    }
  };

  const doSearch = useMemo(() => async (query) => {
    if(!query) return;
    const results = await provider.search({ query });
    setResults(results);
  }, []);

  const onSearch = useMemo(() => debounce(doSearch, SEARCH_DEBOUNCE), [ doSearch ]);

  const pins = position.text ? [position] : [];
  return (
    <div>
      <Form.Item
        label={label}
        hasFeedback
        validateStatus={ error ? 'error' : '' }
        help={error}
      >
        <Select
          showSearch
          value={addressValue || undefined}
          placeholder={placeholder}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={onSearch}
          notFoundContent={null}
          onSelect={doChooseAddress}
        >
          {results.map((r, index) => <Select.Option value={JSON.stringify(r)} key={index}>{r.label}</Select.Option>)}
        </Select>
      </Form.Item>
      <MapWithPins
        latitude={position.latitude}
        longitude={position.longitude}
        zoom={position.zoom}
        pins={pins}
      />
    </div>
  );
}

export default AddressSearch;
