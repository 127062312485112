import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import config from 'config';
import OfficialImages from 'components/InitiativeImages/Official';
import UserImages from 'components/InitiativeImages/User';
import {
  requestInitiativeAttachments,
  updateInitiativeAttachment,
  deleteInitiativeAttachment,
  updateInitiative,
} from 'actions/api';
import { addNewAttachmentImage } from 'actions/initiative';

import {
  Row,
  Col,
  Tabs,
} from 'antd';
import Loading from 'containers/Loading';


const InitiativeImages = ({
  intl,
  organization,
  initiative,
  fetching,
  images,
  requestInitiativeAttachments,
  addNewAttachmentImage,
  updateInitiativeAttachment,
  deleteInitiativeAttachment,
  updateInitiative,
}) => {
  const t = intl.messages;

  useEffect(
    () => {
      requestInitiativeAttachments(
        'image', organization.slug, initiative.slug
      );
    },
    [ organization, initiative, requestInitiativeAttachments ]
  );

  const officialImages = useMemo(
    () => images.filter(image => image.status === 'published'),
    [images]
  );
  const userImages = useMemo(
    () => images.filter(
      image => !image.is_official && image.status === 'draft'
    ),
    [images]
  );

  const uploadURL = useMemo(
    () => `${config.API_URL}/${organization.slug}/initiative/${initiative.slug}/attachment/image`,
    [organization, initiative]
  );

  const handleOnUploadSuccess = useCallback(
    (image) => addNewAttachmentImage(image),
    [addNewAttachmentImage]
  );

  const handleOnDelete = useCallback(
    (imageId) => deleteInitiativeAttachment(
      'image', organization.slug, initiative.slug, imageId
    ),
    [organization, initiative, deleteInitiativeAttachment]
  );

  const handleOnApprove = useCallback(
    (imageId) => updateInitiativeAttachment(
      'image', organization.slug, initiative.slug, imageId,
      { status: 'published' },
    ),
    [organization, initiative, updateInitiativeAttachment]
  );

  const handleOnUnpublish = useCallback(
    (imageId) => updateInitiativeAttachment(
      'image', organization.slug, initiative.slug, imageId,
      { status: 'draft' },
    ),
    [organization, initiative, updateInitiativeAttachment]
  );

  const handleOnChangeCover = useCallback(
    (image) => updateInitiative(
      organization.slug, initiative.slug, { image },
    ),
    [organization, initiative, updateInitiative]
  );

  return (
    <>
      { fetching
        ? <Row type="flex" justify="center">
            <Loading.Block />
          </Row>
        : <Row>
            <Col span={24}>
              <Tabs
                defaultActiveKey="official"
              >
                <Tabs.TabPane tab={t.initiative_images_official} key="official">
                  <OfficialImages
                    images={officialImages}
                    uploadURL={uploadURL}
                    onUploadSuccess={handleOnUploadSuccess}
                    onDelete={handleOnDelete}
                    onUnpublish={handleOnUnpublish}
                    onChangeCover={handleOnChangeCover}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t.initiative_images_user} key="user">
                  <UserImages
                    images={userImages}
                    onApprove={handleOnApprove}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
      }
    </>
  );
}

const mapStateToProps = ({
  initiative_images,
}) => ({
  fetching: initiative_images.fetching,
  images: initiative_images.items,
});

export default connect(
  mapStateToProps,
  {
    requestInitiativeAttachments,
    addNewAttachmentImage,
    updateInitiativeAttachment,
    deleteInitiativeAttachment,
    updateInitiative,
  }
)(injectIntl(InitiativeImages))
