import React from 'react';

const Questions = ({
  questions: questionObj = {},
}) => {
  const questions = Object.keys(questionObj);

  return (
    questions.length < 1 ? null :
      <React.Fragment>
        {
          questions.map(question => (
            <section
              key={question}
              className='actionQuestions'
            >
              <h4 className='actionQuestions-title'>{ question }</h4>
                <ul>
                  {
                    (questionObj[question] || []).map((name) => (
                      <li
                        key={name}
                        className="actionVolunteeringTags-item"
                      >
                        { name || '' }
                      </li>
                    ))
                  }
                </ul>
            </section>
          ))
        }
      </React.Fragment>
  );
};

export default Questions;
