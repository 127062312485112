import intl from 'intl';
import { addLocaleData } from 'react-intl';
import { updateIntl } from 'react-intl-redux';

// Locale Data
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import locale_eu from 'react-intl/locale-data/eu';
import locale_pt from 'react-intl/locale-data/pt';

// Date-fns locale
import dateFns_en from "date-fns/locale/en-US";
import dateFns_es from "date-fns/locale/es";
import dateFns_eu from "date-fns/locale/eu"; // There's no eu
import dateFns_pt from "date-fns/locale/pt";

// This is a polyfill
import 'intl/locale-data/jsonp/en.js';
import 'intl/locale-data/jsonp/es.js';
import 'intl/locale-data/jsonp/eu.js';
import 'intl/locale-data/jsonp/pt.js';

// Messages
import messages_industry_es_ES from './industry/es-ES.json';
import messages_industry_eu_ES from './industry/eu-ES.json';
import messages_industry_en_US from './industry/en-US.json';
import messages_industry_pt_BR from './industry/pt-BR.json';
import messages_industry_pt_PT from './industry/pt-PT.json';

import messages_sector_es_ES from './sector/es-ES.json';
import messages_sector_eu_ES from './sector/eu-ES.json';
import messages_sector_en_US from './sector/en-US.json';
import messages_sector_pt_BR from './sector/pt-BR.json';
import messages_sector_pt_PT from './sector/pt-PT.json';

import messages_es_ES from './es-ES.json';
import messages_eu_ES from './eu-ES.json';
import messages_en_US from './en-US.json';
import messages_pt_BR from './pt-BR.json';
import messages_pt_PT from './pt-PT.json';

// Ant Design
import antd_es from 'antd/lib/locale-provider/es_ES';
import antd_eu from '../locale/antd/eu'; // There's no eu_ES
import antd_en from 'antd/lib/locale-provider/en_US';
import antd_ptbr from 'antd/lib/locale-provider/pt_BR';
import antd_ptpt from 'antd/lib/locale-provider/pt_PT';

// DatePicker
import datePicker_es from 'antd/lib/date-picker/locale/es_ES';
import datePicker_eu from 'antd/lib/date-picker/locale/es_ES'; // There's no eu_ES
import datePicker_en from 'antd/lib/date-picker/locale/en_US';
import datePicker_ptbr from 'antd/lib/date-picker/locale/pt_BR';
import datePicker_ptpt from 'antd/lib/date-picker/locale/pt_PT';

// react-phone-number-input locale
import reactPhoneNumber_en from 'react-phone-number-input/locale/en';
import reactPhoneNumber_es from 'react-phone-number-input/locale/es';
import reactPhoneNumber_pt from 'react-phone-number-input/locale/pt';
import reactPhoneNumber_br from 'react-phone-number-input/locale/br';

// Moment
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/eu';
import 'moment/locale/pt';
import 'moment/locale/pt-br';

//Custom moment for eu locale
import moment_eu from '../locale/moment/eu'; 
moment.updateLocale('eu', moment_eu);

const locales = [
  ...locale_en,
  ...locale_es,
  ...locale_eu,
  ...locale_pt,
];

const REACT_PHONE_NUMBER_PREFIX='react_phone_number_';
const replaceReactPhoneNumberLocales = (base) => (all) => {
  const replacements = Object
    .keys(all)
    .filter(key => key.startsWith(REACT_PHONE_NUMBER_PREFIX))
    .map(key => [key.slice(REACT_PHONE_NUMBER_PREFIX.length), all[key]])
    .reduce((obj, [key, val]) => { obj[key] = val; return obj }, {});

  return {
    ...base,
    ...replacements,
  };
};

const en_US = {
  locale: 'en-US',
  messages: {
    ...messages_en_US,
    industry: messages_industry_en_US,
    sector: messages_sector_en_US,
  },
  formats: {
    antd: antd_en,
    datePicker: datePicker_en,
    dateFns: dateFns_en,
    reactPhoneNumber: replaceReactPhoneNumberLocales(reactPhoneNumber_en)(messages_en_US),
  },
};

const es_ES = {
  locale: 'es-ES',
  messages: {
    ...messages_es_ES,
    industry: messages_industry_es_ES,
    sector: messages_sector_es_ES,
  },
  formats: {
    antd: antd_es,
    datePicker: datePicker_es,
    dateFns: dateFns_es,
    reactPhoneNumber: replaceReactPhoneNumberLocales(reactPhoneNumber_es)(messages_es_ES),
  },
};

const eu_ES = {
  locale: 'eu-ES',
  messages: {
    ...messages_eu_ES,
    industry: messages_industry_eu_ES,
    sector: messages_sector_eu_ES,
  },
  formats: {
    antd: antd_eu,
    datePicker: datePicker_eu,
    dateFns: dateFns_eu,
    reactPhoneNumber: replaceReactPhoneNumberLocales(reactPhoneNumber_es)(messages_eu_ES),
  },
};

const pt_BR = {
  locale: 'pt-BR',
  messages: {
    ...messages_pt_BR,
    industry: messages_industry_pt_BR,
    sector: messages_sector_pt_BR,
  },
  formats: {
    antd: antd_ptbr,
    datePicker: datePicker_ptbr,
    dateFns: dateFns_pt,
    reactPhoneNumber: replaceReactPhoneNumberLocales(reactPhoneNumber_br)(messages_pt_BR),
  },
};

const pt_PT = {
  locale: 'pt-PT',
  messages: {
    ...messages_pt_PT,
    industry: messages_industry_pt_PT,
    sector: messages_sector_pt_PT,
  },
  formats: {
    antd: antd_ptpt,
    datePicker: datePicker_ptpt,
    dateFns: dateFns_pt,
    reactPhoneNumber: replaceReactPhoneNumberLocales(reactPhoneNumber_pt)(messages_pt_PT),
  },
};

const config = {
  'en': {
    default: en_US,
    'en-US': en_US,
  },
  'es': {
    default: es_ES,
    'es-ES': es_ES,
  },
  'eu': {
    default: eu_ES,
    'eu-ES': eu_ES,
  },
  'pt': {
    default: pt_BR,
    'pt-BR': pt_BR,
    'pt-PT': pt_PT,
  },
  default: {
    default: en_US,
  },
};

const defaultLocale = 'en-US';

const getConfig = (locale = defaultLocale) => {
  const langConfig = config[locale.slice(0, 2)] || config.default;
  return langConfig[locale] || langConfig.default;
};

const setupIntl = () => {
  if (typeof window.Intl === 'undefined') window.Intl = intl;
  addLocaleData(locales);
  const config = getConfig(navigator.language || navigator.userLanguage);
  moment.locale(config.locale);

  return config;
};

const changeLocale = (locale, adminIntl={}) => {
  const config = getConfig(locale);
  moment.locale(config.locale);

  const customTranslation = adminIntl[config.locale] || {};

  return updateIntl({
    ...config,
    messages: {
      ...config.messages,
      ...customTranslation
    }
  });
};

export {
  setupIntl,
  changeLocale,
};

