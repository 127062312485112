const TARGET = 'initiative_images';

const initialState = {
  error: null,
  fetching: false,
  pushing: false,
  items: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
        pushing: action.method === 'PUT' || action.method === 'POST',
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          return {
            ...state,
            fetching: false,
            items: result,
          };
        case 'POST':
          return {
            ...state,
            pushing: false,
            items: [...state.items, result],
          };
        case 'PUT': {
          const index = state.items.map(
            item => item.id
          ).indexOf(parseInt(result.id));
          return {
            ...state,
            pushing: false,
            items: [
              ...state.items.slice(0, index),
              result,
              ...state.items.slice(index + 1)
            ],
          };
        }
        case 'DELETE': {
          const index = state.items.map(
            item => item.id
          ).indexOf(parseInt(result.id));
          return {
            ...state,
            pushing: false,
            items: [
              ...state.items.slice(0, index),
              ...state.items.slice(index + 1)
            ],
          };
        }
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code,
      };
    case 'INITIATIVE_ATTACHMENT_ADD_NEW_IMAGE':
      return {
        ...state,
        pushing: false,
        items: [...state.items, action.image],
      };
    default:
      return state;
  }
};

export {
  reducer as initiative_images,
};
