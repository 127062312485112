const COURSE_DETAIL_TARGET  = 'course_detail';
const TAXONOMIES_COURSE_TARGET = 'taxonomies_course'
const ID_FIELD = 'id'; // TODO: Change to slug if we ever stop exposing numerical ids

const isTarget = (target) => [COURSE_DETAIL_TARGET, TAXONOMIES_COURSE_TARGET].includes(target);
const isTaxonomiesCourseTarget = (target) => target === TAXONOMIES_COURSE_TARGET // GET / POST / DELETE
const isCourseDetailTarget = (target) => target === COURSE_DETAIL_TARGET // GET / POST / DELETE

const initialState = {
  data: null,
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        data: action.method === 'GET' ? null : state.data,
        loading: true,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      // Edit course
      if(isTaxonomiesCourseTarget(action.response.target) && ['PUT'].includes(action.response.method)){
        return {
          data: {
            ...state.data,
            name: action.response.result.name,
            params: action.response.result.params,
            slug: action.response.result.slug,
          },
          loading: false,
          error: false,
        }
      }

      // Close course
      if(isTaxonomiesCourseTarget(action.response.target) && ['DELETE'].includes(action.response.method)){
        return {
          data: action.response.result,
          loading: false,
          error: false,
        }
      }

      // Get course detail
      if(isCourseDetailTarget(action.response.target) && ['GET'].includes(action.response.method)){
        return {
          data: action.response.result,
          loading: false,
          error: false,
        }
      }

      // Teacher, student or initiative added
      if(isCourseDetailTarget(action.response.target) && ['PUT'].includes(action.response.method)){
        const role = `${action.response.endpoint.split('/')[3]}`
        const roles = `${role}s`
        return {
          data: {
            ...state.data,
            [roles]: [...state.data[roles], action.response.result[role]]
          },
          loading: false,
          error: false,
        }
      }

      // Teacher, student or initiative removed
      if(isCourseDetailTarget(action.response.target) && ['DELETE'].includes(action.response.method)){

        const role = `${action.response.endpoint.split('/')[3]}`
        const roles = `${role}s`

        if(!action.response.result[role]) return state

        const index = (state.data[roles] || []).findIndex((item) => item[ID_FIELD] === action.response.result[role][ID_FIELD]);
        return {
          data: {
            ...state.data,
            [roles]: [...state.data[roles].slice(0, index), ...state.data[roles].slice(index+1)]
          },
          loading: false,
          error: false,
        }
      }

      return state

    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: action.code
      };
    default:
      return state;
  }
};

export {
  reducer as course_detail,
};
