import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const PROFILE_ENDPOINT_REGEX = new RegExp('^[a-z0-9_-]+/profile$');
const initialState = null;

const reducer = (state = initialState, action) => {
  if(
    action.type === 'API_CALL_FAILED' &&
    action.code === 402 &&
    action.request &&
    (
      PROFILE_ENDPOINT_REGEX.test(action.request.endpoint) &&
      action.request.method === 'GET'
    )
  )
  {
    return loop(state, Cmd.run(requestPush, { args: [`/not_subscribed`] }));
  }
  return state;
};

export {
  reducer as profile_request_failed,
};
