import config from 'config';
import objectToQueryParameters from 'utils/queryParameters';


const getVolunteersDownloadUrl = (organization_slug, slug) => {
  return `${config.API_URL}/${organization_slug}/download/volunteer`;
};

const getParticipantsDownloadUrl = (organization_slug, slug) => {
  return `${config.API_URL}/${organization_slug}/initiative/${slug}/download-participants`;
};

const getInitiativesDownloadUrl = (organization_slug) => {
  return `${config.API_URL}/${organization_slug}/download/initiative`;
};

const getInitiativesParticipationDownloadUrl = (organization_slug) => {
  return `${config.API_URL}/${organization_slug}/download/initiative-participation`;
};

const requestDashboard = (organization_slug, filters) => {
  const endpoint = filters.length === 0
    ?
      `${organization_slug}/dashboard_info`
    :
      `${organization_slug}/dashboard_info?${objectToQueryParameters(filters)}`

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard',
    method: 'GET',
    endpoint,
    body: null,
  };
};

const requestInitiatives = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiatives',
  method: 'GET',
  endpoint: `${organization_slug}/initiative`,
  body: null,
});

const requestInitiativeDetail = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'GET',
  endpoint: `${organization_slug}/initiative/${slug}`,
  body: null,
});

const requestOrganizationTree = organization_slug => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_tree',
  method: 'GET',
  endpoint: `${organization_slug}/organization_tree`,
  body: null
});

const requestProfile = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'profile',
  method: 'GET',
  endpoint: `${organization_slug}/profile`,
  body: null,
});

const requestTaxonomies = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies',
  method: 'GET',
  endpoint: `${organization_slug}/taxonomy`,
  body: null,
});

const requestOrgVolunteers = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteers',
  method: 'GET',
  endpoint: `${organization_slug}/volunteer`,
  body: null,
});

const inviteVolunteer = (organization_slug, name, email, phone) => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteer_detail',
  method: 'POST',
  endpoint: `${organization_slug}/volunteer`,
  body: {
    name,
    email,
    phone
  },
});

const reinviteVolunteer = (organization_slug, member_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteer_detail',
  method: 'POST',
  endpoint: `${organization_slug}/volunteer/${member_slug}/invitation`,
  body: null,
});

const blockVolunteer = (organization_slug, member_slug, block_reason) => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteer_block',
  method: 'PUT',
  endpoint: `${organization_slug}/volunteer/${member_slug}/block`,
  body: {
    block_reason,
  },
});

const deleteVolunteer = (organization_slug, member_slug, anonymize) => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteer_delete',
  method: 'PUT',
  endpoint: `${organization_slug}/volunteer/${member_slug}/delete`,
  body: {
    anonymize
  }
});

const unblockVolunteer = (organization_slug, member_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteer_block',
  method: 'DELETE',
  endpoint: `${organization_slug}/volunteer/${member_slug}/block`,
  body: null,
});

const assignManager = (organization_slug, member_slug, name, email, phone, action = 'assign') => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteer_manager',
  method: 'PUT',
  endpoint: `${organization_slug}/volunteer/${member_slug}/manager`,
  body: {
    name,
    email,
    phone,
    action,
  },
});

const rejectParticipant = (organization_slug, initiative_slug, member_slug, reject_reason) => ({
  type: 'API_CALL_REQUEST',
  target: 'participation_reject',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/${member_slug}/reject`,
  body: {
    reject_reason,
  },
});

const unrejectParticipant = (organization_slug, initiative_slug, member_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'participation_reject',
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/${member_slug}/reject`,
  body: null,
});

const setGrantedHours = (organization_slug, initiative_slug, participant_id, granted_hours = 0) => ({
  type: 'API_CALL_REQUEST',
  target: 'participation_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/participant/${participant_id}`,
  body: {
    granted_hours
  },
});

const setGrantedPoints = (organization_slug, initiative_slug, participant_id, points = 0) => ({
  type: 'API_CALL_REQUEST',
  target: 'participation_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/participant/${participant_id}`,
  body: {
    points
  },
});

const validateParticipation = (organization_slug, initiative_slug, participant_id, participation_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'participation_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/participant/${participant_id}/${participation_id}`,
  participationValidation: true,
  body: null,
});

const rejectParticipation = (organization_slug, initiative_slug, participant_id, participation_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'participation_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/participant/${participant_id}/${participation_id}`,
  body: null,
});

const createInitiative = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'POST',
  endpoint: `${organization_slug}/initiative`,
  body, // TODO: This body definitely needs to be adapted
});

const updateInitiative = (organization_slug, initiative_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}`,
  body, // TODO: This body definitely needs to be adapted
});

const updatePinnedFeaturedInitiative = (organization_slug, initiative_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/pinned_featured`,
  body
});

const cancelInitiative = (organization_slug, initiative_slug, reject_reason, delete_draft, status) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/delete`,
  body: {
    reject_reason,
    delete_draft,
    status,
  },
});

const broadcastMessage = (organization_slug, initiative_slug, text, type, participant_ids) => ({
  type: 'API_CALL_REQUEST',
  target: 'broadcast_message',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/broadcast`,
  body: {
    text,
    type,
    participant_ids
  },
});

const sendMessage = (organization_slug, text, type, member_slugs) => ({
  type: 'API_CALL_REQUEST',
  target: 'broadcast_message',
  method: 'POST',
  endpoint: `${organization_slug}/volunteer/broadcast`,
  body: {
    text,
    type,
    member_slugs
  },
})

const addParticipant = (organization_slug, initiative_slug, name, email, phone) => ({
  type: 'API_CALL_REQUEST',
  target: 'participant_new',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/participant`,
  body: {
    name,
    email,
    phone
  },
});

const resetBroadcastMessage = () => ({
  type: 'API_CALL_RESET',
  target: 'broadcast_message',
});

const setOrganizationCohorts = (organization_slug, cohorts, members) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies',
  method: 'PUT',
  endpoint: `${organization_slug}/taxonomy/cohort`,
  body: {
    cohorts,
    members
  },
});

const setOrganizationCohort = (organization_slug, cohort_slug, name) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies_cohort',
  method: 'PUT',
  endpoint: `${organization_slug}/taxonomy/cohort/${cohort_slug}`,
  body: {
    name
  }
})

const setMemberCohorts = (organization_slug, member_slug, cohorts) => ({
  type: 'API_CALL_REQUEST',
  target: 'volunteer_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/volunteer/${member_slug}`,
  body: {
    cohorts
  },
});

const requestRankings = (organization_slug, filters) => ({
  type: 'API_CALL_REQUEST',
  target: 'rankings',
  method: 'GET',
  endpoint: `${organization_slug}/ranking?${objectToQueryParameters(filters)}`,
  body: null,
});

const requestProposals = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'proposals',
  method: 'GET',
  endpoint: `${organization_slug}/proposal`,
  body: null,
});

const rejectProposal = (organization_slug, initiative_slug, reject_reason) => ({
  type: 'API_CALL_REQUEST',
  target: 'proposal_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/delete`,
  body: {
    reject_reason,
  },
});

const requestCollaborativeInitiatives = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'collaborations',
  method: 'GET',
  endpoint: `${organization_slug}/collaboration`,
  body: null,
});

const requestCollaboration = (
  organization_slug,
  initiative_slug,
  email,
  cohorts,
  is_working_hours,
  program,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'POST',
  endpoint: `${organization_slug}/collaboration/${initiative_slug}`,
  body: {
    email,
    cohorts,
    is_working_hours,
    program,
  },
});

const updateCollaboration = (
  organization_slug,
  initiative_slug,
  email,
  cohorts,
  is_working_hours,
  program,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/collaboration/${initiative_slug}`,
  body: {
    email,
    cohorts,
    is_working_hours,
    program,
  },
});

const cancelCollaborationRequest = (organization_slug, initiative_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/collaboration/${initiative_slug}`,
  body: null,
});

const stopCollaboration = (organization_slug, initiative_slug, collaborating_org_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/collaboration/${initiative_slug}/${collaborating_org_slug}`,
  body: null,
});

const acceptCollaborator = (organization_slug, initiative_slug, collaborating_org_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/collaboration/${initiative_slug}/${collaborating_org_slug}`,
  body: null,
});

const rejectCollaborator = (organization_slug, initiative_slug, collaborating_org_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/collaboration/${initiative_slug}/${collaborating_org_slug}`,
  body: null,
});

const createIndicator = (organization_slug, initiative_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'indicator_detail',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/indicator`,
  body,
});

const updateIndicator = (organization_slug, initiative_slug, indicator_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'indicator_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/indicator/${indicator_id}`,
  body,
});

const updateIndicatorParticipation = (organization_slug, initiative_slug, indicator_id, participation_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'indicator_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/indicator/${indicator_id}/participation/${participation_id}`,
  body,
  indicatorValidation: true,
});

const validateContribution = (organization_slug, initiative_slug, contribution_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'contribution_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/contribution/${contribution_id}`,
  body: null,
});

const rejectContribution = (organization_slug, initiative_slug, contribution_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'contribution_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/contribution/${contribution_id}`,
  body: null,
});

const addContribution = (organization_slug, initiative_slug, name, email, phone, amount, source, anonymous, comment) => ({
  type: 'API_CALL_REQUEST',
  target: 'contribution_detail',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/contribution`,
  body: {
    name,
    email,
    phone,
    amount,
    source,
    anonymous,
    comment,
  },
});

const requestOrgCourses = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies_course',
  method: 'GET',
  endpoint: `${organization_slug}/course`,
  body: null,
});

const requestCourseDetail = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'course_detail',
  method: 'GET',
  endpoint: `${organization_slug}/course/${slug}`,
  body: null,
});

const createCourse = (organization_slug, name, code) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies_course',
  method: 'POST',
  endpoint: `${organization_slug}/course`,
  body: {
    name,
    code,
  },
});

const editCourse = (organization_slug, course_slug, name, code) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies_course',
  method: 'PUT',
  endpoint: `${organization_slug}/course/${course_slug}`,
  body: {
    name,
    code,
  },
});

const closeCourse = (organization_slug, course_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies_course',
  method: 'DELETE',
  endpoint: `${organization_slug}/course/${course_slug}`,
  body: null,
});

const addTeacherToCourse = (organization_slug, course_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'course_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/course/${course_slug}/teacher/${slug}`,
  body: null,
});

const removeTeacherFromCourse = (organization_slug, course_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'course_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/course/${course_slug}/teacher/${slug}`,
  body: null,
});

const addStudentToCourse = (organization_slug, course_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'course_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/course/${course_slug}/student/${slug}`,
  body: null,
});

const removeStudentFromCourse = (organization_slug, course_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'course_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/course/${course_slug}/student/${slug}`,
  body: null,
});

const addInitiativeToCourse = (organization_slug, course_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'course_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/course/${course_slug}/initiative/${slug}`,
  body: null,
});

const removeInitiativeFromCourse = (organization_slug, course_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'course_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/course/${course_slug}/initiative/${slug}`,
  body: null,
});

const requestUserDetail = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'user_detail',
  method: 'GET',
  endpoint: `${organization_slug}/volunteer/${slug}`,
  body: null,
});

const requestUserFromCollaboratorDetail = (organization_slug, organization_collaborator_slug, user_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'user_detail',
  method: 'GET',
  endpoint: `${organization_slug}/collaboration/${organization_collaborator_slug}/volunteer/${user_slug}`,
  body: null,
});

const resetUserDetail = () => ({
  type: 'API_CALL_RESET',
  target: 'user_detail',
});

const requestGenerateResponsibleProfilePDF = (organization_slug, slug, organization_collaborator_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'user_detail_pdf',
  method: 'PUT',
  endpoint: `${organization_slug}/volunteer/${slug}/pdf`,
  body: {
    // NOTICE: This is a workaround, we need to implement proper
    //         TZ support in initiatives
    organization_collaborator_slug,
    timezone_offset: (new Date()).getTimezoneOffset(),
  },
});

const addInitiativeTag = (
  organization_slug,
  initiative_slugs = [],
  tag,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_meta',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slugs}/meta`,
  body: {
    add_tags: [ tag ],
    initiative_slugs,
  },
  keep: true,
});

const removeInitiativeTag = (
  organization_slug,
  initiative_slugs = [],
  tag,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_meta',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slugs}/meta`,
  body: {
    remove_tags: [ tag ],
    initiative_slugs,
  },
  keep: true,
});

const addIndicatorParticapation = (organization_slug, initiative_slug, indicator_id, indicatorResponse) => ({
  type: 'API_CALL_REQUEST',
  target: 'indicator_detail',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/indicator/${indicator_id}/participation`,
  body: indicatorResponse,
});

const requestInitiativeAttachments = (type, organization_slug, initiative_slug) => ({
  type: 'API_CALL_REQUEST',
  target: `initiative_${type}s`,
  method: 'GET',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/attachment/${type}`,
  body: null,
});

const updateInitiativeAttachment = (type, organization_slug, initiative_slug, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: `initiative_${type}s`,
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/attachment/${type}/${id}`,
  body,
});

const deleteInitiativeAttachment = (type, organization_slug, initiative_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: `initiative_${type}s`,
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/attachment/${type}/${id}`,
  body: null,
});

const sendParticipantCertificate = (organization_slug, initiative_slug, participant_ids) => ({
  type: 'API_CALL_REQUEST',
  target: 'participant_certificate',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${initiative_slug}/send_certificate`,
  body: {
    participant_ids,
  },
});

const updateOrganizationConfig = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization',
  method: 'PUT',
  endpoint: `${organization_slug}/config`,
  body,
});

const updateProfile = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'profile',
  method: 'PUT',
  endpoint: `${organization_slug}/profile`,
  body,
});

export {
  requestDashboard,
  requestInitiatives,
  requestInitiativeDetail,
  requestOrganizationTree,
  requestProfile,
  requestTaxonomies,
  requestOrgVolunteers,
  blockVolunteer,
  unblockVolunteer,
  deleteVolunteer,
  assignManager,
  rejectParticipant,
  unrejectParticipant,
  validateParticipation,
  rejectParticipation,
  updateInitiative,
  createInitiative,
  cancelInitiative,
  broadcastMessage,
  resetBroadcastMessage,
  setOrganizationCohorts,
  setMemberCohorts,
  requestRankings,
  requestProposals,
  rejectProposal,
  requestCollaborativeInitiatives,
  requestCollaboration,
  updateCollaboration,
  cancelCollaborationRequest,
  stopCollaboration,
  acceptCollaborator,
  rejectCollaborator,
  createIndicator,
  updateIndicator,
  updateIndicatorParticipation,
  inviteVolunteer,
  reinviteVolunteer,
  setOrganizationCohort,
  setGrantedHours,
  setGrantedPoints,
  addParticipant,
  validateContribution,
  rejectContribution,
  addContribution,
  sendMessage,
  requestOrgCourses,
  requestCourseDetail,
  createCourse,
  closeCourse,
  addTeacherToCourse,
  removeTeacherFromCourse,
  addStudentToCourse,
  removeStudentFromCourse,
  addInitiativeToCourse,
  removeInitiativeFromCourse,
  editCourse,
  requestUserDetail,
  requestUserFromCollaboratorDetail,
  resetUserDetail,
  requestGenerateResponsibleProfilePDF,
  addInitiativeTag,
  removeInitiativeTag,
  addIndicatorParticapation,
  requestInitiativeAttachments,
  updateInitiativeAttachment,
  deleteInitiativeAttachment,
  sendParticipantCertificate,
  updateOrganizationConfig,
  updatePinnedFeaturedInitiative,
  getInitiativesDownloadUrl,
  getInitiativesParticipationDownloadUrl,
  getParticipantsDownloadUrl,
  getVolunteersDownloadUrl,
  updateProfile,
}
