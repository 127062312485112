import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import useSetState from 'utils/useSetState'

import {
  Checkbox,
  Drawer,
  Tag
} from 'antd';

const InitiativeParticipantsFilters = ({
  intl,
  openFilters,
  otherParticipantsOrganizations,
  onCloseFilters,
  shouldCleanFilters,
  tableActive,
  requireManagerApproval,
  slugToNameByType,
  hasPoints,
  showOther,
  onDeselectRowKeys,
  cohorts,
  onSetFilters,
  onClearedFilters,
  isCollectionInitiative
}) => {
  const t = intl.messages;

  /* --- Common Filters --- */
  // Status filter variables
  const filterStatus = useMemo(() => {
    let statusTraslations = [
      {slug: 'accepted', t: t.participating},
      {slug: 'rejected', t: t.rejected}
    ];
    if (!isCollectionInitiative) {
      statusTraslations = [
        ...statusTraslations,
        {slug: 'not_going', t: t.not_going}
      ];
    }
    return statusTraslations;
  }, [isCollectionInitiative, t]);

  const {
   set: filteredStatus,
   has: isStatusFilterSet,
   toggle: toggleStatusFilter,
   replaceAll: setFilteredStatus
  } = useSetState([])
  const statusFilterSet = useMemo(() => filteredStatus.length > 0, [filteredStatus])

  // Newbie (true, false) filter variables
  const filterNewbie = useMemo(() => [{slug: 'newbie', t: t.volunteers_newbie}], [t.volunteers_newbie]);
  const {
    set: filteredNewbie,
    has: isNewbieFilterSet,
    toggle: toggleNewbieFilter,
    replaceAll: setFilteredNewbie
  } = useSetState([])
  const newbieFilterSet = useMemo(() => filteredNewbie.length > 0, [filteredNewbie])

  // Groups (cohorts) filter variables
  const {
    set: filteredGroups,
    has: isGroupsFilterSet,
    toggle: toggleGroupsFilter,
    replaceAll: setFilteredGroups
  } = useSetState([])
  const groupsFilterSet = useMemo(() => filteredGroups.length > 0, [filteredGroups])

  // Click on group filters
  const handleGroupCheck = useCallback((slug) => {
    if(isGroupsFilterSet('noGroups')) {
      toggleGroupsFilter('noGroups');
    }
    toggleGroupsFilter(slug);
  }, [isGroupsFilterSet, toggleGroupsFilter]);

  // Organizations filter variables
  const {
    set: filteredOrganizations,
    has: isOrganizationsFilterSet,
    toggle: toggleOrganizationsFilter,
    replaceAll: setFilteredOrganizations
  } = useSetState([])
  const organizationsFilterSet = useMemo(() => filteredOrganizations.length > 0, [filteredOrganizations])

  // Click on organizations filters
  const handleOrganizationCheck = useCallback((slug) => {
    toggleOrganizationsFilter(slug);
  }, [toggleOrganizationsFilter]);

  // Contact (all methods, only phone, only email) filter variables
  const filterContacts = useMemo(() => {
    return [
      {slug: 'onlyPhone', t: t.volunteers_onlyPhone},
      {slug: 'onlyEmail', t: t.volunteers_onlyEmail}
    ];
  }, [t]);
  const {
    set: filteredContact,
    has: isContactFilterSet,
    toggle: toggleContactFilter,
    replaceAll: setFilteredContact
  } = useSetState([])
  const contactFilterSet = useMemo(() => filteredContact.length > 0, [filteredContact])

  /* --- Only Volunteering/Training Filters --- */
  // With validations (with/withnot) filter variables
  const filterValidations = [{slug: 'with', t: t.participants_havePendingValidations}, {slug: 'withnot', t: t.participants_noPendingValidations}]
  const {
    set: filteredValidation,
    has: isValidationFilterSet,
    toggle: toggleValidationFilter,
    replaceAll: setFilteredValidation
  } = useSetState([])
  const validationFilterSet = useMemo(() => filteredValidation.length > 0, [filteredValidation])

  // Manager Approval status (not_needed, pending, approved, rejected) filter variables
  const filterManagerApproval = [
    { slug: 'not_needed', t: t.manager_approval_status_not_needed },
    { slug: 'pending', t: t.manager_approval_status_pending },
    { slug: 'approved', t: t.manager_approval_status_approved },
    { slug: 'rejected', t: t.manager_approval_status_rejected },
  ];

  const {
    set: filteredManagerApproval,
    has: isManagerApprovalFilterSet,
    toggle: toggleManagerApprovalFilter,
    replaceAll: setFilteredManagerApproval,
  } = useSetState([])
  const managerApprovalFilterSet = useMemo(() => filteredManagerApproval.length > 0, [filteredManagerApproval])

  // With time assigned (with/withnot) filter variables
  const filterTimes = [{slug: 'with', t: t.initiativeparticipants_haveTimeValidated}, {slug: 'withnot', t: t.initiativeparticipants_noTimeValidated}]
  const {
    set: filteredTime,
    has: isTimeFilterSet,
    toggle: toggleTimeFilter,
    replaceAll: setFilteredTime
  } = useSetState([])
  const timeFilterSet = useMemo(() => filteredTime.length > 0, [filteredTime])

  // With points assigned (with/withnot) filter variables
  const filterPoints = [{slug: 'with', t: t.initiativeparticipants_havePointsValidated}, {slug: 'withnot', t: t.initiativeparticipants_noPointsValidated}]
  const {
    set: filteredPoints,
    has: isPointsFilterSet,
    toggle: togglePointsFilter,
    replaceAll: setFilteredPoints
  } = useSetState([])
  const pointsFilterSet = useMemo(() => filteredPoints.length > 0, [filteredPoints])

  // Flag for skiping filtering
  const skipTaxonomyFilters = useMemo(() => {
    if (isCollectionInitiative) {
      return (
        !statusFilterSet &&
        !newbieFilterSet &&
        !groupsFilterSet &&
        !contactFilterSet &&
        !organizationsFilterSet
      );
    }
    return (
      !statusFilterSet &&
      !managerApprovalFilterSet &&
      !newbieFilterSet &&
      !groupsFilterSet &&
      !validationFilterSet &&
      !timeFilterSet &&
      !pointsFilterSet &&
      !contactFilterSet &&
      !organizationsFilterSet
    );
  } , [
    isCollectionInitiative,
    statusFilterSet,
    managerApprovalFilterSet,
    newbieFilterSet,
    groupsFilterSet,
    validationFilterSet,
    timeFilterSet,
    pointsFilterSet,
    contactFilterSet,
    organizationsFilterSet
  ]);

  const cleanFilters = useCallback(() => {
    setFilteredStatus([]);
    setFilteredGroups([]);
    setFilteredOrganizations([]);
    setFilteredTime([]);
    setFilteredPoints([]);
    setFilteredValidation([]);
    setFilteredContact([]);
    setFilteredManagerApproval([])
    setFilteredNewbie([]);
    onClearedFilters();
  }, [
    setFilteredContact,
    setFilteredGroups,
    setFilteredOrganizations,
    setFilteredStatus,
    setFilteredTime,
    setFilteredValidation,
    setFilteredManagerApproval,
    setFilteredNewbie,
    setFilteredPoints,
    onClearedFilters
  ]);

  useEffect(() => {
    if (shouldCleanFilters) {
      cleanFilters();
    }
  }, [cleanFilters, shouldCleanFilters]);

  useEffect(() => {
    onSetFilters({
      filteredStatus,
      filteredManagerApproval,
      filteredNewbie,
      filteredValidation,
      filteredTime,
      filteredPoints,
      filteredGroups,
      filteredOrganizations,
      filteredContact
    });
  }, [
    filteredStatus,
    filteredManagerApproval,
    filteredNewbie,
    filteredValidation,
    filteredTime,
    filteredPoints,
    filteredGroups,
    filteredOrganizations,
    filteredContact,
    onSetFilters
  ]);

  return(
    <>
    { skipTaxonomyFilters ? null :
        <div>
          { !statusFilterSet ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.volunteers_volunteerState }: </span>
          { filteredStatus.map(slug =>
            <Tag
              key={slug}
              closable
              color="blue"
              onClose={() => {
                toggleStatusFilter(slug);
              }}
            >{ filterStatus.filter(item => item.slug === slug)[0].t }</Tag>
          ) }
            </div>
          }
          { isCollectionInitiative || (!isCollectionInitiative && !managerApprovalFilterSet) ? null :
            <div className="Initiative-filter">
              <span className="Initiative-filter-title">{ t.manager_approval_status }: </span>
              { filteredManagerApproval.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="blue"
                  onClose={() => toggleManagerApprovalFilter(slug)}
                >{ filterManagerApproval.filter(item => item.slug === slug)[0].t }</Tag>
              )}
            </div>
          } 
          { !newbieFilterSet ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.volunteers_volunteerNewbie }: </span>
            { filteredNewbie.map(slug =>
              <Tag
                key={slug}
                closable
                color="blue"
                onClose={() => toggleNewbieFilter(slug)}
              >{ t['volunteers_' + slug] }</Tag>
            ) }
            </div>
          }
          { !groupsFilterSet ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.volunteers_groups }: </span>
              { filteredGroups.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="green"
                  onClose={() => toggleGroupsFilter(slug)}
                >{ slugToNameByType(slug, 'cohorts') }</Tag>
              ) }
            </div>
          }
          { !organizationsFilterSet ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.organization }: </span>
              { filteredOrganizations.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="cyan"
                  onClose={() => toggleOrganizationsFilter(slug)}
                >{ slugToNameByType(slug, 'organizations') }</Tag>
              ) }
            </div>
          }
          { isCollectionInitiative || (!isCollectionInitiative &&  !validationFilterSet) ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.participants_validationStatus }: </span>
              { filteredValidation.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="geekblue"
                  onClose={() => toggleValidationFilter(slug)}
                >{ filterValidations.filter(item => item.slug === slug)[0].t }</Tag>
              ) }
            </div>
          }
          { isCollectionInitiative || (!isCollectionInitiative &&  !timeFilterSet) ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.initiativeparticipants_timeStatus }: </span>
              { filteredTime.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="geekblue"
                  onClose={() => toggleTimeFilter(slug)}
                >{ filterTimes.filter(item => item.slug === slug)[0].t }</Tag>
              ) }
            </div>
          }
          { isCollectionInitiative || (!isCollectionInitiative &&  !pointsFilterSet) ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.initiativeparticipants_pointsStatus }: </span>
              { filteredTime.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="geekblue"
                  onClose={() => togglePointsFilter(slug)}
                >{ filterTimes.filter(item => item.slug === slug)[0].t }</Tag>
              ) }
            </div>
          }
          { !contactFilterSet ? null :
            <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.contact_details }: </span>
              { filteredContact.map(slug =>
                  <Tag
                    key={slug}
                    closable
                    color="volcano"
                    onClose={() => toggleContactFilter(slug)}
                  >{ filterContacts.filter(item => item.slug === slug)[0].t }</Tag>
              )}
            </div>
          }
          <div className="Whitespace20"></div>
        </div>
      }
      <Drawer
          title={t.participants_filtersTitle}
          width={350}
          placement="right"
          closable={true}
          onClose={() => onCloseFilters()}
          visible={openFilters}
        >
          {(tableActive === 'state') || isCollectionInitiative ?
            <React.Fragment>
              <div className="Participants-taxonomy-filter">
                <h4>{t.participants_status}</h4>
                <div>
                  <Checkbox
                    checked={!statusFilterSet}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredStatus([])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.all }
                  </Checkbox>
                  { filterStatus.map(status => (
                    <Checkbox
                      key={status.slug}
                      checked={isStatusFilterSet(status.slug)}
                      onChange={() => {
                        onDeselectRowKeys()
                        setFilteredStatus([status.slug])
                      }}
                      style={{ display: 'block' }}
                    >
                      { status.t }
                    </Checkbox>
                  )) }
                </div>
              </div>
              { isCollectionInitiative || (!isCollectionInitiative && !requireManagerApproval) ? null :
                  <div className="Participants-taxonomy-filter">
                    <h4>{t.manager_approval_status}</h4>
                    <div>
                      <Checkbox
                        checked={!managerApprovalFilterSet}
                        onChange={() => {
                          onDeselectRowKeys()
                          setFilteredManagerApproval([])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.all }
                      </Checkbox>
                      { filterManagerApproval.map(item => (
                        <Checkbox
                          key={item.slug}
                          checked={isManagerApprovalFilterSet(item.slug)}
                          onChange={() => {
                            onDeselectRowKeys()
                            setFilteredManagerApproval([item.slug])
                          }}
                          style={{ display: 'block' }}
                        >
                          { item.t }
                        </Checkbox>
                      )) }
                    </div>
                  </div>
              }
              <div className="Participants-taxonomy-filter">
                <div>
                  <Checkbox
                    checked={!newbieFilterSet}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredNewbie([])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.all }
                  </Checkbox>
                  { filterNewbie.map(newbie => (
                    <Checkbox
                      key={newbie.slug}
                      checked={isNewbieFilterSet(newbie.slug)}
                      onChange={() => {
                        onDeselectRowKeys()
                        setFilteredNewbie([newbie.slug])
                      }}
                      style={{ display: 'block' }}
                    >
                      { newbie.t }
                    </Checkbox>
                  )) }
                </div>
              </div>
              {showOther && otherParticipantsOrganizations && (
                <div className="Participants-taxonomy-filter">
                  <h4>{ t.organization }</h4>
                  <div>
                    <Checkbox
                      checked={!organizationsFilterSet}
                      onChange={() => {
                        onDeselectRowKeys()
                        setFilteredOrganizations([])
                      }}
                      style={{ display: 'block' }}
                    >
                      { t.all }
                    </Checkbox>
                    { otherParticipantsOrganizations.map(orgSlug => {
                      return (
                        <Checkbox
                          key={orgSlug}
                          checked={isOrganizationsFilterSet(orgSlug)}
                          onChange={() => {
                            onDeselectRowKeys()
                            handleOrganizationCheck(orgSlug)
                          }}
                          style={{ display: 'block' }}
                        >
                          { slugToNameByType(orgSlug, 'organizations') }
                        </Checkbox>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="Participants-taxonomy-filter">
                <h4>{ t.participants_groups }</h4>
                <div>
                  <Checkbox
                    checked={!groupsFilterSet}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredGroups([])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.all }
                  </Checkbox>
                  <Checkbox
                    checked={isGroupsFilterSet('noGroups')}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredGroups(['noGroups'])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.participants_noGroups }
                  </Checkbox>
                  { cohorts.map(cohort => (
                    <Checkbox
                      key={cohort.slug}
                      checked={isGroupsFilterSet(cohort.slug)}
                      onChange={() => {
                        onDeselectRowKeys()
                        handleGroupCheck(cohort.slug)
                      }}
                      style={{ display: 'block' }}
                    >
                      { cohort.name }
                    </Checkbox>
                  )) }
                </div>
              </div>
              <div className="Participants-taxonomy-filter">
                <div>
                  <Checkbox
                    checked={!contactFilterSet}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredContact([])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.all }
                  </Checkbox>
                  { filterContacts.map(type => (
                    <Checkbox
                      key={type.slug}
                      checked={isContactFilterSet(type.slug)}
                      onChange={() => {
                        onDeselectRowKeys()
                        setFilteredContact([type.slug])
                      }}
                      style={{ display: 'block' }}
                    >
                      { type.t }
                    </Checkbox>
                  )) }
                </div>
              </div>
            </React.Fragment>
          : <React.Fragment>
              <div className="Participants-taxonomy-filter">
                <h4>{t.participants_validationStatus}</h4>
                <div>
                  <Checkbox
                    checked={!validationFilterSet}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredValidation([])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.all }
                  </Checkbox>
                  { filterValidations.map(state => (
                    <Checkbox
                      key={state.slug}
                      checked={isValidationFilterSet(state.slug)}
                      onChange={() => {
                        onDeselectRowKeys()
                        setFilteredValidation([state.slug])
                      }}
                      style={{ display: 'block' }}
                    >
                      { state.t }
                    </Checkbox>
                  )) }
                </div>
              </div>
              <div className="Participants-taxonomy-filter">
                <h4>{t.initiativeparticipants_timeStatus}</h4>
                <div>
                  <Checkbox
                    checked={!timeFilterSet}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredTime([])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.all }
                  </Checkbox>
                  { filterTimes.map(state => (
                    <Checkbox
                      key={state.slug}
                      checked={isTimeFilterSet(state.slug)}
                      onChange={() => {
                        onDeselectRowKeys()
                        setFilteredTime([state.slug])
                      }}
                      style={{ display: 'block' }}
                    >
                      { state.t }
                    </Checkbox>
                  )) }
                </div>
              </div>
              {
                !hasPoints
                ? null
                : (
                  <div className="Participants-taxonomy-filter">
                    <h4>{t.initiativeparticipants_pointsStatus}</h4>
                    <div>
                      <Checkbox
                        checked={!pointsFilterSet}
                        onChange={() => {
                          onDeselectRowKeys()
                          setFilteredTime([])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.all }
                      </Checkbox>
                      { filterPoints.map(state => (
                        <Checkbox
                          key={state.slug}
                          checked={isPointsFilterSet(state.slug)}
                          onChange={() => {
                            onDeselectRowKeys()
                            setFilteredPoints([state.slug])
                          }}
                          style={{ display: 'block' }}
                        >
                          { state.t }
                        </Checkbox>
                      )) }
                    </div>
                  </div>
                )
              }
              <div className="Participants-taxonomy-filter">
                <h4>{ t.contact_details }</h4>
                <div>
                  <Checkbox
                    checked={!contactFilterSet}
                    onChange={() => {
                      onDeselectRowKeys()
                      setFilteredContact([])
                    }}
                    style={{ display: 'block' }}
                  >
                    { t.all }
                  </Checkbox>
                  { filterContacts.map(type => (
                    <Checkbox
                      key={type.slug}
                      checked={isContactFilterSet(type.slug)}
                      onChange={() => {
                        onDeselectRowKeys()
                        setFilteredContact([type.slug])
                      }}
                      style={{ display: 'block' }}
                    >
                      { type.t }
                    </Checkbox>
                  )) }
                </div>
              </div>
            </React.Fragment>
          }
        </Drawer>
    </>
  );
}

InitiativeParticipantsFilters.propTypes = {
  cohorts: PropTypes.array.isRequired,
  hasPoints: PropTypes.bool.isRequired,
  isCollectionInitiative: PropTypes.bool,
  onClearedFilters: PropTypes.func.isRequired,
  onCloseFilters: PropTypes.func.isRequired,
  onDeselectRowKeys: PropTypes.func.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  openFilters: PropTypes.bool.isRequired,
  otherParticipantsOrganizations: PropTypes.array,
  requireManagerApproval: PropTypes.bool.isRequired,
  shouldCleanFilters: PropTypes.bool.isRequired,
  showOther: PropTypes.bool.isRequired,
  slugToNameByType: PropTypes.func.isRequired,
  tableActive: PropTypes.string,
};

export default injectIntl(InitiativeParticipantsFilters);
