const config = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  SENTRY_SAMPLE_RATE: +process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
  ADMIN_APP_URL: process.env.REACT_APP_ADMIN_APP_URL || 'https://localhost:3001',
  API_URL: process.env.REACT_APP_API_URL || 'https://localhost:7500/admin',
  AUTH_URL: process.env.REACT_APP_AUTH_URL || 'https://localhost:7501',
  ACCOUNTS_URL: process.env.REACT_APP_ACCOUNTS_URL || 'https://localhost:3004',
  VOLUNTEER_APP_URL: process.env.REACT_APP_VOLUNTEER_APP_URL || 'https://localhost:3000',
  QR_APP_URL: process.env.REACT_APP_QR_APP_URL || 'https://localhost:3002',
  ATLAS_APP_URL: process.env.REACT_APP_ATLAS_APP_URL || 'https://localhost:3003',
  ORGANIZATION_COOKIE_DOMAIN: process.env.REACT_APP_ORGANIZATION_COOKIE_DOMAIN || 'localhost',
  ORGANIZATION_COOKIE_NAME: process.env.REACT_APP_ORGANIZATION_COOKIE_NAME || 'aplanet_organization_development',
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
  QUICKBLOX_APP_ID: process.env.REACT_APP_QUICKBLOX_APP_ID || '',
  QUICKBLOX_AUTH_KEY: process.env.REACT_APP_QUICKBLOX_AUTH_KEY || '',
  QUICKBLOX_AUTH_SECRET: process.env.REACT_APP_QUICKBLOX_AUTH_SECRET || '',
  QUICKBLOX_ACCOUNT_KEY: process.env.REACT_APP_QUICKBLOX_ACCOUNT_KEY || '',
  INSPECTLET_KEY: process.env.REACT_APP_INSPECTLET_KEY || '',
  DEFAULT_FEATURES: [
    'proposals',
    'rankings',
    'collaborations',
    'responsible_profile',
    //'education',
    'impact',
  ],
  DEFAULT_PERMISSIONS: [
    'can_read_initiatives',
  ],
  DEFAULT_CONFIG: {
    features: {
      rankings: true,
      proposals: true,
      collaborations: true,
      responsible_profile: true,
      education: true,
    },
    can_override_dates: [],
    disabled_sdgs: [],
    mandatory_sdgs: [],
    preferred_sdgs: [],
    initiative_types: [
      'volunteering',
      'donation',
      'informative',
      'training',
      'collection',
    ],
    auto_approve_hours: false,
    default_donation_params: {
      sources: [
        'paypal',
        'inhand',
        'transfer',
        'external',
      ],
      min_amount: 0,
    },
    registration_open_to_anyone: false,
    requires_email_confirmation: true,
    collaborative_initiatives_by_default: false,
    show_powered_by: true,
  },
}

export default config;
