import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef
} from 'react'
import useSetState from 'utils/useSetState'
import { stringToHash } from 'utils/hash'
import { formatDateAndTime } from 'utils/date';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {injectIntl} from 'react-intl'
import Highlighter from 'react-highlight-words'
import {useHotkeys} from 'react-hotkeys-hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { A } from 'aplanet-ui-kit'

import AuthorizedLink from 'containers/AuthorizedLink';
import NewVolunteers from './screens/NewVolunteers'
import CohortAssignModal from './screens/CohortAssignModal'
import BlockModal from './screens/BlockModal'
import AssignManagerModal from './screens/AssignManagerModal'
import {
  requestOrgVolunteers,
  blockVolunteer,
  unblockVolunteer,
  reinviteVolunteer,
  setOrganizationCohorts,
  inviteVolunteer,
  sendMessage,
  assignManager,
  deleteVolunteer,
  getVolunteersDownloadUrl,
} from 'actions/api'

import { refreshAccessToken } from 'actions/auth'

import useOrganizations from 'utils/useOrganizations'

import './style.less'
import colors from 'colors'
import Avatar from 'components/Avatar'
import FloatingButton from 'components/FloatingButton'
import UserProfileModal from 'containers/UserProfileModal'
import MainLayout from 'components/MainLayout';
import SendMessageModal from 'components/SendMessageModal';

import {
  Table,
  Button,
  Empty,
  Row,
  Col,
  Spin,
  Modal,
  Tag,
  Input,
  Drawer,
  Checkbox,
  Menu,
  Dropdown,
} from 'antd';
import {
  FilterOutlined,
  UserOutlined,
  LockOutlined,
  MessageOutlined,
  MailOutlined,
  SearchOutlined,
  UnlockOutlined,
  CloseOutlined,
  UserDeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

const Volunteer = ({
  intl
}) => {
  const {
    organization,
  } = useOrganizations();
  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const { cohorts } = useSelector(state => state.taxonomies);
  const { data: profile } = useSelector(state => state.profile);
  const volunteers = useSelector(state => state.volunteers);
  const { items: members, loading, error } = volunteers;

  const requireManagerApproval = useMemo(() => {
    const orgConfig = organization.config;
    if(!orgConfig) {
      return false;
    }

    return orgConfig.require_manager_approval;
  }, [
    organization,
  ]);

  // Request volunteers to back
  useEffect(() => {
    dispatch(requestOrgVolunteers(organization.slug));
  }, [
    dispatch,
    organization.slug,
  ]);

  // Traductions
  const t = intl.messages

  // Search text state
  const [searchText, setSearchText] = useState('')

  // Drawer filter controller (open/close)
  const [drawerOpen, setDrawerOpen] = useState(false)

  // User detail modal (open/close)
  const [userDetail, setUserDetail] = useState(null)

  /* ---  Filters --- */
  // State (active, blocked) filter variables
  const filterStates = [{slug: 'active', t: t.volunteers_active}, {slug: 'blocked', t: t.volunteers_blocked}, {slug: 'invited', t: t.volunteers_invited}]
  const {
    set: filteredState,
    has: isStateFilterSet,
    toggle: toggleStateFilter,
    replaceAll: setFilteredState
  } = useSetState([])
  const stateFilterSet = useMemo(() => filteredState.length > 0, [filteredState])

  // Manager filter variable
  const filterManager = [
    { slug: 'has_manager', t: t.has_manager },
    { slug: 'no_manager', t: t.no_manager },
  ]
  const {
    set: filteredManager,
    has: isManagerFilterSet,
    toggle: toggleManagerFilter,
    replaceAll: setFilteredManager
  } = useSetState([])
  const managerFilterSet = useMemo(() => filteredManager.length > 0, [filteredManager])

  // Newbie (true, false) filter variables
  const filterNewbie = [{slug: 'newbie', t: t.volunteers_newbie}]
  const {
    set: filteredNewbie,
    has: isNewbieFilterSet,
    toggle: toggleNewbieFilter,
    replaceAll: setFilteredNewbie
  } = useSetState([])
  const newbieFilterSet = useMemo(() => filteredNewbie.length > 0, [filteredNewbie])

  // Type (admin, volunteer) filter variables
  const filterTypes = [{slug: 'admin', t: t.volunteers_admin}, {slug: 'user', t: t.volunteers_user}]
  const {
    set: filteredType,
    has: isTypeFilterSet,
    toggle: toggleTypeFilter,
    replaceAll: setFilteredType
  } = useSetState([])
  const typeFilterSet = useMemo(() => filteredType.length > 0, [filteredType])

  // Groups (cohorts) filter variables
  const {
    set: filteredGroups,
    has: isGroupsFilterSet,
    toggle: toggleGroupsFilter,
    replaceAll: setFilteredGroups
  } = useSetState([])
  const groupsFilterSet = useMemo(() => filteredGroups.length > 0, [filteredGroups])

  // Contact (all methods, only phone, only email) filter variables
  const filterContacts = [{slug: 'onlyPhone', t: t.volunteers_onlyPhone}, {slug: 'onlyEmail', t: t.volunteers_onlyEmail}]
  const {
    set: filteredContact,
    has: isContactFilterSet,
    toggle: toggleContactFilter,
    replaceAll: setFilteredContact
  } = useSetState([])
  const contactFilterSet = useMemo(() => filteredContact.length > 0, [filteredContact])

  // Click on group filters
  const handleGroupCheck = (slug) => {
    if(isGroupsFilterSet('noGroups')) {
      toggleGroupsFilter('noGroups')
    }
    toggleGroupsFilter(slug)
  }

  // Handle group filtering
  const handleGroupFilter = (values, record) => {
    if(values && values.length === 1 && values[0]  === 'noGroups'){
      return record.cohorts.length === 0
    }
    // NOTICE: Cohorts compose with AND
    return values.every(value => (record.cohorts || []).includes(value));
  }

  // Handle name/contact filtering
  const handleContactFilter = (value, record) => {
    return (value === 'onlyPhone' && record.phone && !record.email) || (value === 'onlyEmail' && !record.phone && record.email)
  }

  // Flag for skiping filtering
  const skipTaxonomyFilters = useMemo(() => (
    !stateFilterSet &&
    !managerFilterSet &&
    !newbieFilterSet &&
    !typeFilterSet &&
    !groupsFilterSet &&
    !contactFilterSet
  ), [
    stateFilterSet,
    managerFilterSet,
    newbieFilterSet,
    typeFilterSet,
    groupsFilterSet,
    contactFilterSet,
  ])

  // Focus search field on Ctrl+F
  const searchInputRef = useRef()
  useHotkeys('ctrl+f', (e) => {
    if (searchInputRef.current.focus) {
      searchInputRef.current.focus()
      e.preventDefault()
    }
  }, [searchInputRef])

  // Get cohort names
  const cohortSlugToName = useMemo(() => {
    let cohortMap = {}
    cohorts.forEach(({slug, name}) => {
      cohortMap[slug] = name
    })
    return(slug) => cohortMap[slug] || false
  }, [cohorts])  

  // Show reinvite confirm
  const showReinviteConfirm = useCallback(slugs => {
    slugs = Array.isArray(slugs) ? slugs : [slugs]
    const confirmationText = slugs.length > 1 ? t.volunteers_confirmMultiReinvite : t.reinvite_confirmation
    Modal.confirm({
      title: confirmationText,
      content: t.reinvite_confirmation_desc,
      okText: t.reinvite_yes,
      okType: 'primary',
      cancelText: t.reinvite_no,
      onOk() {
        slugs.forEach(slug => {
          dispatch(reinviteVolunteer(organization.slug, slug));
        })
      },
      onCancel() {}
    })
  }, [t, organization, dispatch])

  // Show unblock confirm
  const showUnblockConfirm = useCallback(slugs => {
    slugs = Array.isArray(slugs) ? slugs : [slugs]
    Modal.confirm({
      title: t.unblock_confirmation,
      content: t.unblock_confirmation_desc,
      okText: t.unblock_yes,
      okType: 'primary',
      cancelText: t.unblock_no,
      onOk() {
        slugs.forEach(slug => {
          dispatch(unblockVolunteer(organization.slug, slug));
        })
      },
      onCancel() {}
    })
  }, [t, organization, dispatch])

  // Block users feature
  const [blockMemberSlugs, setBlockMemberSlugs] = useState([]);

  // Assign manager feature
  const [assignManagerMemberSlugs, setAssignManagerMemberSlugs] = useState([]);

  // Row selection functionality
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  // Returns volunteers selected slug
  const volunteersSelected = useMemo(() => (
    members.filter((member, i) => selectedRowKeys.includes(i))
    ), [members, selectedRowKeys]);  
  
  // Returns volunteers selected slug
  const volunteersSelectedSlugs = useMemo(() => (
    volunteersSelected.map(row => row.slug)
  ), [volunteersSelected]);  

  // Block multi users
  const multiBlock = useCallback(() => {
    const membersToBlock = members.filter((member, i) => selectedRowKeys.includes(i))
    setSelectedRowKeys([])
    setBlockMemberSlugs(membersToBlock.map(row => row.slug))
  }, [ members, selectedRowKeys ]);

  const onBlockVolunteer = useCallback((organizationSlug, slug, blockReason) => {
    dispatch(blockVolunteer(organizationSlug, slug, blockReason));
  }, [dispatch]);

  // Delete multi users
  const onDeleteOrAnonymizeVolunteer = useCallback((deleteMemberSlugs, anonymize) => {
    setSelectedRowKeys([]);
    deleteMemberSlugs.forEach(slug => {
      dispatch(deleteVolunteer(organization.slug, slug, anonymize));
    });
  }, [dispatch, organization.slug]);

  const showDeleteOrAnonymizeVolunteerConfirm = useCallback((deleteMemberSlugs, anonymize = false) => {
    let confirmationTitle;
    if (anonymize) {
      confirmationTitle = deleteMemberSlugs.length > 1 ? t.delete_anonymize_multi_volunteer_title_modal : t.delete_anonymize_volunteer_title_modal;
    } else {
      confirmationTitle = deleteMemberSlugs.length > 1 ? t.delete_multi_volunteer_title_modal : t.delete_volunteer_title_modal;
    }
    const confirmationDesc = anonymize ? t.delete_anonymize_volunteer_desc_modal : t.delete_volunteer_desc_modal;
    const confirmationOk = anonymize ? t.delete_anonymize_volunteer_ok_button : t.delete_volunteer_ok_button;
    Modal.confirm({
      title: confirmationTitle,
      content: confirmationDesc,
      okText: confirmationOk,
      okType: 'danger',
      cancelText: t.delete_volunteer_no_button,
      onOk() {
        onDeleteOrAnonymizeVolunteer(deleteMemberSlugs, anonymize);
      },
      onCancel() {},
    });
  }, [t, onDeleteOrAnonymizeVolunteer]);
  
  const isDisabledMultiDeleteVolunteer = useMemo(() => {
    const adminMemberSelected = members.filter((member, i) => selectedRowKeys.includes(i)).find(row => row.role === 'admin');
    return selectedRowKeys.length < 2 || adminMemberSelected;
  }, [selectedRowKeys, members]);

  const onInviteNewVolunteer = useCallback((organizationSlug, newVolunteerName, newVolunteerEmail, newVolunteerPhone) => {
    dispatch(inviteVolunteer(organizationSlug, newVolunteerName, newVolunteerEmail, newVolunteerPhone));
  }, [dispatch]);

  const onAssignManager = useCallback((
    organizationSlug,
    slug,
    name,
    email,
    phone,
    action
    ) => {
    dispatch(assignManager(
      organizationSlug,
      slug,
      name,
      email,
      phone,
      action
    ));
  }, [dispatch]);

  // AssignManager multiple users
  const multiAssignManager = useCallback(() => {
    const membersToAssign = members.filter((member, i) => selectedRowKeys.includes(i))
    setSelectedRowKeys([])
    setAssignManagerMemberSlugs(membersToAssign.map(row => row.slug))
  }, [
    members,
    selectedRowKeys,
  ]);

  // Re-invite multi users
  const multiReinvite = useCallback(() => {
    const membersToReinvite = members.filter((member, i) => selectedRowKeys.includes(i) && member.status === 'invited')
    setSelectedRowKeys([])
    showReinviteConfirm(membersToReinvite.map(row => row.slug))
  }, [ members, selectedRowKeys, showReinviteConfirm ]);

  // Save on memo members info
  const data = useMemo(() => members.map((member, index) => ({
    key: index,
    name: member.name,
    slug: member.slug,
    email: member.email,
    phone: member.phone,
    edit: index,
    cohorts: member.cohorts,
    joined_at: member.created_at,
    avatar: member.avatar,
    initiatives_joined: member.initiatives_joined,
    hour_count: member.hour_count,
    is_newbie: member.is_newbie,
    manager: member.manager,
    status: member.status,
    actions: member.status,
    block_reason: member.block_reason,
    role: member.role,
    id: member.id
  })).filter(
    record => {
      const filters = [];

      if (searchText) {
        filters.push((record.name || '').toLowerCase().includes(searchText) || (record.email || '').toLowerCase().includes(searchText));
      }
      if (filteredState.length) {
        filters.push(record.actions === filteredState[0]);
      }
      if (filteredManager.length) {
        if(filteredManager.includes('no_manager')) {
          filters.push(!record.manager);
        }
        if(filteredManager.includes('has_manager')) {
          filters.push(!!record.manager);
        }
      }
      if (filteredNewbie.length) {
        filters.push(record.is_newbie && filteredNewbie[0]);
      }
      if (filteredType.length) {
        filters.push(record.role === filteredType[0]);
      }
      if (filteredGroups.length) {
        filters.push(handleGroupFilter(filteredGroups, record));
      }
      if (filteredContact.length) {
        filters.push(handleContactFilter(filteredContact[0], record));
      }
      return filters.every(item => item);
    }
  ), [
    members,
    searchText,
    filteredState,
    filteredManager,
    filteredNewbie,
    filteredType,
    filteredGroups,
    filteredContact,
  ])

  const rowSelection = useMemo(
    () => {
      const unblockedRecords = data.filter(d => d.actions !== 'blocked');
      const showDeselectAll = unblockedRecords.length === selectedRowKeys.length;

      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: record => ({
          disabled: record.status === 'blocked'
        }),
        hideDefaultSelections: true,
        selections: [{
          key: 'toggle_select_deselect',
          text: showDeselectAll
            ? `${t.deselect_all} (${unblockedRecords.length}) ${t.records}`
            : `${t.select_all} (${unblockedRecords.length}) ${t.records}`,
          onSelect: () => {
            if (showDeselectAll) {
              return setSelectedRowKeys([]);
            }
            return setSelectedRowKeys(unblockedRecords.map(d => d.key));
          },
        }],
      }
    }, [t, data, selectedRowKeys]);

  // Number of active volunteers
  const volunteersActive = useMemo(() => {
    const activeVolunteers = data.filter(member => member.status === 'active');
    return activeVolunteers.length;
  },[data]);

  // Manage cohorts
  const [membersToManage, setMembersToManage] = useState([])
  const [addCohortMenuActive, setAddCohortMenuActive] = useState(false)
  const manageCohorts = useCallback((record) => {
    const membersToManage = record ? [record] : members.filter((member, i) => selectedRowKeys.includes(i))
    setMembersToManage(membersToManage)
    setAddCohortMenuActive(true)
  }, [ members, selectedRowKeys ]);

  // Send message modal controller (open/close)
  const [sendMessageModal, setSendMessageModal] = useState(false);

  // Save new cohorts
  const saveNewCohorts = useCallback((newCohorts, membersModified) => {
    // Merge modifed members con all the rest
    let newMembers = members.map(a => ({...a}))
    membersModified.forEach(modified => {
      newMembers = newMembers.map(member => {
        return (member.id === modified.id) ? modified : member
      })
    })
    dispatch(setOrganizationCohorts(organization.slug, newCohorts, newMembers))
  }, [dispatch, members, organization.slug]);

  // Add volunteers modal
  const [addVolunteerModal, setAddVolunteerModal] = useState(false)

  // Slug to name
  const slugToName = (slug) => {
    if(slug === 'noGroups') return t.volunteers_noGroups
    let name = false
    cohorts.forEach(cohort => {
      if(cohort.slug === slug) name = cohort.name
    })
    return name
  }

  // Selected plural or singular
  const selectedText = useCallback(() => {
    return selectedRowKeys.length > 1 ? t.volunteers_selected : t.volunteers_one_selected
  }, [ selectedRowKeys, t ]);

  // Table colums
  const columns = useMemo(() => ([
    {
      title: (selectedRowKeys.length > 0) ? t.name + ' (' + selectedRowKeys.length + ' ' + selectedText() + ')' : t.name,
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
      <React.Fragment>
        <Row type="flex" gutter={10} justify="left" align="middle">
          <Col>
            <A
              onClick={() => setUserDetail(record.slug)}
            >
              <Avatar
                size={38}
                shape="circle"
                src={record.avatar}
                name={record.name}
                icon={<UserOutlined />}
              />
            </A>
          </Col>
          <Col>
            <Row type="flex" gutter={2}>
              <Col>
                {
                  (record.is_newbie)
                    ? <img className="typeIcon typeIcon-img" src='/images/icon-new.svg' alt={t.newbie} />
                    : null
                }
                {
                  (record.role === 'admin')
                    ? <img className="typeIcon typeIcon-img typeIcon-img-crown" src='/images/crown.svg' alt={t.admin} />
                    : null
                }
                {
                  (requireManagerApproval && record.manager)
                    ? <FontAwesomeIcon
                        icon="sitemap"
                        color="#33c38b"
                        size={'sm'}
                      />
                    : null
                }
              </Col>
              <Col>
                {
                  !searchText
                    ? name
                    : <Highlighter highlightStyle={{
                          backgroundColor: colors['@text-selection-bg'],
                          padding: 0
                        }} searchWords={[searchText]} autoEscape textToHighlight={name}/>
                }
              </Col>
            </Row>
            <Row>
              {!record.email ? null :
                !searchText
                  ? record.email
                  : <Highlighter highlightStyle={{
                        backgroundColor: colors['@text-selection-bg'],
                        padding: 0
                      }} searchWords={[searchText]} autoEscape textToHighlight={record.email}/>
              }
            </Row>
            <Row>
              {!record.phone ? null :
                !searchText
                  ? record.phone
                  : <Highlighter highlightStyle={{
                        backgroundColor: colors['@text-selection-bg'],
                        padding: 0
                      }} searchWords={[searchText]} autoEscape textToHighlight={record.phone}/>
              }
            </Row>
          </Col>
        </Row>
      </React.Fragment>)
    },
    (
      !cohorts || cohorts.length === 0
      ? null
      : {
        title: t.group,
        dataIndex: 'cohorts',
        key: 'cohorts',
        render: (cohorts, status) => <div className="Volunteer-tagline">
          {
            !cohorts || cohorts.length === 0
              ? ''
              : cohorts.map(cohortSlugToName).filter(Boolean).map(cohort => (
                  <Tag
                    className="Volunteer__groupTag"
                    key={cohort}
                    color={colors['tag-palette'][stringToHash(cohort, 11)]}
                  >
                    {cohort}
                  </Tag>
                )
              )

          }
        </div>
      }),
    {
      title: intl.formatMessage({id: `joined_at`}),
      dataIndex: 'joined_at',
      key: 'joined_at',
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        return new Date(a.joined_at) - new Date(b.joined_at);
      },
      render: joined_at => {
        const { date, time } = formatDateAndTime(joined_at, intl);
        return `${date} ${time}`;
      }
    }, {
      title: t.status,
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        if (status === 'blocked') {
          if(record.block_reason) {
            return (
              <A
                className="Volunteer-blocked"
                onClick={() => Modal.info({title:t.reason_block ,content: record.block_reason})}
              >
                { t.volunteers_blocked }
              </A>
            );
          }
          return <div className="Volunteer-blocked">{t.volunteers_blocked}</div>
        }else if(status === 'active'){
          return <div className="Volunteer-active">{t.volunteers_active}</div>
        }else{
          return <div className="Volunteer-invited">{t.volunteers_invited}</div>
        }
      }
    }, {
      title:
        (
          <Row gutter={10} type="flex" className="Volunteer__tableButtons">
            { selectedRowKeys.length > 1 && (
              <>
                { requireManagerApproval && (
                  <Col>
                    <Button
                      shape='circle'
                      className={'manageGroupsButton'}
                      onClick={() => multiAssignManager()}
                      title={t.assign_manager}
                      type="primary"
                    >
                      <FontAwesomeIcon className='manageUsersGroupsIcon' icon="sitemap"/>
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button
                    shape='circle'
                    className={'manageGroupsButton'}
                    onClick={() => manageCohorts()}
                    title={t.manage_groups}
                    type="primary"
                  >
                    <FontAwesomeIcon className='manageUsersGroupsIcon' icon="users-cog"/>
                  </Button>
                </Col>
                <Col>
                  <Button
                    shape='circle'
                    className={'multiBlock'}
                    onClick={() => multiBlock()}
                    title={t.block}
                    icon={<LockOutlined />}
                    type="danger"
                  />
                </Col>
                <Col>
                  <Button
                    shape='circle'
                    className={'multiReinvite'}
                    onClick={() => multiReinvite()}
                    title={t.reinvite}
                    icon={<MailOutlined />}
                    type="primary"
                  />
                </Col>
                { !isDisabledMultiDeleteVolunteer && (
                  <>
                    <Col>
                      <Button
                        shape='circle'
                        className='multiDeleteVolunteer'
                        onClick={() => showDeleteOrAnonymizeVolunteerConfirm(volunteersSelectedSlugs)}
                        title={t.volunteer_delete}
                        icon={<CloseOutlined />}
                        type="danger"
                      />
                    </Col>
                    {profile && profile.role === 'system' && (
                      <Col>
                        <Button
                          shape='circle'
                          className='multiAnonymizeVolunteer'
                          onClick={() => showDeleteOrAnonymizeVolunteerConfirm(volunteersSelectedSlugs, true)}
                          title={t.volunteer_delete}
                          icon={<UserDeleteOutlined />}
                          type="danger"
                        />
                      </Col>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
        ),
      dataIndex: 'status',
      key: 'actions',
      width: 200,
      align: 'center',
      render: (status, record) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                onClick={({ key, }) => {
                  switch(key) {
                    case 'assign_manager':
                      setAssignManagerMemberSlugs([record.slug])
                      break;
                    case 'cohorts':
                      manageCohorts(record);
                      break;
                    case 'unblock':
                      showUnblockConfirm(record.slug);
                      break;
                    case 'block':
                      setBlockMemberSlugs([record.slug])
                      break;
                    case 'reinvite':
                      showReinviteConfirm(record.slug);
                      break;
                    case 'delete':
                      showDeleteOrAnonymizeVolunteerConfirm([record.slug]);
                      break;
                    case 'anonymize':
                      showDeleteOrAnonymizeVolunteerConfirm([record.slug], true);
                      break;
                    default:
                  }
                }}
              >
                {
                  status !== 'blocked'
                  ? null
                  : (
                    <Menu.Item
                      key="unblock"
                    >
                      <UnlockOutlined />
                      { t.unblock }
                    </Menu.Item>
                  )
                }
                {
                  !requireManagerApproval
                  ? null
                  : (
                    <Menu.Item
                      key="assign_manager"
                    >
                      <FontAwesomeIcon className='Volunteer__context_IconInline' icon="sitemap"/>
                      { t.assign_manager }
                    </Menu.Item>
                  )
                }

                {
                  status === 'blocked'
                  ? null
                  : (
                    <Menu.Item
                      key="cohorts"
                    >
                      <FontAwesomeIcon className='Volunteer__context_IconInline' icon="users-cog"/>
                      { t.manage_groups }
                    </Menu.Item>
                  )
                }
                {
                  status === 'blocked'
                  ? null
                  : (
                    <Menu.Item
                      key="block"
                    >
                      <LockOutlined />
                      { t.block }
                    </Menu.Item>
                  )
                }
                {
                  status !== 'invited'
                  ? null
                  : (
                    <Menu.Item
                      key="reinvite"
                    >
                      <MailOutlined />
                      { t.reinvite }
                    </Menu.Item>
                  )
                }
                { record.role !== 'admin' && (
                    <Menu.Item
                      key="delete"
                    >
                      <CloseOutlined />
                      { t.volunteer_delete }
                    </Menu.Item>
                )}
                { record.role !== 'admin' && (
                    <Menu.Item
                      key="anonymize"
                    >
                      <UserDeleteOutlined />
                      { t.volunteer_anonymize }
                    </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              shape='circle'
            >
              <FontAwesomeIcon icon="ellipsis-v"/>
            </Button>
          </Dropdown>
        );
      }
    }
  ].filter(Boolean)), [
    t,
    intl,
    cohorts,
    profile,
    cohortSlugToName,
    searchText,
    showReinviteConfirm,
    selectedRowKeys,
    manageCohorts,
    multiBlock,
    selectedText,
    multiReinvite,
    showUnblockConfirm,
    requireManagerApproval,
    multiAssignManager,
    showDeleteOrAnonymizeVolunteerConfirm,
    volunteersSelectedSlugs,
    isDisabledMultiDeleteVolunteer,
  ])

  const onCloseSendMessageModal = useCallback(() => {
    setSendMessageModal(false);
  }, []);

  const onSendMessage = useCallback((message, messageType) => {
    dispatch(sendMessage(organization.slug, message, messageType, volunteersSelectedSlugs));
    onCloseSendMessageModal();
  }, [
    dispatch,
    onCloseSendMessageModal,
    organization.slug,
    volunteersSelectedSlugs
  ]);


  return (
    <MainLayout
      errorMessage={intl.formatMessage({id: 'error_boundary_volunteers_message'})}
    >
      <section className='volunteersHeader'>
        <h1 className='volunteersTitle'>{ t.volunteers }</h1>
        <h2 className='volunteersSubtitle'>{' (' + volunteersActive + ' ' + t.volunteers_actives + ')' }</h2>
      </section>
      <div className="Whitespace20"></div>
      <FloatingButton onClick={() => setAddVolunteerModal(true)} iconUrl='images/icon-event-white.svg'/>
      <Modal
        visible={addVolunteerModal}
        title={t.volunteers_addVolunteerModalTitle}
        centered
        width={900}
        onCancel={() => setAddVolunteerModal(false)}
        footer={false}
      >
        <NewVolunteers
          inviteFunction={onInviteNewVolunteer}
          t={t}
          api_requests={api_requests}
          refreshAccessToken={() => dispatch(refreshAccessToken())}
          volunteers={volunteers}
        />
      </Modal>
      <SendMessageModal
        showSendMessageModal={sendMessageModal}
        onCloseModal={onCloseSendMessageModal}
        onCancelModal={onCloseSendMessageModal}
        onSend={onSendMessage}
        participantsdWithPhone={volunteersSelected.filter(p => p.phone).length}
        participantsdWithEmail={volunteersSelected.filter(p => p.email).length}
        totalParticipants={volunteersSelected.length}
      />
      {
        loading && (!data || data.length === 0)
          ? <Spin size="large"/>
          : null
      }
      {
        !loading && members.length === 0
          ? <Empty
              className='big'
              image={'images/icon-no-users.svg'}
              description={t.emptyCommunity}
            />
          : null
      }
      {
        (members && members.length)
          ? <div>
              <Row type="flex" gutter={20} align={'middle'}>
                <Col>
                  <Button
                    size='large'
                    type='primary'
                    disabled={selectedRowKeys.length < 1}
                    icon={<MessageOutlined />}
                    onClick={() => setSendMessageModal(true)}
                  >
                    {t.participants_sendMessage}
                  </Button>
                </Col>
                <Col style={{
                    flex: 1
                  }}>
                  <Input.Search
                    size='large'
                    className='searchVolunteers'
                    type="text"
                    prefix={<SearchOutlined style = {{ color: 'rgba(0,0,0,.25)' }}/>}
                    placeholder={t.volunteer_search_placeholder}
                    enterButton={t.volunteer_search}
                    onSearch={(term) => setSearchText((term || '').toLowerCase().trim())}
                    onChange={(term) => !term.target.value && setSearchText('')}
                    allowClear="allowClear"
                    ref={searchInputRef}
                  />
                </Col>
                <Col>
                  <AuthorizedLink
                    href={getVolunteersDownloadUrl(organization.slug)}
                    download={`volunteers.xlsx`}
                  >
                    <Button
                      size="large"
                      icon={<DownloadOutlined />}
                      type="default"
                    >
                    </Button>
                  </AuthorizedLink>
                </Col>
                <Col>
                  <Button
                    size='large'
                    className='filterVolunteers'
                    type="default"
                    icon={<FilterOutlined />}
                    onClick={() => setDrawerOpen(true)}
                  />
                </Col>
              </Row>
              <div className="Whitespace20"></div>
              { skipTaxonomyFilters ? null :
                <div>
                  { !stateFilterSet ? null :
                    <div className="Initiative-filter">
                    <span className="Initiative-filter-title">{ t.volunteers_volunteerState }: </span>
                  { filteredState.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="blue"
                      onClose={() => toggleStateFilter(slug)}
                    >{ t['volunteers_' + slug] }</Tag>
                  ) }
                    </div>
                  }
                  {
                    !managerFilterSet ? null :
                    <div className="Initiative-filter">
                      <span className="Initiative-filter-title">{ t.volunteers_volunteer_manager }: </span>
                      { filteredManager.map(slug =>
                        <Tag
                          key={slug}
                          closable
                          color="blue"
                          onClose={() => toggleManagerFilter(slug)}
                        >{ t[slug] }</Tag>
                      ) }
                    </div>
                  }

                  { !newbieFilterSet ? null :
                    <div className="Initiative-filter">
                    <span className="Initiative-filter-title">{ t.volunteers_volunteerNewbie }: </span>
                  { filteredNewbie.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="blue"
                      onClose={() => toggleNewbieFilter(slug)}
                    >{ t['volunteers_' + slug] }</Tag>
                  ) }
                    </div>
                  }
                  { !typeFilterSet ? null :
                    <div className="Initiative-filter">
                    <span className="Initiative-filter-title">{ t.volunteers_volunteerType }: </span>
                  { filteredType.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="green"
                      onClose={() => toggleTypeFilter(slug)}
                    >{ t['volunteers_' + slug] }</Tag>
                  ) }
                    </div>
                  }
                  { !groupsFilterSet ? null :
                    <div className="Initiative-filter">
                  <span className="Initiative-filter-title">{ t.volunteers_groups }: </span>
                { filteredGroups.map(slug =>
                  <Tag
                    key={slug}
                    closable
                    color="geekblue"
                    onClose={() => toggleGroupsFilter(slug)}
                  >{ slugToName(slug) }</Tag>
                ) }
                    </div>
                  }
                  { !contactFilterSet ? null :
                    <div className="Initiative-filter">
                    <span className="Initiative-filter-title">{ t.contact_details }: </span>
                  { filteredContact.map(slug =>
                    <Tag
                      key={slug}
                      closable
                      color="volcano"
                      onClose={() => toggleContactFilter(slug)}
                    >{ filterContacts.filter(item => item.slug === slug)[0].t }</Tag>
                  ) }
                    </div>
                  }
                  <div className="Whitespace20"></div>
              </div>
              }
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                rowClassName={(record, index) =>
                  record.actions
                }
              />
              <Drawer
                title={t.volunteers_volunteerFilterTitle}
                width={350}
                placement="right"
                closable={true}
                onClose={() => setDrawerOpen(false)}
                visible={drawerOpen}
              >
                {
                  <React.Fragment>
                  <div className="Volunteer-taxonomy-filter">
                    <h4>{ t.volunteers_volunteerState }</h4>
                    <div>
                      <Checkbox
                        checked={!stateFilterSet}
                        onChange={() => {
                          setSelectedRowKeys([])
                          setFilteredState([])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.all }
                      </Checkbox>
                      { filterStates.map(state => (
                        <Checkbox
                          key={state.slug}
                          checked={isStateFilterSet(state.slug)}
                          onChange={() => {
                            setSelectedRowKeys([])
                            setFilteredState([state.slug])
                          }}
                          style={{ display: 'block' }}
                        >
                          { state.t }
                        </Checkbox>
                      )) }
                    </div>
                  </div>
                  {
                    !requireManagerApproval ? null :
                    <div className="Volunteer-taxonomy-filter">
                      <h4>{ t.volunteers_volunteer_manager }</h4>
                      <div>
                        <Checkbox
                          checked={!managerFilterSet}
                          onChange={() => {
                            setSelectedRowKeys([])
                            setFilteredManager([])
                          }}
                          style={{ display: 'block' }}
                        >
                          { t.all }
                        </Checkbox>
                        { filterManager.map(item => (
                          <Checkbox
                            key={item.slug}
                            checked={isManagerFilterSet(item.slug)}
                            onChange={() => {
                              setSelectedRowKeys([])
                              setFilteredManager([item.slug])
                            }}
                            style={{ display: 'block' }}
                          >
                            { item.t }
                          </Checkbox>
                        )) }
                      </div>
                    </div>
                  }
                  <div className="Volunteer-taxonomy-filter">
                    <div>
                      <Checkbox
                        checked={!newbieFilterSet}
                        onChange={() => {
                          setSelectedRowKeys([])
                          setFilteredNewbie([])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.all }
                      </Checkbox>
                      { filterNewbie.map(state => (
                        <Checkbox
                          key={state.slug}
                          checked={isNewbieFilterSet(state.slug)}
                          onChange={() => {
                            setSelectedRowKeys([])
                            setFilteredNewbie([state.slug])
                          }}
                          style={{ display: 'block' }}
                        >
                          { state.t }
                        </Checkbox>
                      )) }
                    </div>
                  </div>
                  <div className="Volunteer-taxonomy-filter">
                    <h4>{ t.volunteers_volunteerType }</h4>
                    <div>
                      <Checkbox
                        checked={!typeFilterSet}
                        onChange={() => {
                          setSelectedRowKeys([])
                          setFilteredType([])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.all }
                      </Checkbox>
                      { filterTypes.map(type => (
                        <Checkbox
                          key={type.slug}
                          checked={isTypeFilterSet(type.slug)}
                          onChange={() => {
                            setSelectedRowKeys([])
                            setFilteredType([type.slug])
                          }}
                          style={{ display: 'block' }}
                        >
                          { type.t }
                        </Checkbox>
                      )) }
                    </div>
                  </div>
                  <div className="Volunteer-taxonomy-filter">
                    <h4>{ t.volunteers_groups }</h4>
                    <div>
                      <Checkbox
                        checked={!groupsFilterSet}
                        onChange={() => {
                          setSelectedRowKeys([])
                          setFilteredGroups([])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.all }
                      </Checkbox>
                      <Checkbox
                        checked={isGroupsFilterSet('noGroups')}
                        onChange={() => {
                          setSelectedRowKeys([])
                          setFilteredGroups(['noGroups'])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.volunteers_noGroups }
                      </Checkbox>
                      { cohorts.map(cohort => (
                        <Checkbox
                          key={cohort.slug}
                          checked={isGroupsFilterSet(cohort.slug)}
                          onChange={() => {
                            setSelectedRowKeys([])
                            handleGroupCheck(cohort.slug)
                          }}
                          style={{ display: 'block' }}
                        >
                          { cohort.name }
                        </Checkbox>
                      )) }
                    </div>
                  </div>
                  <div className="Volunteer-taxonomy-filter">
                    <div>
                      <Checkbox
                        checked={!contactFilterSet}
                        onChange={() => {
                          setSelectedRowKeys([])
                          setFilteredContact([])
                        }}
                        style={{ display: 'block' }}
                      >
                        { t.all }
                      </Checkbox>
                      { filterContacts.map(type => (
                        <Checkbox
                          key={type.slug}
                          checked={isContactFilterSet(type.slug)}
                          onChange={() => {
                            setSelectedRowKeys([])
                            setFilteredContact([type.slug])
                          }}
                          style={{ display: 'block' }}
                        >
                          { type.t }
                        </Checkbox>
                      )) }
                    </div>
                  </div>

                  </React.Fragment>
                }
              </Drawer>
            </div>
          : null
      }
      {
        !addCohortMenuActive
          ? null
          : (
            <CohortAssignModal
              t={t}
              cohorts={cohorts}
              members={membersToManage}
              onClose={() => setAddCohortMenuActive(false)}
              onSave={(newCohorts, membersModified) => saveNewCohorts(newCohorts, membersModified)}
              loading={loading}
              error={error}
            />
          )
      }
      {
        !blockMemberSlugs || blockMemberSlugs.length === 0
          ? null
          : (
            <BlockModal
              slugs={blockMemberSlugs}
              onClose={() => setBlockMemberSlugs([])}
              onBlock={onBlockVolunteer}
              loading={loading}
              error={error}
            />
          )
      }
      {
        !assignManagerMemberSlugs || assignManagerMemberSlugs.length === 0
          ? null
          : (
            <AssignManagerModal
              slugs={assignManagerMemberSlugs}
              onClose={() => setAssignManagerMemberSlugs([])}
              onAssignManager={onAssignManager}
              loading={loading}
              error={error}
              managers={members}
            />
          )
      }
      <UserProfileModal
        slug={userDetail}
        visible={!!userDetail}
        onClose={() => setUserDetail(null)}
      />
    </MainLayout>)
}

export default injectIntl(Volunteer);
