import React, { Fragment, useState, useMemo, useCallback } from 'react';
import {injectIntl} from 'react-intl';

import SdgLogo from 'components/SdgLogo';
import T from 'components/T';

import { A } from 'aplanet-ui-kit';

import {
  Card,
  Row,
  Col,
  Alert,
  Modal,
  List,
  Checkbox,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const SDG_STYLES = {
  active: {
    marginBottom: 30,
  },
  inactive: {
    marginBottom: 30,
    opacity: 0.4,
  }
};

const DEFAULT_MAX_SDG = 3;

const Sdgs = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  values,
  showError,
  errors,
  handleChange,
  errorRefs,
}) => {
  const t = intl.messages;

  const [sdgInfoOpen, setSdgInfoOpen] = useState(false);
  const { sdgs } = taxonomies;

  const maxSDGs = useMemo(
    () => organization.config.max_number_of_sdgs || DEFAULT_MAX_SDG,
    [ organization ]
  );

  const getSdgStyle = useCallback((sdg) => SDG_STYLES[
    values.sdgs.includes(sdg)
      ? 'active'
      : 'inactive'
  ], [ values.sdgs ]);

  const handleSdgChange = useCallback((sdg) => () => {
    // Only allow to choose three
    if (values.sdgs.length < maxSDGs || values.sdgs.includes(sdg)) {
      handleChange('sdgs')(sdg);
    } else {
      // TODO: Shake element?
    }
  }, [ values.sdgs, maxSDGs, handleChange ]);

  return (
    <Fragment>
      <Card
        className="InitiativeForm-card"
        title={
          <span>
            {t.impacted_sdgs} (<T impacted_sdgs_max={{num: maxSDGs}} />) <InfoCircleOutlined
              onClick = { () => setSdgInfoOpen(true) }
            />
          </span>
        }
        extra={
          showError('sdgs')
            ? <Alert type="error" message={errors.sdgs}/>
            : null
        }
      >
        <div ref={errorRefs.sdgs}></div>
        <Row type="flex" gutter={30}>
          {
            sdgs.map(({slug}) => (<Col key={slug} xs={8} sm={6} md={4} lg={3}>
              <div
                className="InitiativeForm-sdglogo-wrapper"
              >
                <SdgLogo
                  square="square"
                  sdg={slug}
                  className="InitiativeForm-sdglogo"
                  style={getSdgStyle(slug)}
                  bw={!values.sdgs.includes(slug)}
                  onClick={handleSdgChange(slug)}/>
                <Checkbox
                  className="InitiativeForm-sdglogo-checkbox"
                  checked={values.sdgs.includes(slug)}
                  onChange={handleSdgChange(slug)}
                />
              </div>
            </Col>))
          }
        </Row>
      </Card>

      <Modal visible={sdgInfoOpen} title={t.impacted_sdgs} centered="centered" onCancel={() => setSdgInfoOpen(false)} footer={null}>
        <List
          layout="vertical"
          dataSource={sdgs.map(({slug}) => slug)}
          renderItem={slug => (
            <List.Item className="InitiativeForm-sdg" key={slug}>
              <List.Item.Meta
                avatar={<SdgLogo square sdg = {slug} />}
              />
              <div>
                <div>
                  <strong>{t[`sdg_${slug}`]}</strong>
                </div>
                {t[`sdg_${slug}_desc`]}
                <A href={t[`sdg_${slug}_url`]}>
                  <div>{t.learn_more_link}</div>
                </A>
              </div>
            </List.Item>
          )}
        />
      </Modal>
    </Fragment>
  );
}

export default injectIntl(Sdgs);
