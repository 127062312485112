import React, { useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {connect} from 'react-redux';
import { requestOrgVolunteers } from 'actions/api';
import DonationProgress from 'components/DonationProgress';
import { daysLeft } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';

import {
  Row,
  Col,
} from 'antd';

const Donation = ({
  intl,
  contribution_total,
  contribution_validated,
  donation_params,
  active,
  requestOrgVolunteers,
  loading: loading_member_id,
  initiative_detail,
  start_time,
  end_time,
  organizationRegion,
  ...initiative
}) => {
  const t = intl.messages;
  
  const {
    organization,
  } = useOrganizations();

  const currency = donation_params.currency || organization.config.currency;
  const target_amount = donation_params.target_amount;

  // Contributions data
  const contributions = initiative_detail.data.contributions
  const contributor_count = useMemo(() => (
    new Set(
      contributions
        .filter(({ status }) => status !== 'rejected')
        .map(({ member_id }) => member_id)
    ).size
  ), [ contributions ]);

  // Request volunteers to back
  useEffect(() => {
    requestOrgVolunteers(organization.slug)
  }, [organization.slug, requestOrgVolunteers])

  const currencyFormatter = useMemo(() => {
    return new Intl.NumberFormat(intl.locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0
    })
  }, [ intl, currency ]);

  const daysLeftStr = useMemo(() => (daysLeft(end_time) > 0 && daysLeft(end_time)) || 0, [ end_time ]);

  return (
    <section className='actionDonation'>
      <div className='actionDonation-title'>{t.donation_url_title}</div>
      <DonationProgress
        total={contribution_total}
        validated={contribution_validated}
        limit={target_amount}
        currency={currency}
        active={active}
        mode={'detail'}
        className='actionDonation-progress'
      />
      <Row type="flex" className='actionDonation-stats'>
        <Col className='actionDonation-contributed'>
          <div className='actionDonation-contributed-title'>{currencyFormatter.format(contribution_total)}</div>
          <div className='actionDonation-contributed-subtitle'>{`${t.donation_amount_target}: ${currencyFormatter.format(target_amount)}`}</div>
        </Col>
        <Col className='actionDonation-confirmed'>
          <div className='actionDonation-confirmed-title'>{currencyFormatter.format(contribution_validated)}</div>
          <div className='actionDonation-confirmed-subtitle'>{t.confirmed}</div>
        </Col>
        <Col className='actionDonation-donators'>
          <div className='actionDonation-donators-title'>{contributor_count || '0'}</div>
          <div className='actionDonation-donators-subtitle'>{t.contributors}</div>
        </Col>
        <Col className='actionDonation-daysLeft'>
          <div className='actionDonation-daysLeft-title'>{daysLeftStr}</div>
          <div className='actionDonation-daysLeft-subtitle'>{t.daysLeft}</div>
        </Col>
      </Row>
      <section className="actionDonation-info">
        { !donation_params.tax_deductible ? null :
          <section className='actionDonation-feature'>
            <img src='/images/checkmark.svg' alt={t.donation_tax_deductible}/>
            <span>{ t.donation_tax_deductible }</span>
          </section>
        }
        {
          !donation_params.show_target_amount ? null :
          <section className='actionDonation-feature'>
            <img src='/images/checkmark.svg' alt={t.donation_show_target_amount}/>
            <span>{ t.donation_show_target_amount }</span>
          </section>
        }
        { !donation_params.sources || donation_params.sources.length === 0 ? null :
          <section className='actionDonation-donationWays'>
            <span>{`${t.donation_allowed_sources}:`}</span>
            <figure className='actionDonation-donationWay'>
            { donation_params.sources.filter(Boolean).map(source => (
              <img src={`/images/icon-${source}.svg`} alt={source}/>
            ))}
            </figure>
          </section>
        }
      </section>
    </section>
  );
};

const mapStateToProps = ({
  organization,
  initiative_detail
}) => ({
  organization,
  initiative_detail
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      requestOrgVolunteers
    }
  )(Donation)
);
