const addNewAttachmentDocument = (document) => ({
  type: 'INITIATIVE_ATTACHMENT_ADD_NEW_DOCUMENT',
  document,
});

const addNewAttachmentImage = (image) => ({
  type: 'INITIATIVE_ATTACHMENT_ADD_NEW_IMAGE',
  image,
});

export {
  addNewAttachmentDocument,
  addNewAttachmentImage,
}
