import React, { useEffect, useCallback, useMemo, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import InitiativeTypeSelector from 'components/InitiativeTypeSelector';
import InitiativeForm from 'components/InitiativeForm';
import MainLayout from 'components/MainLayout';

import {
  createInitiative,
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

const DEFAULT_INITIATIVE_TYPES = ['volunteering'];
const DEFAULT_CURRENCY = 'EUR';
const SUBTYPESEP = '__';

const CreateInitiative = ({
  intl,
  match,
  history,
}) => {
  const dispatch = useDispatch();
  const t = intl.messages;
  const { push } = history;

  const {
    organization,
  } = useOrganizations();
  const { config: organizationConfig } = organization;

  const taxonomies = useSelector(state => state.taxonomies);
  const { data: profileData = {} } = useSelector(state => state.profile);
  const {
    loading: initiativeDetailLoading,
    error: initiativeDetailError
  } = useSelector(state => state.initiative_detail);

  const communityConfig = useMemo(
    () => (profileData && profileData.organization && profileData.organization.product_config.community) || {},
    [profileData],
  );

  // TODO: type and subtype
  const { type: typeStr } = match.params;
  const [
    type,
    subtype = null,
  ] = useMemo(() => (typeStr || '').split(SUBTYPESEP), [ typeStr ]);

  const acceptedTypes = useMemo(() => {
    const types = (organizationConfig || {}).initiative_types || DEFAULT_INITIATIVE_TYPES;
    const aliases = (organizationConfig || {}).initiative_type_aliases || {};
    const typesWithSubtypes = types.map(type => {
      if(!aliases[type]) {
        return [ [type, null] ];
      }
      return aliases[type].map(subtype => type === subtype ? [type, null] : [type, subtype]);
    }).reduce((acc, el) => acc.concat(el), []);
    return typesWithSubtypes.map(([type, subtype]) => {
      if(!subtype) return type;
      return `${type}${SUBTYPESEP}${subtype}`;
    });
  }, [ organizationConfig ]);

  const [ collaborative, setCollaborative ] = useState(communityConfig.collaborative_initiatives_by_default);
  const [ initiativeTitle, setInitiativeTitle ] = useState('')

  useEffect(() => {
    if(
      typeof communityConfig.collaborative_initiatives_by_default !== 'undefined' &&
      collaborative !== communityConfig.collaborative_initiatives_by_default
    ) {
      setCollaborative(communityConfig.collaborative_initiatives_by_default)
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    communityConfig,
  ]);

  useEffect(() => {
    if(typeStr && !acceptedTypes.includes(typeStr)) {
      return push(`/create`);
    }
    if(!typeStr && acceptedTypes.length === 1) {
      const newTypeStr = acceptedTypes[0];
      return push(`/create/${newTypeStr}`);
    }
  }, [ typeStr, acceptedTypes, push ]);

  const handleChange = useCallback((type, subtype) => {
    const newTypeStr = subtype
      ? `${type}${SUBTYPESEP}${subtype}`
      : type;
    return push(`/create/${newTypeStr}`);
  }, [ push ]);

  const initiativeDefaults = useMemo(() => {
    const { default_restrictions } = organizationConfig;
    const defaults = {
      title: initiativeTitle,
      collaborative,
      is_in_person: true,
      minors_allowed: default_restrictions && default_restrictions.minors_allowed,
      ...(profileData.name && { contact_person: profileData.name }),
      ...(profileData.email && { contact_email: profileData.email }),
      ...(profileData.phone && { contact_phone: profileData.phone }),
    };
    switch(type) {
      case 'donation':
        return {
          ...defaults,
          donation_params: {
            currency: (organizationConfig || {}).currency || DEFAULT_CURRENCY,
            transfer_recipient: organization.name, // TODO: Replace with legal_name
            ...((organizationConfig || {}).default_donation_params || {}),
          },
        }
      default:
        return defaults;
    }
  }, [ initiativeTitle, organizationConfig, organization.name, type, collaborative, profileData ]);

  const onCreateInitiative = useCallback((body) => {
    dispatch(createInitiative(organization.slug, body));
  }, [organization.slug, dispatch]);

  return (
    <MainLayout
      errorMessage={intl.formatMessage({id: 'error_boundary_create_initiative_message'})}
    >
      <h1>{t.create_initiative}</h1>
      { !type
      ?  <InitiativeTypeSelector
            organization={organization}
            onSubmit={handleChange}
            setInitiativeTitle={setInitiativeTitle}
            acceptedTypes={acceptedTypes}
            subtypeSep={SUBTYPESEP}
          />
      : <InitiativeForm
          organization={organization}
          formMode="create"
          type={type}
          subtype={subtype}
          loading={initiativeDetailLoading}
          error={initiativeDetailError}
          taxonomies={taxonomies}
          defaultValues={initiativeDefaults}
          onSubmit={onCreateInitiative}
          hideCategories={communityConfig.hide_categories}
          hideTargetGroups={communityConfig.hide_target_groups}
          organizationRegion={organization.region}
        />
      }
    </MainLayout>
  );
}

export default injectIntl(withRouter(CreateInitiative));
