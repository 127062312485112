import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';

import AuthorizedLink from 'containers/AuthorizedLink';
import Preview from 'components/InitiativeImages/Preview';
import Avatar from 'components/Avatar';
import Filters from 'components/InitiativeDocuments/Filters';

import {
  Row,
  Col,
  Modal,
  Tooltip,
} from 'antd';
import {
  ExclamationCircleOutlined,
  DownloadOutlined,
  CheckOutlined,
} from '@ant-design/icons';

const UserImages = ({
  intl,
  images,
  onApprove,
}) => {
  const t = intl.messages;
  const [selectedImage, setSelectedImage] = useState();
  const [userImages, setUserImages] = useState(images);

  const handleOnApprove = useCallback(
    (e) => {
      e.preventDefault();

      Modal.confirm({
        title: t.initiative_images_approve_modal_title,
        icon: <ExclamationCircleOutlined />,
        content: t.initiative_images_approve_modal_description,
        okText: t.initiative_images_approve_modal_ok,
        cancelText: t.initiative_images_approve_modal_cancel,
        onOk() {
          setSelectedImage();
          onApprove(selectedImage.id);
        },
      });
    },
    [onApprove, t, selectedImage]
  );

  const handleOnClick = useCallback(
    (image) => setSelectedImage(image),
    []
  );

  const handleOnClosePreview = useCallback(
    () => setSelectedImage(),
    []
  );

  return (
    <Row>
      <Col span={24}>
        <Filters
          data={images}
          setFilteredData={setUserImages}
        />
      </Col>
      <Col span={24}>
        <Row gutter={15}>
          { userImages.map(image => {
              return (
                <Col
                  key={image.id}
                  className="InitiativeImages-thumbnail"
                  onClick={() => handleOnClick(image)}
                >
                  <Avatar
                    shape="square"
                    size={90}
                    src={image.download}
                  />
                </Col>
              )
            })
          }
        </Row>
        <Preview
          visible={selectedImage ? true : false}
          image={selectedImage}
          close={handleOnClosePreview}
          actions={[
            <AuthorizedLink
              className="ImagePreview-download"
              key="download"
              href={selectedImage?.download}
              download={selectedImage?.filename}
              mimeType={selectedImage?.mimetype}
            >
              <Tooltip title={t.initiative_images_download}>
                <DownloadOutlined />
              </Tooltip>
            </AuthorizedLink>,
            <Tooltip title={t.initiative_images_approve}>
              <CheckOutlined
                key="approve"
                onClick={handleOnApprove}
              />
            </Tooltip>,
          ]}
        />
      </Col>
    </Row>
  )
}

export default injectIntl(UserImages);