import React from 'react';
import { injectIntl } from 'react-intl';

import QRCode from 'qrcode.react';

import {
  Modal,
  Button,
} from 'antd';

const download = (slug) => {
  // TODO: This sucks
  const canvas = document.getElementById('Volunteer-qr-canvas');
  const image = canvas.toDataURL('image/png', 1.0).replace("image/png", "image/octet-stream");
  const link = document.createElement('a');
  link.download = `aplanet-${slug}-qr.png`;
  link.href = image;
  link.click();
};

const QrModal = ({
  intl,
  organization,
  onClose,
  url,
}) => {
  const t = intl.messages;

  return (
    <Modal
      visible
      title={ t.qr_title }
      centered
      className="Volunteer-qrmodal"
      onCancel={onClose}
      footer={[
        <Button onClick={onClose} key="cancel">{ t.qr_close }</Button>,
        <Button type="primary" onClick={() => download(organization.slug)} key="ok">{ t.qr_download }</Button>
      ]}
    >
      <div className="Volunteer-qrcode">
        <QRCode
          id="Volunteer-qr-canvas"
          value={ url }
          renderAs="canvas"
          size={400}
        />
      </div>
    </Modal>
  );
}

export default injectIntl(QrModal);

