const DELETED_STATUS = 'deleted';

const isDeletedUser = (userData, statusField = 'status') => {
  return userData[statusField] === DELETED_STATUS;
};

const getUserName = (userData, intl, nameField = 'name', statusField = 'status') => {
  if (isDeletedUser(userData, statusField)) {
    return intl.formatMessage({ id: 'deleted_user_label' })
  }
  return userData[nameField];
};

export {
  isDeletedUser,
  getUserName
}
