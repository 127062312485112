import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import config from 'config';
import { withRouter } from 'react-router';

import { A } from 'aplanet-ui-kit';

const ParticipationMethod = ({
  intl,
  participation_method,
  location,
  collaboration_status,
}) => {
  const t = intl.messages;

  const participationUrl = useMemo(() => {
    let result = (' ' + location.pathname).slice(1).split('/')
    result.splice(-1, 1, 'participants')
    result = result.join('/')
    return result;
  }, [ location.pathname ]);

  return (
    collaboration_status === 'pending' || collaboration_status === 'rejected' ? null :
    <div className='actionParticipationMethod'>
      <div className='actionParticipationMethod-title'>{t.how_to_validate}</div>
      { participation_method === "qr" ?
        <section className='actionParticipationMethod-qrMode'>
          <section>
            <img className="actionParticipationMethod-qr" src="/images/qr-code.svg" alt={t.scan_qr} />
            <section>
              <div className="actionParticipationMethod-method">{ t.scan_qr }</div>
              <A newWindow href={config.QR_APP_URL}>
                <span className='actionParticipationMethod-link'>{`${t.go_to_app} →`}</span>
              </A>
            </section>
          </section>
          <section>
            <span className="actionParticipationMethod-instructions">{ t.scan_qr_instructions }</span>
          </section>
        </section>
      :
        <section className='actionParticipationMethod-manualMode'>
          <section>
            <img className="actionParticipationMethod-manual" src="/images/icon-clock.svg" alt={t.report_hours} />
            <section>
              <div className="actionParticipationMethod-method">{ t.report_hours }</div>
              <Link to={participationUrl}>
                <span className='actionParticipationMethod-link'>{`${t.go_to_confirm_hours} →`}</span>
              </Link>
            </section>
          </section>
          <section>
            <span className="actionParticipationMethod-instructions">{ t.report_hours_instructions }</span>
          </section>
        </section>
        }
    </div>
  );
};

export default injectIntl(withRouter(ParticipationMethod))
