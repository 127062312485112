import React, { useCallback, useState, } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from 'components/Avatar';
import Preview from 'components/InitiativeImages/Preview';
import ImageCrop from 'components/ImageCrop';
import AuthorizedLink from 'containers/AuthorizedLink';
import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

import { UploadButton } from 'aplanet-ui-kit';
import {
  Row,
  Col,
  Modal,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  UndoOutlined,
  PictureOutlined,
} from '@ant-design/icons';

import './style.less';


const OfficialImages = ({
  intl,
  images,
  uploadURL,
  onUploadSuccess,
  onDelete,
  onUnpublish,
  onChangeCover,
}) => {
  const t = intl.messages;

  const [selectedImage, setSelectedImage] = useState();
  const [imageCropOpen, setImageCropOpen] = useState(false);

  const onImageUploadSuccess = useCallback(
    (response) => onUploadSuccess(JSON.parse(response)),
    [onUploadSuccess],
  );

  const handleOnDelete = useCallback(
    (e) => {
      e.preventDefault();

      Modal.confirm({
        title: t.initiative_images_delete_modal_title,
        icon: <ExclamationCircleOutlined />,
        content: t.initiative_images_delete_modal_description,
        okText: t.initiative_images_delete_modal_ok,
        cancelText: t.initiative_images_delete_modal_cancel,
        onOk() {
          setSelectedImage();
          onDelete(selectedImage.id);
        },
      });
    },
    [onDelete, t, selectedImage]
  );

  const handleOnUnpublish = useCallback(
    (e) => {
      e.preventDefault();

      Modal.confirm({
        title: t.initiative_documents_unpublish_modal_title,
        icon: <ExclamationCircleOutlined />,
        content: t.initiative_documents_unpublish_modal_description,
        okText: t.initiative_documents_unpublish_modal_ok,
        cancelText: t.initiative_documents_unpublish_modal_cancel,
        onOk() {
          setSelectedImage();
          onUnpublish(selectedImage.id);
        },
      });
    },
    [onUnpublish, t, selectedImage]
  );

  const handleOnUseAsCover = useCallback(
    (image) => {
      Modal.confirm({
        title: t.initiative_images_use_as_cover_modal_title,
        icon: <ExclamationCircleOutlined />,
        content: t.initiative_images_use_as_cover_modal_description,
        okText: t.initiative_images_use_as_cover_modal_ok,
        cancelText: t.initiative_images_use_as_cover_modal_cancel,
        onOk() {
          setSelectedImage();
          onChangeCover(image);
          setImageCropOpen(false);
        },
      });
    },
    [onChangeCover, t]
  );

  const handleOnClick = useCallback(
    (image) => setSelectedImage(image),
    []
  );

  const handleOnClosePreview = useCallback(
    () => setSelectedImage(),
    []
  );

  const getPossibleActions = useCallback(
    () => {
      if (!selectedImage) {
        return [];
      }

      let actions = [
        <AuthorizedLink
          className="ImagePreview-download"
          key="download"
          href={selectedImage?.download}
          download={selectedImage?.filename}
          mimeType={selectedImage?.mimetype}
        >
          <Tooltip title={t.initiative_images_download}>
            <DownloadOutlined />
          </Tooltip>
        </AuthorizedLink>,
        <Tooltip title={t.initiative_images_use_as_cover}>
          <PictureOutlined
            key="cover"
            onClick={() => setImageCropOpen(true)}
          />
        </Tooltip>,
        <Tooltip title={t.initiative_images_delete}>
          <DeleteOutlined
            key="delete"
            onClick={handleOnDelete}
          />
        </Tooltip>,
      ];

      if (!selectedImage?.is_official) {
        actions.splice(2, 0, (
          <Tooltip title={t.initiative_images_unpublish}>
            <UndoOutlined
              key="unpublish"
              onClick={handleOnUnpublish}
            />
          </Tooltip>
        ));
      }
      return actions;
    },
    [selectedImage, handleOnDelete, handleOnUnpublish, t]
  );

  // For upload button
  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(
    () => dispatch(_refreshAccessToken()),
    [ dispatch ]
  );

  return (
    <Row className="InitiativeImages">
      <Col span={24}>
        <Row gutter={15}>
          <Col>
            <UploadButton
              intl={intl}
              actionUrl={uploadURL}
              accept="image/*"
              onSuccess={onImageUploadSuccess}
              component={
                <Avatar shape="square" size={90} icon={<PlusOutlined />} />
              }
              api_requests={api_requests}
              refreshAccessToken={refreshAccessToken}
            />
          </Col>
          { images.map(image => {
              return (
                <Col
                  key={image.id}
                  className="InitiativeImages-thumbnail"
                  onClick={() => handleOnClick(image)}
                >
                  <Avatar
                    shape="square"
                    size={90}
                    src={image.download}
                  />
                </Col>
              )
            })
          }
        </Row>
        <Preview
          visible={!!selectedImage && !imageCropOpen}
          image={selectedImage}
          close={handleOnClosePreview}
          actions={getPossibleActions()}
        />
      </Col>

      { imageCropOpen &&
      <ImageCrop
        t={t}
        src={selectedImage.download}
        onCancel={() => setImageCropOpen(false)}
        onCrop={image => handleOnUseAsCover(image)}
      />
      }
    </Row>
  )
}

export default injectIntl(OfficialImages);
