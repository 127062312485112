import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { useHotkeys } from 'react-hotkeys-hook';
import InitiativeCard from 'components/InitiativeCard';
import MainLayout from 'components/MainLayout';

import useOrganizations from 'utils/useOrganizations';
import {
  Spin,
  Input,
  Modal,
  Button,
  Empty
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './style.less';

import {
  requestProposals,
  rejectProposal,
} from 'actions/api';

const Proposal = ({
  intl,
  proposals,
  requestProposals,
  history,
  taxonomies,
  rejectProposal
}) => {
  const {
    organization,
  } = useOrganizations();

  useEffect(() => {
    requestProposals(organization.slug);
  }, [
    organization.slug,
    requestProposals,
  ]);

  const [searchText, setSearchText] = useState('');
  const [ modalShown, setModalShown ] = useState(false);
  const [ rejectReason, setRejectReason ] = useState('');
  const [ initiativeToReject, setInitiativeToReject ] = useState(false)

  const t = intl.messages;

  const {
    items,
    loading,
  } = proposals;

  // Focus search field on Ctrl+F
  const searchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if(searchInputRef.current.focus) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  }, [ searchInputRef ]);

  const closeModal = useCallback(() => {
    setInitiativeToReject(false)
    setRejectReason('');
    setModalShown(false);
  }, [setInitiativeToReject]);

  const submitRejectModal = useCallback(() => {
    rejectProposal(organization.slug, initiativeToReject, rejectReason)
    setModalShown(false);
  }, [ rejectReason, rejectProposal, organization, initiativeToReject ]);

  const sortInitiativesByTime = (a, b) => {
    if(a.start_time > b.start_time){
      return 1
    }else if(a.start_time < b.start_time){
      return -1
    }else{
      return 0
    }
  }

  const filteredData = useMemo(() => {
    // Bail out early if no filters are set
    if(!searchText) return items;

    return items.filter(({
      title,
      description,
      address,
      organization_name,
      collaborators = [],
      start_time,
      end_time,
      categories = [],
      competences = [],
      sdgs = [],
    }) => {
      const searchMatch = (
        !searchText ||
        (title || '').toLowerCase().includes(searchText) ||
        (description || '').toLowerCase().includes(searchText) ||
        (address || '').toLowerCase().includes(searchText) ||
        (organization_name || '').toLowerCase().includes(searchText) ||
        collaborators.find(({ name }) => (name || '').toLowerCase().includes(searchText))
      );

      return searchMatch
    })
  }, [items, searchText]);

	const pendingItems = useMemo(() => filteredData.sort(sortInitiativesByTime).filter((i) => i.status === 'proposed' && i.submitter), [filteredData])
	const revisedItems = useMemo(() => filteredData.sort(sortInitiativesByTime).filter((i) => i.status !== 'proposed' && i.submitter), [filteredData])

  return (
    <MainLayout
      errorMessage={intl.formatMessage({id: 'error_boundary_proposals_message'})}
    >
      <h1>{t.proposals}</h1>
      { loading && (!items || items.length === 0) ? <Spin size="large" /> : null }
      { !items && items.length < 1 ? null :
        <div>
          <div className="Whitespace20"></div>
          <h2>{ t.proposals_sent }</h2>
          <div>
            <Input.Search
              type="text"
              placeholder={t.proposal_search_placeholder}
              enterButton={t.proposal_search}
              prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              onSearch={(term) => setSearchText((term || '').toLowerCase())}
              onChange={(term) => !term.target.value && setSearchText('')}
              allowClear
              size="large"
              ref={searchInputRef}
            />
          </div>
          <div className="Whitespace20"></div>
          { pendingItems.length > 0
            ? <section className='initiativeContainer'>
              {
                pendingItems.map(initiative => (
                  <InitiativeCard
                    from={'proposal'}
                    activeNow={true}
                    organization={organization}
                    searchText={searchText}
                    rejectProposal={() => setModalShown(true)}
                    setInitiativeToReject={setInitiativeToReject}
                    {...initiative}
                  />
                ))
              }
              </section>
            : <Empty
                className='big'
                image={'images/icon-empty-inbox.svg'}
                description={t.emptyProposals}
              />

          }
          { !revisedItems || revisedItems.length === 0 ? null :
            <React.Fragment>
              <section className='initiativeDivider'>
                <figure className='line'/>
                <section className='alreadyPassed'>
                  <span>{t.reviewed}</span>
                  <figure>{revisedItems.length}</figure>
                </section>
                <figure className='line'/>
              </section>
              <section className='initiativeContainer'>
                {revisedItems.map(initiative => (
                    <InitiativeCard
                      from={'proposal'}
                      activeNow={true}
                      organization={organization}
                      searchText={searchText}
                      rejectProposal={() => setModalShown(true)}
                      setInitiativeToReject={setInitiativeToReject}
                      {...initiative}
                    />
                  ))
                }
              </section>
            </React.Fragment>
          }
        </div>
      }
      <Modal
        visible={modalShown}
        title={t.reject_proposal}
        onCancel={closeModal}
        onOk={submitRejectModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>{ t.cancel }</Button>,
          <Button key="submit" type="primary" disabled={!rejectReason} onClick={submitRejectModal}>{ t.reject_confirm }</Button>
        ]}
      >
        <p>{ t.reject_proposal_desc }</p>
        <Input.TextArea
          autoSize={ false }
          rows={6}
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
    </MainLayout>
  )
}

const mapStateToProps = ({
  proposals,
  taxonomies
}) => ({
  proposals,
  taxonomies
});

export default injectIntl(connect(mapStateToProps, { requestProposals, rejectProposal })(withRouter(Proposal)));
