import React, {
  useMemo,
  useCallback,
} from 'react';
import { Menu, Row, Col, Dropdown, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import useOrganizations from 'utils/useOrganizations';
import {
  resetOrganization,
} from 'actions/organizationTree';

import config from 'config';

import Avatar from 'components/Avatar';
import OrganizationLogo from 'components/OrganizationLogo';

import { A } from 'aplanet-ui-kit';

import './style.less';

const { Text } = Typography;

const OrgLogos = injectIntl(({
  intl,
  organization,
}) => {
  const t = intl.messages;

  const atlasBaseUrl = useMemo(() => {
    return organization?.config?.atlas?.custom_redirect_urls?.admin || config.ATLAS_APP_URL;
  }, [
    organization,
  ]);

  return (
    <Row type='flex' align='middle'>
      <Col>
        <Link to="/">
          <OrganizationLogo
            className='Nav-logo'
            {...organization}
            showPoweredBy={
              (
                organization.config || {}
              ).show_powered_by
            }
          />
        </Link>
      </Col>
      <Col>
        <Text className='AppHeader-appTitle'>
          { t.product_community }
        </Text>
      </Col>
      {organization?.products?.includes('atlas') &&
      <Col>
        <A href={atlasBaseUrl} className='AppHeader-linkToAtlas'>
          { t.product_atlas }
        </A>
      </Col>
      }
    </Row>
  );
});

const AvatarDropdown = withRouter(({
  t,
  dispatch,
  avatar,
  name,
  memberships = [],
  organization,
}) => {
  const handleResetOrg = useCallback(() => dispatch(resetOrganization()), [ dispatch ]);

  return (
    <Dropdown
      overlay={
        <Menu>
          { memberships.length <= 1 ? null
              :
                <Menu.Item
                  onClick={handleResetOrg}
                >
                  { t.avatardropdown_change_organization }
                </Menu.Item>
          }
          <Menu.Item>
            <a
              href={`${config.ACCOUNTS_URL}/profile?org=${organization.slug}`}>
              { t.avatardropdown_profile }
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              href={`${config.ACCOUNTS_URL}/organization?org=${organization.slug}`}>
              { t.avatardropdown_organization }
            </a>
          </Menu.Item>
          <Menu.Item>
            <Link to="/logout">
              {t.avatardropdown_logout}
            </Link>
          </Menu.Item>
        </Menu>
      }
      className="AppHeader-avatarMenu"
      trigger={['click']}
    >
      <section>
        <Avatar
          shape="circle"
          src={avatar || undefined}
          name={name || undefined}
          icon={<UserOutlined />}
          className='AppHeader-avatar'
        />
        <div
          className='AppHeader-profileName'
        >{name}</div>
        <img
          className='AppHeader-chevron'
          src='/images/assets/chevron-down.svg'
          alt='chevron-down'
        />
      </section>
    </Dropdown>
  );
});

const AppHeader = ({
  intl,
  logout,
}) => {
  const t = intl.messages;

  const dispatch = useDispatch();

  const {
    organization,
    memberships,
  } = useOrganizations();

  const {
    data: profile
  } = useSelector(state => state.profile);

  const {
    avatar,
    name,
  } = profile || {};

  return (
    <section className='AppHeader'>
      <Row
        type='flex'
        justify='space-between'
        align='middle'
      >
        <Col
          className="AppHeader__left"
        >
          <OrgLogos
            organization={organization}
          />
        </Col>
        <Col
          className="AppHeader__right"
        >
          <Row
            type='flex'
            justify='end'
            align='middle'
            gutter={8}
          >
            <Col>
              <AvatarDropdown
                t={t}
                dispatch={dispatch}
                avatar={avatar}
                name={name}
                memberships={memberships}
                organization={organization}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}

export default injectIntl(
  withRouter(
    AppHeader
  )
);
