import React from 'react';
import { injectIntl } from 'react-intl';
import { Card, Tag, Row, Col } from 'antd';

import Categories from './Categories';
import Share from './Share';

const DEFAULT_IMAGE = 'https://aplanet-static.ams3.digitaloceanspaces.com/assets/default_initiative_image.jpg';

const CANCELED_STATUSES = new Set([ 'suspended', 'canceled' ]);

const Title = ({ intl, ...initiative }) => {
  const t = intl.messages;
  const isCanceled = CANCELED_STATUSES.has(initiative.status);
  return (
    <Card
      cover={<img className="InitiativeDetail-cover" alt="" src={initiative.image || DEFAULT_IMAGE} />}
    >
      <Row type="flex" gutter={{ xs: 8, sm: 16, md: 24}}>
        <Col style={{ flex: 1 }}>
          <h3 className="InitiativeDetail-title">
            { initiative.title }
          </h3>
          <Row
            type="flex"
          >
            <Col style={{ flex: 1 }}>
              <Categories t={t} {...initiative}/>
            </Col>
            <Share t={t} {...initiative}/>
          </Row>
          <Categories t={t} {...initiative}/>
        </Col>
        { !isCanceled ? null :
          <Col style={{ flex: '0 1' }}>
            <Tag color="red">{ t.canceled }</Tag>
          </Col>
        }
      </Row>
    </Card>
  );
};

export default injectIntl(Title);
