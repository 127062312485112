import { loop, Cmd } from 'redux-loop';

const DETAIL_TARGET  = 'user_detail';
const DETAIL_TARGET_PDF  = 'user_detail_pdf';

const isTarget = (target) => [DETAIL_TARGET, DETAIL_TARGET_PDF].includes(target);
const isGeneralTarget = (target) => target === DETAIL_TARGET;

const waitForPdf = (endpoint) => ({
  type: 'ACTIVE_WAIT_REQUEST',
  endpoint,
});

const initialState = {
  data: null,
  loading: false,
  error: false,
  pdf: {
    processing: false,
    ready: false,
    error: null,
    endpoint: '',
    ticket: '',
    url: '',
  },
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_RESET':
      if(!isTarget(action.target)) return state;
      return initialState;
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      if(isGeneralTarget(action.target)) {
        return {
          data: action.method === 'GET' ? null : state.data,
          loading: true,
          error: false,
          pdf: initialState.pdf,
        };
      }
      return state;
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      // Get course detail
      if(isGeneralTarget(action.response.target)){
        return {
          data: action.response.result,
          loading: false,
          error: false,
          pdf: initialState.pdf,
        }
      }

      // here action.response.target === PDF_TARGET
      const { ticket } = action.response.result || {};
      if(!ticket) return {
        ...state,
        pdf: initialState.pdf,
      }

      const endpoint = `${action.response.endpoint}/status/${ticket}`;
      return loop({
        ...state,
        pdf: {
          ...(action.response.result || initialState.pdf),
          processing: true,
          ready: false,
          error: false,
          endpoint,
        },
      }, Cmd.action(waitForPdf(endpoint)));

    case 'ACTIVE_WAIT_COMPLETE':
      if(!state.pdf.endpoint || state.pdf.endpoint !== action.endpoint) return state;
      return {
        ...state,
        pdf: {
          ...state.pdf,
          done: true,
          processing: false,
          error: false,
        }
      };
    case 'ACTIVE_WAIT_FAILED':
      if(!state.pdf.endpoint || state.pdf.endpoint !== action.endpoint) return state;
      return {
        ...state,
        pdf: {
          ...state.pdf,
          done: false,
          processing: false,
          error: true,
        }
      };

    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as user_detail,
};
