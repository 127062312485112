import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  FilterOutlined,
  ClockCircleOutlined,
  UserOutlined,
  MessageOutlined,
  CheckOutlined,
  CloseOutlined,
  RollbackOutlined,
  StopOutlined,
  SaveOutlined,
  EditOutlined,
  SearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { A } from 'aplanet-ui-kit';

import colors from 'colors'
import Avatar from 'components/Avatar';
import { formatShort, isAfterNow } from 'utils/date';
import Highlighter from 'react-highlight-words'
import { useHotkeys } from 'react-hotkeys-hook'
import { stringToHash } from 'utils/hash'
import FloatingButton from 'components/FloatingButton'
import UserProfileModal from 'containers/UserProfileModal'
import { usePermissionList } from 'components/PermissionSwitch';
import AuthorizedLink from 'containers/AuthorizedLink';
import { formatDateLong } from 'utils/date';

import useOrganizations from 'utils/useOrganizations';

import { getParticipantsDownloadUrl } from 'actions/api';

import {
  Button,
  Col,
  Input,
  InputNumber,
  List,
  Modal,
  Radio,
  Row,
  Table,
  Tabs,
  Tag
} from 'antd';

import './style.less';

import {
  setGrantedHours,
  setGrantedPoints,
  rejectParticipant,
  unrejectParticipant,
  validateParticipation,
  rejectParticipation,
  broadcastMessage,
  resetBroadcastMessage,
  requestOrgVolunteers,
  addParticipant,
  sendParticipantCertificate,
} from 'actions/api';
import AddParticipantsModal from 'containers/AddParticipantsModal';
import RejectModal from 'components/RejectModal';
import SendMessageModal from 'components/SendMessageModal';
import SendCertificateModal from 'components/SendCertificateModal';
import InitiativeParticipantsFilters from './InitiativeParticipantsFilters.js';
import ParticipantIndicators from 'components/ParticipantIndicators';
import { isDeletedUser, getUserName } from 'utils/user';

const { TabPane } = Tabs;

const getPendingCount = ({ participations = [] }) => {
  // TODO: WHAT DO if claim_mode = 'granted'
  return participations
    .filter(({ type, status }) => (type !== 'qr' && status === 'pending'))
    .length;
};

const InitiativeParticipants = ({
  intl,
  loading: loading_member_id,
  isCollectionInitiative,
  ...initiative
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const { done } = useSelector(state => state.broadcast_message);
  const { cohorts } = useSelector(state => state.taxonomies);
  const { items: volunteersItems } = useSelector(state => state.volunteers);
  const {
    data: initiativeDetailData,
    loading: initiativeDetailLoading,
    error: initiativeDetailError
  } = useSelector(state => state.initiative_detail);

  const {
    organization,
  } = useOrganizations();
  const { config: organizationConfig } = organization;

  const requireManagerApproval = useMemo(() => {
    if(!organizationConfig || isCollectionInitiative) {
      return false;
    }
    return organizationConfig.require_manager_approval && (
      !organizationConfig.consider_working_hours || (initiativeDetailData || {}).is_working_hours
    );
  }, [
    organizationConfig,
    initiativeDetailData,
    isCollectionInitiative
  ]);

  const hasPoints = useMemo(() => {
    if (isCollectionInitiative) {
      return false;
    }
    const orgFeatures = organization.features;
    if(!orgFeatures) {
      return false;
    }
    return orgFeatures.includes('points') &&
      typeof (initiativeDetailData || {}).points !== 'undefined' &&
      (initiativeDetailData || {}).points >= 0
  }, [
    organization,
    initiativeDetailData,
    isCollectionInitiative
  ]);

  const { collaborators, collaboration_status } = initiative;
  const isOrganizer = collaboration_status === 'organizer';

  const participants = useMemo(() => initiativeDetailData.participation, [initiativeDetailData.participation]);
  const participants_other = useMemo(() => initiativeDetailData.participation_other, [initiativeDetailData.participation_other]);

  const my_participant_count = useMemo(() => {
    const participantsCount = isCollectionInitiative
    ? (initiative.participation || []).length
    : (participants || []).filter(p => p.participant_status === 'accepted').length;
    return participantsCount;
  }, [initiative.participation, isCollectionInitiative, participants]);
  
  const other_participant_count = useMemo(() => {
    const otherParticipantsCount = isCollectionInitiative
    ? (initiative.participation_other || []).length
    : (participants_other || []).filter(p => p.participant_status === 'accepted').length;
    return otherParticipantsCount;
  }, [initiative.participation_other, isCollectionInitiative, participants_other]);

  const permissionList = usePermissionList();

  const [showOther, setShowOther] = useState(isOrganizer && my_participant_count === 0 && other_participant_count > 0);
  const [userDetail, setUserDetail] = useState(null);
  const [userCollaboratorSlug, setUserCollaboratorSlug] = useState(null);

  const [modalDetailParticipation, setModalDetailParticipation] = useState(null);

  useEffect(() => {
    if(done) {
      Modal.success({
        title: t.sent,
        content: t.broadcast_message_sent,
        onOk: () => dispatch(resetBroadcastMessage()),
      });
    }
  }, [dispatch, done, t]);

  // Request volunteers to back
  useEffect(() => {
    dispatch(requestOrgVolunteers(organization.slug));
  }, [dispatch, organization.slug])

  // Handle name/contact filtering
  const handleContactFilter = useCallback((value, record) => {
    return (value === 'onlyPhone' && record.phone && !record.email)
    || (value === 'onlyEmail' && !record.phone && record.email);
  }, []);

  // Handle group filtering
  const handleGroupFilter = useCallback((value, record) => {
    if (value === 'noGroups'){
      return record.participation.cohorts.length === 0;
    }
    return record.participation.cohorts.includes(value);
  }, []);

  // Handle organizations filtering
  const handleOrganizationFilter = useCallback((value, record) => {
    return record.organization_slug.includes(value)
  }, []);

  // Handle validation filtering
  const handleValidationFilter = useCallback((value, record) => {
    // Dont have participations
    if (!record.participation || !record.participation.participations || record.participation.participations.length < 1){
      return (value !== 'with')
    }

    // Look participations, have pending ones?
    let havePendValidations = false
    record.participation.participations.forEach(participation => {
      if(participation.status === 'pending'){
        havePendValidations = true
      }
    })
    return (value === 'with') ? havePendValidations : !havePendValidations
  }, []);

  // Handle time filtering
  const handleTimeFilter = useCallback((value, record) => {
    return (value === 'with')
    ? (record.participation.valid_hours > 0)
    : (record.participation.valid_hours <= 0);
  }, []);

  // Slug to name
  const slugToNameByType = useCallback((slug, type) => {
    if (type === 'cohorts') {
      if (slug === 'noGroups') {
        return t.volunteers_noGroups;
      }
      const cohortFound = cohorts.find(cohort => cohort.slug === slug);
      return cohortFound && cohortFound.name;
    }

    if (type === 'organizations') {
      const collaboratorFound = collaborators.find(c => c.slug === slug);
      return collaboratorFound && collaboratorFound.name;
    }
    return slug;
  }, [
    t,
    cohorts,
    collaborators
  ]);

  // Search text state
  const [searchText, setSearchText] = useState('')

  // Reject participants feature
  const [rejectMemberRecords, setRejectMemberRecords] = useState([]);

  // Participants data
  const data = useMemo(() => participants.map((p) => {
    let participantData = {
      key: p.participant_id,
      name: p.name,
      member_id: p.member_id,
      slug: p.slug,
      participant_id: p.participant_id,
      email: p.email,
      phone: p.phone,
      avatar: p.avatar,
      status: p.participant_status,
      member_status: p.status,
      joined_at: p.joined_at,
      entry_count: (p.participations || []).length,
      valid_hours: p.valid_hours,
      role: p.member_role,
      is_newbie: p.is_newbie,
      pending_count: getPendingCount(p),
      loading: p.member_id === loading_member_id,
      participation: p,
      granted_hours: p.granted_hours,
      reject_reason: p.reject_reason
    }
    if (isCollectionInitiative) {
      participantData = {
        ...participantData,
        participations: p.participations,
      }
    } else {
      participantData = {
        ...participantData,
        granted_points: p.points,
        manager_approval_status: p.manager_approval_status,
        manager: p.manager,
        accepted_at: p.accepted_at,
      }
    }
    return participantData;
  }).sort((a, b) => a.accepted_at && b.accepted_at && (new Date(b.accepted_at) - new Date(a.accepted_at))),
  [
    participants,
    loading_member_id,
    isCollectionInitiative
  ]);

  // Participants (collaborating from other organization) data
  const collaboratorData = useMemo(() => (participants_other || []).map((p) => {
    let participantCollaboratorData = {
      key: p.participant_id,
      name: p.name,
      member_id: p.member_id,
      email: p.email,
      phone: p.phone,
      slug: p.slug,
      participant_id: p.participant_id,
      avatar: p.avatar,
      organization: p.organization_name,
      organization_slug: collaborators.find(c => c.id === p.organization_id).slug,
      organization_avatar: p.organization_logo_small,
      joined_at: p.joined_at,
      status: p.participant_status,
      member_status: p.status,
      entry_count: (p.participations || []).length,
      pending_count: getPendingCount(p),
      participation: p
    }
    if (isCollectionInitiative) {
      participantCollaboratorData = {
        ...participantCollaboratorData,
        loading: false
      }
    } else {
      participantCollaboratorData = {
        ...participantCollaboratorData,
        accepted_at: p.accepted_at,
        granted_hours: p.granted_hours,
        granted_points: p.points
      }
    }
    return participantCollaboratorData;
  }).sort((a, b) => a.accepted_at && b.accepted_at && (new Date(b.accepted_at) - new Date(a.accepted_at))),
  [
    participants_other,
    collaborators,
    isCollectionInitiative
  ]);

  // Row selection functionality
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [collaborationSelectedRowKeys, setCollaborationSelectedRowKeys] = useState([])

  const rowSelection = useMemo(() => {
    const selection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
      }
    }
    if (isCollectionInitiative) {
      return selection;
    }
    const unblockedRecords = data.filter(d => d.status !== 'rejected');
    const showDeselectAll = unblockedRecords.length === selectedRowKeys.length;
    return {
      ...selection,
      getCheckboxProps: record => ({
        disabled: record.status === 'rejected'
      }),
      hideDefaultSelections: true,
      selections: [{
        key: 'toggle_select_deselect',
        text: showDeselectAll
          ? `${t.deselect_all} (${unblockedRecords.length}) ${t.records}`
          : `${t.select_all} (${unblockedRecords.length}) ${t.records}`,
        onSelect: () => {
          if (showDeselectAll) {
            return setSelectedRowKeys([]);
          }
          return setSelectedRowKeys(unblockedRecords.map(d => d.key));
        },
      }],
    };
  }, [
    selectedRowKeys,
    isCollectionInitiative,
    data,
    t
  ]);

  const collaborationRowSelection = useMemo(() => {
    const selection = {
      selectedRowKeys: collaborationSelectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setCollaborationSelectedRowKeys(selectedRowKeys)
      }
    }
    if (isCollectionInitiative) {
      return selection;
    }
    const unblockedRecords = collaboratorData.filter(d => d.status !== 'rejected');
    const showDeselectAll = unblockedRecords.length === collaborationSelectedRowKeys.length;
    return {
      ...selection,
      getCheckboxProps: record => ({
        disabled: record.status === 'rejected'
      }),
      hideDefaultSelections: true,
      selections: [{
        key: 'toggle_select_deselect',
        text: showDeselectAll
          ? `${t.deselect_all} (${unblockedRecords.length}) ${t.records}`
          : `${t.select_all} (${unblockedRecords.length}) ${t.records}`,
        onSelect: () => {
          if (showDeselectAll) {
            return setCollaborationSelectedRowKeys([]);
          }
          return setCollaborationSelectedRowKeys(unblockedRecords.map(d => d.key));
        },
      }],
    };
  }, [
    t,
    collaboratorData,
    isCollectionInitiative,
    collaborationSelectedRowKeys,
  ]);

  // Returns participants selected
  const participantsSelected = useMemo(() => (
    !showOther
    ? data.filter((p) => selectedRowKeys.includes(p.key))
    : collaboratorData.filter((p) => collaborationSelectedRowKeys.includes(p.key))
  ), [collaborationSelectedRowKeys, selectedRowKeys, showOther, data, collaboratorData])

  const hasDeletedParticipantsSelected = useMemo(() => (
    participantsSelected.some(p => isDeletedUser({ status: p.member_status }))
  ), [participantsSelected]);

  // State for showing add participant modal
  const [addParticipantModal, setAddParticipantModal] = useState(false);

  // Focus search field on Ctrl+F
  const searchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if (!addParticipantModal && searchInputRef.current.focus) {
      searchInputRef.current.focus()
      e.preventDefault();
    }
  }, [searchInputRef, addParticipantModal]);

  /* --- Filters --- */
  const [filtersConfigState, setFiltersConfigState] = useState({
    filteredContact: [],
    filteredTime: [],
    filteredValidation: [],
    filteredOrganizations: [],
    filteredManagerApproval: [],
    filteredStatus: [],
    filteredNewbie: [],
    filteredGroups: []
  })

  const {
    filteredContact,
    filteredTime,
    filteredValidation,
    filteredOrganizations,
    filteredManagerApproval,
    filteredStatus,
    filteredNewbie,
    filteredGroups
  } = filtersConfigState;

  const [openFilters, setOpenFilters] = useState(false)

  const onCloseFilters = useCallback(() => {
    setOpenFilters(false);
  }, []);

  const onSetFilters = useCallback((filterConfig) => {
    setFiltersConfigState(prevState => ({
      ...prevState,
      ...filterConfig
    }));
  }, []);

  const [shouldCleanFilters, setShouldCleanFilters] = useState(false);

  const onClearedFilters = useCallback(() => {
    if (shouldCleanFilters) {
      setShouldCleanFilters(false);
    }
  }, [shouldCleanFilters]);

  // Send message modal controller (open/close)
  const [sendMessageModal, setSendMessageModal] = useState(false)

  // Send certificate modal controller (open/close)
  const [sendCertificateModal, setSendCertificateModal] = useState(false);

  // Table active
  const [tableActive, setTableActive] = useState('state')

  // Member on manual time edit
  const [memberOnEditHours, setMemberOnEditHours] = useState({})
  const [memberOnEditPoints, setMemberOnEditPoints] = useState({})

  // Returns participants selected id
  const participantsSelectedId = useMemo(
    () => participantsSelected.map(p => p.participation.participant_id),
    [participantsSelected]
  );

  // Granted hours input value
  const [inputGrantedHours, setInputGrantedHours] = useState(0)
  const [inputGrantedPoints, setInputGrantedPoints] = useState(0)
  const [bulkInputGrantedHours, setBulkInputGrantedHours] = useState(0)
  const [bulkInputGrantedPoints, setBulkInputGrantedPoints] = useState(0)

  // Save manual time for a record and clean input
  const saveManualTime = useCallback((record) => {
    dispatch(setGrantedHours(organization.slug, initiative.slug, record.participation.participant_id, inputGrantedHours));
    setInputGrantedHours(0);
    setMemberOnEditHours({});
  }, [
    organization,
    initiative,
    inputGrantedHours,
    dispatch
  ]);

  const saveManualPoints = useCallback((record) => {
    dispatch(setGrantedPoints(organization.slug, initiative.slug, record.participation.participant_id, inputGrantedPoints));
    setInputGrantedPoints(0);
    setMemberOnEditPoints({});
  }, [
    dispatch,
    organization.slug,
    initiative.slug,
    inputGrantedPoints
  ]);

  const showUnrejectConfirm = useCallback(records => {
    Modal.confirm({
      title: t.unreject_confirmation,
      content: t.unreject_confirmation_desc,
      okText: t.unreject_yes,
      okType: 'primary',
      cancelText: t.unreject_no,
      onOk() {
        records.forEach(record => {
          if (record.status === 'rejected'){
            dispatch(unrejectParticipant(organization.slug, initiative.slug, record.member_id));
          }
        })
      },
      onCancel() {},
    });
  }, [t, dispatch, organization.slug, initiative.slug]);

  // Render participant actions for each participant in their row
  const getParticipationActions = useCallback((type, status, participant_id, id, disable_buttons = false) => {
    switch(status) {
      case 'validated':
        return [ <span className="InitiativeParticipant-validated">{ t.validated }</span> ];
      case 'rejected':
        return [ <span className="InitiativeParticipant-rejected">{ t.rejected }</span> ];
      case 'pending':
      default:
        if(type === 'qr') {
          // QR participations are validated automatically
          return [ <span className="InitiativeParticipant-pending">{ t.pending }</span> ];
        } else {
          return [
            <Button
              className='acceptRequest'
              key="accept"
              size="small"
              type="primary"
              shape='circle'
              icon={<CheckOutlined />}
              disabled={disable_buttons}
              onClick={() => dispatch(validateParticipation(organization.slug, initiative.slug, participant_id, id))}
            />,
            <Button
              className='rejectRequest'
              key="reject"
              size="small"
              type='danger'
              shape='circle'
              icon={<CloseOutlined />}
              disabled={disable_buttons}
              onClick={() => dispatch(rejectParticipation(organization.slug, initiative.slug, participant_id, id)) }
            />
          ];
        }
    };
  }, [t, dispatch, organization.slug, initiative.slug]);

  const onDeselectRowKeys = useCallback(() => {
    setSelectedRowKeys([]);
    setCollaborationSelectedRowKeys([])
  }, []);

  // Cleans selected and all filters
  const cleanFiltersAndSelected = useCallback(() => {
    onDeselectRowKeys();
    setShouldCleanFilters(true);
  }, [onDeselectRowKeys]);

  // Selected plural or singular
  const selectedText = useCallback(() => {
    return selectedRowKeys.length > 1 ? t.volunteers_selected : t.volunteers_one_selected
  }, [ selectedRowKeys, t ]);

  const showContactInfo = useMemo(() => (
    !showOther || (showOther && organizationConfig && organizationConfig.show_contact_collaborator_participation)
  ), [organizationConfig, showOther]);

  const nameCell = useCallback((name, record) => {
    const userData = { ...record, status: record.member_status };
    return (
      <React.Fragment>
        <Row type="flex" gutter={10} justify="left" align="middle">
          <Col>
            { permissionList.has('can_manage_volunteers') && record.slug && !isDeletedUser(userData)
              ? (
                <A
                  onClick={() => {
                    setUserDetail(record.slug);
                    showOther && setUserCollaboratorSlug(record.organization_slug);
                  }}
                >
                  { isDeletedUser(userData)
                    ? <Avatar icon={<DeleteOutlined />} />
                    : <Avatar
                        size={38}
                        shape="circle"
                        src={record.avatar}
                        name={getUserName(userData, intl)}
                        icon={<UserOutlined />}
                      />
                  }
                </A>
              ) : (
                isDeletedUser(userData)
                ? <Avatar icon={<DeleteOutlined />} />
                : <Avatar
                    size={38}
                    shape="circle"
                    src={record.avatar}
                    name={getUserName(userData, intl)}
                    icon={<UserOutlined />}
                  />
              )
            }
          </Col>
          <Col>
            <Row type="flex" gutter={2}>
              <Col>
                { record.is_newbie && 
                    <img className="typeIcon typeIcon-img" src='/images/icon-new.svg' alt={t.newbie} />
                }
              </Col>
              <Col>
                {
                  !searchText
                    ? getUserName(userData, intl)
                    : <Highlighter highlightStyle={{
                          backgroundColor: colors['@text-selection-bg'],
                          padding: 0
                        }} searchWords={[searchText]} autoEscape textToHighlight={getUserName(userData, intl)}/>
                }
              </Col>
            </Row>
              { showContactInfo && (
                <>
                  <Row>
                    { record.email && !isDeletedUser(userData) && (
                        !searchText
                        ? record.email
                        : <Highlighter
                            highlightStyle={{
                              backgroundColor: colors['@text-selection-bg'],
                              padding: 0
                            }}
                            searchWords={[searchText]}
                            textToHighlight={record.email}
                            autoEscape
                          />
                    )}
                  </Row>
                  <Row>
                    { record.phone && !isDeletedUser(userData) && (
                        !searchText
                        ? record.phone
                        : <Highlighter
                            highlightStyle={{
                              backgroundColor: colors['@text-selection-bg'],
                              padding: 0
                            }}
                            searchWords={[searchText]}
                            textToHighlight={record.phone}
                            autoEscape
                          />
                    )}
                  </Row>
                </>
              )}
  
          </Col>
        </Row>
      </React.Fragment>
    )
  }, [permissionList, t.newbie, searchText, showContactInfo, showOther, intl]);

  const initiativeDone = !isAfterNow(initiative.end_time);

  const statusVolunteerCell = useCallback((status, record) => {
    switch(status) {
      case 'not_going':
        return <div className="InitiativeParticipant-status-notgoing">{ t.not_going }</div>
      case 'rejected':
        return (
          record.reject_reason ? (
            <A
              className="InitiativeParticipant-status-rejected"
              onClick={
                () => Modal.info({ title:t.reason_reject, content: record.reject_reason })
              }
            >
              { t.rejected }
            </A>
          ) : (
            <div className="InitiativeParticipant-status-rejected">{ t.rejected }</div>
          )
        );
      case 'accepted':
      default:
        return <div className="InitiativeParticipant-status-participated">{ initiativeDone ? t.participated : t.participating }</div>
    }
  }, [
    t,
    initiativeDone,
  ]);

  const statusCollectionContributorCell = useCallback((status, record) => {
    switch(status) {
      case 'not_going':
        return (
          <div className="InitiativeParticipant-status-notgoing">
            { t.collection_not_going }
          </div>
        );
      case 'rejected':
        return (
          record.reject_reason ? (
            <A
              className="InitiativeParticipant-status-rejected"
              onClick={
                () => Modal.info({ title:t.reason_reject, content: record.reject_reason })
              }
            >
              { t.collection_rejected }
            </A>
          ) : (
            <div className="InitiativeParticipant-status-rejected">
              { t.collection_rejected }
            </div>
          )
        );
      case 'accepted':
      default:
        return (
          (record.participations || []).length > 0
          ?
            <div className="InitiativeParticipant-status-participated">
              { t.collection_participated }
            </div>
          :
            <div className="InitiativeParticipant-status-participating">
              { t.collection_participating }
            </div>
        );
    }
  }, [
    t,
  ]);

  const cohortsCell = useCallback((text, record) => (
    <React.Fragment>
    { (!record.participation || !record.participation.cohorts) ? null :
      record.participation.cohorts.map(cohort => (
        <Tag
          className="InitiativeParticipant__groupTag"
          key={cohort}
          color={colors['tag-palette'][stringToHash(slugToNameByType(cohort, 'cohorts'), 11)]}
        >
          {slugToNameByType(cohort, 'cohorts')}
        </Tag>
      ))
    }</React.Fragment>
  ), [
    slugToNameByType,
  ])

  const actionsCell = useCallback((status, record) => {
    return (
      <Row type="flex" gutter={8} justify="end">
        {isCollectionInitiative && (record.participations || []).length > 0 && (
          <Col>
            <Button
              type="default"
              icon={<EyeOutlined />}
              shape="circle"
              onClick={() => setModalDetailParticipation(record.participation)}
            />
          </Col>
        )}
        <Col>
          {
            (() => {
              switch(status) {
                case 'not_going':
                  return '';
                case 'rejected':
                  if (initiativeDone) {
                    return '';
                  } else {
                    return (
                      <Button
                        type='default'
                        icon={<RollbackOutlined />}
                        shape='circle'
                        className={'unrejectButton'}
                        onClick={() => showUnrejectConfirm([record])}
                      />
                    );
                  }
                case 'accepted':
                default:
                  if (initiativeDone) {
                    return '';
                  } else {
                    return (
                      <Button
                        type='default'
                        icon={<StopOutlined />}
                        shape='circle'
                        className={'rejectButton'}
                        onClick={() => setRejectMemberRecords([record])}
                      />
                    );
                  }
              }
            })()
          }
        </Col>
      </Row>
    );
  }, [
    showUnrejectConfirm,
    initiativeDone,
    isCollectionInitiative
  ]);

  const managerApprovalCell = useCallback((status, record) => {
    return (
      <div>
        {
          status === 'rejected' && record.reject_reason ? (
            <A
              className={`InitiativeParticipant-managerstatus InitiativeParticipant-managerstatus-${status}`}
              onClick={
                () => Modal.info({ title:t.reason_reject, content: record.reject_reason })
              }
            >
              { t.manager_approval_status_rejected }
            </A>
          ) : (
            <div className={`InitiativeParticipant-managerstatus InitiativeParticipant-managerstatus-${status}`}>
              { t[`manager_approval_status_${status}`] }
            </div>
          )
        }
        {
          (!record.manager || status === 'not_needed') ? null : (
            <Row type="flex" gutter={10} justify="center" align="middle">
              <Col>
                <Avatar
                  size={20}
                  shape="circle"
                  src={record.manager.avatar}
                  name={record.manager.name}
                  icon={<UserOutlined />}
                />
              </Col>
              <Col>
                { record.manager.name }
              </Col>
            </Row>
          )
        }
      </div>
    );
  }, [
    t,
  ]);

  const totalTimeCell = useCallback((text, record) => {
    return (
      <React.Fragment>
        <span>{(record.participation.valid_hours || '0') + ' ' + t.initiativeparticipants_hours}</span>
      </React.Fragment>
    )
  }, [
    t,
  ])

  const timeRequestsCell = useCallback((text, record) => (
    <List
      size="small"
      dataSource={record.participation.participations}
      renderItem={({ id, type, hours, created_at, status }) => (
        <List.Item>
          <Row className='timeRequest' type='flex' justify='space-around'>
            <Col>{ type === 'report_hours' ? `${hours || '0'} ${t.initiativeparticipants_hours}` : null }</Col>
            <Col>{ formatShort(created_at, intl) }</Col>
            <Col>{getParticipationActions(type, status, record.participant_id, id)}</Col>
          </Row>
        </List.Item>
      )}
    />
  ), [
    t,
    intl,
    getParticipationActions,
  ])

  const manualTimeCell = useCallback((text, record) => (
    <React.Fragment>
      {
        (memberOnEditHours && memberOnEditHours.member_id === record.member_id)
        ? <React.Fragment>
            <InputNumber value={inputGrantedHours} min={0} className='manualTimeInput' size='small' onChange={(term) => setInputGrantedHours(term)}/>
            <Button
              className='cancelManualTime'
              size='small'
              type='link'
              icon={<CloseOutlined />}
              onClick={() => setMemberOnEditHours({})}
            />
            <Button
              size='small'
              type='link'
              icon={<SaveOutlined />}
              onClick={() => saveManualTime(record)}
            />
          </React.Fragment>
        : <React.Fragment>
            <span>
              {`${record.granted_hours || '0'} ${t.initiativeparticipants_hours}`}
            </span>
            <Button
              type='link'
              size='small'
              icon={<EditOutlined />}
              onClick={() => {
                setInputGrantedHours(record.granted_hours)
                setMemberOnEditHours(record)}
              }
            />
          </React.Fragment>
      }
    </React.Fragment>
  ), [
    memberOnEditHours,
    inputGrantedHours,
    saveManualTime,
    t,
  ])

  const manualPointsCell = useCallback((text, record) => (
    <React.Fragment>
      {
        (memberOnEditPoints && memberOnEditPoints.member_id === record.member_id)
        ? <React.Fragment>
            <InputNumber value={inputGrantedPoints} min={0} className='manualPointsInput' size='small' onChange={(term) => setInputGrantedPoints(term)}/>
            <Button
              className='cancelManualPoints'
              size='small'
              type='link'
              icon={<CloseOutlined />}
              onClick={() => setMemberOnEditPoints({})}
            />
            <Button
              size='small'
              type='link'
              icon={<SaveOutlined />}
              onClick={() => saveManualPoints(record)}
            />
          </React.Fragment>
        : <React.Fragment>
            <span>
              {`${record.granted_points} ${t.initiativeparticipants_points}`}
            </span>
            <Button
              type='link'
              size='small'
              icon={<EditOutlined />}
              onClick={() => {
                setInputGrantedPoints(record.granted_points)
                setMemberOnEditPoints(record)}
              }
            />
          </React.Fragment>
      }
    </React.Fragment>
  ), [
    memberOnEditPoints,
    inputGrantedPoints,
    saveManualPoints,
    t,
  ])

  // Save manual time for all selected records and clean input
  const saveBulkManualTime = useCallback(() => {
    participantsSelected.forEach(record => {
      dispatch(setGrantedHours(
        organization.slug,
        initiative.slug,
        record.participation.participant_id,
        bulkInputGrantedHours,
      ));
    })
    setBulkInputGrantedHours(0)
    onDeselectRowKeys()
  }, [
    organization,
    initiative,
    dispatch,
    bulkInputGrantedHours,
    participantsSelected,
    onDeselectRowKeys
  ])

  const saveBulkManualPoints = useCallback(() => {
    participantsSelected.forEach(record => {
      dispatch(setGrantedPoints(
        organization.slug,
        initiative.slug,
        record.participation.participant_id,
        bulkInputGrantedPoints,
      ));
    })
    setBulkInputGrantedPoints(0)
    onDeselectRowKeys()
  }, [
    dispatch,
    organization,
    initiative,
    bulkInputGrantedPoints,
    participantsSelected,
    onDeselectRowKeys
  ])

  // Validate all participations of participants selected
  const validateBulkParticipations = useCallback(() => {
    participantsSelected.map(p => p.participation).forEach(participant => {
      if(participant.participations && participant.participations.length > 0){
        participant.participations.forEach(participation => {
          dispatch(validateParticipation(organization.slug, initiative.slug, participant.participant_id, participation.id));
        })
      }
    })
  }, [participantsSelected, dispatch, organization.slug, initiative.slug])

  // Reject all participations of participants selected
  const rejectBulkParticipations = useCallback(() => {
    participantsSelected.map(p => p.participation).forEach(participant => {
      if(participant.participations && participant.participations.length > 0){
        participant.participations.forEach(participation => {
          dispatch(rejectParticipation(organization.slug, initiative.slug, participant.participant_id, participation.id));
        })
      }
    })
  }, [participantsSelected, dispatch, organization.slug, initiative.slug])

  // Colums for time table
  const columnsTime = useMemo(() => {
    const columns = [
      {
        title: (selectedRowKeys.length > 0) ? t.name + ' (' + selectedRowKeys.length + ' ' + selectedText() + ')' : t.name,
        dataIndex: 'name',
        key: 'name',
        filteredValue: searchText
          ? [searchText]
          : null,
        onFilter: (value, record) =>
          (record.name || '').toLowerCase().includes(value) ||
          (record.email || '').toLowerCase().includes(value) ||
          (record.phone || '').toLowerCase().includes(value),
        render: nameCell,
      },
      {
        title: '',
        dataIndex: 'contact',
        key: 'contact',
        filteredValue: (filteredContact.length > 0)
          ? filteredContact
          : null,
        onFilter: (value, record) => (
          handleContactFilter(value, record)
        ),
        className: 'InitiativeParticipant__contactColumn',
        render: null,
        with: 0
      },
      {
        title: t.initiativeparticipants_total,
        dataIndex: 'totalTime',
        key: 'totalTime',
        filteredValue: filteredTime.length > 0
          ? filteredTime
          : null,
        onFilter: (value, record) => (
          handleTimeFilter(value, record)
        ),
        render: totalTimeCell,
      },
      {
        title:
          <React.Fragment>
            <span>{t.requested_hours}</span>
            {(selectedRowKeys.length <= 1) ? null :
              <section>
                <Button
                  className='acceptRequest bulk'
                  size="small"
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={() => validateBulkParticipations()}
                />
                <Button
                  className='rejectRequest bulk'
                  size="small"
                  type='danger'
                  icon={<CloseOutlined />}
                  onClick={() => rejectBulkParticipations()}
                />
              </section>
            }
          </React.Fragment>
        ,
        dataIndex: 'timeRequest',
        key: 'timeRequest',
        align: 'center',
        filteredValue: filteredValidation.length > 0
          ? filteredValidation
          : null,
        onFilter: (value, record) => (
          handleValidationFilter(value, record)
        ),
        render: timeRequestsCell,
      },
      {
        title:
          <React.Fragment>
            <span>{t.initiativeparticipants_hours_entered_manually}</span>
            {(selectedRowKeys.length > 1 || collaborationSelectedRowKeys.length > 1) &&
              <section>
                <InputNumber value={bulkInputGrantedHours} min={0} className='manualTimeInput' size='small' defaultValue={0} onChange={(term) => setBulkInputGrantedHours(term)}/>
                <Button
                  size='small'
                  type='link'
                  icon={<SaveOutlined />}
                  onClick={() => saveBulkManualTime()}
                />
              </section>
            }
          </React.Fragment>
        ,
        dataIndex: 'manualTime',
        key: 'manualTime',
        render: manualTimeCell,
      },
      (
        !hasPoints ? null :
        {
          title:
            <React.Fragment>
              <span>{t.initiativeparticipants_points_entered_manually}</span>
              {(selectedRowKeys.length > 1 || collaborationSelectedRowKeys.length > 1) &&
                <section>
                  <InputNumber value={bulkInputGrantedPoints} min={0} className='manualTimeInput' size='small' defaultValue={0} onChange={(term) => setBulkInputGrantedPoints(term)}/>
                  <Button
                    size='small'
                    type='link'
                    icon={<SaveOutlined />}
                    onClick={() => saveBulkManualPoints()}
                  />
                </section>
              }
            </React.Fragment>
          ,
          dataIndex: 'manualPoints',
          key: 'manualPoints',
          render: manualPointsCell,
        }
      )
    ];
    if (showOther) {
      const organizationColumn = {
        title: t.organization,
        dataIndex: 'organization',
        key: 'organization',
        filteredValue: filteredOrganizations.length > 0
        ? filteredOrganizations
        : null,
        onFilter: (value, record) => (
          handleOrganizationFilter(value, record)
        ),
        render: (organization, record) => (
          <Row type="flex" gutter={10} justify="left" align="middle">
            <Col>
              <Avatar
                size={38}
                shape="circle"
                src={record.organization_avatar}
                name={organization}
                icon={<UserOutlined />}
              />
            </Col>
            <Col>{organization}</Col>
          </Row>
        )
      };
      columns.splice(1, 0, organizationColumn);
    }
    return columns.filter(Boolean);
  }, [
    selectedRowKeys.length,
    t,
    selectedText,
    searchText,
    nameCell,
    filteredContact,
    filteredTime,
    totalTimeCell,
    filteredValidation,
    timeRequestsCell,
    collaborationSelectedRowKeys.length,
    bulkInputGrantedHours,
    manualTimeCell,
    hasPoints,
    bulkInputGrantedPoints,
    manualPointsCell,
    showOther,
    handleContactFilter,
    handleTimeFilter,
    validateBulkParticipations,
    rejectBulkParticipations,
    handleValidationFilter,
    saveBulkManualTime,
    saveBulkManualPoints, 
    filteredOrganizations,
    handleOrganizationFilter
  ]);

  // Colums for state table
  const columnsState = useMemo(() => {
    const columns = [
      {
        title: (!showOther)
          ? (selectedRowKeys.length > 0) ? t.name + ' (' + selectedRowKeys.length + ' ' + selectedText() + ')' : t.name
          : (collaborationSelectedRowKeys.length > 0) ? t.name + ' (' + collaborationSelectedRowKeys.length + ' ' + selectedText() + ')' : t.name
        ,
        dataIndex: 'name',
        key: 'name',
        filteredValue: searchText
          ? [searchText]
          : null,
        onFilter: (value, record) =>
          (record.name || '').toLowerCase().includes(value) ||
          (record.email || '').toLowerCase().includes(value) ||
          (record.phone || '').toLowerCase().includes(value),
        render: nameCell,
      },
      {
        title: '',
        dataIndex: 'contact',
        key: 'contact',
        filteredValue: filteredContact.length > 0
          ? filteredContact
          : null,
        onFilter: (value, record) => (
          handleContactFilter(value, record)
        ),
        className: 'InitiativeParticipant__contactColumn',
        render: null,
        with: 0
      },
      (
        !isCollectionInitiative &&
        {
          title: t.date_of_enrollment,
          dataIndex: 'accepted_at',
          key: 'accepted_at',
          render: isCollectionInitiative ? null : (date) => formatDateLong(date, intl),
          sorter: (a, b) => (new Date(b.accepted_at) - new Date(a.accepted_at)),
          sortDirections: ['descend', 'ascend']
        }
      ),
      (
        (!requireManagerApproval || isCollectionInitiative) ? null :
          {
            title: t.manager_approval_status,
            dataIndex: 'manager_approval_status',
            key: 'manager_approval_status',
            align: 'center',
            filteredValue: filteredManagerApproval.length > 0
              ? filteredManagerApproval
              : null,
            onFilter: (value, record) => (
              record.manager_approval_status === value
            ),
            render: managerApprovalCell,
          }
      ),
      {
        title: t.status,
        dataIndex: 'status',
        key: 'status',
        align: 'right',
        filteredValue: filteredStatus.length > 0
          ? filteredStatus
          : null,
        onFilter: (value, record) => (
          record.status === value
        ),
        render: isCollectionInitiative ? statusCollectionContributorCell : statusVolunteerCell,
      },
      {
        title: (selectedRowKeys.length <= 1) ? null : (
          <Button
            type='danger'
            icon={<StopOutlined />}
            shape='circle'
            onClick={() => setRejectMemberRecords(participantsSelected)}
          />
        ),
        dataIndex: 'status',
        filteredValue: filteredNewbie.length > 0
          ? filteredNewbie
          : null,
        onFilter: (value, record) => (
          record.is_newbie && value
        ),
        key: 'actions',
        align: 'right',
        render: actionsCell,
      },
    ];
    if (showOther) {
      const organizationColumn = {
        title: t.organization,
        dataIndex: 'organization',
        key: 'organization',
        filteredValue: filteredOrganizations.length > 0
        ? filteredOrganizations
        : null,
        onFilter: (value, record) => (
          handleOrganizationFilter(value, record)
        ),
        render: (organization, record) => (
          <Row type="flex" gutter={10} justify="left" align="middle">
            <Col>
              <Avatar
                size={38}
                shape="circle"
                src={record.organization_avatar}
                name={organization}
                icon={<UserOutlined />}
              />
            </Col>
            <Col>{organization}</Col>
          </Row>
        )
      };
      columns.splice(1, 0, organizationColumn);
    } else {
      const groupsColumn = {
        title: t.groups,
        dataIndex: 'cohorts',
        key: 'cohorts',
        filteredValue: filteredGroups.length > 0
          ? filteredGroups
          : null,
        onFilter: (value, record) => (
          handleGroupFilter(value, record)
        ),
        render: cohortsCell,
      };
      columns.splice(4, 0, groupsColumn);
    }
    return columns.filter(Boolean);
  }, [
    showOther,
    selectedRowKeys.length,
    t,
    selectedText,
    collaborationSelectedRowKeys.length,
    searchText,
    nameCell,
    filteredContact,
    requireManagerApproval,
    filteredManagerApproval,
    managerApprovalCell,
    filteredStatus,
    isCollectionInitiative,
    statusCollectionContributorCell,
    statusVolunteerCell,
    filteredNewbie,
    actionsCell,
    intl,
    handleContactFilter,
    participantsSelected,
    filteredOrganizations,
    handleOrganizationFilter,
    filteredGroups,
    cohortsCell,
    handleGroupFilter
  ]);

  const handleSendCertificate = useCallback(
    () => {
      dispatch(sendParticipantCertificate(organization.slug, initiative.slug, participantsSelectedId));
    },
    [participantsSelectedId, dispatch, organization.slug, initiative.slug]
  );

  const onRejectParticipant = useCallback((organizationSlug, initiativeSlug, memberId, rejectReason) => {
    dispatch(rejectParticipant(organizationSlug, initiativeSlug, memberId, rejectReason));
  }, [dispatch]);

  const otherParticipantsOrganizations = useMemo(() => {
    if (participants_other && participants_other.length > 0) {
      const collaboratorSlugs = [];
      participants_other.forEach(collaboratorParticipant => {
        const collaboratorFound = collaborators.find(c => c.id === collaboratorParticipant.organization_id);
        if (collaboratorFound && !collaboratorSlugs.includes(collaboratorFound.slug)) {
          collaboratorSlugs.push(collaboratorFound.slug);
        }
      });
      return collaboratorSlugs.length > 0 ? collaboratorSlugs : null;
    }
  }, [
    collaborators,
    participants_other
  ]);

  const onCloseSendMessageModal = useCallback((message, messageType) => {
    setSendMessageModal(false);
  }, []);

  const onSendMessage = useCallback((message, messageType) => {
    dispatch(broadcastMessage(organization.slug, initiative.slug, message, messageType, participantsSelectedId));
    onCloseSendMessageModal();
  }, [dispatch, initiative.slug, onCloseSendMessageModal, organization.slug, participantsSelectedId]);

  const onCancelAddParticipantsModal = useCallback(() => {
    setAddParticipantModal(false);
  }, []);

  const onAddParticipantToInitiative = useCallback((name, email, phone) => {
    dispatch(addParticipant(organization.slug, initiative.slug, name, email, phone));
  }, [dispatch, initiative.slug, organization.slug]);

  return (
    <React.Fragment>
      <FloatingButton onClick={() => setAddParticipantModal(true)} iconUrl='/images/icon-event-white.svg'/>
      <AddParticipantsModal
        showAddParticipantModal={addParticipantModal}
        onAddParticipant={onAddParticipantToInitiative}
        onCancelModal={onCancelAddParticipantsModal}
        volunteersItems={volunteersItems}
        organizationRegion={organization.region}
        initiativeDone={initiativeDone}
        participants={participants}
        loading={initiativeDetailLoading}
        error={initiativeDetailError}
      />
      { rejectMemberRecords && rejectMemberRecords.length > 0 && (
        <RejectModal
          initiative={initiative}
          records={rejectMemberRecords}
          onClose={() => setRejectMemberRecords([])}
          onReject={onRejectParticipant}
          loading={!!loading_member_id}
        />
      )}
      {isCollectionInitiative && (
        <ParticipantIndicators
          visible={!!modalDetailParticipation}
          participation={modalDetailParticipation}
          onCancel={() => setModalDetailParticipation(null)}
        />
      )}
      {!isCollectionInitiative && (
        <SendCertificateModal
          showSendCertificateModal={sendCertificateModal}
          onSend={handleSendCertificate}
          onClose={() => setSendCertificateModal(false)}
        />
      )}
      <SendMessageModal
        showSendMessageModal={sendMessageModal}
        onCloseModal={onCloseSendMessageModal}
        onCancelModal={onCloseSendMessageModal}
        onSend={onSendMessage}
        participantsdWithPhone={participantsSelected.filter(p => p.phone).length}
        participantsdWithEmail={participantsSelected.filter(p => p.email).length}
        totalParticipants={participantsSelected.length}
      />
      { !isOrganizer || !initiative.collaborative ? null :
        <React.Fragment>
          <div className="Whitespace20"></div>
          <div>
            <Radio.Group
              defaultValue={showOther}
              buttonStyle="solid"
              disabled={tableActive === 'time'}
              onChange={() => {
                cleanFiltersAndSelected()
                setShowOther(!showOther)
            }}>
              <Radio.Button value={false}>{ t.my_users } ({ my_participant_count })</Radio.Button>
              <Radio.Button value={true}>{ t.collaboration_volunteers } ({ other_participant_count })</Radio.Button>
            </Radio.Group>
          </div>
          <div className="Whitespace20"></div>
        </React.Fragment>
      }
      {
        <React.Fragment>
          <Row type="flex" gutter={20} align={'middle'}>
            <Col>
              <Button
                size='large'
                type='primary'
                disabled={hasDeletedParticipantsSelected || (!showOther ? selectedRowKeys.length < 1 : collaborationSelectedRowKeys.length < 1)}
                icon={<MessageOutlined />}
                onClick={() => setSendMessageModal(true)}
              >
                {t.participants_sendMessage}
              </Button>
            </Col>
            <Col style={{
                flex: 1
              }}>
              <Input.Search
                type="text"
                prefix={<SearchOutlined
                style = {{ color: 'rgba(0,0,0,.25)' }}/>}
                placeholder={t.volunteer_search_placeholder}
                enterButton={t.volunteer_search}
                onSearch={(term) => setSearchText((term || '').toLowerCase())}
                onChange={(term) => !term.target.value && setSearchText('')}
                allowClear="allowClear"
                size="large"
                ref={searchInputRef}
              />
            </Col>
            <Col>
              <AuthorizedLink
                href={getParticipantsDownloadUrl(organization.slug, initiative.slug)}
                download={`${initiative.title}.xlsx`}
              >
                <Button
                  size="large"
                  icon={<DownloadOutlined />}
                  type="default"
                >
                </Button>
              </AuthorizedLink>
            </Col>
            <Col>
              <Button
                size='large'
                className='filterVolunteers'
                type="default"
                icon={<FilterOutlined />}
                onClick={() => setOpenFilters(true)}
              />
            </Col>
            {!isCollectionInitiative && (
              <Col>
                {
                  (tableActive === 'state')
                  ? <Button
                      size='large'
                      type='primary'
                      icon={<ClockCircleOutlined />}
                      onClick={() => {
                        cleanFiltersAndSelected()
                        setTableActive('time')
                      }}
                    >
                      {t.participants_timeManagement}<ArrowRightOutlined />
                    </Button>
                  : <Button
                      size='large'
                      type='primary'
                      icon={<UserOutlined />}
                      onClick={() => {
                        cleanFiltersAndSelected()
                        setTableActive('state')
                      }}
                    >
                      {t.participants}<ArrowLeftOutlined />
                    </Button>
                }
              </Col>
            )}
          </Row>
          { !isCollectionInitiative && organization.config.can_send_participant_certificates
            ? <Row type="flex" gutter={20} align={'middle'} className="InitiativeParticipant-send-certificate">
                <Col>
                  <Button
                    size='large'
                    type='primary'
                    disabled={hasDeletedParticipantsSelected || (!showOther ? selectedRowKeys.length < 1 : collaborationSelectedRowKeys.length < 1)}
                    icon={<MessageOutlined />}
                    onClick={() => setSendCertificateModal(true)}
                  >
                    {t.participants_sendCertificate}
                  </Button>
                </Col>
              </Row>
            : null
          }
          <div className="Whitespace20"></div>
        </React.Fragment>
      }
      {
        <InitiativeParticipantsFilters
          otherParticipantsOrganizations={otherParticipantsOrganizations}
          openFilters={openFilters}
          onCloseFilters={onCloseFilters}
          shouldCleanFilters={shouldCleanFilters}
          onDeselectRowKeys={onDeselectRowKeys}
          showOther={showOther}
          hasPoints={hasPoints}
          tableActive={tableActive}
          requireManagerApproval={requireManagerApproval}
          slugToNameByType={slugToNameByType}
          cohorts={cohorts}
          onSetFilters={onSetFilters}
          onClearedFilters={onClearedFilters}
          isCollectionInitiative={isCollectionInitiative}
        />
      }
      { isCollectionInitiative ? (
        <Table
          rowSelection={showOther ? collaborationRowSelection : rowSelection}
          columns={columnsState}
          dataSource={showOther ?  collaboratorData : data}
        />
        ) : (
          <Tabs className='tableTabsOwn' tabPosition='top' renderTabBar={(props, tab) => <div></div>} activeKey={tableActive}>
            <TabPane tab={'state'} key="state">
              <Table
                rowSelection={showOther ? collaborationRowSelection : rowSelection}
                columns={columnsState}
                dataSource={showOther ?  collaboratorData : data}
              />
            </TabPane>
            <TabPane tab={'time'} key="time">
              {
                !showOther
                ? <Table
                  rowSelection={rowSelection}
                  columns={columnsTime}
                  dataSource={data.filter((p) => p.status === "accepted")}
                />
                : <Table
                  rowSelection={collaborationRowSelection}
                  columns={columnsTime}
                  dataSource={collaboratorData.filter((p) => p.status === "accepted")}
                />
              }
            </TabPane>
          </Tabs>
        )
      }
      <UserProfileModal
        slug={userDetail}
        visible={!!userDetail}
        onClose={() => {
          setUserDetail(null);
          !!userCollaboratorSlug && setUserCollaboratorSlug(null);
        }}
        collaboratorSlug={userCollaboratorSlug}
      />
    </React.Fragment>
  )
}

export default injectIntl(InitiativeParticipants);
