import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';

import AuthorizedLink from 'containers/AuthorizedLink';
import FileIcon from 'components/InitiativeDocuments/FileIcon';
import T from 'components/T';
import Filters from 'components/InitiativeDocuments/Filters';
import formatNumber from 'utils/formatNumber';
import { formatDateLong } from 'utils/date';

import {
  Row,
  Col,
  List,
  Modal,
} from 'antd';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';


const FORMAT_FILESIZE = {
  locale: 'bytes', // LOL
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const UserDocuments = ({
  intl,
  documents,
  onApprove,
}) => {
  const t = intl.messages;
  const [userDocuments, setUserDocuments] = useState(documents);

  const handleOnApprove = useCallback(
    (e, document) => {
      e.preventDefault();

      Modal.confirm({
        title: t.initiative_documents_approve_modal_title,
        icon: <ExclamationCircleOutlined />,
        content: t.initiative_documents_approve_modal_description,
        okText: t.initiative_documents_approve_modal_ok,
        cancelText: t.initiative_documents_approve_modal_cancel,
        onOk() {
          onApprove(document.id);
        },
      });
    },
    [onApprove, t]
  );

  return (
    <Row>
      <Col span={24}>
        <Filters
          data={documents}
          setFilteredData={setUserDocuments}
        />
      </Col>
      <Col span={24}>
        <List
          itemLayout="horizontal"
          dataSource={userDocuments}
          renderItem={document => (
            <List.Item
              actions={[
                <AuthorizedLink
                  href={document.download}
                  download={document.filename}
                  mimeType={document.mimetype}
                >
                  { t.initiative_documents_download }
                </AuthorizedLink>,
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={e => handleOnApprove(e, document)}
                >
                  { t.initiative_documents_approve }
                </a>
              ]}
            >
              <List.Item.Meta
                avatar={<FileIcon mimetype={document.mimetype} />}
                title={document.filename}
                description={
                  <Row gutter={10}>
                    <Col>
                      {formatNumber(document.size, FORMAT_FILESIZE)}
                    </Col>
                    <Col>|</Col>
                    <Col>
                      <T
                        initiative_documents_shared_by={{
                          name: document.member_name,
                          date: formatDateLong(document.created_at, intl)
                        }}
                      />
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  )
}

export default injectIntl(UserDocuments);