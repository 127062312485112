import React from 'react';
import {injectIntl} from 'react-intl';

import {
  Card,
  Select,
  Form,
} from 'antd';

const Cohorts = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  errorRefs,
  showError,
  getValue,
  handleChange,
}) => {
  const t = intl.messages;

  const {cohorts} = taxonomies;

  return (
    cohorts.length > 0
      ? <Card
          className="InitiativeForm-card"
          title={<span > { t.groups } </span>}
        >
          <div className="InitiativeForm-clarification">{t.group_clarification}</div>
          <div ref={errorRefs.groups}></div>
          <Form.Item
            hasFeedback="hasFeedback"
            validateStatus={
              showError('cohorts')
                ? 'error'
                : ''
              }
              help={showError('cohorts')}
            >
              <Select
                placeholder={t.groups}
                value={getValue('cohorts')}
                onChange={handleChange('cohorts')}
                mode="multiple"
              >
              {cohorts.map(cohort => (<Select.Option key={cohort.slug} value={cohort.slug}>{cohort.name}</Select.Option>))}
            </Select>
          </Form.Item>
        </Card>
      : null
  );
}

export default injectIntl(Cohorts);
