import React from 'react';

import T from 'components/T';
import formatNumber from 'utils/formatNumber';
import { formatDateLong } from 'utils/date';

import {
  Row,
  Col,
  Modal,
  Card,
} from 'antd';
import { injectIntl } from 'react-intl';


const FORMAT_FILESIZE = {
  locale: 'bytes', // LOL
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const Preview = ({
  intl,
  visible,
  close,
  image,
  actions = [],
}) => {
  return (
    <Modal
      className="ImagePreview"
      visible={visible}
      onCancel={close}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Card
            title={image?.filename}
            cover={
              <img
                className="ImagePreview-image"
                alt=""
                src={image?.download}
              />
            }
            actions={actions}
          >
            <Card.Meta
              description={
                <Row gutter={10}>
                  <Col>
                    {formatNumber(image?.size, FORMAT_FILESIZE)}
                  </Col>
                  <Col>|</Col>
                  <Col>
                    <T
                      initiative_images_shared_by={{
                        name: image?.member_name,
                        date: formatDateLong(image?.created_at, intl) || '-'
                      }}
                    />
                  </Col>
                </Row>
              }
            />
          </Card>
        </Col>
      </Row>
    </Modal>
  );
}

export default injectIntl(Preview);
