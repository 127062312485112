import React, { useRef, useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import {
  message,
  Button,
  Input,
  Modal,
  Tooltip,
  Row,
  Col,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Share = ({
	intl,
	categories = [],
	share,
  onClose,
  ...initiative
}) => {
  const t = intl.messages;
  const inputRef = useRef(null);
  const [shareModalOpen, setShareModalOpen] = useState(false)

  const copy = useCallback(() => {
    inputRef.current.select();
    document.execCommand('copy');
    message.success(t.copy_success);
  }, [ t ]);

  return (
    <React.Fragment>
      <Tooltip
        placement="bottomRight"
        title={ t.share }
      >
        <Button shape="circle" className="InitiativeDetail__share" onClick={() => setShareModalOpen(true)}>
          <FontAwesomeIcon icon="share-alt" />
        </Button>
      </Tooltip>

      <Modal
        visible={shareModalOpen}
        title={t.share}
        centered
        onCancel={() => setShareModalOpen(false)}
        width={700}
        footer={false}
      >
        <Row
          type="flex"
          gutter={5}
        >
          <Col style={{ flex: 1 }}>
            <Input
              ref={inputRef}
              value={initiative.share_url}
            />
          </Col>
          <Col>
            <Button onClick={copy}>
              <FontAwesomeIcon icon="copy" />
            </Button>
          </Col>
        </Row>
    </Modal>

  </React.Fragment>
  );
};

export default injectIntl(Share);
