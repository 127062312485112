import React, { useMemo, useCallback, useLayoutEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import iban from 'iban';
import {
  Card,
  Checkbox,
  Collapse,
  Divider,
  Input,
  InputNumber,
  Row,
  Col,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import FormItem from 'antd/lib/form/FormItem';

const IBAN_CHARS_REGEX = new RegExp('[^A-Z0-9]', 'g');

const Donation = ({
  intl,
  showError,
  getValue,
  values,
  handleChange,
  handleChangeEvent,
  errorRefs,
  organization
}) => {
  const t = intl.messages;
  const ibanRef = useRef(null);
  const ibanCursorPosition = useRef(0);
  const handleBooleanChange = useCallback((name) => () => {
    handleChange(name)(!getValue(name));
  }, [ handleChange, getValue ]);

  const handleSourceChange = useCallback((sources) => {
    if (sources.length >= 1) {
      handleChange('donation_sources')(sources);
    } else {
      // TODO: Shake element?
    }
  }, [ handleChange ]);

  const handleIBANChange = useCallback((event) => {
    const { target = {} } = event;
    const {
      value = '',
      name,
    } = target;

    ibanRef.current = target;
    ibanCursorPosition.current = target.selectionEnd;

    const patchedValue = value.toUpperCase().replace(IBAN_CHARS_REGEX, '');
    handleChange(name)(patchedValue);
    setTimeout(() => {
    });
  }, [ handleChange ]);

  const ibanValue = useMemo(() => getValue('donation_transfer_iban') || '', [ getValue ]);

  useLayoutEffect(() => {
    const target = ibanRef.current;
    const length = ibanValue.length;
    if(target) {
      ibanCursorPosition.current += (
        (target.value.charAt(ibanCursorPosition.current - 1) === ' ' &&
        length !== target.value.length
      ) ? 1 : 0);
      target.selectionEnd = ibanCursorPosition.current;
    }
  }, [ ibanValue ]);

  const formItemFullLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      md: { span: 16 },
      lg: { span: 18 },
    },
  };

  const formItemHalfLayout = {
    labelCol:{
      xs: { span: 24 },
      lg: { span: 12 },
    },
    wrapperCol:{
      xs: { span: 24 },
      lg: { span: 12 },
    },
  };

  return (
    <Card
      className="InitiativeForm-card"
      title={<span> <span className="InitiativeForm-required-star">*</span> { t.donation_url_title }</span>}
    >
      <div ref={errorRefs.donation}></div>
      <div className="InitiativeForm-donation">
        <div
          className="InitiativeForm-clarification"
        >
          { t.donation_general }
        </div>
        <Row
          type="flex"
          gutter={0}
        >
          <Col xs={24} lg={12}>
            <FormItem
              {...formItemHalfLayout}
              label={t.donation_amount_target_short}
              hasFeedback="hasFeedback"
              validateStatus={showError('donation_target_amount') ? 'error' : ''}
              help={showError('donation_target_amount')}
              required="required"
            >
              <InputNumber
                placeholder={t.donation_amount_target_placeholder}
                name="donation_target_amount"
                value={getValue('donation_target_amount')}
                onChange={handleChange('donation_target_amount')}
              />
            </FormItem>
            <FormItem
              {...formItemHalfLayout}
              label={
                <span>
                  {t.donation_amount_min} <Tooltip placement="bottomRight" title={t.donation_amount_min_desc}><InfoCircleOutlined /></Tooltip>
                </span>
              }
              hasFeedback="hasFeedback"
              validateStatus={showError('donation_min_amount') ? 'error' : ''}
              help={showError('donation_min_amount')}
            >
              <InputNumber
                placeholder={t.donation_amount_min}
                name="donation_min_amount"
                value={getValue('donation_min_amount')}
                onChange={handleChange('donation_min_amount')}
              />
            </FormItem>
            <FormItem
              {...formItemHalfLayout}
              label={
                <span>
                  {t.donation_amount_max} <Tooltip placement="bottomRight" title={t.donation_amount_max_desc}><InfoCircleOutlined /></Tooltip>
                </span>
              }
              hasFeedback="hasFeedback"
              validateStatus={showError('donation_max_amount') ? 'error' : ''}
              help={showError('donation_max_amount')}
            >
              <InputNumber
                placeholder={t.donation_amount_max}
                name="donation_max_amount"
                value={getValue('donation_max_amount')}
                onChange={handleChange('donation_max_amount')}
              />
            </FormItem>
          </Col>
          <Col xs={24} lg={12}>
            <div className="InitiativeForm-checkbox">
              <span style={{marginLeft: '32px'}}>
                { t.donation_currency }: <strong>{ t[getValue('donation_currency')] }</strong>
              </span>
            </div>
            <div className="InitiativeForm-checkbox">
              <Checkbox
                checked={getValue('donation_tax_deductible')}
                onChange={handleBooleanChange('donation_tax_deductible')}
              >
                { t.donation_tax_deductible }
              </Checkbox>
            </div>
            <div className="InitiativeForm-checkbox">
              <Checkbox
                className="InitiativeForm-checkbox"
                checked={getValue('donation_show_target_amount')}
                onChange={handleBooleanChange('donation_show_target_amount')}
              >
                { t.donation_show_target_amount }
              </Checkbox>
            </div>
          </Col>
        </Row>
      </div>

      <Divider />

      <div
        className="InitiativeForm-clarification"
      >
        { t.donation_allowed_sources }
      </div>
      <Collapse
        className="InitiativeForm-sources"
        bordered={false}
        expandIcon={({ isActive }) => <Checkbox checked={isActive} />}
        activeKey={values.donation_sources}
        onChange={handleSourceChange}
      >
        <Collapse.Panel
          header={ t.donation_paypal }
          key="paypal"
        >
          <FormItem
            {...formItemFullLayout}
            label={ t.donation_paypal_desc }
            hasFeedback="hasFeedback"
            validateStatus={showError('donation_paypal') ? 'error' : ''}
            help={showError('donation_paypal')}
            required="required"
          >
            <Input
              placeholder={t.donation_paypal_clientid}
              name="donation_paypal_clientid"
              value={getValue('donation_paypal_clientid')}
              onChange={handleChangeEvent}
            />
          </FormItem>
        </Collapse.Panel>
        <Collapse.Panel
          header={ t.donation_transfer }
          key="transfer"
        >
          <p>{ t.donation_transfer_desc }</p>
          {
            organization.region === 'BR'
            ? (
              <>
                <FormItem
                  {...formItemFullLayout}
                  label={t.donation_transfer_bank}
                  hasFeedback="hasFeedback"
                  validateStatus={showError('donation_br_transfer_bank') ? 'error' : ''}
                  help={showError('donation_br_transfer_bank')}
                  required="required"
                  >
                  <Input
                    placeholder={t.donation_transfer_bank}
                    name="donation_br_transfer_bank"
                    value={getValue('donation_br_transfer_bank')}
                    onChange={handleChangeEvent}
                    />
                </FormItem>
                <Row
                  type="flex"
                  gutter={0}
                >
                  <Col xs={24} lg={12}>
                    <FormItem
                      {...formItemHalfLayout}
                      label={t.donation_transfer_cpf}
                      hasFeedback="hasFeedback"
                      validateStatus={showError('donation_br_transfer_cpf') ? 'error' : ''}
                      help={showError('donation_br_transfer_cpf')}
                      required="required"
                    >
                      <Input
                        placeholder={t.donation_transfer_cpf}
                        name="donation_br_transfer_cpf"
                        value={getValue('donation_br_transfer_cpf')}
                        onChange={handleChangeEvent}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <FormItem
                      {...formItemHalfLayout}
                      label={t.donation_transfer_cnpj}
                      hasFeedback="hasFeedback"
                      validateStatus={showError('donation_br_transfer_cnpj') ? 'error' : ''}
                      help={showError('donation_br_transfer_cnpj')}
                    >
                      <Input
                        placeholder={t.donation_transfer_cnpj}
                        name="donation_br_transfer_cnpj"
                        value={getValue('donation_br_transfer_cnpj')}
                        onChange={handleChangeEvent}
                        />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem
                  {...formItemFullLayout}
                  label={t.donation_transfer_agency}
                  hasFeedback="hasFeedback"
                  validateStatus={showError('donation_br_transfer_agency') ? 'error' : ''}
                  help={showError('donation_br_transfer_agency')}
                  required="required"
                >
                  <Input
                    placeholder={t.donation_transfer_agency}
                    name="donation_br_transfer_agency"
                    value={getValue('donation_br_transfer_agency')}
                    onChange={handleChangeEvent}
                    maxLength={4}
                  />
                </FormItem>
                <Row
                  gutter={0}
                  type="flex"
                  justify="end"
                >
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <FormItem
                      {...formItemFullLayout}
                      label={t.donation_transfer_account}
                      hasFeedback="hasFeedback"
                      validateStatus={showError('donation_br_transfer_account') ? 'error' : ''}
                      help={showError('donation_br_transfer_account')}
                      required="required"
                    >
                      <div className="donation__brAccountWrapper">
                        <Input
                          placeholder={t.donation_transfer_account}
                          name="donation_br_transfer_account_first_part"
                          value={getValue('donation_br_transfer_account_first_part')}
                          onChange={handleChangeEvent}
                          maxLength={5}
                        />
                        <hr/>
                        <Input
                          placeholder={t.donation_transfer_account}
                          name="donation_br_transfer_account_second_part"
                          value={getValue('donation_br_transfer_account_second_part')}
                          onChange={handleChangeEvent}
                          maxLength={1}
                        />
                      </div>
                    </FormItem>
                  </Col>
                </Row>
                <FormItem
                  {...formItemFullLayout}
                  label={t.donation_transfer_guidelines}
                  hasFeedback="hasFeedback"
                  validateStatus={showError('donation_br_transfer_guidelines') ? 'error' : ''}
                  help={showError('donation_br_transfer_guidelines')}
                >
                  <Input
                    placeholder={t.donation_transfer_guidelines_placeholder}
                    name="donation_br_transfer_guidelines"
                    value={getValue('donation_br_transfer_guidelines')}
                    onChange={handleChangeEvent}
                    />
                </FormItem>
              </>
            ) : (
              <>
                <Row
                  type="flex"
                  gutter={0}
                >
                  <Col xs={24} lg={12}>
                    <FormItem
                      {...formItemHalfLayout}
                      label={t.donation_transfer_bank}
                      hasFeedback="hasFeedback"
                      validateStatus={showError('donation_transfer_bank') ? 'error' : ''}
                      help={showError('donation_transfer_bank')}
                      required="required"
                    >
                      <Input
                        placeholder={t.donation_transfer_bank}
                        name="donation_transfer_bank"
                        value={getValue('donation_transfer_bank')}
                        onChange={handleChangeEvent}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} lg={12}>
                    <FormItem
                      {...formItemHalfLayout}
                      label={
                        <span>
                          {t.donation_transfer_swift} <Tooltip placement="bottomRight" title={t.donation_transfer_swift_desc}><InfoCircleOutlined /></Tooltip>
                        </span>
                      }
                      hasFeedback="hasFeedback"
                      validateStatus={showError('donation_transfer_swift') ? 'error' : ''}
                      help={showError('donation_transfer_swift')}
                    >
                      <Input
                        placeholder={t.donation_transfer_swift_placeholder}
                        name="donation_transfer_swift"
                        value={getValue('donation_transfer_swift')}
                        onChange={handleChangeEvent}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <FormItem
                {...formItemFullLayout}
                  label={t.donation_transfer_iban}
                  hasFeedback="hasFeedback"
                  validateStatus={showError('donation_transfer_iban') ? 'error' : ''}
                  help={showError('donation_transfer_iban')}
                  required="required"
                >
                  <Input
                    placeholder={t.donation_transfer_iban}
                    name="donation_transfer_iban"
                    value={iban.printFormat(ibanValue, ' ')}
                    onChange={handleIBANChange}
                  />
                </FormItem>
              </>
            )
          }
        </Collapse.Panel>

        <Collapse.Panel
          header={ t.donation_external }
          key="external"
        >
          <FormItem
            {...formItemFullLayout}
            label={ t.donation_external_desc }
            hasFeedback="hasFeedback"
            validateStatus={showError('donation_external_url') ? 'error' : ''}
            help={showError('donation_external_url')}
            required="required"
          >
            <Input
              placeholder={t.donation_external_placeholder}
              name="donation_external_url"
              value={getValue('donation_external_url')}
              onChange={handleChangeEvent}
            />
          </FormItem>
        </Collapse.Panel>

        <Collapse.Panel
          header={ t.donation_inhand }
          key="inhand"
        >
          <FormItem
            label={ t.donation_inhand_desc }
            hasFeedback="hasFeedback"
            validateStatus={showError('donation_inhand_instructions') ? 'error' : ''}
            help={showError('donation_inhand_instructions')}
          >
            <Input.TextArea
              placeholder={t.donation_inhand_placeholder}
              name="donation_inhand_instructions"
              value={getValue('donation_inhand_instructions')}
              onChange={handleChangeEvent}
              autosize={{ minRows: 2 }}
            />
          </FormItem>
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
};

export default injectIntl(Donation);
