import PropTypes from 'prop-types';

export const propTypes = {
  data: PropTypes.exact({
    updated_at: PropTypes.string,
    periods: PropTypes.arrayOf(PropTypes.string),
    series: PropTypes.arrayOf(PropTypes.string).isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    samples: PropTypes.arrayOf(
      PropTypes.exact({
        key: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.number)
      })
    ).isRequired,
    extras: PropTypes.object,
  }),
};

