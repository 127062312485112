import React, {
  useEffect,
  useMemo,
  useCallback
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  requestUserDetail,
  requestUserFromCollaboratorDetail,
  resetUserDetail,
  requestGenerateResponsibleProfilePDF,
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as CheckIcon } from 'assets/icons/icon-checkmark.svg';

import {
  Modal,
  Spin,
  Alert,
  Tabs,
  Row,
  Col,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import {
  slugToUrl,
} from 'utils/social';

import { formatDateLong } from 'utils/date';

import { A } from 'aplanet-ui-kit';

import Avatar from 'components/Avatar';
import T from 'components/T';

import './style.less';
import Activities from 'components/UserProfileModal/Activities';
import Profile from 'components/UserProfileModal/Profile';

const ICON_PER_PROFILE = {
  linkedin: ["fab", "linkedin"],
  twitter: ["fab", "twitter-square"],
  github: ["fab", "github"],
  gitlab: ["fab", "gitlab"],
  facebook: ["fab", "facebook"],
  default: "user-circle",
};

const HIDDEN_STEPS = new Set([
  'public_groups',
  'social_networks',
  'phone',
]);

const UserProfileModal = ({
  intl,
  collaboratorSlug,
  onClose,
  visible,
  slug
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const {
    organization
  } = useOrganizations();

  useEffect(() => {
    if(collaboratorSlug) {
      dispatch(requestUserFromCollaboratorDetail(organization.slug, collaboratorSlug, slug));
    } else if (organization.slug && slug) {
      dispatch(requestUserDetail(organization.slug, slug));
    } else {
      dispatch(resetUserDetail());
    }
  }, [
    collaboratorSlug,
    organization.slug,
    slug,
    dispatch
  ]);

  const {
    data: user,
    loading,
    error,
    pdf: pdfStatus,
  } = useSelector(state => state.user_detail);

  useEffect(() => {
    if (!pdfStatus.processing && pdfStatus.done && pdfStatus.url) {
      window.open(pdfStatus.url);
    }
  }, [
    pdfStatus,
  ]);

  const socialProfiles = useMemo(() => {
    if(!user) {
      return [];
    }

    const orgWiseNetworks = new Set(((organization.config || {}).social_network_profiles || []));

    return Object.keys(user.social)
      .filter(network => orgWiseNetworks.has(network))
      .reduce((obj, network) => {
        obj[network] = user.social[network];
        return obj;
      }, {});
  }, [
    organization.config,
    user,
  ]);

  const onboardingSteps = useMemo(() => {
      if (!!collaboratorSlug) {
        return (user && user.organization_collaborator?.config?.onboarding_steps) || [];
      }
        return organization?.config?.onboarding_steps || [];
  }, [collaboratorSlug, organization, user]);

  const showProfile = useMemo(() => {
    // Just remove the onboarding_steps that do not appear in the profile
    // This is easier than the previous strategy of listing the ones that appear
    return  onboardingSteps.filter(step => !HIDDEN_STEPS.has(step)).length > 0;
  }, [
    onboardingSteps,
  ]);

  const onGenerateResponsibleProfilePDF = useCallback(() => {
    dispatch(requestGenerateResponsibleProfilePDF(organization.slug, slug, collaboratorSlug));
  }, [collaboratorSlug, dispatch, organization.slug, slug]);

  return (
    <Modal
      centered
      visible={visible}
      className="UserProfileModal"
      onCancel={onClose}
      footer={null}
      width={'880px'}
    >
      <div className="ant-modal-header">
      </div>
      {
        !loading ? null
          : <div
              className="UserProfileModal__loading_row"
            >
              <Spin
                className="UserProfileModal__loading"
                size="large"
              />
            </div>
      }
      {
        !error ? null
        : <Alert
            type="error"
            message={t[`user_profile_error_${error}`] || t.user_profile_error_default }
          />
      }
      {
        loading || error || !user ? null
        : (
          <>
            <div
              className="UserProfileModal__avatar_row"
            >
              <Avatar
                className="UserProfileModal__avatar"
                size={128}
                shape="circle"
                icon={<UserOutlined />}
                name={user.name}
                src={user.avatar}
              />
            </div>
            <div
              className="UserProfileModal__bio_row"
            >
              <h1>{ user.name }</h1>
              <div>
                <T
                  user_profile_member_since={{
                    date: formatDateLong(user.created_at, intl)
                  }}
                />
              </div>

              {
                Object.keys(socialProfiles).map(network => (
                  <div
                    key={network}
                  >
                    <A
                      newWindow
                      href={slugToUrl(network)(socialProfiles[network])}
                    >
                      <FontAwesomeIcon
                        className="UserProfileModal__socialicon"
                        icon={ICON_PER_PROFILE[network] || ICON_PER_PROFILE.default}
                      />
                      { ' ' }
                      { t[`social_network_${network}`] }
                    </A>
                  </div>
                ))
              }
              {
                loading ||
                !user ||
                !organization.last_terms_version ||
                user.org_last_terms_accepted !== organization.last_terms_version
                ? null
                : <Row
                    type="flex"
                    gutter={8}
                  >
                    <Col>
                      <CheckIcon />
                      <FontAwesomeIcon className="UserProfileModal__icon" icon="clipboard-check" />
                      { ' ' }
                      <strong>{ t.user_profile_volunteering_terms_and_conditions_accepted }</strong>
                    </Col>
                </Row>
              }
              {
                loading || !user || !user.manager || !organization?.config.require_manager_approval ? null
                : <Row
                    type="flex"
                    gutter={8}
                  >
                    <Col>
                      <FontAwesomeIcon className="UserProfileModal__icon" icon="sitemap" />
                      { ' ' }
                      <strong>{ t.user_profile_manager }</strong>:
                    </Col>
                    <Col>
                      <Row
                        type="flex"
                        gutter={8}
                      >
                        <Col>
                          <Avatar
                            className="UserProfileModal__manager_avatar"
                            size={25}
                            shape="circle"
                            icon={<UserOutlined />}
                            name={user.manager.name}
                            src={user.manager.avatar}
                          />
                        </Col>
                        <Col>
                          <div>
                            <div>{ user.manager.name }</div>
                            {(!collaboratorSlug || (!!collaboratorSlug && organization.config.show_contact_collaborator_participation)) && (
                              <div>{ user.manager.email }</div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                </Row>
              }
            </div>
            <Tabs
              animated={false}
              defaultActiveKey={
                showProfile ? 'profile' : 'activities'
              }
              tabPosition="top"
            >
              {
                !showProfile
                ? null
                : (
                  <Tabs.TabPane tab={ t.profile } key="profile">
                    <Profile
                      user={user}
                      onboardingSteps={onboardingSteps}
                    />
                  </Tabs.TabPane>
                )
              }
              <Tabs.TabPane tab={ t.activities } key="activities">
                <Activities
                  user={user}
                  pdfStatus={pdfStatus}
                  requestGenerateResponsibleProfilePDF={onGenerateResponsibleProfilePDF}
                  organization={!collaboratorSlug ? organization : user.organization_collaborator}
                  slug={slug}
                />
              </Tabs.TabPane>
            </Tabs>
          </>
        )
      }
    </Modal>
  );
};

export default injectIntl(UserProfileModal);
