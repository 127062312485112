import React from 'react';
import { injectIntl } from 'react-intl';

import { formatShort } from 'utils/date';

import {
  Row,
  Col,
} from 'antd';

const Restrictions = ({
  intl,
  max_participants,
  registration_end_time,
  minors_allowed,
}) => {
  const t = intl.messages;
  const max = max_participants > 0 ? max_participants : t.unlimited;
  const latest = registration_end_time ? formatShort(registration_end_time, intl) : t.no;
  const data = [
    {name: t.max_participants, value: max},
    {name: t.registration_end_time, value: latest},
    {name: t.minors_allowed, value: minors_allowed ? t.yes : t.no}
  ];

  return (
    <section className='actionRestrictions'>
      <div className='actionRestrictions-title'>{t.restrictions}</div>
      { data.map((item) => (
        <Row type="flex" justify="space-between" className='actionRestrictions-item'>
          <Col className='actionRestrictions-name'>{item.name}</Col>
          <Col className='actionRestrictions-value'>{item.value}</Col>
        </Row>
      ))}
    </section>
  );
};

export default injectIntl(Restrictions);
