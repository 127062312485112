import React from 'react';
import { injectIntl } from 'react-intl';

import InitiativeCollaborators from 'containers/InitiativeCollaborators';
import InitiativeIndicators from 'containers/InitiativeIndicators';
import InitiativeTags from 'containers/InitiativeTags';
import InitiativeDocuments from 'containers/InitiativeDocuments';
import InitiativeImages from 'containers/InitiativeImages';
import Title from '../cards/Title';
import When from '../cards/When';
import Organization from '../cards/Organization';
import Submitter from '../cards/Submitter';
import Description from '../cards/Description';
import Sdgs from '../cards/Sdgs';
import Contact from '../cards/Contact';
import Collaboration from '../cards/Collaboration';
import Buttons from '../cards/Buttons';
import Collaborators from '../cards/Collaborators';
import { useFeatureList } from 'components/FeatureSwitch';
import { usePermissionList } from 'components/PermissionSwitch';
import PendingCountBadge from 'components/PendingCountBadge';

import { Tabs, Row, Col } from 'antd';

// TODO: Remove this if we ever implement "professional" as "internal with collaborators"
const INTERNAL_INITIATIVES_DO_NOT_HAVE_COLLABORATORS = true;

const Internal = ({
  intl,
  push,
  slug,
  action,
  organization,
  initiative,
  admin_profile,
  taxonomies,
  requestInitiativeDetail,
  cancelInitiative,
  requestCollaboration,
  updateCollaboration,
  cancelCollaborationRequest,
  stopCollaboration,
  isOrganizer,
  isCollaborator,
  loading,
  loading_member_id,
  canEdit,
}) => {
  const t = intl.messages;
  const featureList = useFeatureList();
  const permissionList = usePermissionList();

  return (
    <Tabs
      className='initiativeTabs'
      defaultActiveKey="details"
      onChange={key => push(`/initiative/${slug}/${key}`)}
      renderTabBar={(props) =>
        <Row type="flex" className='actionTabs'>
          { props.panes.map((tab) => (
            !tab ? null :
            <Col
              className={'tab ' + (props.activeKey === tab.key ? 'active' : '')}
              onClick={() => props.onTabClick(tab.key)}>
                {tab.props.tab}
                <PendingCountBadge initiative={initiative} tab={tab} />
            </Col>
          ))}
        </Row>
    }>
      <Tabs.TabPane tab={ t.details } key="details">
        <Row type="flex" gutter={20}>
          <Col xs={ 24 } md={ 15 } className="InitiativeDetail-col">
            <Title {...initiative} />
            <When {...initiative} />
            <Description {...initiative} />
          </Col>
          <Col xs={ 24 } md={ 9 } className="InitiativeDetail-col">
            { isOrganizer ?
              <Buttons
                loading={loading}
                canEdit={canEdit}
                onEdit={() => push(`/initiative/${initiative.slug}/edit`)}
                onDelete={(reason) => cancelInitiative(organization.slug, initiative.slug, reason)}
                {...initiative}
              /> :
              <Collaboration
                defaultEmail={(admin_profile || {}).email || ''}
                onRequest={(email, cohorts) => requestCollaboration(organization.slug, initiative.slug, email, cohorts)}
                onUpdate={(email, cohorts) => updateCollaboration(organization.slug, initiative.slug, email, cohorts)}
                onCancel={() => cancelCollaborationRequest(organization.slug, initiative.slug)}
                onStop={() => stopCollaboration(organization.slug, initiative.slug, organization.slug)}
                {...initiative}
              />
            }
            <Sdgs {...initiative} />
            <Organization {...initiative} />
            {
              !initiative.collaborative ? null :
              <Collaborators {...initiative} />
            }
            {
              !initiative.submitter ? null :
              <Submitter {...initiative} />
            }
            <Contact {...initiative} />
            {
              !canEdit && (!initiative.tags || initiative.tags.length === 0)
              ? null
              : (
                <InitiativeTags
                  loading={loading}
                  initiative_slug={initiative.slug}
                  {...initiative}
                  canEdit={canEdit}
                />
              )
            }
          </Col>
        </Row>
      </Tabs.TabPane>

      {
        INTERNAL_INITIATIVES_DO_NOT_HAVE_COLLABORATORS ||
        !isOrganizer ||
        !initiative.collaborative ||
        !permissionList.has('can_manage_collaborations')
        ? null
        : <Tabs.TabPane tab={ t.collaborators } key="collaborators">
            <InitiativeCollaborators loading={loading} {...initiative} />
          </Tabs.TabPane>
      }

      { (isOrganizer || isCollaborator) && featureList.has('impact') &&
        <Tabs.TabPane tab={ t.indicators } key="indicators">
          <InitiativeIndicators
            initiative={initiative}
            organization={organization} />
        </Tabs.TabPane>
      }
      { (isCollaborator || isOrganizer) && !organization?.config?.hide_initiative_images &&
      <Tabs.TabPane tab={ t.initiative_images } key="images">
        <InitiativeImages
          canEdit={canEdit}
          initiative={initiative}
          organization={organization}
        />
      </Tabs.TabPane>
      }
      { (isCollaborator || isOrganizer) && !organization?.config?.hide_initiative_documents &&
      <Tabs.TabPane tab={ t.initiative_documents } key="documents">
        <InitiativeDocuments
          canEdit={canEdit}
          initiative={initiative}
          organization={organization}
        />
      </Tabs.TabPane>
      }
    </Tabs>
  )
}

export default injectIntl(Internal);
