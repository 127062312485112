import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import { formatDateLong } from 'utils/date';
import { isDeletedUser, getUserName } from 'utils/user';

import {
  Modal,
  Table,
  Spin,
  Row,
  Col,
  Avatar,
  Button,
} from 'antd';
import {
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

const IndicatorParticipants = ({
  intl,
  visible,
  indicator,
  onCancel,
  updateIndicatorParticipation,
}) => {
  const t = intl.messages;

  const getActions = useCallback(({id, status}) => {
    switch(status) {
      case 'validated':
        return [ <span className="InitiativeParticipant-validated">{ t.validated }</span> ];
      case 'rejected':
        return [ <span className="InitiativeParticipant-rejected">{ t.rejected }</span> ];
      case 'pending':
      default:
        return [
          <Button
            className='acceptRequest'
            size="small"
            type="primary"
            shape='circle'
            icon={<CheckOutlined />}
            onClick={() => updateIndicatorParticipation(id, {status: 'validated'})}
          />,
          <Button
            className='rejectRequest'
            size="small"
            type='danger'
            shape='circle'
            icon={<CloseOutlined />}
            onClick={() => updateIndicatorParticipation(id, {status: 'rejected'})}
          />
        ];
    };
  }, [t, updateIndicatorParticipation]);

  const columns = useMemo(
    () => [
      {
        title: t.name,
        dataIndex: 'member_name',
        key: 'name',
        render: (_, row) => (
          <Row type="flex" gutter={10} justify="left" align="middle">
            <Col>
              { isDeletedUser(row, 'member_status')
                ? <Avatar icon={<DeleteOutlined />} />
                : <Avatar
                    size={38}
                    shape="circle"
                    src={row.member_name}
                    name={''}
                    icon={<UserOutlined />}
                  />
              }
            </Col>
            <Col>
              <Row>{getUserName(row, intl, 'member_name', 'member_status')}</Row>
              {!isDeletedUser(row, 'member_status') && <Row>{row.member_email}</Row>}
              {!isDeletedUser(row, 'member_status') && <Row>{row.member_phone}</Row>}
            </Col>
          </Row>
        )
      },
      {
        title: t.value,
        dataIndex: 'value',
        key: 'value',
        render: (value, {unit}) => <span>{`${value} ${t[`unit_${unit}_symbol`]}`}</span>
      },
      {
        title: t.reported_date,
        width: 130,
        dataIndex: 'created_at',
        render: (date) => formatDateLong(date, intl)
      },
      {
        title: t.status,
        width: 120,
        render: getActions,
      }
    ],
    [t, getActions, intl]
  );

  const sortedParticipations = useMemo(
    () => {
      if (!indicator) {
        return [];
      }

      const statusPriority = {
        'rejected': 3,
        'validated': 2,
        'pending': 1,
      }
      return indicator.participations.sort(
        (a, b) => statusPriority[a.status] > statusPriority[b.status] ? 1 : -1
      );
    },
    [indicator]
  )

  return (
    <Modal
      title={`${t.indicator_replies} ${indicator ? indicator.name : ''}`}
      visible={visible}
      width={800}
      centered
      footer={null}
      onCancel={onCancel}
    >
      {!indicator
        ? <Spin />
        : <Table
            columns={columns}
            dataSource={sortedParticipations}
            rowKey={(_, index) => `row_${index}`}
            rowClassName={(_, index) => index % 2 ? 'Table-row-odd' : 'Table-row-even'}
          />
      }
    </Modal>
  )
};

export default injectIntl(IndicatorParticipants);