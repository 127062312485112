import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

import config from 'config';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const TARGET = 'organization';

const DEFAULT_LANGUAGES = ['en-US', 'es-ES', 'pt-PT', 'pt-BR'];

const INVISIBLE_PRODUCTS = [
  'sso',
];

const FEATURE_PRODUCTS = [
  'materiality',
  'equality',
];

const VALID_STATUSES = [
  'active',

  // TODO: remove these two after the statuses are fixed
  'invited',
  'pending_email',
];

const DEFAULT_ORGANIZATION = {
  logo: `/images/aplanet.png`,
  logo_small: `/images/aplanet_small.png`,
  name: 'Aplanet',
  slug: 'aplanet',
  default_latitude: 40.4083539,
  default_longitude: -3.692422,
  id: 1,
  region: 'ES',
  is_default_org: true,
  signup_token: '',
  languages: DEFAULT_LANGUAGES,
  email_domains: [],
  features: config.DEFAULT_FEATURES,
  config: config.DEFAULT_CONFIG,
  last_terms_version: 0,
  role: 'admin',
  status: 'active',
};

const initialState = {
  fetching: false,
  pushing: false,
  slug: null, // NOTICE: We do not prefer if you mark the default org
  data: DEFAULT_ORGANIZATION,
  memberships: [
    DEFAULT_ORGANIZATION,
  ],
  error: null
};

const getOrganizationFromResponse = (response) => {
  const {
    organization_name,
    organization_slug,
    organization_logo,
    organization_logo_small,
    organization_uuid,
    name: provided_name,
    slug: provided_slug,
    logo: provided_logo,
    logo_small: provided_logo_small,
    uuid: provided_uuid,
    region,
    default_latitude,
    default_longitude,
    email_domains = [],
    product_config,
    products: base_products,
    last_terms_version,
    role,
    status,
  } = response;

  // NOTICE: if we are processing a member we prefer organization_*
  const name = organization_name || provided_name;
  const slug = organization_slug || provided_slug;
  const logo = organization_logo || provided_logo;
  const logo_small = organization_logo_small || provided_logo_small;
  const signup_token = organization_uuid || provided_uuid;

  //if(slug === initialState.slug) {
  //  return initialState;
  //}

  const features = product_config && product_config.community && product_config.community.features
    ? Object.keys(product_config.community.features).filter(feature => product_config.community.features[feature])
    : config.DEFAULT_FEATURES;

  const products = (base_products || [])
    .filter(
      product => !INVISIBLE_PRODUCTS.includes(product)
    )
    .concat(
      features.filter(feature => FEATURE_PRODUCTS.includes(feature))
    )

  return {
    name,
    slug,
    logo,
    logo_small,
    region,
    default_latitude,
    default_longitude,
    signup_token,
    email_domains,
    features,
    config: (product_config || {}).community || product_config,
    general_config: product_config?.general || product_config,
    languages: DEFAULT_LANGUAGES,
    products,
    last_terms_version,
    role,
    status,
  };
};

const identityDataToState = (state, user) => {
  let org, memberships;
  if(
    !user ||
    !user.organizations ||
    user.organizations.length === 0
  ) {
    return state;
  }
  if(user.organizations.length > 1) {
    memberships = user.organizations.filter(org => VALID_STATUSES.includes(org.status)).map(getOrganizationFromResponse);
    org = memberships.find(
      ({
        slug,
        products = [],
      }) => {
        return state.slug &&
          slug === state.slug &&
          products.find(product => product === 'community')
      }
    );
    return {
      ...state,
      data: org || null,
      slug: (org || {}).slug || null,
      memberships,
      fetching: false,
      pushing: false,
    };
  }

  org = getOrganizationFromResponse(user.organizations[0]);
  return {
    ...state,
    data: org,
    slug: org.slug,
    memberships: [ org ],
    fetching: false,
    pushing: false,
  };
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'IDENTITY_COMPLETE':
      return identityDataToState(state, action.user);
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
        pushing: ['PUT', 'POST', 'DELETE'].includes(action.method),
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      switch (action.response.method) {
        case 'PUT':
          return identityDataToState(state, action.response.result);
        default:
          return {
            ...state,
            fetching: false,
            pushing: false,
          };
      }
    case 'ORGANIZATION_RESET':
      if(state.memberships.length > 1) {
        return loop({
          ...state,
          data: null,
          slug: null,
        }, Cmd.run(requestPush, { args: [`/organizations`] }));
      }
      return state;
    case 'ORGANIZATION_SET':
      const org = state.memberships.find(({ slug }) => slug === action.slug);

      if(org) {
        return {
          ...state,
          data: org,
          slug: org.slug,
        };
      }
      return state;
    case 'REFRESH_TOKEN_FAILED':
      return initialState;
    case 'HYDRATE_STORE':
      return action.state && action.state.organization_slug ? {
        ...state,
        slug: action.state.organization_slug,
      } : state;
    default:
      return state;
  }
};

export {
  reducer as organization,
  DEFAULT_ORGANIZATION as defaultOrganization,
};

