import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import moment from 'moment';
import {
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import FloatingButton from 'components/FloatingButton';
import IndicatorForm from 'components/IndicatorForm';
import IndicatorParticipants from 'components/IndicatorParticipants';
import AddIndicatorResponseModal from 'components/AddIndicatorResponseModal';

import {
  Button,
  Modal,
  Table,
  Row,
  Col,
} from 'antd';

import {
  createIndicator,
  updateIndicator,
  updateIndicatorParticipation,
  requestOrgVolunteers,
  addIndicatorParticapation,
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

import './style.less';

const { success, error } = Modal;

const TYPES_WITH_PARTICIPATION = [
  'volunteering',
  'training',
  'collection',
];

export const getAggregateForIndicator = (indicator) => {
  if (!indicator || !indicator.participations) {
    return 0;
  }

  const validated_participations = indicator.participations.filter(
    entry => entry.status === 'validated'
  );
  if (validated_participations.length === 0) {
    return 0;
  }
  switch (indicator.aggregate) {
    case 'sum':
      return validated_participations.reduce((a, b) => a + b.value, 0);
    case 'average':
      return validated_participations.reduce((a, b) => a + b.value, 0) / validated_participations.length;
    case 'min':
      return Math.min(...validated_participations.map(entry => entry.value));
    case 'max':
      return Math.max(...validated_participations.map(entry => entry.value));
    default:
      return 0;
  };
};

const InitiativeIndicators = ({
  intl,
  initiative,
  indicators,
  createIndicator,
  updateIndicator,
  updateIndicatorParticipation,
  volunteers,
  requestOrgVolunteers,
  addIndicatorParticapation,
}) => {
  const t = intl.messages;

  const hasParticipation = useMemo(() => {
    return TYPES_WITH_PARTICIPATION.includes((initiative || {}).type);
  }, [
    initiative,
  ]);

  const defaultDefaultValues = useMemo(() => {
    // TODO: add other defaults to make this more usable
    return {
      ask_participants: hasParticipation,
      start_date: moment(initiative.start_time),
      end_date: moment(initiative.end_time),
    };
  }, [
    hasParticipation,
    initiative,
  ]);

  const {
    organization,
  } = useOrganizations();

  const [showNewIndicatorModal, setShowNewIndicatorModal] = useState(false);
  const [formMode, setFormMode] = useState('create');
  const [formDefaultValues, setFormDefaultValues] = useState(defaultDefaultValues);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [showNewResponseModal, setShowNewResponseModal] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState();

  const addIndicatorResponse = useCallback((responseData) => {
    addIndicatorParticapation(
      organization.slug,
      initiative.slug,
      selectedIndicator.id,
      responseData
    )
  }, [
    addIndicatorParticapation,
    organization.slug,
    initiative.slug,
    selectedIndicator,
  ]);

  const onEditClick = useCallback((rowData) => {
    setFormMode('edit');
    setFormDefaultValues({...rowData});
    setShowNewIndicatorModal(true);
  }, [setShowNewIndicatorModal, setFormDefaultValues, setFormMode]);

  const resetAndCloseForm = useCallback(() => {
    setFormMode('create');
    setFormDefaultValues(defaultDefaultValues);
    setShowNewIndicatorModal(false);
  }, [
    defaultDefaultValues,
  ]);

  const toggleParticipantsModal = useCallback(
    (indicator) => {
      if (indicator) {
        setSelectedIndicator(indicator);
        setShowParticipantsModal(true);
      } else {
        setSelectedIndicator();
        setShowParticipantsModal(false);
      }
    },
    [setSelectedIndicator, setShowParticipantsModal]
  );

  const toggleIndicatorResponseModal = useCallback(
    (indicator) => {
      if (indicator) {
        setSelectedIndicator(indicator);
        setShowNewResponseModal(true);
      } else {
        setSelectedIndicator();
        setShowNewResponseModal(false);
      }
    },
    [setSelectedIndicator, setShowNewResponseModal]
  );

  const indicatorCell = useCallback((name) => {
    return (
      <React.Fragment>
        <strong>{name}</strong>
      </React.Fragment>
    );
  }, []);

  const initialValueCell = useCallback((initial_value, row) => {
    return (
      <>
        <span>{`${initial_value} ${t[`unit_${row.unit}_symbol`]}`}</span>
        <p>{moment(row.start_date).format('D MMM YYYY')}</p>
      </>
    );
  }, [t]);

  const currentValueCell = useCallback((current_value, row) => {
    return (
      <>
        <span>{current_value ? `${current_value} ${t[`unit_${row.unit}_symbol`]}` : '-'}</span>
      </>
    );
  }, [t]);

  const targetValueCell = useCallback((target_value, row) => {
    return (
      <>
        <span>{target_value ? `${target_value} ${t[`unit_${row.unit}_symbol`]}` : '-'}</span>
        <p>{moment(row.end_date).format('D MMM YYYY')}</p>
      </>
    );
  }, [t]);

  const actionsCell = useCallback((_, row) => {
    const hasPendingParticipant = row.participations && row.participations.map(
      p => p.status
    ).includes('pending');

    return (
      <>
        { !hasParticipation ? null :
          <Button
            icon={<EyeOutlined />}
            shape="circle"
            className={{
              "IndicatorForm-table-actions-button": true,
              "colorRed": hasPendingParticipant,
            }}
            onClick={() => toggleParticipantsModal(row)}
          />
        }
        <Button
          icon={<EditOutlined />}
          shape="circle"
          className="IndicatorForm-table-actions-button"
          onClick={() => onEditClick(row)}
        />
        { !hasParticipation ? null :
          <Button
            icon={<PlusOutlined />}
            shape="circle"
            className="IndicatorForm-table-actions-button"
            onClick={() => toggleIndicatorResponseModal(row)}
          />
        }
      </>
    );
  }, [
    toggleIndicatorResponseModal,
    toggleParticipantsModal,
    onEditClick,
    hasParticipation,
  ]);

  const setCurrentValue = useCallback(
    (indicator) => {
      updateIndicator(
        organization.slug, initiative.slug, indicator.id,
        {current_value: parseFloat(indicator.initial_value) + getAggregateForIndicator(indicator)}
      );
    },
    [organization, initiative, updateIndicator]
  );

  const askParticipantCell = useCallback((checked, row) => {
    return (
      <>
        <span>{checked ? t.yes : t.no}</span>
        {row.ask_participants ?
          <Row>
            <Col span={12}>
              <span>{`${t[`aggregate_${row.aggregate}`]}: ${getAggregateForIndicator(row)}`}</span>
            </Col>
            <Col span={12}>
              <Button size="small" onClick={() => setCurrentValue(row)}>{t.use}</Button>
            </Col>
          </Row> :
          null
        }
      </>
    );
  }, [t, setCurrentValue])

  const columns = useMemo(
    () => [
      {
        title: t.kpi,
        dataIndex: 'name',
        key: 'name',
        render: indicatorCell,
      },
      {
        title: t.indicators_initial_value,
        dataIndex: 'initial_value',
        key: 'initial_value',
        render: initialValueCell,
      },
      {
        title: t.indicators_current_value,
        dataIndex: 'current_value',
        key: 'current_value',
        render: currentValueCell,
      },
      {
        title: t.indicators_target_value,
        dataIndex: 'target_value',
        key: 'target_value',
        render: targetValueCell,
      },
      (
        !hasParticipation ? null :
        {
          title: t.participant_data,
          dataIndex: 'ask_participants',
          key: 'ask_participants',
          render: askParticipantCell,
        }
      ),
      {
        width: 160,
        className: 'IndicatorForm-table-actions',
        render: actionsCell,
      }
    ].filter(Boolean),
    [
      t,
      hasParticipation,
      indicatorCell, initialValueCell, currentValueCell,
      targetValueCell, actionsCell, askParticipantCell,
    ]
  );

  const handleOnSubmitForm = useCallback(
    indicator => {
      if (indicator.id) {
        updateIndicator(organization.slug, initiative.slug, indicator.id, indicator);
      } else {
        createIndicator(organization.slug, initiative.slug, indicator);
      }
      resetAndCloseForm();
    },
    [initiative, organization, createIndicator, updateIndicator, resetAndCloseForm]
  );

  const onUpdateIndicatorParticipation = useCallback(
    (participation_id, body) => {
      updateIndicatorParticipation(
        organization.slug, initiative.slug, selectedIndicator.id, participation_id, body
      );
    },
    [organization, initiative, selectedIndicator, updateIndicatorParticipation]
  );

  useEffect(() => {
    if(!indicators.loading && showNewResponseModal){
      if(indicators.error) {
        let errorMsg = indicators.error === 400
          ? t.indicator_response_added_error_400
          : t.indicator_response_added_error_default

        error({
          title: t.indicator_response_added_error,
          content: errorMsg
        });
      } else {
        success({
          title: t.indicator_response_added
        });
      }
      toggleIndicatorResponseModal();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    t,
    indicators,
    toggleIndicatorResponseModal,
  ]);

  useEffect(() => {
    requestOrgVolunteers(organization.slug)
  }, [organization.slug, requestOrgVolunteers]);

  useEffect(
    () => {
      if (selectedIndicator) {
        setSelectedIndicator(
          indicators.items.find(indicator => indicator.id === selectedIndicator.id)
        );
      }
    },
    [selectedIndicator, indicators, setSelectedIndicator]
  );

  // Need this to make sure the form re-renders after closing and reopening it
  const getFormKey = useCallback(
    () => {
      if (!showNewIndicatorModal) {
        return 'new';
      } else {
        return formMode === 'create' ? 'create' : formDefaultValues.id;
      }
    },
    [formMode, formDefaultValues, showNewIndicatorModal]
  );

  return (
    <React.Fragment>
      <h2>{t.indicators_description}</h2>
      <FloatingButton onClick={() => setShowNewIndicatorModal(true)} iconUrl='/images/white-plus.svg' />
      <Table
        columns={columns}
        dataSource={indicators.items}
        rowKey={(_, index) => `row_${index}`}
        rowClassName={(_, index) => index % 2 ? 'Table-row-odd' : 'Table-row-even'}
      />
      <Modal
        title={formMode === 'create' ? t.indicators_add : t.indicators_edit}
        visible={showNewIndicatorModal}
        width="600"
        centered
        okText={t.save}
        cancelText={t.cancel}
        wrapClassName="IndicatorForm-modal"
        okButtonProps={{
          htmlType: 'submit',
          form: 'indicator_form',
        }}
        onCancel={resetAndCloseForm}
      >
        <IndicatorForm
          hasParticipation={hasParticipation}
          key={getFormKey()}
          formMode={formMode}
          defaultValues={formDefaultValues}
          onSubmit={handleOnSubmitForm}
          initiative={initiative}
        />
      </Modal>
      <AddIndicatorResponseModal
        visible={showNewResponseModal}
        volunteers={volunteers}
        organization={organization}
        indicator={selectedIndicator}
        onOk={addIndicatorResponse}
        onClose={() => toggleIndicatorResponseModal()}
      />
      <IndicatorParticipants
        visible={showParticipantsModal}
        indicator={selectedIndicator}
        onCancel={() => toggleParticipantsModal()}
        updateIndicatorParticipation={onUpdateIndicatorParticipation} />
    </React.Fragment>
  );
}

const mapStateToProps = ({
  indicators,
  volunteers,
}) => ({
  indicators,
  volunteers,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      createIndicator,
      updateIndicator,
      updateIndicatorParticipation,
      requestOrgVolunteers,
      addIndicatorParticapation,
    }
  )(withRouter(InitiativeIndicators))
);
