import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const CREATE_ENDPOINT_REGEX = new RegExp('^[a-z0-9_-]+/initiative$');
const UPDATE_ENDPOINT_REGEX = new RegExp('^[a-z0-9_-]+/initiative/([a-z0-9_-]+)$');
const initialState = null;

const reducer = (state = initialState, action) => {
  if(
    action.type === 'API_CALL_COMPLETE' &&
    action.response &&
    (
      (
        CREATE_ENDPOINT_REGEX.test(action.response.endpoint) &&
        action.response.method === 'POST'
      )
        ||
      (
        UPDATE_ENDPOINT_REGEX.test(action.response.endpoint) &&
        action.response.method === 'PUT'
      )
    )
  )
  {
    return loop(state, Cmd.run(requestPush, { args: [`/initiative`] }));
  }
  return state;
};

export {
  reducer as initiative_form_done,
};
