import React from 'react';
import {injectIntl} from 'react-intl';

import {
  Card,
  Form,
  InputNumber,
} from 'antd';

const Beneficiaries = ({
  intl,
  error,
  showError,
  getValue,
  handleChange,
}) => {

  return (
    <Card
      className="InitiativeForm-card"
      title={intl.formatMessage({ id: 'initiativeform_beneficiaries' })}
    >
      <Form.Item
        label={intl.formatMessage({ id: 'initiativeform_beneficiaries_number_of_beneficiaries' })}
        colon={false}
        hasFeedback="hasFeedback"
        validateStatus={showError('beneficiaries')
          ? 'error'
          : ''}
        help={showError('beneficiaries')}
        labelCol={{
          xs: 24,
          sm: 18,
          md: 12
        }} wrapperCol={{
          xs: 24,
          sm: 6
        }}
      >
        <InputNumber
          name="beneficiaries"
          value={getValue('beneficiaries') >= 0
            ? getValue('beneficiaries')
            : ''
          }
          min={0}
          onChange={handleChange('beneficiaries')}
          placeholder={''}
          disabled={getValue('beneficiaries') < 0}
        />
      </Form.Item>
    </Card>
  );
}

export default injectIntl(Beneficiaries);
