import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  Modal,
  Tooltip,
} from 'antd';

import { isPassed } from 'utils/date';


const InitiativeCardSubmitFooter = ({
  history,
  intl,
  collaboration_status,
  isCanceled,
  isCollaborative,
  rejectProposal,
  reject_reason,
  requestCollaboration,
  setInitiativeToReject,
  slug,
  status,
  submitter,
  registration_end_time,
}) => {
  const t = intl.messages;

  const isRegistrationClose = useMemo(
    () => isPassed(registration_end_time), [registration_end_time]
  );

  return (
    <div className="InitiativeCard-submitFooter">
      { !!submitter ? (
        <section className='InitiativeCard-submit'>
          { status === 'rejected'
          ? <React.Fragment>
              <button onClick={() => Modal.info({title: t.reason_reject ,content: reject_reason})} className='submit-review'>{t.showReason}</button>
              <span className='submit-rejected'>{t.proposal_rejected}</span>
              <img className='rejected-img' src='/images/icon-cross.svg' alt={t.proposal_rejected} />
            </React.Fragment>
          : status === 'open'
            ? <React.Fragment>
                <span className='submit-accepted'>{t.proposal_accepted}</span>
                <img className='accepted-img' src='/images/icon-checkmark.svg' alt={t.proposal_accepted} />
              </React.Fragment>
            : <React.Fragment>
                { !isCanceled && (
                  <React.Fragment>
                    <button onClick={() => history.push(`/initiative/${slug}/edit`)} className='submit-review'>{t.review}</button>
                    <span
                      onClick={() => {
                        setInitiativeToReject(slug)
                        return rejectProposal();
                      }}
                      className='submit-reject'
                    >
                      {t.reject}
                    </span>
                  </React.Fragment>
                )}
              </React.Fragment>
          }
        </section>
      ) : (
        <React.Fragment>
          { isCollaborative && (
            <section className='InitiativeCard-submit'>
              { collaboration_status
                ? collaboration_status === 'pending'
                  ? <React.Fragment>
                      <span className='submit-pending'>{t.pending}</span>
                      <img className='pending-img' src='/images/icon-pending-collab.svg' alt={t.pending} />
                    </React.Fragment>
                  : collaboration_status === 'accepted'
                    ? <React.Fragment>
                        <span className='submit-collaborating'>{t.collaborating}</span>
                        <svg className='collaborating-img' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path
                              fill="rgba(0, 180, 110, 1)"
                              fill-rule="nonzero"
                              d="M16.293 7.612c.368 0 .775.31.775.692.005 2.34-.776 4.673-2.342 6.353-3.323 3.566-8.68 3.773-12.113.32v2.332c0 .382-.38.693-.748.693s-.797-.31-.797-.693v-4.156c-.001-.299.183-.565.456-.66l4-1.385c.35-.12.79.086.906.45.117.363-.064.815-.414.936l-2.634 1.013c1.389 1.576 2.96 2.356 5.018 2.357 4.05 0 7.162-3.352 7.163-7.56 0-.382.362-.692.73-.692zM15.27 0c.368 0 .797.31.797.693v4.156a.691.691 0 0 1-.456.659l-4 1.385c-.35.121-.79-.085-.906-.449-.116-.363.064-.815.414-.936l2.634-1.013c-1.389-1.576-2.96-2.357-5.018-2.357-4.05-.001-7.162 3.351-7.162 7.56 0 .382-.363.692-.73.692-.369 0-.776-.31-.776-.693C.063 7.358.844 5.025 2.41 3.344c3.323-3.566 8.68-3.772 12.113-.32V.693c0-.383.38-.693.748-.693z"
                            />
                        </svg>
                      </React.Fragment>
                    : <React.Fragment>
                        <span className='submit-rejected'>{t.rejected}</span>
                        <img className='rejected-img' src='/images/icon-cross.svg' alt={t.rejected} />
                      </React.Fragment>
                : (
                  <Tooltip
                    title={isRegistrationClose ? t.collaboration_registration_date_passed : t.collaborate}
                    trigger='hover'>
                    <button
                      onClick={isRegistrationClose ? null : requestCollaboration}
                      className='submit-collaborate'
                    >{t.collaborate}</button>
                  </Tooltip>
                )
              }
            </section>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default injectIntl(withRouter(InitiativeCardSubmitFooter));
