import React from "react";
import {connect} from "react-redux";
import { injectIntl } from "react-intl";

import { Layout } from "antd";

const {
  Content,
} = Layout;

const NotFound = ({ intl }) => {
  const t = intl.messages;
  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        404! { t.not_found }
      </Content>
    </Layout>
  )
}

const mapStateToProps = () => ({});

export default injectIntl(connect(mapStateToProps)(NotFound));
