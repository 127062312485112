import React, {
    useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  addInitiativeTag,
  removeInitiativeTag,
} from 'actions/api';
import { addTagToTaxonomies } from 'actions/taxonomies';
  
import CustomTagSelector from 'components/CustomTagSelector';
import useOrganizations from 'utils/useOrganizations';

  
const InitiativeTags = ({
  intl,
  initiative_slug,
  tags = [],
  loading,
  addInitiativeTag,
  removeInitiativeTag,
  canEdit,
  addTagToTaxonomies,
}) => {
  const t = intl.messages;

  const {
    organization,
  } = useOrganizations();

  const onAdd = useCallback((tag) => {
    addInitiativeTag(
      organization.slug,
      [ initiative_slug ],
      tag,
    );
    addTagToTaxonomies(tag);
  }, [
    addInitiativeTag,
    addTagToTaxonomies,
    organization,
    initiative_slug,
  ]);

  const onDelete = useCallback((tag) => {
    removeInitiativeTag(
      organization.slug,
      [ initiative_slug ],
      tag,
    );
  }, [
    removeInitiativeTag,
    organization,
    initiative_slug,
  ]);

  return (
    <>
      <div className='InitiativeDetail__title'>{ t.tags }</div>
      <div className="InitiativeDetail__card InitiativeDetail__tags">
        <CustomTagSelector
          tags={tags}
          loading={loading}
          onAdd={onAdd}
          onDelete={onDelete}
          canEdit={canEdit}
        />
      </div>
    </>
  );
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {
    addInitiativeTag,
    removeInitiativeTag,
    addTagToTaxonomies,
  })(
    injectIntl(
      InitiativeTags
    )
  )
