import React, { useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import styles from '../styles.css';
import formatNumber from '../formatNumber';

const PERCENT_REPLACER = '#PERCENT#';
const PERIOD_REPLACER = '#PERIOD#';
const DEFAULT_PARAMETERS = {
  key: 'value',
  compareKey: 'compare',
  periodKey: 'period',
  comparePeriodKey: 'compare_period',
  compareDecimals: 0,
  decimals: 0,
  suffix: '',
  prefix: '',
  sameValueMsg: 'roughly the same',
  increaseMsg: `by ${PERCENT_REPLACER}% since ${PERIOD_REPLACER}`,
  decreaseMsg: `by ${PERCENT_REPLACER}% since ${PERIOD_REPLACER}`,
  nodata: 'No data',
  locale: undefined,
};

const SingleNumber = forwardRef(({
  parameters = DEFAULT_PARAMETERS,
  data,
}, ref) => {
  const {
    key = DEFAULT_PARAMETERS.key,
    compareKey = DEFAULT_PARAMETERS.compareKey,
    comparePeriodKey = DEFAULT_PARAMETERS.comparePeriodKey,
    compareDecimals = DEFAULT_PARAMETERS.compareDecimals,
    decimals = DEFAULT_PARAMETERS.decimals,
    suffix = DEFAULT_PARAMETERS.suffix,
    prefix = DEFAULT_PARAMETERS.prefix,
    sameValueMsg = DEFAULT_PARAMETERS.sameValueMsg,
    increaseMsg = DEFAULT_PARAMETERS.increaseMsg,
    decreaseMsg = DEFAULT_PARAMETERS.decreaseMsg,
    nodata = DEFAULT_PARAMETERS.nodata,
    locale = DEFAULT_PARAMETERS.locale,
  } = parameters;

  const currentValue = useMemo(() => {
    if(!data[0] || typeof data[0][key] === 'undefined') {
      return null;
    }
    return `${prefix}${ formatNumber(Number(data[0][key]), { locale, minimumFractionDigits: decimals }) }${suffix}`;
  }, [ data, key, prefix, suffix, decimals, locale ]);

  const {
    percent,
    isEqual,
    isIncrease,
    comparison,
  } = useMemo(() => {
    if(!data[0] || typeof data[0][compareKey] === 'undefined') {
      return {};
    }

    const currentValue = Number(data[0][key]);
    const compareValue = Number(data[0][compareKey]);
    const comparePeriod = data[0][comparePeriodKey];

    const threshold = 1 / (10 ** decimals+1);

    const isEqual = Math.abs(currentValue - compareValue) < threshold;
    const isIncrease = currentValue > compareValue;
    const icon = isEqual ? 'equals' : ( isIncrease ? 'arrow-up' : 'arrow-down' );
    const className = isEqual ?
      styles.compare_equal :
      (
        isIncrease ?
        styles.compare_greater :
        styles.compare_lower
      );

    const percent = Math.abs( 100.0 * ( currentValue - compareValue ) / compareValue ).toFixed(compareDecimals);
    const text = (isEqual ? sameValueMsg : (isIncrease ? increaseMsg : decreaseMsg)).replace(PERCENT_REPLACER, percent).replace(PERIOD_REPLACER, comparePeriod);

    return {
      percent,
      isEqual,
      isIncrease,
      comparison: {
        icon,
        className,
        text,
      },
    };
  }, [ compareKey, key, data, compareDecimals, comparePeriodKey, decimals, decreaseMsg, increaseMsg, sameValueMsg ]);

  useImperativeHandle(ref, () => {
    return {
      getExtras: () => ({
        '__comparison': {
          percent,
          isEqual,
          isIncrease,
          comparison,
        },
      }),
    }
  }, [ percent, isEqual, isIncrease, comparison ]);

  return (
    <div className={styles.card_content}>
      {
        !currentValue
        ?
          <div
            className={styles.nodata}
          >
            { nodata }
          </div>
        :
          <div
            className={styles.singlenumber}
          >
            <output>{ currentValue }</output>
          </div>
      }
    </div>
  );
});

SingleNumber.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.arrayOf( PropTypes.object ),
};

export default SingleNumber;

