import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import './style.css';

import { Modal, Button } from 'antd';

const RATIO = 5/3;
const MAX_HEIGHT = 6000;
const MAX_WIDTH = 10000;

const doCrop = (
  src,
  cropUnscaled,
  { x: xRatio, y: yRatio },
  filename='image.jpeg'
) => {
  const canvas = document.createElement('canvas');

  const crop = {
    ...cropUnscaled,
    x: Math.floor(cropUnscaled.x * xRatio),
    y: Math.floor(cropUnscaled.y * yRatio),
    width: Math.ceil(cropUnscaled.width * xRatio),
    height: Math.ceil(cropUnscaled.height * yRatio),
  };

  canvas.width = Math.min(crop.width, MAX_WIDTH);
  canvas.height = Math.min(crop.height, MAX_HEIGHT);
  const ctx = canvas.getContext('2d');

  return new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin= 'anonymous';

    image.onload = () => {
      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      canvas.toBlob(blob => {
        blob.name = filename;
        resolve(blob);
      }, 'image/jpeg');
    };

    image.src = src;
  });
};

const convertBlobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = e => resolve(e.target.result);
    fr.readAsDataURL(blob);
  });
};

const ImageCrop = ({ t, src, onCrop, onCancel }) => {
  const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: RATIO });
  const [imageRatios, setImageRatios] = useState({ x: 1, y: 1 });

  const onOk = () => {
    if( Object.keys(crop).length === 0 ) { return; }
    doCrop(src, crop, imageRatios)
      .then(convertBlobToBase64)
      .then(onCrop);
  };

  const changeCrop = (crop) => {
    setCrop(crop);
  };

  const imageLoaded = (image) => {
    if(!image || image.width === 0 || image.height === 0) {
      return false;
    }

    const imageRatio = image.width / image.height;
    const isWide = imageRatio > RATIO;

    const width = !isWide ? image.width : Math.floor( image.height * RATIO );
    const height = isWide ? image.height : Math.floor( image.width / RATIO );

    const x = !isWide ? 0 : Math.floor((image.width - width) / 2);
    const y = isWide ? 0 : Math.floor((image.height - height) / 2);

    const crop = {
      unit: 'px',
      aspect: RATIO,
      width,
      height,
      x,
      y,
    };

    setImageRatios({
      x: image.naturalWidth / image.width,
      y: image.naturalHeight / image.height,
    })
    setCrop(crop)
    return false
  }

  return (
    <Modal
      visible
      closable={false}
      maskClosable={false}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel} key="cancel">{ t.cancel }</Button>,
        <Button type="primary" onClick={() => onOk()} disabled={crop.width === 0 || crop.height === 0} key="ok">{ t.save }</Button>
      ]}
    >
      <div
        className="ImageCrop-inner"
      >
        <ReactCrop
          src={src}
          crop={crop}
          onChange={changeCrop}
          onImageLoaded={imageLoaded}
        />
      </div>
    </Modal>
  );
}

export default ImageCrop;
