import React, {
  useState,
  useCallback,
  useRef,
} from 'react';
import CustomTag from 'components/CustomTag';
import { injectIntl } from 'react-intl';
import './style.less';

import {
  Input,
  Spin,
} from 'antd';

const NO_OP = () => {};

const TagEditor = injectIntl(({
  intl,
  tags = [],
  loading,
  onAdd = NO_OP,
  onDelete = null,
  canEdit,
}) => {
  const t = intl.messages;
  const [newTag, setNewTag] = useState('');
  const inputRef = useRef();

  const addNewTag = useCallback(() => {
    if(
      newTag === '' ||
      tags.includes(newTag)
    ) {
      inputRef.current.focus();
      setNewTag('');
      return;
    }
    onAdd(newTag);
    setNewTag('');
  },[
    tags,
    newTag,
    onAdd,
  ]);

  const handleChange = useCallback((event) => {
    setNewTag(event.target.value)
  }, []);

  const handleKeyPress = useCallback((event) => {
    if(event.key === 'Enter') {
      addNewTag();
    }
  }, [
    addNewTag,
  ]);

  return (
    <section className='CustomTagSelector-menu'>
      { tags.map(tag => (
        <CustomTag
          key={tag}
          name={tag}
          onDelete={canEdit && onDelete}
        />
      ))}

      { canEdit
        ? (
        <>
          <Input
            ref={inputRef}
            value={newTag}
            disabled={loading}
            suffix={loading ? <Spin size="small" /> : ''}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            className='CustomTagSelector-input'
            placeholder={t.custom_tag_selector_placeholder}
          />
        <button
          className='CustomTagSelector-add'
          disabled={loading}
          onClick={addNewTag}
        >
          {t.custom_tag_selector_add}
        </button>
        </>)
      : null
      }
    </section>
  );
});

const CustomTagSelector = ({
  intl,
  tags = [],
  loading,
  onAdd,
  onDelete,
  className,
  dropdown = false,
  canEdit,
}) => {
  const t = intl.messages;

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <section className={`CustomTagSelector ${className} ${dropdown ? 'CustomTagSelector-dropdown' : ''}`}>
    { dropdown
      ? <React.Fragment>
          <section
            onClick={() => setMenuOpen(!menuOpen)}
            className={`CustomTagSelector-button ${menuOpen ? 'CustomTagSelector-open' : ''}`}
          >
            { t.custom_tag_selector_title }
          </section>
          {
            !menuOpen
            ? null
            : (
              <TagEditor
                tags={tags}
                loading={loading}
                onAdd={onAdd}
                onDelete={onDelete}
                canEdit={canEdit}
              />
            )
          }
        </React.Fragment>
      : (
        <TagEditor
          tags={tags}
          loading={loading}
          onAdd={onAdd}
          onDelete={onDelete}
          canEdit={canEdit}
        />
      )
    }
    </section>
  );
};

export default injectIntl(CustomTagSelector);
