import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles.css';

const DEFAULT_PARAMETERS = {
  nodata: 'Wrong Chart',
};

const UnknownChart = ({
  parameters = DEFAULT_PARAMETERS,
}) => {
  const {
    nodata = DEFAULT_PARAMETERS.nodata,
  } = parameters;

  return (
    <div className={styles.card_content}>
      <div
        className={styles.nodata}
      >
        { nodata }
      </div>
    </div>
  );
};

UnknownChart.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.arrayOf( PropTypes.object ),
};

export default UnknownChart;

