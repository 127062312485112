import React from 'react';
import { injectIntl } from 'react-intl';

import './style.css';

const srcs = {
  'no-poverty': '01-no-poverty.png',
  'zero-hunger': '02-zero-hunger.png',
  'good-health-and-wellbeing': '03-good-health-and-wellbeing.png',
  'quality-education': '04-quality-education.png',
  'gender-equality': '05-gender-equality.png',
  'clean-water-and-sanitation': '06-clean-water-and-sanitation.png',
  'affordable-and-clean-energy': '07-affordable-and-clean-energy.png',
  'decent-work-and-economic-growth': '08-decent-work-and-economic-growth.png',
  'industry-innovation-and-infrastructure': '09-industry-innovation-and-infrastructure.png',
  'reduced-inequalities': '10-reduced-inequalities.png',
  'sustainable-cities-and-communities': '11-sustainable-cities-and-communities.png',
  'responsible-consumption-and-production': '12-responsible-consumption-and-production.png',
  'climate-action': '13-climate-action.png',
  'life-below-water': '14-life-below-water.png',
  'life-on-land': '15-life-on-land.png',
  'peace-justice-and-strong-institutions': '16-peace-justice-and-strong-institutions.png',
  'partnership-for-the-goals': '17-partnership-for-the-goals.png',
};

const AVAILABLE_LANGUAGES = ['en', 'es', 'pt', 'pt_br', 'fr'];

const getImage = (locale, sdg, bw) => {
  const language = locale === 'pt-BR' ? 'pt_br' : (locale || '').slice(0, 2);
  if(AVAILABLE_LANGUAGES.includes(language)) {
    return `/images/sdg/${language}/${bw ? 'bw/' : ''}${srcs[sdg]}`;
  }
  return `/images/sdg/${AVAILABLE_LANGUAGES[0]}/${bw ? 'bw/' : ''}${srcs[sdg]}`;
}

const SdgLogo = ({
  intl,
  sdg,
  style = {},
  className,
  onClick,
  bw = false,
}) => {
  const t = intl.messages;
  const locale = intl.locale;
  return (
    <div
      className={`SdgLogo ${className}`}
      style={style}
      onClick={onClick}
    >
      <img alt={t[`sdg_${sdg}`]} src={getImage(locale, sdg, bw)} />
    </div>
  );
}

export default injectIntl(SdgLogo);
