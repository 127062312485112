import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AuthorizedLink from 'containers/AuthorizedLink';
import FileIcon from 'components/InitiativeDocuments/FileIcon';
import T from 'components/T';
import formatNumber from 'utils/formatNumber';
import { formatDateLong } from 'utils/date';
import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

import { UploadButton } from 'aplanet-ui-kit';
import {
  Row,
  Col,
  Button,
  List,
  Modal,
} from 'antd';
import {
  UploadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import './style.less';


const FORMAT_FILESIZE = {
  locale: 'bytes', // LOL
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const OfficialDocuments = ({
  intl,
  documents,
  uploadURL,
  onUploadSuccess,
  onDelete,
  onUnpublish,
}) => {
  const t = intl.messages;

  const onDocumentUploadSuccess = useCallback(
    (response) => onUploadSuccess(JSON.parse(response)),
    [onUploadSuccess],
  );

  const handleOnDelete = useCallback(
    (e, document) => {
      e.preventDefault();

      Modal.confirm({
        title: t.initiative_documents_delete_modal_title,
        icon: <ExclamationCircleOutlined />,
        content: t.initiative_documents_delete_modal_description,
        okText: t.initiative_documents_delete_modal_ok,
        cancelText: t.initiative_documents_delete_modal_cancel,
        onOk() {
          onDelete(document.id);
        },
      });
    },
    [onDelete, t]
  );

  const handleOnUnpublish = useCallback(
    (e, document) => {
      e.preventDefault();

      Modal.confirm({
        title: t.initiative_documents_unpublish_modal_title,
        icon: <ExclamationCircleOutlined />,
        content: t.initiative_documents_unpublish_modal_description,
        okText: t.initiative_documents_unpublish_modal_ok,
        cancelText: t.initiative_documents_unpublish_modal_cancel,
        onOk() {
          onUnpublish(document.id);
        },
      });
    },
    [onUnpublish, t]
  );

  const getPossibleActions = useCallback(
    document => {
      let actions = [
        <AuthorizedLink
          href={document.download}
          download={document.filename}
          mimeType={document.mimetype}
        >
          { t.initiative_documents_download }
        </AuthorizedLink>,
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={e => handleOnDelete(e, document)}
        >
          { t.initiative_documents_delete }
        </a>,
      ];

      if (!document.is_official) {
        actions.splice(1, 0, (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={e => handleOnUnpublish(e, document)}
          >
            { t.initiative_documents_unpublish }
          </a>
        ));
      }
      return actions;
    },
    [handleOnDelete, handleOnUnpublish, t]
  );

  // For upload button
  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(
    () => dispatch(_refreshAccessToken()),
    [ dispatch ]
  );

  return (
    <Row className="OfficialDocuments">
      <Col span={24}>
        <Row className="OfficialDocuments-upload-button-container">
          <Col span={10}>
            <UploadButton
              intl={intl}
              actionUrl={uploadURL}
              onSuccess={onDocumentUploadSuccess}
              component={
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                >
                  {t.initiative_documents_upload_button}
                </Button>
              }
              api_requests={api_requests}
              refreshAccessToken={refreshAccessToken}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={documents}
              renderItem={document => (
                <List.Item
                  actions={getPossibleActions(document)}
                >
                  <List.Item.Meta
                    avatar={<FileIcon mimetype={document.mimetype} />}
                    title={document.filename}
                    description={
                      <Row gutter={10}>
                        <Col>
                          {formatNumber(document.size, FORMAT_FILESIZE)}
                        </Col>
                        <Col>|</Col>
                        <Col>
                          <T
                            initiative_documents_shared_by={{
                              name: document.member_name,
                              date: formatDateLong(document.created_at, intl) || '-'
                            }}
                          />
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default injectIntl(OfficialDocuments);
