import React, {
  useMemo,
  useCallback,
} from 'react';

import { injectIntl } from 'react-intl';

import {
  Card,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
} from 'antd';

import {
  QUESTION_SEPARATOR,
} from '../converters';

const Questions = ({
  intl,
  organization,
  loading,
  error,
  errorRefs,
  showError,
  values,
  handleChange,
}) => {
  const t = intl.messages;

  const questions = useMemo(() => {
    return ((organization || {}).config || {}).initiative_questions || {};
  }, [
    organization,
  ]);

  const handleAnswerChange = useCallback((question, answer) => () => {
    handleChange('questions')(`${question}${QUESTION_SEPARATOR}${answer}`);
  }, [ handleChange ]);

  const handleOtherChange = useCallback((question) => (event) => {
    const other = event.target.value;
    const answers = [
      // All selected answers to OTHER questions
      ...(
        (values.questions || []).filter(
          val => !val.startsWith(`${question}${QUESTION_SEPARATOR}`)
        )
      ),

      // All default answers to THIS question
      ...(
        questions[question].filter(
          answer => (values.questions || []).includes(`${question}${QUESTION_SEPARATOR}${answer}`)
        ).map(answer => `${question}${QUESTION_SEPARATOR}${answer}`)
      ),

      // This answer for this question
      ...(
        other ? [ `${question}${QUESTION_SEPARATOR}${other}` ] : []
      ),
    ];
    handleChange('questions')(answers);
  }, [
    questions,
    values,
    handleChange,
  ]);

  const additionalAnswers = useMemo(() => {
    const result = {};

    Object.keys(questions).forEach(question => {
      result[question] = [];
      (values.questions || [])
        .filter(v => v.startsWith(`${question}${QUESTION_SEPARATOR}`))
        .forEach(v => {
          const [ question, answer ] = v.split(QUESTION_SEPARATOR);
          if(
            question &&
            answer &&
            !questions[question].includes(answer)
          ) {
            result[question].push(answer);
          }
        })
    });
    return result;
  }, [
    questions,
    values,
  ]);

  return (
    <>
      {
        Object.keys(questions).map(question => {
          const defaultAnswers = questions[question];
          return (
            <Card
              className="InitiativeForm-card"
              key={question}
              title={<span > { question } </span>}
            >
              <div ref={errorRefs.questions}></div>
              <Form.Item
                hasFeedback="hasFeedback"
                validateStatus={
                  showError('questions')
                    ? 'error'
                    : ''
                  }
                  help={showError('questions')}
                >
                  <div>
                  {
                    defaultAnswers.map((answer) => (
                      <Checkbox
                        key={answer}
                        checked={(values.questions || []).includes(`${question}${QUESTION_SEPARATOR}${answer}`)}
                        onChange={handleAnswerChange(question, answer)}
                        style={{ display: 'block', marginLeft: '0' }}
                      >
                        { answer }
                      </Checkbox>
                    ))
                  }
                  {
                    <Checkbox
                      checked={additionalAnswers[question].length > 0}
                      onChange={() => {
                        if(additionalAnswers[question].length > 0) {
                          handleAnswerChange(`${question}${QUESTION_SEPARATOR}${additionalAnswers[question][0]}`)
                        }
                      }}
                      style={{ display: 'block', marginLeft: '0' }}
                    >
                      <div
                        style={{
                          minWidth: '80%',
                          display: 'inline-block',
                        }}
                      >
                        <Row
                          gutter={16}
                          type="flex"
                        >
                          <Col>
                            { t.questions_other }:
                          </Col>
                          <Col
                            style={{
                              flex: '1',
                            }}
                          >
                            <Input
                              value={additionalAnswers[question][0] || ''}
                              onChange={handleOtherChange(question)}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Checkbox>
                  }
                  </div>
              </Form.Item>
            </Card>
          );
        })
      }
    </>
  );
}

export default injectIntl(Questions);
