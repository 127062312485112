export const stringToHash = (string, limit) => {
  if(!string) {
    return null;
  }
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash
  }
  return limit ? hash % limit : hash
}
