import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { toDbDate } from 'utils/date';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Row,
  Tag,
} from 'antd';
import {
  FilterOutlined,
} from '@ant-design/icons';
import useSetState from 'utils/useSetState';


const { RangePicker } = DatePicker;

const Filters = ({
  intl,
  filters,
  setFilters,
  taxonomies,
}) => {
  const t = intl.messages;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dateInterval, setDateInterval] = useState('year');

  const onChangeDateInterval = useCallback(
    (interval) => {
      setDateInterval(interval);
      setFilters({
        ...filters,
        start: toDbDate(moment().startOf(interval)),
        end: toDbDate(moment().endOf(interval)),
      });
    },
    [filters, setFilters]
  );

  // Set date interval
  const onSetCustomDateInterval = useCallback(
    (value) => {
      if(!value) {
        setDateInterval('year');
        setFilters({
          ...filters,
          start: toDbDate(moment().startOf('year')),
        end: toDbDate(moment().endOf('year')),
        });
        return;
      }

      if (
        value[0]
        && value[0].isSame(moment().startOf('week'), 'day')
        && value[1].isSame(moment().endOf('week'), 'day')
      ) {
        // This week selected
        setDateInterval('week');
      } else if (
        value[0]
        && value[0].isSame(moment().startOf('month'), 'day')
        && value[1].isSame(moment().endOf('month'), 'day')
      ) {
        // This month selected
        setDateInterval('month');
      } else if (
        value[0]
        && value[0].isSame(moment().startOf('year'), 'day')
        && value[1].isSame(moment().endOf('year'), 'day')
      ) {
        setDateInterval('year');
      }
      // Custom interval
      else {
        setDateInterval('');
      }

      setFilters({
        ...filters,
        start: toDbDate(value[0]),
        end: toDbDate(value[1]),
      });
    },
    [filters, setFilters]
  );

  const {
    set: filteredTags,
    has: isTagsFilterSet,
    toggle: toggleTagsFilter,
    replaceAll: setFilteredTags,
  } = useSetState([]);
  const tagsFilterSet = useMemo(
    () => filteredTags.length > 0,
    [ filteredTags ]
  );

  useEffect(
    () => {
      if (filters.tags !== filteredTags.join(',')) {
        setFilters({
          ...filters,
          tags: filteredTags.join(','),
        });
      }
    },
    [filters, setFilters, filteredTags]
  );

  return (
    <div className="Filters">
      <Row className='Filters-top' type="flex" align="middle">
        <Col>
          <figure className='Filters-divider'/>
        </Col>
        <Col className={`Filters-intervalPicker ${dateInterval}`}>
          <span
            className='week'
            onClick={() => onChangeDateInterval('week')}
          >
            {t.thisWeek}
          </span>
          <span
            className='month'
            onClick={() => onChangeDateInterval('month')}
          >
            {t.thisMonth}
          </span>
          <span
            className='year'
            onClick={() => onChangeDateInterval('year')}
          >
            {t.thisYear}
          </span>
        </Col>
        <Col>
          <RangePicker
            ranges={{
              [t.today]: [moment(), moment()],
              [t.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
            }}
            showTime={false}
            format="DD MMM"
            className='Filters-datePicker'
            value={[moment(filters.start), moment(filters.end)]}
            onChange={(value) => onSetCustomDateInterval(value)}
            placeholder={[t.from, t.to]}
          />
        </Col>
        <Col>
          <Button
            className='Filters-drawerButton'
            icon={<FilterOutlined />}
            onClick={() => setDrawerOpen(true)}
          />
        </Col>
        <Drawer
          title={t.reporting_filter_title}
          width={350}
          placement="right"
          closable={true}
          onClose={() => setDrawerOpen(false)}
          visible={drawerOpen}
        >
          { taxonomies.tags.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.tags }</h4>
            <div>
              <Checkbox
                checked={!tagsFilterSet}
                onChange={() => setFilteredTags([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.tags.map(name => (
                <Checkbox
                  key={name}
                  checked={isTagsFilterSet(name)}
                  onChange={() => toggleTagsFilter(name)}
                  style={{ display: 'block' }}
                >
                  { name }
                </Checkbox>
              )) }
            </div>
          </div>
        }
        </Drawer>
      </Row>
      <Row
        type="flex"
        align="middle"
        className='Filters-selected'
      >
        {
          filteredTags.length === 0
          ? null
          : (
            <div className="Initiative-filter">
              <span className="Initiative-filter-title">{ t.tags }: </span>
              { filteredTags.map(slug =>
                <Tag
                  key={slug}
                  closable
                  color="magenta"
                  onClose={() => toggleTagsFilter(slug)}
                >{ slug }</Tag>
              ) }
            </div>
          )
        }
      </Row>
    </div>
  );
}

export default injectIntl(Filters);
