import React, { useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
} from 'antd';

import { ReactComponent as PDFIcon } from 'assets/icons/icon-pdf.svg';

import { A } from 'aplanet-ui-kit';

import HourTable from './HourTable';

const Activities = ({
  intl,
  user,
  pdfStatus,
  requestGenerateResponsibleProfilePDF,
  organization,
  slug
}) => {
  const t = intl.messages;

  const [category, setCategory] = useState(null);

  const hasPoints = useMemo(() => {
    const orgFeatures = organization.features;
    return orgFeatures && orgFeatures.includes('points')
  }, [
    organization,
  ]);

  const categoryProps = useMemo(() => ({
    sdg: {
      title: t.user_profile_impacted_sdgs,
      emptyText: t.user_profile_impacted_sdgs_empty,
      entries: (user || {}).sdg_report,
      transformName: slug => t[`sdg_${slug}`],
      isSdg: true,
    },
    competence: {
      title: t.user_profile_competences_applied,
      emptyText: t.user_profile_competences_applied_empty,
      entries: (user || {}).competence_report,
      transformName: slug => t[`competence_${slug}`],
    },
    initiative: {
      title: t.user_profile_initiatives_joined,
      emptyText: t.user_profile_initiatives_joined_empty,
      entries: (user || {}).initiative_report,
      nameProp: 'title',
      valueProp: 'valid_hours',
      otherValueProp: hasPoints ? 'points' : null,
    },
  }), [
    t,
    user,
    hasPoints,
  ]);

  return (
    <>
      {category
        ? <>
            <div className="UserProfileModal__link">
              <A
                onClick={() => setCategory(null)}
              >
                <span className="UserProfileModal__arrow">
                  &larr;
                  </span>
                {t.back}
              </A>
            </div>
            <HourTable
              {...categoryProps[category]}
              showAll
            />
          </>
        : <>
            <Row type="flex" gutter={25}>
              <Col
                xs={24}
                md={hasPoints ? 8 : 12}
              >
                <div className="UserProfileModal__card">
                  <div className="UserProfileModal__title">
                    {t.user_profile_initiatives_participated}
                  </div>
                  <div className="UserProfileModal__content">
                    <div className="UserProfileModal__bignumber">
                      {user.initiatives_joined}
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                md={hasPoints ? 8 : 12}
              >
                <div className="UserProfileModal__card">
                  <div className="UserProfileModal__title">
                    {t.user_profile_hours_validated}
                  </div>
                  <div className="UserProfileModal__content">
                    <div className="UserProfileModal__bignumber">
                      {`${user.hour_count} h`}
                    </div>
                  </div>
                </div>
              </Col>
              {
                !hasPoints ? null :
                <Col
                  xs={24}
                  md={8}
                >
                  <div className="UserProfileModal__card">
                    <div className="UserProfileModal__title">
                      {t.user_profile_points}
                    </div>
                    <div className="UserProfileModal__content">
                      <div className="UserProfileModal__bignumber">
                        { user.point_count || '-' }
                      </div>
                    </div>
                  </div>
                </Col>
              }
            </Row>
            <div className="UserProfileModal__grid" >
              <HourTable
                {...categoryProps.sdg}
                onMore={() => setCategory('sdg')}
              />
              <HourTable
                {...categoryProps.initiative}
                onMore={() => setCategory('initiative')}
              />
              <HourTable
                {...categoryProps.competence}
                onMore={() => setCategory('competence')}
              />
            </div>
            <div className="UserProfileModal__download_row" >
              <Button
                className="UserProfileModal__download"
                type="primary"
                onClick={() => requestGenerateResponsibleProfilePDF()}
                loading={pdfStatus.processing}
                disabled={pdfStatus.processing || pdfStatus.error}
              >
                <PDFIcon />
                {t.user_profile_download_profile}
              </Button>
            </div>
          </>
      }
    </>
  );
}

export default injectIntl(Activities);
