import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { Layout, Card } from 'antd';

import config from 'config';
import './style.less';

import OrganizationLogo from 'components/OrganizationLogo';
import SignedOutFooter from 'components/SignedOutFooter';

import { defaultOrganization } from 'reducers/organization';
import useOrganizations from 'utils/useOrganizations';

const {
  Content,
} = Layout;

// This is a horrible workaround for themes
const HOST_TO_ORG_MAPPING = {
  'criticaltechworks.voluntariadoempresa.pt': 'critical-techworks',
  'roletoplay-getinvolved-backoffice.novasbe.pt': 'nova-sbe',
   //'localhost:3001': 'aplanet',
};

const AccountsRedirect = ({
  intl,
  location,
}) => {
  const t = intl.messages;
  const {
    organization = defaultOrganization,
  } = useOrganizations();

  useEffect(() => {
    const suffix =
      HOST_TO_ORG_MAPPING[window.location.host]
      ? `&org=${HOST_TO_ORG_MAPPING[window.location.host]}`
      : (
        organization &&
        organization.data &&
        organization.data.slug &
        !organization.data.is_default_org
      ) ? `&org=${organization.data.slug}`
        : '';

    window.location.replace(
      location.search
      ? `${config.ACCOUNTS_URL}${location.pathname}${location.search}&app=admin${suffix}`
      : `${config.ACCOUNTS_URL}${location.pathname}?app=admin${suffix}`
    );
  }, [
    organization,
    location.search,
    location.pathname,
  ]);

  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        <div className="AccountsRedirect-cardwrapper">
          <Card className="AccountsRedirect-card">
            <div className="AccountsRedirect-heading">
              <OrganizationLogo
                className="AccountsRedirect-logo"
                {...organization}
              />
            </div>
            <div className="AccountsRedirect-message">
              <p>
                { t.redirecting_to_accounts }
              </p>
            </div>
          </Card>
        </div>
      </Content>
      <SignedOutFooter />
    </Layout>
  )
}

export default injectIntl(
  withRouter(
    AccountsRedirect
  )
);
