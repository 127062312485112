const TARGET = 'proposals';
const INDIVIDUAL_TARGET = 'proposal_detail';
const ID_FIELD = 'id';

const isTarget = (target) => [TARGET, INDIVIDUAL_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  items: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state, // When loading keep same state
        loading: true,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      // General target (only wants the state)
      if(isGeneralTarget(action.response.target)) {
        return {
          items: action.response.result,
          loading: false,
          error: false,
        };
      }

      // This is not a request for me, bailing out
      const targetProposal = action.response.result;
      if(!targetProposal || !['POST', 'PUT', 'DELETE'].includes(action.response.method)) {
        return state;
      }

      // Details target (need to modify state)
      const index = state.items.findIndex((propossal) => propossal[ID_FIELD] === targetProposal[ID_FIELD]);

      if(index >= 0) {
        return {
          items: [ ...state.items.slice(0, index), targetProposal, ...state.items.slice(index+1) ],
          loading: false,
          error: false,
        }
      }else{
        return {
          items: action.response.result,
          loading: false,
          error: false,
        }
      }
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export {
  reducer as proposals,
};
