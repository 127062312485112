import React, { useMemo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles.css';
import { hasDownloadButton, cardMap, iconMap, getParametersFromData, convertData } from './types';
import { propTypes } from '../propTypes';

const defaultRenderCard = ({
  type,
  size,
  icon,
  title,
  period,
  updated_at,
  renderContent,
  extras = [],
  actions = [],
  showDownloadButton,
  handleDownload,
  renderExtras,
}) => {
  return (
    <div
      className={`${styles.card} ${styles[`card_size_${size}`]}`}
    >
      {
        !title ? null :
        <div className={styles.card_title}>
          <Icon className={styles.card_icon} type={type} icon={icon} />
          <h3>{ title }</h3>
          {
            !period
            ?
              null
            :
              <Period className={styles.card_period} period={period} />
          }
        </div>
      }
      {
      !updated_at
        ?
          null
        :
          <div className={styles.card_meta}>
            <em>{ updated_at }</em>
          </div>
      }
      { renderContent() }
      {
        ((Object.keys(extras).length === 0 || Object.keys(extras).filter(key => extras[key].length > 0).length === 0 || !renderExtras) && actions.length === 0) ? null :
        <div
          className={styles.card_footer}
        >
          { !renderExtras ? null : renderExtras(extras) }
          { actions }
          { !showDownloadButton ? null :
            <button
              onClick={handleDownload}
              className={styles.card_download}
            >
              <Icon icon="download" size="md" />
            </button>
          }
        </div>
      }
    </div>
  );

};

const DEFAULTS = {
  size: 'medium',
  parameters: {},
  data: {},
  icon: 'auto',
  title: '',
  description: '',
  actions: [],
  extras: {},
  renderExtras: undefined,
  periods: [],
  updated_at: undefined,
  onDownload: undefined,
  preprocessed: false,
};

const Icon = ({
  className,
  type,
  icon,
  size,
}) => (
  <FontAwesomeIcon
    className={className}
    icon={ !icon || icon === DEFAULTS.icon ? (iconMap[type] || iconMap.default) : icon}
    size={size || 'lg'}
  />
);

const Period = ({
  period,
}) => (
  <div
    className={styles.period_box}
  >
    { period }
  </div>
);

const Card = ({
  id,
  type,
  size = DEFAULTS.size,
  parameters = DEFAULTS.parameters,
  data = DEFAULTS.data,
  icon = DEFAULTS.icon,
  title = DEFAULTS.title,
  description = DEFAULTS.description,
  actions = DEFAULTS.actions,
  render = defaultRenderCard,
  renderExtras = DEFAULTS.renderExtras,
  onDownload = DEFAULTS.onDownload,
  preprocessed = DEFAULTS.preprocessed,
}) => {
  const Content = cardMap[type] || cardMap.default;
  const showDownloadButton = hasDownloadButton(type) && onDownload;
  const contentRef = useRef(null);
  const adaptedData = useMemo(() => {
    return preprocessed ? data : convertData(type, data, parameters) || {};
  }, [ type, data, parameters, preprocessed ]);

  const adaptedParameters = useMemo(() => {
    return preprocessed ? parameters : {
      ...(getParametersFromData(data)),
      ...parameters,
    }
  }, [ data, parameters, preprocessed ]);

  const {
    extras = DEFAULTS.extras,
    periods = DEFAULTS.periods,
    updated_at = DEFAULTS.updated_at,
  } = data || {};

  const handleDownload = useCallback(() => {
    if(!contentRef.current) {
      return;
    }
    const anchor = document.createElement('a');
    anchor.setAttribute(
      'href',
      contentRef.current.chartInstance.toBase64Image()
    );
    anchor.setAttribute('download', title);
    anchor.click();
  }, [ title ]);

  const additionalExtras = useMemo(() => {
    if(!contentRef.current || !contentRef.current.getExtras) {
      return {};
    }
    return contentRef.current.getExtras();
  }, [ ]);

  const renderContent = useCallback(() => {
    return (
      <Content
        ref={contentRef}
        slug={periods.length > 0 ? `${id}_${periods[0]}` : id}
        onDownload={onDownload}
        parameters={adaptedParameters}
        data={adaptedData}
      />
    );
  }, [ id, adaptedParameters, adaptedData, periods, onDownload ]);

  return render({
    type,
    size,
    icon,
    period: periods[0],
    title,
    updated_at,
    extras: { ...additionalExtras, ...extras },
    actions,
    renderContent,
    renderExtras,
    showDownloadButton,
    handleDownload,
    parameters: adaptedParameters,
    data: adaptedData,
  });
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  parameters: PropTypes.object,
  data: propTypes.data,
  title: PropTypes.node,
  description: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.element
  ),
  extras: PropTypes.object,
  renderExtras: PropTypes.func,
  onDownload: PropTypes.func,
};

export { defaultRenderCard };
export default Card;
