/* Imports */
import React, {
  useMemo,
  useEffect,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useFeatureList } from 'components/FeatureSwitch';
import { usePermissionList } from 'components/PermissionSwitch';
import {
  Menu,
  Anchor,
  Modal,
} from 'antd';
import './style.less';
import { connect } from 'react-redux';
import {
  requestProposals,
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

import { ReactComponent as ReportingLogo } from './images/reporting.svg';
import { ReactComponent as RankingsLogo } from './images/rankings.svg';
import { ReactComponent as InitiativeLogo } from './images/initiative.svg';
import { ReactComponent as VolunteerLogo } from './images/volunteer.svg';
import { ReactComponent as ProposalLogo } from './images/proposal.svg';
import { ReactComponent as CollaborationsLogo } from './images/collaborations.svg';
import { ReactComponent as CourseLogo } from './images/course.svg';
import { ReactComponent as ContactUsLogo } from './images/icon-contact-us.svg';
import {
  InfoCircleOutlined,
} from "@ant-design/icons";

import { A } from 'aplanet-ui-kit';

const Nav = ({
  intl,
  location,
  proposals,
  requestProposals
}) => {
  const {
    organization,
  } = useOrganizations();

  // Constants
  const t = intl.messages;
  const path = location.pathname;
  const featureList = useFeatureList();
  const permissionList = usePermissionList();
  const selected = [path.split('/')[1]];
  const [ modalActive, setModalActive ] = useState(false)

  useEffect(() => {
    requestProposals(organization.slug);
  }, [
    organization.slug,
    requestProposals,
  ]);

  const {
    items,
  } = proposals;

  const pendingItems = useMemo(() => items.filter((i) => i.status === 'proposed' && i.submitter), [items])

  const contactEmail = useMemo(
    () => {
      return organization?.general_config?.contact_email
        || `${ t.contact_email_address }@aplanet.org`;
    },
    [organization, t]
  );

  return (
    <Anchor offsetTop={120}>
      <div className="Nav">
        <Menu mode="vertical" theme="light" className="Nav-menu" selectable={false} selectedKeys={selected}>
          {
            !permissionList.has('can_read_reports') || !featureList.has('dashboard')
            ? null
          : <Menu.Item className='reporting' key="reporting" icon={<ReportingLogo className="anticon"/>}>
              <Link to="/reporting">
                <span className="navLabel">{t.reporting}</span>
              </Link>
            </Menu.Item>
          }
          {
            !permissionList.has('can_read_reports') || !featureList.has('rankings')
            ? null
            : <Menu.Item className='rankings' key="rankings" icon={<RankingsLogo className="anticon"/>}>
                <Link to="/rankings">
                  <span className="navLabel">{t.rankings}</span>
                </Link>
              </Menu.Item>
          }
          <Menu.Item className='initiative' key="initiative" icon={<InitiativeLogo className="anticon"/>}>
            <Link to="/initiative">
              <span className="navLabel">{t.my_initiatives}</span>
            </Link>
          </Menu.Item>
          {
            !featureList.has('community')
            ? (
              <Menu.Item
                className='volunteer'
                key="volunteer"
                onClick={() => setModalActive(true)}
                icon={<VolunteerLogo className="anticon"/>}
              >
                <span className="navLabel">{t.volunteers}</span>
              </Menu.Item>
            ) : (
              !permissionList.has('can_manage_volunteers')
              ? null
              : <Menu.Item className='volunteer' key="volunteer" icon={<VolunteerLogo className="anticon"/>}>
                  <Link to="/volunteer">
                    <span className="navLabel">{t.volunteers}</span>
                  </Link>
                </Menu.Item>
            )
          }
          {
            !featureList.has('education') ? null :
              <Menu.Item key="course" icon={<CourseLogo className="anticon"/>}>
                <Link to="/course">
                  <span className="navLabel">{t.courses}</span>
                </Link>
              </Menu.Item>
          }
          {
            !featureList.has('proposals') ||
            !permissionList.has('can_manage_proposals')
              ? null
              : <Menu.Item className='proposal' key="proposal" icon={<ProposalLogo className="anticon"/>}>
                  <Link to="/proposal">
                    <span className="navLabel">{t.proposals}</span>
                    { pendingItems.length < 1 ? null :
                      <figure className='badge proposal-badge'>{pendingItems.length}</figure>
                    }
                  </Link>
                </Menu.Item>
          }
          {
            !featureList.has('collaborations') ||
            !permissionList.has('can_manage_collaborations')
              ? null
              : <Menu.Item className='collaboration' key="collaboration" icon={<CollaborationsLogo className="anticon"/>}>
                  <Link to="/collaboration">
                    <span className="navLabel">{t.collaborations_explore}</span>
                  </Link>
                </Menu.Item>
          }
          {
            !featureList.has('about_our_program') ? null :
              <Menu.Item key="about-our-program" icon={<InfoCircleOutlined className="about-our-program-icon anticon" />}>
                <Link to="/about-our-program">
                  <span className="navLabel">{t.about_our_program}</span>
                </Link>
              </Menu.Item>
          }
          <Menu.Item key="contact-us" icon={<ContactUsLogo className="anticon"/>}>
            <A href={`mailto:${ contactEmail }`}>
              <span className="navLabel">
                {t.contact_us}
              </span>
            </A>
          </Menu.Item>
        </Menu>
        <Modal
          visible={modalActive}
          centered
          title={t.feature_not_enabled}
          onCancel={() => setModalActive(false)}
          footer={[]}
          className={'modalComingSoon'}
          width={'auto'}
        >
          <div>
            {
              <div>{ t.feature_not_enabled_desc } <A href={`mailto:${ contactEmail }`}> { contactEmail } </A> </div>
            }
          </div>
        </Modal>
      </div>
    </Anchor>
  )
}

const mapStateToProps = ({
  proposals,
}) => ({
  proposals,
});

export default injectIntl(connect(mapStateToProps, {requestProposals})(withRouter(Nav)))
