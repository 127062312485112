import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import {
  requestOrgVolunteers,
  requestInitiatives,
  requestOrgCourses,
  requestCourseDetail,
  createCourse,
  closeCourse,
  addTeacherToCourse,
  removeTeacherFromCourse,
  addStudentToCourse,
  removeStudentFromCourse,
  addInitiativeToCourse,
  removeInitiativeFromCourse,
  editCourse
} from 'actions/api'
import {refreshAccessToken} from 'actions/auth'
import './style.less'
import ColumnMenu from 'components/ColumnMenu'
import FloatingButton from 'components/FloatingButton'
import MainLayout from 'components/MainLayout';

import useOrganizations from 'utils/useOrganizations'

import {
  Spin,
  Modal,
  Input,
} from 'antd'

const sortByClosed = (a, b) => {
  if(a.status === 'closed' && b.status !== 'closed'){
    return 1
  }else if(a.status !== 'closed' && b.status === 'closed'){
    return -1
  }else{
    return a.name < b.name ? -1 : 1
  }
}

const Course = ({
  intl,
  api_requests,
  taxonomies,
  course_detail,
  initiatives,
  volunteers,
  requestOrgCourses,
  requestCourseDetail,
  closeCourse,
  refreshAccessToken,
  setOrganizationCohorts,
  setMemberCohorts,
  removeCohort,
  createCourse,
  addTeacherToCourse,
  removeTeacherFromCourse,
  addStudentToCourse,
  removeStudentFromCourse,
  addInitiativeToCourse,
  removeInitiativeFromCourse,
  requestOrgVolunteers,
  requestInitiatives,
  editCourse,
}) => {
  const {
    organization,
  } = useOrganizations();

  // Request all info
  useEffect(() => {
    requestOrgCourses(organization.slug);
    requestOrgVolunteers(organization.slug);
    requestInitiatives(organization.slug);
  }, [
    organization.slug,
    requestOrgCourses,
    requestOrgVolunteers,
    requestInitiatives,
  ])

  // Traductions
  const t = intl.messages

  // Taxonomies
  const {
    courses = [],
    loading,
    error,
  } = taxonomies;

  // Course_details
  const {
    data: detail,
    loading: loadingGroupDetail,
    error: errorGroupDetail,
  } = course_detail;

  // Check items functions
  const isTeacher = useCallback((item) => {
    if(!detail || !detail.teachers) return false
    return detail.teachers.filter(teacher => teacher.slug === item.slug).length > 0
  },[detail])

  const isStudent = useCallback((item) => {
    if(!detail || !detail.students) return false
    return detail.students.filter(student => student.slug === item.slug).length > 0
  },[detail])

  const initiativeOnCourse = useCallback((item) => {
    if(!detail || !detail.initiatives) return false
    return detail.initiatives.filter(initiative => initiative.slug === item.slug).length > 0
  },[detail])

  // Initiatives
  const {
    items: allInitiatives,
    loading: loadingInitiatives,
    error: errorInitiatives,
  } = initiatives;


  // Volunteers
  const {
    items: allVolunteers,
    loading: loadingVolunteers,
    error: errorVolunteers,
  } = volunteers;
  const admins = useMemo(() => allVolunteers.filter((initiative) => initiative.role === 'admin'),[allVolunteers])

  const loadingDetail = loadingGroupDetail || loadingInitiatives || loadingVolunteers;
  const errorDetail = errorGroupDetail || errorInitiatives || errorVolunteers;

  // Save on memo members info
  const data = useMemo(() => courses.sort(sortByClosed).map((course, index) => ({
    key: index,
    name: course.name,
    slug: course.slug,
    code: (course.params || {}).code,
    teachers: course.manager_count,
    students: course.member_count,
    initiatives: course.initiative_count,
    edit: index,
    status: course.status,
    actions: course.status,
    id: course.id
  })), [courses])

  const itemDetail = useMemo(() => (
    !course_detail.data ? null : {
      ...course_detail.data,
      code: (course_detail.data.params && course_detail.data.params.code) ? course_detail.data.params.code : null
    }
  ),[course_detail])

  // Add course modal
  const [addCourseModal, setAddCourseModal] = useState(false)
  const [newCourseName, setNewCourseName] = useState('')
  const [newCourseCode, setNewCourseCode] = useState('')
  const [creating, setCreating] = useState(false)

  // Listen courses response to show error/success
  useEffect(() => {
    if(!loading && creating){
      if(error){
        let errorMsg = error === 500
          ? t.course_error_500
          : t.course_error_generic

        Modal.error({
          title: t.course_error,
          content: errorMsg
        })
      }else{
        setAddCourseModal(false)
      }
      setCreating(false)
    }
  }, [ loading, error, creating, t ])

  const createNewCourse = useCallback(() => {
    if(creating) {
      return
    }
    createCourse(organization.slug, newCourseName, newCourseCode)
    setNewCourseName('')
    setNewCourseCode('')
    setCreating(true)
  }, [ creating, newCourseName, newCourseCode, createCourse, organization ]);

  const addCourse = useCallback(() => {
    if(newCourseName !== '' && newCourseCode !== ''){
      const codeRepeated = data.filter((course) => course.code === newCourseCode).length > 0
      if(codeRepeated) return Modal.info({ title: t.course_error, content: t.course_error_500 })
      createNewCourse()
    }else{
      Modal.info({ title: t.course_error, content: t.course_error_needData })
    }
  },[createNewCourse, data, newCourseCode, newCourseName, t.course_error, t.course_error_500, t.course_error_needData])

  const renderAddCourseModal = useMemo(() => (
    <Modal
      title={t.courses_addNewCourse}
      visible={addCourseModal}
      onCancel={() => setAddCourseModal(false)}
      width={500}
      footer={false}
    >
      <section className='newCourseName'>
        <span>{t.name}</span>
        <Input value={newCourseName} onChange={(term) => setNewCourseName(term.target.value)} size='large' placeholder={t.courses_courseName} allowClear="allowClear"/>
      </section>
      <section className='newCourseCode'>
        <span>{t.courses_code}</span>
        <Input value={newCourseCode} onChange={(term) => setNewCourseCode(term.target.value)} size='large' placeholder={t.courses_courseCode} allowClear="allowClear"/>
      </section>
      <span className='saveNewCourse' onClick={() => addCourse()}>{t.save}</span>
      <span className='cancelNewCourse' onClick={() => setAddCourseModal(false)}>{t.cancel}</span>
    </Modal>
  ),[addCourseModal, newCourseCode, newCourseName, t, addCourse])

  const sortStudents = useCallback((a, b) => {
    if(isStudent(a) && !isStudent(b)){
      return -1
    }else if(!isStudent(a) && isStudent(b)){
      return 1
    }else{
      return a.name - b.name
    }
  },[isStudent])

  const sortTeachers = useCallback((a, b) => {
    if(isTeacher(a) && !isTeacher(b)){
      return -1
    }else if(!isTeacher(a) && isTeacher(b)){
      return 1
    }else{
      return a.name - b.name
    }
  },[isTeacher])

  const sortInitiatives = useCallback((a, b) => {
    if(initiativeOnCourse(a) && !initiativeOnCourse(b)){
      return -1
    }else if(!initiativeOnCourse(a) && initiativeOnCourse(b)){
      return 1
    }else{
      return a.title - b.title
    }
  },[initiativeOnCourse])

  const renderCourse = useMemo(() => (
    <React.Fragment>
      <ColumnMenu
        withBulk={{left:false, right:true}}
        data={data}
        dataTitle={{
          avatar: false,
          title: ['name', 'code']
        }}
        dataSubTitle={{
          students: {avatar: true, title: ['name'], secondTitle: ['email'], thirdTitle: ['phone']},
          teachers: {avatar: true, title: ['name'], secondTitle: ['email'], thirdTitle: ['phone']},
          initiatives: {avatar: false, title: ['title'], secondTitle: ['address']}
        }}
        requestSelected={requestCourseDetail}
        organization={organization}
        itemTitle={t.name}
        itemContent={[
          {type:'number', name: 'students', items: admins},
          {type:'number', name: 'teachers', items: admins},
          {type:'number', name: 'initiatives', items: allInitiatives}
        ]}
        sortContent={{
          students: sortStudents,
          teachers: sortTeachers,
          initiatives: sortInitiatives,
        }}
        itemSubContent={[{
          type:'action',
          name: t.add,
          secondName: t.added,
          second:{
            students: isStudent,
            teachers: isTeacher,
            initiatives: initiativeOnCourse,
          },
          action: {
            students: (courseSlug, studentSlug) => addStudentToCourse(organization.slug, courseSlug, studentSlug),
            teachers: (courseSlug, teacherSlug) => addTeacherToCourse(organization.slug, courseSlug, teacherSlug),
            initiatives: (courseSlug, initiativeSlug) => addInitiativeToCourse(organization.slug, courseSlug, initiativeSlug),
          },
          secondAction: {
            students: (courseSlug, studentSlug) => removeStudentFromCourse(organization.slug, courseSlug, studentSlug),
            teachers: (courseSlug, teacherSlug) => removeTeacherFromCourse(organization.slug, courseSlug, teacherSlug),
            initiatives: (courseSlug, initiativeSlug) => removeInitiativeFromCourse(organization.slug, courseSlug, initiativeSlug),
          }
        }]}
        removeItem={(courseSlug) => closeCourse(organization.slug, courseSlug)}
        editItem={(courseSlug, name, code) => editCourse(organization.slug, courseSlug, name, code)}
        itemDetail={itemDetail}
        loadingDetail={loadingDetail}
        errorDetail={errorDetail}
      />
      { renderAddCourseModal }
    </React.Fragment>
  ),[addInitiativeToCourse, addStudentToCourse, addTeacherToCourse, allInitiatives, admins, data, itemDetail, errorDetail, initiativeOnCourse, isStudent, isTeacher, loadingDetail, organization, removeInitiativeFromCourse, removeStudentFromCourse, removeTeacherFromCourse, renderAddCourseModal, requestCourseDetail, sortInitiatives, sortStudents, sortTeachers, closeCourse, editCourse, t])

  return (
    <MainLayout
      errorMessage={intl.formatMessage({id: 'error_boundary_courses_message'})}
    >
      <div style={{padding: '0 25px'}}>
        <section className='Course-header'>
          <h1 className='Course-title'>{ t.courses }</h1>
        </section>
        <div className="Whitespace"></div>
        <FloatingButton onClick={() => setAddCourseModal(true)} iconUrl='images/white-plus.svg'/>
        { loading && !data
          ? <Spin size="large"/>
          : null
        }
        { !data ? null : renderCourse }
      </div>
    </MainLayout>
)}

const mapStateToProps = ({
  api_requests,
  taxonomies,
  course_detail,
  volunteers,
  initiatives,
}) => ({
  api_requests,
  taxonomies,
  course_detail,
  volunteers,
  initiatives,
})

export default injectIntl(
  connect(
    mapStateToProps,
    {
      requestOrgCourses,
      requestCourseDetail,
      createCourse,
      closeCourse,
      addTeacherToCourse,
      removeTeacherFromCourse,
      addStudentToCourse,
      removeStudentFromCourse,
      addInitiativeToCourse,
      removeInitiativeFromCourse,
      refreshAccessToken,
      requestOrgVolunteers,
      requestInitiatives,
      editCourse,
    }
  )(Course)
)
