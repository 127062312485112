import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import { A } from 'aplanet-ui-kit';

const Contact = ({ intl, contact_person, contact_email, contact_phone }) => {
  const t = intl.messages;

  const data = useMemo(() => {
    const mailto_link = `mailto:${contact_email}`;
    const tel_link = `tel:${contact_phone}`;
    return [
      {title: contact_person, iconType: "user"},
      {title: contact_email, href: mailto_link, iconType: "mail"},
      {title: (contact_phone || t.phone_not_available) , href: (contact_phone ? tel_link : undefined) , iconType: "phone"}
    ];
  }, [contact_person, contact_phone, contact_email, t]);

  return (
    <section className='actionContact'>
      <div className='actionContact-title'>{t.contact_details_public}</div>
      { data.map((item, i) => (
        <div key={`contact_${i}`}>
          { item.href
              ? <A className='actionContact-item link' href={item.href} newWindow>{item.title}</A>
              : <div className='actionContact-item'>{item.title}</div>
          }
        </div>
      ))}
    </section>
  );
};

export default injectIntl(Contact);
