import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import InitiativeIndicators from 'containers/InitiativeIndicators';
import InitiativeCollaborators from 'containers/InitiativeCollaborators';
import InitiativeTags from 'containers/InitiativeTags';
import InitiativeDocuments from 'containers/InitiativeDocuments';
import InitiativeImages from 'containers/InitiativeImages';
import When from '../cards/When';
import Organizer from '../cards/Organizer';
import Description from '../cards/Description';
import Categories from '../cards/Categories';
import Address from '../cards/Address';
import Contact from '../cards/Contact';

import { useFeatureList } from 'components/FeatureSwitch';
import { usePermissionList } from 'components/PermissionSwitch';
import PendingCountBadge from 'components/PendingCountBadge';

import { Tabs, Row, Col } from 'antd';

const Informative = ({
  intl,
  push,
  slug,
  action,
  organization,
  initiative,
  admin_profile,
  taxonomies,
  requestInitiativeDetail,
  cancelInitiative,
  requestCollaboration,
  updateCollaboration,
  cancelCollaborationRequest,
  stopCollaboration,
  isOrganizer,
  isCollaborator,
  loading,
  loading_member_id,
  canEdit,
  location,
}) => {
  const t = intl.messages;
  const featureList = useFeatureList();
  const permissionList = usePermissionList();
  const activeKey = useMemo(() => {
    return location.pathname.split('/').splice(-1,1).toString()
  }, [ location.pathname ]);

  return (
    <Tabs
      className='initiativeTabs'
      defaultActiveKey="details"
      onChange={key => push(`/initiative/${slug}/${key}`)}
      activeKey={activeKey}
      renderTabBar={(props) =>
        <Row type="flex" className='actionTabs'>
          { props.panes.filter(tab => !!tab).map((tab) => (
            <Col
              key={tab.key}
              className={'tab ' + (props.activeKey === tab.key ? 'active' : '')}
              onClick={() => props.onTabClick(tab.key)}>
                {tab.props.tab}
                <PendingCountBadge initiative={initiative} tab={tab} />
            </Col>
          ))}
        </Row>
    }>
      <Tabs.TabPane tab={ t.details } key="details">
        <Row type="flex" gutter={50} className='actionDetails'>
          <Col xs={24} md={18}>
            <Row>
              <Description {...initiative} />
            </Row>
            <Row>
              <Col lg={24} xl={11}>
                <Categories {...initiative} />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={6}>
            <When {...initiative} />
            <Address {...initiative} />
            <Contact {...initiative} />
            <Organizer {...initiative} />
            {
              !canEdit && (!initiative.tags || initiative.tags.length === 0)
              ? null
              : (
                <InitiativeTags
                  loading={loading}
                  initiative_slug={initiative.slug}
                  {...initiative}
                  canEdit={canEdit}
                />
              )
            }
          </Col>
        </Row>
      </Tabs.TabPane>
      {
        !isOrganizer || (
          !initiative.collaborative &&
          (initiative.collaborators || []).length === 0
        ) ||
        !permissionList.has('can_manage_collaborations')
        ? null : (
          <Tabs.TabPane tab={ t.collaborators } key="collaborators">
            <InitiativeCollaborators loading={loading} {...initiative} />
          </Tabs.TabPane>
        )
      }
      { (isOrganizer || isCollaborator) && featureList.has('impact') &&
        <Tabs.TabPane tab={ t.indicators } key="indicators">
          <InitiativeIndicators
            initiative={initiative}
            organization={organization} />
        </Tabs.TabPane>
      }
      { (isCollaborator || isOrganizer) && !organization?.config?.hide_initiative_images &&
      <Tabs.TabPane tab={ t.initiative_images } key="images">
        <InitiativeImages
          canEdit={canEdit}
          initiative={initiative}
          organization={organization}
        />
      </Tabs.TabPane>
      }
      { (isCollaborator || isOrganizer) && !organization?.config?.hide_initiative_documents &&
      <Tabs.TabPane tab={ t.initiative_documents } key="documents">
        <InitiativeDocuments
          canEdit={canEdit}
          initiative={initiative}
          organization={organization}
        />
      </Tabs.TabPane>
      }
    </Tabs>
  )
}

export default injectIntl(withRouter(Informative));
