import React, {useState, useMemo} from 'react';
import { injectIntl } from 'react-intl';

import {
  Button,
} from 'antd';
import {CustomTextArea} from "aplanet-ui-kit";

const MAX_DESCRIPTION_CHARACTERS = 300

const Description = ({ intl, description }) => {
  const t = intl.messages;
  const [forceShowDescription, setForceShowDescription] = useState(false)

  const isDescriptionLong = useMemo(
    () => description.length > MAX_DESCRIPTION_CHARACTERS,
    [description]
  );

  return (
    <section className='actionDescription'>
      <div className='actionDescription-title'>{t.description}</div>
      <div className={!forceShowDescription && 'actionDescription-description-showMore'}>
        <CustomTextArea
          intl={intl}
          readOnly
          value={description}
        />
      </div>
      <section className='actionDescription-showMore'>
        {isDescriptionLong &&
        <>
          {!forceShowDescription &&
          <figure className='actionDescription-shadow'></figure>
          }
          <Button onClick={() => setForceShowDescription(!forceShowDescription)}>
            <span>{forceShowDescription ? t.showLess : t.showMore}</span>
          </Button>
        </>
        }
      </section>
    </section>
  );
};

export default injectIntl(Description);
