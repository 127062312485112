import React, {useRef} from 'react';
import {injectIntl} from 'react-intl';

import SelectableCard from 'components/SelectableCard';

import {
  Card,
  Alert,
} from 'antd';
import {
  QrcodeOutlined,
  EditOutlined,
} from '@ant-design/icons';

const ParticipationMethod = ({
  intl,
  organization,
  taxonomies,
  loading,
  error,
  onSubmit,
  onRejectProposal,
  defaultValues,
  handleChange,
  showError,
  errors,
  getValue,
}) => {
  const t = intl.messages;

  const errorRefs = {
    participation_method: useRef(null)
  };

  return (
    <Card className="InitiativeForm-card" title={<span > <span className="InitiativeForm-required-star">*</span>
        {t.how_to_validate}</span>} extra={showError('participation_method')
        ? <Alert type="error" message={errors.participation_method}/>
        : null}>
      <div ref={errorRefs.participation_method}></div>
      <SelectableCard.Container>
        <SelectableCard
          title={t.scan_qr}
          type="qr"
          icon={<QrcodeOutlined className='SelectableCard__icon' />}
          onSelect={handleChange('participation_method')}
          selected={getValue('participation_method') === 'qr'}
        >
          <div>{t.scan_qr_instructions}</div>
          <div>{t.auto_assistance_validation}</div>
        </SelectableCard>
        <SelectableCard
          title={t.report_hours}
          type="report_hours"
          icon={<EditOutlined className='SelectableCard__icon' />}
          onSelect={handleChange('participation_method')}
          selected={getValue('participation_method') === 'report_hours'}
        >
          <div>{t.report_hours_instructions}</div>
          <div>{t.manual_assistance_validation}</div>
        </SelectableCard>
      </SelectableCard.Container>
    </Card>
  );
}

export default injectIntl(ParticipationMethod);
