import React from 'react';
import mime from 'mime-types';

import { ReactComponent as DocxIcon } from './icons/file-docx.svg';
import { ReactComponent as ImageIcon } from './icons/file-image.svg';
import { ReactComponent as PdfIcon } from './icons/file-pdf.svg';
import { ReactComponent as PptxIcon } from './icons/file-pptx.svg';
import { ReactComponent as PsIcon } from './icons/file-ps.svg';
import { ReactComponent as TxtIcon } from './icons/file-txt.svg';
import { ReactComponent as XlsxIcon } from './icons/file-xlsx.svg';
import { ReactComponent as OtherIcon } from './icons/file-other.svg';


const DEFAULT_ICON_PER_TYPE = {
  docx: DocxIcon,
  jpeg: ImageIcon,
  gif: ImageIcon,
  pdf: PdfIcon,
  png: ImageIcon,
  pptx:PptxIcon,
  ps: PsIcon,
  svg: ImageIcon,
  txt: TxtIcon,
  xlsx: XlsxIcon,
  default: OtherIcon,
};

const FileIcon = ({
  mimetype,
}) => {
  const type = mime.extension(mimetype);
  const Icon = DEFAULT_ICON_PER_TYPE[type] || DEFAULT_ICON_PER_TYPE.default;

  return <Icon />;
};

export default FileIcon;
