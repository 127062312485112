const refreshAccessToken = () => ({
  type: 'REFRESH_TOKEN_REQUEST',
});

const requestIdentity = () => ({
  type: 'IDENTITY_REQUEST',
});

export {
  refreshAccessToken,
  requestIdentity,
}
