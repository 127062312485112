import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import { formatDateLong } from 'utils/date';

import {
  Modal,
  Table,
  Spin,
} from 'antd';

const ParticipantIndicators= ({
  intl,
  visible,
  participation,
  onCancel,
}) => {
  const t = intl.messages;

  const getActions = useCallback(({id, status}) => {
    switch(status) {
      case 'validated':
        return [ <span className="InitiativeParticipant-validated">{ t.validated }</span> ];
      case 'rejected':
        return [ <span className="InitiativeParticipant-rejected">{ t.rejected }</span> ];
      case 'pending':
      default:
        return [ <span className="InitiativeParticipant-pending">{ t.pending }</span> ];
    };
  }, [
    t,
  ]);

  const columns = useMemo(
    () => [
      {
        title: t.name,
        dataIndex: 'indicator_name',
        key: 'indicator_name',
      },
      {
        title: t.value,
        dataIndex: 'value',
        key: 'value',
        render: (value, {unit}) => <span>{`${value} ${t[`unit_${unit}_symbol`]}`}</span>
      },
      {
        title: t.reported_date,
        width: 130,
        dataIndex: 'created_at',
        render: (date) => formatDateLong(date, intl)
      },
      {
        title: t.status,
        width: 120,
        render: getActions,
      }
    ],
    [t, getActions, intl]
  );

  const sortedParticipations = useMemo(
    () => {
      if (!participation) {
        return [];
      }

      const statusPriority = {
        'rejected': 3,
        'validated': 2,
        'pending': 1,
      }
      return participation.participations.sort(
        (a, b) => statusPriority[a.status] > statusPriority[b.status] ? 1 : -1
      );
    },
    [participation]
  )

  return (
    <Modal
      title={`${t.indicator_replies_for_member} ${participation ? participation.name : ''}`}
      visible={visible}
      width={800}
      centered
      footer={null}
      onCancel={onCancel}
    >
      {!participation
        ? <Spin />
        : <Table
            columns={columns}
            dataSource={sortedParticipations}
            rowKey={(_, index) => `row_${index}`}
            rowClassName={(_, index) => index % 2 ? 'Table-row-odd' : 'Table-row-even'}
          />
      }
    </Modal>
  )
};

export default injectIntl(ParticipantIndicators);
