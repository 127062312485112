import { findTree } from 'utils/tree';

const TARGET = 'organization_tree';

const initialState = {
  current_id: 0,
  current_slug: '',
  tree: null,
  fetching: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (!response || response.target !== TARGET) return state;
      const tree = action.response.result;

      const topNode = findTree(
          Array.isArray(tree) ? tree : [tree],
          node => node.permissions && node.permissions.can_read_initiatives
        ) || tree;

      const current_id = topNode.id || state.current_id;
      const current_slug = topNode.slug || state.current_slug;

      return {
        ...state,
        current_id,
        current_slug,
        tree,
        fetching: false,
        error: null
      };
    case 'API_CALL_FAILED':
      if (!action.request || action.request.target !== TARGET) return state;
      const { code, text } = action;
      return {
        ...state,
        fetching: false,
        error: {
          code,
          text
        }
      };
    case 'SWITCH_CURRENT_ORGANIZATION':
      return {
        ...state,
        current_id: action.organization.id,
        current_slug: action.organization.slug,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export { reducer as organization_tree };
