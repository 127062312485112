const VOLUNTEERS_TARGET = 'volunteers';
const VOLUNTEERS_DETAIL_TARGET = 'volunteer_detail';
const VOLUNTEERS_BLOCK_TARGET = 'volunteer_block';
const VOLUNTEERS_ASSIGN_MANAGER_TARGET = 'volunteer_manager';
const VOLUNTEERS_DELETE_TARGET = 'volunteer_delete';

const TAXONOMIES_TARGET = 'taxonomies'
const TAXONOMIES_COHORT_TARGET = 'taxonomies_cohort' // Target for cohorts edits

const PARTICIPATION_NEWPARTICIPANT = 'participant_new';

const ID_FIELD = 'id'; // TODO: Change to slug if we ever stop exposing numerical ids

const isTarget = (target) => [
  VOLUNTEERS_TARGET,
  VOLUNTEERS_DETAIL_TARGET,
  VOLUNTEERS_BLOCK_TARGET,
  VOLUNTEERS_ASSIGN_MANAGER_TARGET,
  TAXONOMIES_TARGET,
  TAXONOMIES_COHORT_TARGET,
  PARTICIPATION_NEWPARTICIPANT,
  VOLUNTEERS_DELETE_TARGET
].includes(target);

const isVolunteersTarget = (target) => target === VOLUNTEERS_TARGET;
const isVolunteersDetailTarget = (target) => target === VOLUNTEERS_DETAIL_TARGET;
const isVolunteersBlockTarget = (target) => target === VOLUNTEERS_BLOCK_TARGET;
const isVolunteersAssignManagerTarget = (target) => target === VOLUNTEERS_ASSIGN_MANAGER_TARGET;
const isTaxonomiesTarget = (target) => target === TAXONOMIES_TARGET;
const isTaxonomiesCohortTarget = (target) => target === TAXONOMIES_COHORT_TARGET;
const isNewParticipantTarget = (target) => target === PARTICIPATION_NEWPARTICIPANT;
const isVolunteersDeleteTarget = (target) => target === VOLUNTEERS_DELETE_TARGET;

const OLDCOHORT_ENDPOINT_REGEX = new RegExp('/cohort.*/([^/]+)/?$');

const initialState = {
  items: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;
      return {
        items: state.items, // When loading keep same items
          loading: true,
          error: false,
      };
    case 'API_CALL_COMPLETE':
      // No target, return state
      if (!action.response || !isTarget(action.response.target)) return state;

      // New participant (not existing) added
      if(isNewParticipantTarget(action.response.target) && ['POST'].includes(action.response.method) && action.response.result.new_member){
        return {
            items: [...state.items, action.response.result.new_member],
            loading: false,
            error: false
        }
      }

      // taxonomies_cohort target && put: Editing a cohort, renaming members with that cohort
      if (isTaxonomiesCohortTarget(action.response.target) && ['PUT'].includes(action.response.method)) {
        const oldCohort = OLDCOHORT_ENDPOINT_REGEX.exec(action.response.endpoint)
        const newItems = state.items.forEach(volunteer => {
          if (volunteer.cohorts.includes(oldCohort[1])) {
            volunteer.cohorts[volunteer.cohorts.indexOf(oldCohort[1])] = action.response.result.slug
          }
          return {
            items: newItems,
            loading: false,
            error: false
          }
        })
      }

      // taxonomies target && put: New cohorts, some cohorts may affect members
      if (isTaxonomiesTarget(action.response.target) && ['PUT'].includes(action.response.method)) {
        if (!action.response.result.member) return state
        return {
          items: action.response.result.member,
          loading: false,
          error: false
        }
      }

      // volunteers_detail target: Post or put a volunteer
      if (isVolunteersDetailTarget(action.response.target)) {
        const targetVolunteer = action.response.result;
        if (!targetVolunteer || !['POST', 'PUT'].includes(action.response.method)) {
          // This is not a request for me, bailing out
          return state;
        }

        // post: Add a new volunteer
        if (['POST'].includes(action.response.method)) {
          return {
            items: [...state.items, action.response.result],
            loading: false,
            error: false,
          }
        }

        // delete or put: Remove or modify a volunteer
        const index = state.items.findIndex((volunteer) => volunteer[ID_FIELD] === targetVolunteer[ID_FIELD]);
        if (index >= 0) {
          return {
            items: [...state.items.slice(0, index), targetVolunteer, ...state.items.slice(index + 1)],
            loading: false,
            error: false,
          };
        } else {
          return {
            ...state,
            loading: false,
            error: false,
          };
        }
      }

      // volunteers_block target: block or unblock a volunteer
      if (isVolunteersBlockTarget(action.response.target)) {
        const targetVolunteer = action.response.result;
        if (!targetVolunteer || !['PUT', 'DELETE'].includes(action.response.method)) {
          // This is not a request for me, bailing out
          return state;
        }

        // delete or put: Remove or modify a volunteer
        const index = state.items.findIndex((volunteer) => volunteer[ID_FIELD] === targetVolunteer[ID_FIELD]);
        if (index >= 0) {
          return {
            items: [...state.items.slice(0, index), targetVolunteer, ...state.items.slice(index + 1)],
            loading: false,
            error: false,
          };
        } else {
          return {
            ...state,
            loading: false,
            error: false,
          };
        }
      }

      // volunteers_delete target: delete volunteers
      if (isVolunteersDeleteTarget(action.response.target)) {
        const deletedVolunteer = action.response.result;
        const filteredState = [...state.items].filter(member => member.id !== deletedVolunteer.id);
        return {
          items: filteredState,
          loading: false,
          error: false,
        };
      }

      // volunteers_assign (or delete, though deletion is not implemented yet) manager
      if (isVolunteersAssignManagerTarget(action.response.target)) {
        const targetVolunteer = action.response.result;
        if (!targetVolunteer || !['PUT', 'DELETE'].includes(action.response.method)) {
          // This is not a request for me, bailing out
          return state;
        }

        // delete or put: Remove or modify a volunteer
        const index = state.items.findIndex((volunteer) => volunteer[ID_FIELD] === targetVolunteer[ID_FIELD]);
        if (index >= 0) {
          return {
            items: [...state.items.slice(0, index), targetVolunteer, ...state.items.slice(index + 1)],
            loading: false,
            error: false,
          };
        } else {
          return {
            ...state,
            loading: false,
            error: false,
          };
        }
      }

      // volunteers target: Get volunteers
      if (isVolunteersTarget(action.response.target)) {
        return {
          items: action.response.result,
          loading: false,
          error: false,
        };

      }

      // Target not found
      return state
    case 'API_CALL_FAILED':
      if (!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as volunteers,
};
