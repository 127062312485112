import React, { forwardRef } from 'react'

import UnknownChart from './UnknownChart';
import SingleNumber from './SingleNumber';
import Bars from './Bars';
import Pie from './Pie';
import Lines from './Lines';

const horizontalBarsMargin = {
  'top': 50,
  'right': 20,
  'bottom': 80,
  'left': 150
}

const verticalBarsMargin = {
  'top': 30,
  'right': 20,
  'bottom': 80,
  'left': 50
}

const chartsWeCanDownload = new Set([
  'vertical-bars',
  'horizontal-bars',
  'vertical-stacks',
  'horizontal-stacks',
  'pie',
  'donut',
  'ring',
  'smooth-lines',
  'sharp-lines',
]);

export const hasDownloadButton = (type) => type && chartsWeCanDownload.has(type);

export const cardMap = {
  'number': SingleNumber,
  'vertical-bars': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'vertical',
        groupMode: 'grouped',
        margin: verticalBarsMargin,
      }}
      {...props}
    />)),
  'horizontal-bars': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'horizontal',
        groupMode: 'grouped',
        margin: horizontalBarsMargin,
        leyends: []
      }}
      {...props}
    />)),
  'vertical-stacks': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'vertical',
        groupMode: 'stacked',
        margin: verticalBarsMargin
      }}
      {...props}
    />)),
  'horizontal-stacks': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'horizontal',
        groupMode: 'stacked',
        margin: horizontalBarsMargin,
        leyends: []
      }}
      {...props}
    />)),
  'pie': forwardRef(({ parameters, ...props }, ref) => (
    <Pie
      ref={ref}
      parameters={{
        ...parameters,
        innerRadius: 0,
      }}
      {...props}
    />)),
  'donut': forwardRef(({ parameters, ...props }, ref) => (
    <Pie
      ref={ref}
      parameters={{
        ...parameters,
        innerRadius: 0.4,
      }}
      {...props}
    />)),
  'ring': forwardRef(({ parameters, ...props }, ref) => (
    <Pie
      ref={ref}
      parameters={{
        ...parameters,
        innerRadius: 0.75,
      }}
      {...props}
    />)),
  'smooth-lines': forwardRef(({ parameters, ...props }, ref) => (
    <Lines
      ref={ref}
      parameters={{
        ...parameters,
        curve: 'monotoneX',
      }}
      {...props}
    />)),
  'sharp-lines': forwardRef(({ parameters, ...props }, ref) => (
    <Lines
      ref={ref}
      parameters={{
        ...parameters,
        curve: 'linear',
      }}
      {...props}
    />)),
  'default': UnknownChart,
};

export const iconMap = {
  'number': 'calculator',
  'vertical-bars': 'chart-bar',
  'horizontal-bars': 'chart-bar',
  'vertical-stacks': 'chart-bar',
  'horizontal-stacks': 'chart-bar',
  'pie': 'chart-pie',
  'donut': 'chart-pie',
  'ring': 'chart-pie',
  'smooth-lines': 'chart-line',
  'sharp-lines': 'chart-line',
  'default': 'question',
};

export const getParametersFromData = (data = {}) => {
  const {
    series = [],
    extras = {},
  } = data;

  return {
    index: 'id',
    keys: series,
    key: (series[0] || 'value'),
    ...( extras.color && { colors: extras.color } )
  };
};

export const convertData = (chart_type, data = {}, parameters = {}) => {
  const {
    series = [],
    keys = [],
    samples = [],
  } = data;

  const defaultParameters = getParametersFromData(data);

  const {
    index: indexName = defaultParameters.index,
    keys: keyNames = defaultParameters.keys,
    key: singleKeyName = defaultParameters.key,
  } = parameters;

  const colorsPerKey = defaultParameters.colors;

  if(samples.length === 0 || series.length === 0 || keys.length === 0) return []; // No data

  switch(chart_type) {
    case 'number':
      // More than 1 value? We compare
      if(samples.length > 1) {
        return series.map((serie, index) => ({
          [singleKeyName]: samples[0].values[index],
          period: samples[0].key,
          compare: samples[1].values[index],
          compare_period: samples[1].key,
        }));
      }

      // Only one value, we do not compare
      return series.map((serie, index) => ({
        [singleKeyName]: samples[0].values[index],
        period: serie,
      }));
    case 'vertical-bars':
    case 'vertical-stacks':
    case 'horizontal-bars':
    case 'horizontal-stacks':
      return samples.map(({ key, values = [] }) => {
        let valuesObject = {};
        keyNames.forEach((keyName, index) => {
          valuesObject[keyName] = values[index];
        });
        return {
          ...valuesObject,
          [indexName]: key,
          color: colorsPerKey,
        };
      });
    case 'pie':
    case 'donut':
    case 'ring':
      // More than one key and only one serie? Let's flip this
      if(samples.length > 1 && series.length === 1) {
        return samples.map(({ key, values = [] }) => {
          return {
            [indexName]: key,
            [singleKeyName]: values[0],
            color: colorsPerKey,
          };
        });
      }

      if(samples.length > 1) {
        console.log(`WARNING: misformatted information while converting for ${chart_type}`, samples);
      }
      return series.map((serie, index) => {
        return {
          [indexName]: serie,
          [singleKeyName]: samples[0].values[index],
          color: colorsPerKey,
        };
      });
    case 'smooth-lines':
    case 'sharp-lines':
      return series.map((serie, index) => {
        return {
          id: serie,
          data: samples.map(sample => ({
            x: sample.key,
            y: sample.values[index],
          })),
          color: colorsPerKey,
        };
      });
    default:
      return data;
  }
};
