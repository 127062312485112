import React, {
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
} from 'react';

import { Input } from 'antd';
import { parseIncompletePhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js/core'


const InputPhone = ({
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  country,
  metadata,
  error,
  inputRef,
}) => {
  const innerInputRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focus: () => {
      innerInputRef.current.focus();
    }
  }));

  const format = useCallback((value) => {
    return formatIncompletePhoneNumber(value, country, metadata);
  }, [country, metadata]);

  const handleChange = useCallback((event) => {
    let newValue = event.target.value
    let parsedValue = parseIncompletePhoneNumber(newValue);

    if(parsedValue === value) {
      if(format(parsedValue).indexOf(newValue) === 0) {
        parsedValue = parsedValue.slice(0, -1);
      }
    }

    onChange(parsedValue);
  }, [
    format,
    onChange,
    value,
  ]);

  const formattedValue = useMemo(() => format(value), [value, format]);

  return(
    <Input
      clear="true"
      placeholder={placeholder}
      className="PhoneInput-input"
      ref={innerInputRef}
      value={formattedValue}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={error}
      type="tel"
    />
  );
};


export default InputPhone;
