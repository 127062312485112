import React, { useState, useMemo, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { useHotkeys } from 'react-hotkeys-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterOutlined } from '@ant-design/icons';

import {
  rangeOverlap,
  formatDb,
} from 'utils/date';

import useSetState from 'utils/useSetState';
import SdgLogo from 'components/SdgLogo';

import {
  Input,
  Button,
  Row,
  Col,
  Drawer,
  DatePicker,
  Checkbox,
  Tag,
  Empty,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const getSorterByTranslation = (t, prefix = '') => (slug_a, slug_b) =>
   t[`${prefix}${slug_a}`].localeCompare(t[`${prefix}${slug_b}`]);

const getSorterByNumericalProp = (entityMap, propName = 'id') => (slug_a, slug_b) =>
  ( entityMap.get(slug_a)[propName] - entityMap.get(slug_b)[propName] );

const Filters = ({
  intl,
  data = [],
  pastData = [],
  sdgs: allSdgs,
  showInMap,
  toggleShowInMap,
  alreadyPassedText,
  emptyCardText,
  renderChildren = () => null,
}) => {
  const t = intl.messages;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [dateRangeValues, setDateRangeValues] = useState(['', '']);
  const [dateRange, setDateRange] = useState(null);

  const {
    set: filteredCategories,
    has: isCategoryFilterSet,
    toggle: toggleCategoryFilter,
    replaceAll: setFilteredCategories,
  } = useSetState([]);
  const categoryFilterSet = useMemo(() => filteredCategories.length > 0, [ filteredCategories ]);

  const {
    set: filteredCompetences,
    has: isCompetenceFilterSet,
    toggle: toggleCompetenceFilter,
    replaceAll: setFilteredCompetences,
  } = useSetState([]);
  const competenceFilterSet = useMemo(() => filteredCompetences.length > 0, [ filteredCompetences ]);

  const {
    set: filteredSdgs,
    has: isSdgFilterSet,
    toggle: toggleSdgFilter,
    replaceAll: setFilteredSdgs,
  } = useSetState([]);
  const sdgFilterSet = useMemo(() => filteredSdgs.length > 0, [ filteredSdgs ]);

  const {
    set: filteredTargetAudience,
    has: isTargetAudienceFilterSet,
    toggle: toggleTargetAudienceFilter,
    replaceAll: setFilteredTargetAudience,
  } = useSetState([]);
  const targetAudienceFilterSet = useMemo(() => filteredTargetAudience.length > 0, [ filteredTargetAudience ]);

  const {
    set: filteredType,
    has: isTypeFilterSet,
    toggle: toggleTypeFilter,
    replaceAll: setFilteredType,
  } = useSetState([]);
  const typeFilterSet = useMemo(() => filteredType.length > 0, [ filteredType ]);

  const {
    set: filteredStatus,
    has: isStatusFilterSet,
    toggle: toggleStatusFilter,
    replaceAll: setFilteredStatus,
  } = useSetState([]);
  const statusFilterSet = useMemo(() => filteredStatus.length > 0, [ filteredStatus ]);

  const {
    set: filteredCollaborationStatus,
    has: isCollaborationStatusFilterSet,
    toggle: toggleCollaborationStatusFilter,
    replaceAll: setFilteredCollaborationStatus,
  } = useSetState([]);
  const collaborationStatusFilterSet = useMemo(() => filteredCollaborationStatus.length > 0, [ filteredCollaborationStatus ]);

  const {
    set: filteredInitiativeTags,
    has: isInitiativeTagsFilterSet,
    toggle: toggleInitiativeTagsFilter,
    replaceAll: setFilteredInitiativeTags,
  } = useSetState([]);
  const initiativeTagsFilterSet = useMemo(
    () => filteredInitiativeTags.length > 0,
    [ filteredInitiativeTags ]
  );

  // Focus search field on Ctrl+F
  const searchInputRef = useRef();
  useHotkeys('ctrl+f', (e) => {
    if(searchInputRef.current.focus) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  }, [ searchInputRef ]);

  const sdgMap = useMemo(() => {
    return new Map(allSdgs.map(sdg => [sdg.slug, sdg]));
  }, [ allSdgs ]);

  const taxonomies = useMemo(() => {
    let categories = new Set();
    let competences = new Set();
    let sdgs = new Set();
    let targetAudience = new Set();
    let initiativeTags = new Set();
    let status = new Set();
    let collaborationStatus = new Set();
    let type = new Set();
    const allData = [...data, ...pastData];

    for(let item of allData) {
      item.categories.forEach(categories.add, categories);
      (item.competence || []).forEach(competences.add, competences);
      item.sdgs.forEach(sdgs.add, sdgs);
      (item.target_audience || []).forEach(targetAudience.add, targetAudience);
      (item.tags || []).forEach(initiativeTags.add, initiativeTags);
      status.add(item.status);
      type.add(item.type);
      if(item.collaboration_status !== 'organizer') {
        collaborationStatus.add(item.collaboration_status || 'NA');
      }
    }

    return {
      categories: Array.from(categories).sort(getSorterByTranslation(t, 'category_')),
      competences: Array.from(competences).sort(getSorterByTranslation(t, 'competence_')),
      sdgs: Array.from(sdgs).sort(getSorterByNumericalProp(sdgMap, 'id')),
      targetAudience: Array.from(targetAudience).sort(getSorterByTranslation(t, 'target_audience_')),
      initiativeTags: Array.from(initiativeTags).sort(),
      type: Array.from(type).sort(getSorterByTranslation(t)),
      status: Array.from(status).sort(getSorterByTranslation(t)),
      collaboration_status: Array.from(collaborationStatus).sort(getSorterByTranslation(t, 'collaboration_status_')),
    }
  }, [ data, pastData, sdgMap, t ]);

  const disableTaxonomyFilters = useMemo(() => (
    Object.keys(taxonomies).filter(taxonomy => taxonomies[taxonomy].length > 0).length === 0
  ), [ taxonomies ]);

  const skipTaxonomyFilters = useMemo(() => (
    !categoryFilterSet && !competenceFilterSet && !sdgFilterSet &&
    !targetAudienceFilterSet && !initiativeTagsFilterSet && !typeFilterSet &&
    !statusFilterSet && !collaborationStatusFilterSet
  ), [
    categoryFilterSet,
    competenceFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    initiativeTagsFilterSet,
    typeFilterSet,
    statusFilterSet,
    collaborationStatusFilterSet,
  ]);

  const filteredData = useMemo(() => {
    // Bail out early if no filters are set
    if(!searchText &&
       !dateRange &&
       !categoryFilterSet &&
       !competenceFilterSet &&
       !sdgFilterSet &&
       !targetAudienceFilterSet &&
       !initiativeTagsFilterSet &&
       !typeFilterSet &&
       !statusFilterSet &&
       !collaborationStatusFilterSet
      ) return data;

    return data.filter(({
      title,
      type,
      status,
      collaboration_status,
      description,
      address,
      organization_name,
      collaborators = [],
      start_time,
      end_time,
      categories = [],
      competences = [],
      sdgs = [],
      target_audience = [],
      tags = [],
    }) => {
      const searchMatch = (
        !searchText ||
        (title || '').toLowerCase().includes(searchText) ||
        (description || '').toLowerCase().includes(searchText) ||
        (address || '').toLowerCase().includes(searchText) ||
        (organization_name || '').toLowerCase().includes(searchText) ||
        collaborators.find(({ name }) => (name || '').toLowerCase().includes(searchText))
      );

      const dateMatch = (
        !dateRange ||
        rangeOverlap(
          [
            `${dateRange[0]}T00:00:00.000Z`,
            `${dateRange[1]}T23:59:59.999Z`,
          ],
          [
            formatDb(start_time), // NOTICE: this should solve timezones...
            formatDb(end_time),
          ]
        )
      );

      const categoryMatch = (
        !categoryFilterSet ||
        categories.find(isCategoryFilterSet)
      );

      const competenceMatch = (
        !competenceFilterSet ||
        competences.find(isCompetenceFilterSet)
      );

      const sdgMatch = (
        !sdgFilterSet ||
        sdgs.find(isSdgFilterSet)
      );

      const typeMatch = (
        !typeFilterSet ||
        [type].find(isTypeFilterSet)
      );

      const statusMatch = (
        !statusFilterSet ||
        [status].find(isStatusFilterSet)
      );

      const collaborationStatusMatch = (
        !collaborationStatusFilterSet ||
        [collaboration_status || 'NA'].find(isCollaborationStatusFilterSet)
      );

      const targetAudienceMatch = (
        !targetAudienceFilterSet ||
        target_audience.find(isTargetAudienceFilterSet)
      );

      const initiativeTagsMatch = (
        !initiativeTagsFilterSet ||
        tags.find(isInitiativeTagsFilterSet)
      );

      return searchMatch &&
        dateMatch &&
        (
          skipTaxonomyFilters || (
            (!categoryFilterSet || categoryMatch) &&
            (!competenceFilterSet || competenceMatch) &&
            (!sdgFilterSet || sdgMatch) &&
            (!targetAudienceFilterSet || targetAudienceMatch) &&
            (!initiativeTagsFilterSet || initiativeTagsMatch) &&
            (!typeFilterSet || typeMatch) &&
            (!statusFilterSet || statusMatch) &&
            (!collaborationStatusFilterSet || collaborationStatusMatch)
          )
        );
    })
  }, [
    data,
    searchText,
    dateRange,
    skipTaxonomyFilters,
    categoryFilterSet,
    competenceFilterSet,
    isCategoryFilterSet,
    isCompetenceFilterSet,
    isSdgFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    isTargetAudienceFilterSet,
    initiativeTagsFilterSet,
    isInitiativeTagsFilterSet,
    isTypeFilterSet,
    typeFilterSet,
    isStatusFilterSet,
    statusFilterSet,
    isCollaborationStatusFilterSet,
    collaborationStatusFilterSet,
  ]);

  const filteredPastData = useMemo(() => {
    if(!pastData) return []
    // Bail out early if no filters are set
    if(!searchText &&
       !dateRange &&
       !categoryFilterSet &&
       !competenceFilterSet &&
       !sdgFilterSet &&
       !targetAudienceFilterSet &&
       !initiativeTagsFilterSet &&
       !typeFilterSet &&
       !statusFilterSet &&
       !collaborationStatusFilterSet
     ) return pastData;

    return pastData.filter(({
      title,
      type,
      status,
      collaboration_status,
      description,
      address,
      organization_name,
      collaborators = [],
      start_time,
      end_time,
      categories = [],
      competences = [],
      sdgs = [],
      target_audience = [],
      tags = [],
    }) => {
      const searchMatch = (
        !searchText ||
        (title || '').toLowerCase().includes(searchText) ||
        (description || '').toLowerCase().includes(searchText) ||
        (address || '').toLowerCase().includes(searchText) ||
        (organization_name || '').toLowerCase().includes(searchText) ||
        collaborators.find(({ name }) => (name || '').toLowerCase().includes(searchText))
      );

      const dateMatch = (
        !dateRange ||
        rangeOverlap(
          [
            `${dateRange[0]}T00:00:00.000Z`,
            `${dateRange[1]}T23:59:59.999Z`,
          ],
          [
            formatDb(start_time), // NOTICE: this should solve timezones...
            formatDb(end_time),
          ]
        )
      );

      const categoryMatch = (
        !categoryFilterSet ||
        categories.find(isCategoryFilterSet)
      );

      const competenceMatch = (
        !competenceFilterSet ||
        competences.find(isCompetenceFilterSet)
      );

      const sdgMatch = (
        !sdgFilterSet ||
        sdgs.find(isSdgFilterSet)
      );

      const typeMatch = (
        !typeFilterSet ||
        [type].find(isTypeFilterSet)
      );

      const statusMatch = (
        !statusFilterSet ||
        [status].find(isStatusFilterSet)
      );

      const collaborationStatusMatch = (
        !collaborationStatusFilterSet ||
        [collaboration_status].find(isCollaborationStatusFilterSet)
      );

      const targetAudienceMatch = (
        !targetAudienceFilterSet ||
        target_audience.find(isTargetAudienceFilterSet)
      );

      const initiativeTagsMatch = (
        !initiativeTagsFilterSet ||
        tags.find(isInitiativeTagsFilterSet)
      );

      return searchMatch &&
        dateMatch &&
        (
          skipTaxonomyFilters ||
          (categoryFilterSet && categoryMatch) ||
          (competenceFilterSet && competenceMatch) ||
          (sdgFilterSet && sdgMatch) ||
          (targetAudienceFilterSet && targetAudienceMatch) ||
          (initiativeTagsFilterSet && initiativeTagsMatch) ||
          (typeFilterSet && typeMatch) ||
          (statusFilterSet && statusMatch) ||
          (collaborationStatusFilterSet && collaborationStatusMatch)
        );
    })
  }, [
    pastData,
    searchText,
    dateRange,
    skipTaxonomyFilters,
    categoryFilterSet,
    competenceFilterSet,
    isCategoryFilterSet,
    isCompetenceFilterSet,
    isSdgFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    isTargetAudienceFilterSet,
    initiativeTagsFilterSet,
    isInitiativeTagsFilterSet,
    typeFilterSet,
    isTypeFilterSet,
    statusFilterSet,
    isStatusFilterSet,
    collaborationStatusFilterSet,
    isCollaborationStatusFilterSet,
  ]);

  return (
    <React.Fragment>
      <Row className='Initiative-filtersBar' type="flex" gutter={20}>
        <Col style={{ flex: 1 }}>
          <Input.Search
            type="text"
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={ t.initiative_search_placeholder_collaborations }
            enterButton={ t.initiative_search }
            onSearch={(term) => setSearchText((term || '').toLowerCase())}
            onChange={(term) => !term.target.value && setSearchText('')}
            allowClear
            ref={searchInputRef}
            className="button"
          />
        </Col>
        <Col>
          <DatePicker.RangePicker
            open={datePickerOpen}
            onOpenChange={setDatePickerOpen}
            value={dateRangeValues}
            className='Initiative-datePicker'
            onChange={(dates, dateStrings) => {
              setDateRangeValues(dates);
              setDateRange(dateStrings.filter(Boolean).length === 0 ? null : dateStrings);
            }}
            renderExtraFooter={() =>
              <Button
                onClick={() => {
                  setDatePickerOpen(false);
                  setDateRangeValues(['', '']);
                  setDateRange(null);
                }}
                disabled={!dateRange}
                size={"small"}
              >{ t.clear }</Button>
            }
          />
        </Col>
        <Col>
          <Button
            className='Initiative-filtersButton'
            icon={<FilterOutlined />}
            disabled={disableTaxonomyFilters}
            onClick={() => setDrawerOpen(true)}
          />
        </Col>
          { toggleShowInMap
            ? <Col>
                <Button
                  className="button"
                  type="primary"
                  onClick={toggleShowInMap}
                >
                  { showInMap
                    ? <><FontAwesomeIcon icon="th-list"/> {t.show_in_list}</>
                    : <><FontAwesomeIcon icon="map-marked-alt"/> {t.show_in_map}</>
                  }
                </Button>
              </Col>
            : null
          }
      </Row>
      <Drawer
        title={t.initiative_filter_title}
        width={350}
        placement="right"
        closable={true}
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
      >
        { taxonomies.categories.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.categories }</h4>
            <div>
              <Checkbox
                checked={!categoryFilterSet}
                onChange={() => setFilteredCategories([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.categories.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isCategoryFilterSet(slug)}
                  onChange={() => toggleCategoryFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`category_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.competences.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.competences }</h4>
            <div>
              <Checkbox
                checked={!competenceFilterSet}
                onChange={() => setFilteredCompetences([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.competences.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isCompetenceFilterSet(slug)}
                  onChange={() => toggleCompetenceFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`competence_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.sdgs.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.sdg }</h4>
            <div>
              <Checkbox
                checked={!sdgFilterSet}
                onChange={() => setFilteredSdgs([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.sdgs.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isSdgFilterSet(slug)}
                  onChange={() => toggleSdgFilter(slug)}
                  style={{ display: 'block' }}
                >
                  <SdgLogo
                    sdg={slug}
                    className="Initiative-taxonomy-filter-sdg-logo"
                  />
                  { t[`sdg_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.type.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.type }</h4>
            <div>
              <Checkbox
                checked={!typeFilterSet}
                onChange={() => setFilteredType([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.type.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isTypeFilterSet(slug)}
                  onChange={() => toggleTypeFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`filter_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.status.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.initiative_status }</h4>
            <div>
              <Checkbox
                checked={!statusFilterSet}
                onChange={() => setFilteredStatus([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.status.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isStatusFilterSet(slug)}
                  onChange={() => toggleStatusFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[slug] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.collaboration_status.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.status }</h4>
            <div>
              <Checkbox
                checked={!collaborationStatusFilterSet}
                onChange={() => setFilteredCollaborationStatus([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.collaboration_status.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isCollaborationStatusFilterSet(slug)}
                  onChange={() => toggleCollaborationStatusFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`collaboration_status_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.targetAudience.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.target_audience }</h4>
            <div>
              <Checkbox
                checked={!targetAudienceFilterSet}
                onChange={() => setFilteredTargetAudience([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.targetAudience.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isTargetAudienceFilterSet(slug)}
                  onChange={() => toggleTargetAudienceFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`target_audience_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }

        { taxonomies.initiativeTags.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.tags }</h4>
            <div>
              <Checkbox
                checked={!initiativeTagsFilterSet}
                onChange={() => setFilteredInitiativeTags([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativeTags.map(name => (
                <Checkbox
                  key={name}
                  checked={isInitiativeTagsFilterSet(name)}
                  onChange={() => toggleInitiativeTagsFilter(name)}
                  style={{ display: 'block' }}
                >
                  { name }
                </Checkbox>
              )) }
            </div>
          </div>
        }

      </Drawer>
      { skipTaxonomyFilters ? null :
        <React.Fragment>
          <div className="Whitespace20"></div>
          <div>
            <div className="Initiative-filter">
              <span className="Initiative-filter-title">{ t.categories }: </span>
            { filteredCategories.map(slug =>
              <Tag
                key={slug}
                closable
                color="volcano"
                onClose={() => toggleCategoryFilter(slug)}
              >{ t[`category_${slug}`] }</Tag>
            ) }
          </div>
          <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.competences }: </span>
            { filteredCompetences.map(slug =>
              <Tag
                key={slug}
                closable
                color="purple"
                onClose={() => toggleCompetenceFilter(slug)}
              >{ t[`competence_${slug}`] }</Tag>
            ) }
          </div>
          <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.sdg }: </span>
            { filteredSdgs.map(slug =>
              <Tag
                key={slug}
                closable
                color={sdgMap.get(slug).color}
                onClose={() => toggleSdgFilter(slug)}
              >{ t[`sdg_${slug}`] }</Tag>
            ) }
          </div>
          <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.target_audience }: </span>
            { filteredTargetAudience.map(slug =>
              <Tag
                key={slug}
                closable
                color="green"
                onClose={() => toggleTargetAudienceFilter(slug)}
              >{ t[`target_audience_${slug}`] }</Tag>
            ) }
          </div>
          <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.type }: </span>
            { filteredType.map(slug =>
              <Tag
                key={slug}
                closable
                color="blue"
                onClose={() => toggleTypeFilter(slug)}
              >{ t[slug] }</Tag>
            ) }
          </div>
          <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.initiative_status }: </span>
            { filteredStatus.map(slug =>
              <Tag
                key={slug}
                closable
                color="red"
                onClose={() => toggleStatusFilter(slug)}
              >{ t[slug] }</Tag>
            ) }
          </div>
          <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.status }: </span>
            { filteredCollaborationStatus.map(slug =>
              <Tag
                key={slug}
                closable
                color="brown"
                onClose={() => toggleCollaborationStatusFilter(slug)}
              >{ t[`collaboration_status_${slug}`] }</Tag>
            ) }
          </div>
          <div className="Initiative-filter">
            <span className="Initiative-filter-title">{ t.tags }: </span>
            { filteredInitiativeTags.map(name =>
              <Tag
                key={name}
                closable
                color="magenta"
                onClose={() => toggleInitiativeTagsFilter(name)}
              >{ name }</Tag>
            ) }
          </div>
          </div>
        </React.Fragment>
      }
      { (!filteredData || filteredData.length < 1) && !showInMap
        ? <Empty
            className='big'
            image={'/images/empty-card.svg'}
            description={emptyCardText}
          />
        : renderChildren(filteredData, searchText)}
      { !filteredPastData.length ? null :
        <React.Fragment>
          <section className='initiativeDivider'>
            <figure className='line'/>
            <section className='alreadyPassed'>
              <span>{alreadyPassedText}</span>
              <figure>{filteredPastData.length}</figure>
            </section>
            <figure className='line'/>
          </section>
          {renderChildren(filteredPastData, searchText)}
        </React.Fragment>
      }
    </React.Fragment>
  );
}

export default injectIntl(Filters);
