//import { useMemo } from 'react';
import { useSelector } from 'react-redux';
//import { findTree } from 'utils/tree';

const useOrganizations = () => {
  const {
    data: organization,
    memberships,
  } = useSelector(state => state.organization);

  // NOTICE: Let's wait until suborganizations are a thing in Community
  /*
  const {
    tree: organizationTree,
    current_slug: currentSlug,
  } = useSelector(state => state.organization_tree);

  const suborganization = useMemo(() => {
    return findTree(
      [organizationTree],
      ({ slug }) => slug === currentSlug,
    );
  }, [
    organizationTree,
    currentSlug
  ]);

  const permissions = useMemo(() => {
    return suborganization ? suborganization.permissions : null;
  }, [
    suborganization,
  ]);
  */

  return {
    organization,
    memberships,
    //suborganization,
    //organizationTree,
    //permissions,
  };
};

export default useOrganizations;
